import { RANKS_CONST, RANKS_ORDER } from "~/constants/constants";

export default function ({ store, redirect, $config }) {
    if ($config.isEliteVoteDisabled) {
        return redirect('/partner-area/career-status');
    }
    if (!store.state?.application?.driver?.authUser) {
        return redirect('/nfts/products');
    } else if (!store.state?.application?.driver?.authUser?.isReferralLinkActive) {
        return redirect('/dashboard');
    } else if (RANKS_ORDER[store.state?.application?.driver?.authUser?.currentRankName] < RANKS_ORDER[RANKS_CONST.PLATIN]) {
        return redirect('/partner-area/career-status');
    }
}
