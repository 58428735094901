
import {copyToClipboard, isMobileDevice} from "@/core/helpers/GlobalHelpers";
import GYMButton from "@/components/utils/buttons/GYMButton";
import {
    MetaMaskInstallationStepsDesktop,
    MetaMaskInstallationStepsMobile
} from "@/constants/metamask-installation-process";
import {MetaWorldManager} from "@/core/services/map/MetaWorldManager";
import {getChainById} from "@/constants/networks";
import {diagLog} from "@/core/services/utils/Constants";
import {DataService} from "@/core/services/DataService";
import {PopupHelper} from "@/core/helpers/PopupHelper";
const METAMASK_CHECK_IF_INSTALLED = 'MetaMaskInstallationStep';

export default {
    name: "MetaMaskInstallationPopup",
    components: {GYMButton},
    props: {
        popupVisible: {
            type: Boolean,
            default: false
        }
    },
    data () {
        return {
            isMobile: isMobileDevice(),
            resizeHandler: () => {this.isMobile = isMobileDevice();},
            step: 1,
            isWindowEthereumExist: false
        };
    },
    computed: {
        metaMaskInstallationSteps () {
            return this.isMobile ? MetaMaskInstallationStepsMobile : MetaMaskInstallationStepsDesktop;
        }
    },
    watch: {
        isMobile () {
            this.step = 1;
        }
    },
    mounted () {
        window.addEventListener('resize', this.resizeHandler);

        if (!this.isMobile) {
            if (process.client && window.ethereum) {
                this.isWindowEthereumExist = true;
            }
            setTimeout(() => {
                const showModalKey = DataService.getFromLocalStorage(METAMASK_CHECK_IF_INSTALLED);
                if (showModalKey) {
                    this.$store.dispatch('application/popup-control/openMetaMaskInstallationPopup', true);
                    DataService.removeFromLocalStorage(METAMASK_CHECK_IF_INSTALLED);
                    this.step = 8;
                    if (!this.isWindowEthereumExist) {
                        setTimeout(() => {
                            PopupHelper.showErrorAlert(this.$t('MetaMask is not installed'));
                        }, 1000);
                    } else {
                        setTimeout(() => {
                            PopupHelper.showSuccessNotification(this.$t('You have successfully installed MetaMask. Now you can add a Network.'));
                        }, 1000);
                        this.step = 9;
                    }
                }
            }, 3000);
        }
    },
    beforeDestroy () {
        window.removeEventListener('resize', this.resizeHandler);
    },
    methods: {
        handleCloseSignInPopup () {
            this.step = 1;
            this.$emit('onPopupClose');
        },
        handleNext () {
            this.step += 1;
        },
        handlePrev () {
            this.step -= 1;
        },
        handleButtonClick (methodName) {
            this[methodName]();
        },
        handleDownloadMetamask () {
            window.open('https://metamask.io/download/', '_blank');
            this.step = 2;
        },
        refreshPage () {
            DataService.storeToLocalStorage(METAMASK_CHECK_IF_INSTALLED, 8);
            window.location.reload();
        },
        async handleAddBinanceSmartChain () {
            const ethereum = MetaWorldManager.sharedInstance().ethereum();
            const chainId = this.$config.chainId;
            const chain = getChainById(chainId);

            if (window.ethereum) {
                if (window.ethereum.networkVersion !== chainId.toString()) {
                    await ethereum.sendAsync({
                        id: 1,
                        jsonrpc: "2.0",
                        method: "wallet_addEthereumChain",
                        params: [
                            {
                                chainId: `0x${chainId.toString(16)}`,
                                chainName: chain.name,
                                rpcUrls: [chain.url],
                                nativeCurrency: chain.nativeCurrency,
                                blockExplorerUrls: [chain.blockExplorerUrl],
                            },
                        ],
                    }, (err, added) => {
                        if (err) {
                            PopupHelper.showErrorAlert((err && err.message) ? err.message : this.$t('Something Went Wrong'));
                        } else if (added) {
                            this.step += 1;
                        }
                    });
                } else {
                    PopupHelper.showSuccessNotification(this.$t('You already have this network'));
                    this.step += 3;
                }
            } else {
                PopupHelper.showErrorAlert(this.$t('MetaMask is not installed'));
            }
        },
        handleImportNFTToken () {
            diagLog('', 'handleImportNFTToken');
        },
        handleCopyNFTContract () {
            const mwm = MetaWorldManager.sharedInstance();
            const address = mwm.getWeb3Contract('StandardParcel').options.address;
            const btn = this.$refs.multipleBtn ? this.$refs.multipleBtn[0].$el : null;
            btn.classList.add('handle-copy');
            copyToClipboard(address);
            setTimeout(() => {
                btn.classList.remove('handle-copy');
            }, 2000);
        },
        handleGetStarted () {
            this.$store.dispatch('application/popup-control/openMetaMaskInstallationPopup', false);
            setTimeout(() => {
                this.step = 1;
            }, 500);
        },
    }
};
