
import {defineComponent} from 'vue';
import InfoTooltip from '~/components/utils/InfoTooltip.vue';
import InfoAlertCard from '~/components/utils/cards/info-alert-card/InfoAlertCard.vue';
import GymNewButton from '~/components/utils/form-components/GymNewButton.vue';
import {PaymentMethodOption} from '~/core/types/purchase-popup-2/PaymentMethodOption';
import {PaymentMethodEnum} from '~/core/types/purchase-popup-2/PaymentMethodEnum';
import {MetaUser} from '~/core/models/MetaUser';
import {UserBalance} from '~/core/models/purchase-popup-2/UserBalance';
import {PaymentSpecialConditions} from '~/core/types/purchase-popup-2/PaymentSpecialConditions';
import MunicipalityTx from '~/core/models/purchase-popup-2/MunicipalityTx';
import Web3MunicipalityTx from '~/core/models/purchase-popup-2/Web3MunicipalityTx';

const BUSD_FEE_VALUE = 3;

const ALWAYS_AVAILABLE_PAYMENT_METHODS: PaymentMethodOption[] = [
    {label: 'Web3 Wallet', value: PaymentMethodEnum.WEB3_WALLET, disabled: false},
    // {label: 'External wallet', value: PaymentMethodEnum.EXTERNAL, disabled: false}, // disabled: true, disabledTitle: 'Maintenance', disabledReason: 'We apologize for the inconvenience. Our third-party provider is currently undergoing maintenance, and during this time, you can only make payments using USDT. We appreciate your understanding.'
];

export default defineComponent({
    name: 'PaymentMethodSelection',
    components: {
        InfoTooltip,
        InfoAlertCard,
        GymNewButton,
    },
    emits: [
        'onPaymentMethodSelected',
        'onStartLoading',
        'onStopLoading',
        'onClickBack',
    ],
    props: {
        user: {
            type: MetaUser,
            required: true,
        },
        userBalance: {
            type: UserBalance,
            required: true,
        },
        transactionObject: {
            type: [MunicipalityTx, Web3MunicipalityTx],
            required: true,
        },
        initiallyChosenTokenPAP: {
            type: Boolean,
            default: false,
        }
    },
    data() {
        return {
            PaymentMethodEnum,
            selectedPaymentMethod: ALWAYS_AVAILABLE_PAYMENT_METHODS[0],
            paymentMethodOptions: ALWAYS_AVAILABLE_PAYMENT_METHODS,
            specialConditions: {
                isGasBack: false,
                mustUseUtility: false,
            } as PaymentSpecialConditions,
            isDirty: false,
        };
    },
    watch: {
        userBalance: {
            handler(newBalance, oldBalance) {
                if (newBalance !== oldBalance && this.userBalance) {
                    this.updatePaymentMethods();
                }
            },
            immediate: true,
        },
    },
    methods: {
        handlePaymentMethodSelected() {
            this.$emit('onPaymentMethodSelected', this.selectedPaymentMethod.value, this.specialConditions);
        },
        handleMethodChange() {
            this.isDirty = true;
            this.handleSetSelectedCurrency();
        },
        async handleSetSelectedCurrency() {
            if (this.selectedPaymentMethod.value === PaymentMethodEnum.PAP_BALANCE) {
                await this.transactionObject.updateSelectedCurrency(true);
            } else {
                await this.transactionObject.updateSelectedCurrency(false);
            }
        },
        updatePaymentMethods() {
            this.specialConditions.isGasBack = false;
            this.specialConditions.mustUseUtility = false;
            this.specialConditions.mustUseGymnet = false;
            const totalBUSDPrice = this.transactionObject.priceInfo.totalAmount.value;
            const newPaymentMethods = [...ALWAYS_AVAILABLE_PAYMENT_METHODS];

            if (this.user.isFullyWeb2User && this.userBalance) {
                const canUseGymnetOrUtility = this.transactionObject.canUseGymnetOrUtility;

                const bnbBalance = this.userBalance.bnb.value;
                const usdtBalance = this.userBalance.usdt.value;
                const utilityBalance = this.userBalance.utility.value;
                const gymnetBalance = this.userBalance.gymnet.value;

                const gasFee = this.transactionObject.priceInfo.gasAmount.value;
                const useUtilityGymnetBalance_USDT_Price = this.transactionObject.priceInfo.splitAmountUtility?.usdt.value || 0;
                const useUtilityGymnetBalance_GYMNET_Price = this.transactionObject.priceInfo.splitAmountUtility?.gymnet.value || 0;

                const isGasFeeSufficient = bnbBalance >= gasFee;
                const isTotalBUSDSufficient = usdtBalance >= totalBUSDPrice;
                const isTotalBUSDSufficientWithGasFee = usdtBalance >= totalBUSDPrice + BUSD_FEE_VALUE;
                const isUtilityBUSDSufficient = usdtBalance >= useUtilityGymnetBalance_USDT_Price;
                const isUtilityBUSDSufficientWithGasFee = usdtBalance >= useUtilityGymnetBalance_USDT_Price + BUSD_FEE_VALUE;

                const isUseUtilityGymnetBalance_UtilitySufficient = utilityBalance >= useUtilityGymnetBalance_GYMNET_Price;
                const isUseUtilityGymnetBalance_GYMNETSufficient = gymnetBalance >= useUtilityGymnetBalance_GYMNET_Price;

                const internalMethod = {label: 'Internal wallet', value: PaymentMethodEnum.INTERNAL};

                let disabled = false;
                let disabledReason = null;
                let disabledTitle = null;

                const textOR = this.$t('Or').toLowerCase();

                if (isTotalBUSDSufficient) {
                    if (!isGasFeeSufficient) {
                        if (!isTotalBUSDSufficientWithGasFee) {
                            if (canUseGymnetOrUtility && (isUseUtilityGymnetBalance_UtilitySufficient || isUseUtilityGymnetBalance_GYMNETSufficient)) {
                                if (!isGasFeeSufficient) {
                                    this.specialConditions.isGasBack = true;
                                }
                                if (isUseUtilityGymnetBalance_UtilitySufficient && this.transactionObject.canUseUtility) {
                                    this.specialConditions.mustUseUtility = true;
                                }
                                if (isUseUtilityGymnetBalance_GYMNETSufficient) {
                                    this.specialConditions.mustUseGymnet = true;
                                }
                            } else {
                                disabled = true;
                                disabledTitle = this.$t('Insufficient funds');
                                disabledReason = this.$t('Please note that AMOUNT_CURRENCY is required for gas fee', {
                                    amount_currency: BUSD_FEE_VALUE + ' USDT ' + textOR + ' ' + gasFee + ' BNB',
                                });
                            }
                        } else {
                            this.specialConditions.isGasBack = true;
                        }
                    }
                } else {
                    if ((!isUseUtilityGymnetBalance_UtilitySufficient && !isUseUtilityGymnetBalance_GYMNETSufficient) || !canUseGymnetOrUtility) {
                        disabled = true;
                        disabledTitle = this.$t('Insufficient funds');
                        disabledReason = this.$t('Please note that AMOUNT worth of CURRENCY is required to complete the transaction.', {
                            amount: totalBUSDPrice,
                            currency: 'USDT',
                        });
                    } else {
                        if (!isUtilityBUSDSufficient) {
                            const usdt_V = useUtilityGymnetBalance_USDT_Price;
                            const gym_V = useUtilityGymnetBalance_GYMNET_Price.toFixed(3);
                            disabled = true;
                            disabledTitle = this.$t('Insufficient funds');
                            disabledReason = this.$t('Please note that AMOUNT worth of CURRENCY is required to complete the transaction.', {
                                amount: totalBUSDPrice,
                                currency: `USDT ${textOR} ${usdt_V} USDT ${this.$t('and')} ${gym_V} Utility ${textOR} GYMNET`,
                            });
                        } else {
                            if (!isGasFeeSufficient) {
                                if (!isUtilityBUSDSufficientWithGasFee) {
                                    disabled = true;
                                    disabledTitle = this.$t('Insufficient funds');
                                    disabledReason = this.$t('Please note that AMOUNT_CURRENCY is required for gas fee', {
                                        amount_currency: BUSD_FEE_VALUE + ' USDT ' + textOR + ' ' + gasFee + ' BNB',
                                    });
                                } else {
                                    if (isUseUtilityGymnetBalance_UtilitySufficient && this.transactionObject.canUseUtility) {
                                        this.specialConditions.mustUseUtility = true;
                                    }
                                    if (isUseUtilityGymnetBalance_GYMNETSufficient) {
                                        this.specialConditions.mustUseGymnet = true;
                                    }
                                    this.specialConditions.isGasBack = true;
                                }
                            } else {
                                if (isUseUtilityGymnetBalance_UtilitySufficient && this.transactionObject.canUseUtility) {
                                    this.specialConditions.mustUseUtility = true;
                                }
                                if (isUseUtilityGymnetBalance_GYMNETSufficient) {
                                    this.specialConditions.mustUseGymnet = true;
                                }
                            }
                        }
                    }
                }
                Object.assign(internalMethod, {disabled, disabledReason, disabledTitle});
                if (disabled) {
                    if (this.selectedPaymentMethod.value === PaymentMethodEnum.INTERNAL) {
                        this.selectedPaymentMethod = newPaymentMethods[0];
                    }
                    newPaymentMethods.push(internalMethod);
                } else {
                    if (!this.isDirty) {
                        this.selectedPaymentMethod = internalMethod;
                    }
                    newPaymentMethods.unshift(internalMethod);
                }
            }

            if (this.transactionObject.canUsePAP && this.userBalance) {
                const papBalanceMethod = {label: 'PAP Balance', value: PaymentMethodEnum.PAP_BALANCE};
                const papBalance = this.userBalance.pap.value;
                const isTotalPAPSufficient = papBalance >= totalBUSDPrice;
                let disabled = !isTotalPAPSufficient;

                Object.assign(papBalanceMethod, { disabled, disabledTitle: isTotalPAPSufficient ? '' : this.$t('Insufficient funds') });

                if (disabled) {
                    if (this.selectedPaymentMethod.value === PaymentMethodEnum.PAP_BALANCE) {
                        this.selectedPaymentMethod = newPaymentMethods[0];
                    }
                    newPaymentMethods.push(papBalanceMethod);
                } else {
                    newPaymentMethods.unshift(papBalanceMethod);

                    if (this.initiallyChosenTokenPAP && !this.isDirty) {
                        this.selectedPaymentMethod = papBalanceMethod;
                    }
                }
            }

            this.paymentMethodOptions = newPaymentMethods;
            this.handleSetSelectedCurrency();
        }
    },
});
