import { ACTIONS ,MUTATIONS,GETTERS,STATE} from "./names";
import type {  SuccessModalParams } from "./types";

export default {
    [ACTIONS.SHOW_SUCCESS_MODAL] ({ commit, dispatch, state }, modalParams: SuccessModalParams) {
        const {
            title,
            desc,
            buttonText,
            buttonVisible,
            closeByClickOutside,
            closeByEsc,
            timeout,
        } = modalParams;

        commit(MUTATIONS.ADD_TO_QUEUE, {
            title,
            desc,
            buttonText,
            buttonVisible,
            closeByClickOutside,
            closeByEsc,
            timeout,
        });

        if (!state[STATE.IS_SUCCESS_MODAL_OPEN]) {
            dispatch(ACTIONS.NEXT_MODAL);
        }
    },
    [ACTIONS.HIDE_SUCCESS_MODAL] ({ commit, dispatch, state }) {
        commit(MUTATIONS.SET_IS_SUCCESS_MODAL_OPEN, false);
        commit(MUTATIONS.SET_SUCCESS_MODAL_TITLE, '');
        commit(MUTATIONS.SET_SUCCESS_MODAL_DESC, '');
        commit(MUTATIONS.SET_SUCCESS_MODAL_BTN_TEXT, '');
        commit(MUTATIONS.SET_SUCCESS_MODAL_BUTTON_VISIBLE, true);
        commit(MUTATIONS.SET_SUCCESS_MODAL_CLOSE_BY_CLICK_MODAL, true);
        commit(MUTATIONS.SET_SUCCESS_MODAL_CLOSE_ON_PRESS_ESCAPE, true);
        dispatch(ACTIONS.CANCEL_SUCCESS_CLOSE_TIMEOUT);

        if (state.queue.length > 0) {
            dispatch(ACTIONS.NEXT_MODAL);
        }
    },
    [ACTIONS.NEXT_MODAL]({ commit, state, dispatch }) {
        const nextModal = state.queue[0];
        if (nextModal) {
            commit(MUTATIONS.SET_CURRENT_MODAL, nextModal);
            commit(MUTATIONS.REMOVE_FROM_QUEUE);
            commit(MUTATIONS.SET_IS_SUCCESS_MODAL_OPEN, true);

            if (nextModal.timeout) {
                const timeoutId = setTimeout(() => {
                    dispatch(ACTIONS.HIDE_SUCCESS_MODAL);
                }, nextModal.timeout);
                commit(MUTATIONS.SET_SUCCESS_MODAL_TIMEOUT_ID, timeoutId);
            }
        }
    },
    [ACTIONS.CANCEL_SUCCESS_CLOSE_TIMEOUT] ({ commit, getters }) {
        clearTimeout(getters[GETTERS.SUCCESS_MODAL_TIMEOUT_ID]);
        commit(MUTATIONS.SET_SUCCESS_MODAL_TIMEOUT_ID, null);
    },
    [ACTIONS.AWAIT_FOR_CLOSED] ({ getters }) {
        return new Promise<void>((resolve) => this.watch(
            () => getters[GETTERS.IS_SUCCESS_MODAL_OPEN],
            (newVal) => !newVal && resolve(),
        ));
    },
};