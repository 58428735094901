import { render, staticRenderFns } from "./Only.vue?vue&type=template&id=4a580e32&scoped=true"
import script from "./Only.vue?vue&type=script&setup=true&lang=ts"
export * from "./Only.vue?vue&type=script&setup=true&lang=ts"
import style0 from "./Only.vue?vue&type=style&index=0&id=4a580e32&prod&scoped=true&lang=scss"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "4a580e32",
  null
  
)

export default component.exports