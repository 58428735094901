
import GYMButton from "@/components/utils/buttons/GYMButton";

export const BUTTON_NAME = {
    YES: 0,
    NO: 1,
}

export default {
    components: {
        GYMButton,
    },
    name: "ComfirmationPopup",
    emits: ['handleButtonClick'],
    data() {
        return {
            BUTTON_NAME,
        }
    },
    props: {
        popupVisible: Boolean,
        popupData: Object
    },
    methods: {
        closePopup() {
            this.$store.commit('application/popup-control/SET_COMFIRMATION_POPUP_DATA', null);
        },
        handleButtonClick(buttonName) {
            this.popupData.buttons[buttonName]?.callback?.();
            this.$emit('handleButtonClick', buttonName);
            this.closePopup();
        }
    }
}
