// import Web3 from 'web3';
import ExchangeService from '~/pages/finances/wallet/services/ExchangeService';
import type WalletAddress from "~/pages/finances/wallet/models/WalletAddress";
import BNBService from '~/pages/finances/wallet/services/BNBService';
import Asset from '~/pages/finances/wallet/models/Asset';
import WalletAsset, { WalletCurrencyName } from '~/pages/finances/wallet/models/WalletAsset';
import BNBAsset from '~/pages/finances/wallet/models/BNBAsset';
import ContractService from '~/pages/finances/wallet/services/ContractService';
import ApiService from '~/core/services/api-interaction/ApiService';
import APIServiceError from '~/core/helpers/APIServiceError';
import { InvalidTokenImportedException } from '../exceptions/InvalidTokenImportedException';
import { TokenAlreadyExistsException } from '../exceptions/TokenAlreadyExistsException';
import { ERROR_STATUSES } from '~/core/services/utils/Constants';
import { ImportedToken } from '../types/ImportedToken';
// import { ImportedTokensBalanceFetchResult } from '../types/ImportedTokensBalanceFetchResult';
import { /*addAndPromisifyBatchCalls,*/ fromWei, getClientRuntimeConfig } from '~/core/helpers/GlobalHelpers';
import { InternalWalletActivities } from '../types/InternalWalletActivities';
import { TokenDoesNotExistException } from '../exceptions/TokenDoesNotExistException';
import { MetaUser } from '~/core/models/MetaUser';
import GYMNETService from './GYMNETService';
import { MetaWorldManager } from '~/core/services/map/MetaWorldManager';
import { UtilityAsset } from '../models/UtilityAsset';
import FILAsset from '../models/FILAsset';
import { FileCoinService } from '~/pages/finances/wallet/services/FileCoinService';
import {GlobalMakerService} from "~/core/services/GlobalMakerService";
import { EthService, ethWeb3 } from './EthService';
import ETHAsset from '../models/ETHAsset';
import { ETH_CONTRACTS } from '~/contracts/addresses';
import { EthereumNetwork } from '../constants/WalletNetworkConstants';

const $config = getClientRuntimeConfig();

export type InventoryContainer = {
    minerRewardsData: {
        minerRewards: {
            minerRewardsUtilityInfo: {
                realAvailable: number;
            };
        };
    };
    userCryptoBalance: {
        gymnet: number;
        busd: number;
        subscriptionPoints: number;
        usdt: number;
        filecoinNative: number;
        eth: number;
        moonberg: number;
        btc: number;
    };
};

export default class WalletService {
    /**
     * return assets of current wallet
     * in specific output object
     * @example
     * WalletService.getAssets(Wallet) =>
     * @returns
     * current assets in set
     */
    public static async getAssets(inventoryContainer: InventoryContainer, walletAddress: WalletAddress, metaWorldManager: any, metaverseID: number, realTime: boolean = false): Promise<Set<Asset>> { //TODO: fix it type and name
        let bnbRatePromise = ExchangeService.getRate('BNB');
        let gymnetRatePromise = ExchangeService.getRate('GYMNET');
        let busdRatePromise = ExchangeService.getRate('BUSD');
        let usdtRatePromise = ExchangeService.getRate('USDT');
        let bitopexRatePromise = ExchangeService.getRate('BPX');
        let filecoinRatePromise = ExchangeService.getRate('FIL');
        let ethRatePromise = ExchangeService.getRate('ETH');
        let moonbergRatePromise = ExchangeService.getRate('MNBRG');
        let btcRatePromise = ExchangeService.getRate('BTC');
        let bnbAmountPromise = BNBService.getBalance(walletAddress, metaWorldManager).then(Number);
        let gymnetAmountPromise = Promise.resolve(inventoryContainer.userCryptoBalance?.gymnet).then(Number);
        let busdAmountPromise = Promise.resolve(inventoryContainer.userCryptoBalance?.busd).then(Number);
        let usdtAmountPromise = Promise.resolve(inventoryContainer.userCryptoBalance?.usdt).then(Number);
        let filecoinAmountPromise = Promise.resolve(inventoryContainer.userCryptoBalance?.filecoinNative).then(Number);
        let ethAmountPromise = Promise.resolve(inventoryContainer.userCryptoBalance?.eth).then(Number);
        let moonbergAmountPromise = Promise.resolve(inventoryContainer.userCryptoBalance?.moonberg).then(Number);
        let btcAmountPromise = Promise.resolve(inventoryContainer.userCryptoBalance?.btc).then(Number);
        let bitopexAmountPromise = GlobalMakerService.$store.dispatch('finances/minerRewards/getBitopexBalance', { walletAddress }).then(Number);

        if(realTime) {
            gymnetAmountPromise = GYMNETService.getBalance(walletAddress, metaWorldManager).then(Number);
            const busdContract = metaWorldManager.contracts.BUSD;
            const usdtContract = metaWorldManager.contracts.USDT;
            const btcContract = metaWorldManager.contracts.BTCB;
            busdAmountPromise = busdContract.methods.balanceOf(walletAddress).call().then(fromWei).then(Number);
            usdtAmountPromise = usdtContract.methods.balanceOf(walletAddress).call().then(fromWei).then(Number);
            filecoinAmountPromise = FileCoinService.getNativeBalance(walletAddress);
            ethAmountPromise = EthService.getNativeBalance(walletAddress);
            moonbergAmountPromise = EthService.getMoonbergBalance(walletAddress);
            btcAmountPromise = btcContract.methods.balanceOf(walletAddress).call().then(fromWei).then(Number);
        }

        const ratePromises = [
            bnbRatePromise,
            gymnetRatePromise,
            busdRatePromise,
            bitopexRatePromise,
            usdtRatePromise,
            filecoinRatePromise,
            ethRatePromise,
            moonbergRatePromise,
            btcRatePromise,
        ];
        const balancePromises = [
            bnbAmountPromise,
            gymnetAmountPromise,
            busdAmountPromise,
            bitopexAmountPromise,
            usdtAmountPromise,
            filecoinAmountPromise,
            ethAmountPromise,
            moonbergAmountPromise,
            btcAmountPromise,
        ];
        const [
            bnbRate,
            gymnetRate,
            busdRate,
            bitopexRate,
            usdtRate,
            filecoinRate,
            ethRate,
            moonbergRate,
            btcRate,
        ] = await Promise.allSettled(ratePromises).then((results) => results.map((result: any) => result.value ?? 0));
        const [
            bnbAmount,
            gymnetAmount,
            busdAmount,
            bitopexAmount,
            usdtAmount,
            filecoinAmount,
            ethAmount,
            moonbergAmount,
            btcAmount,
        ] = await Promise.allSettled(balancePromises).then((results) => results.map((result: any) => result.value ?? 0));

        let bnbAsset = new BNBAsset(
            bnbAmount,
            bnbRate,
            false,
            metaWorldManager,
        );
        if (bnbAsset.usd < 1 && !realTime) {
            bnbAsset = new BNBAsset(
                0,
                bnbRate,
                false,
                metaWorldManager,
            );
        }
        let filecoinAsset = new FILAsset(
            filecoinAmount,
            filecoinRate,
            false,
            metaWorldManager,
        );
        const gymnetAsset = new WalletAsset(
            null,
            "Gym Network Token",
            'GYMNET',
            require("~/assets/images/icons/gymnet-icon.svg"),
            gymnetAmount,
            gymnetRate,
            false,
            metaverseID,
            metaWorldManager,
            await ContractService.getContract('GYMNET', metaWorldManager, metaverseID),
        );
        const busdAsset = new WalletAsset(
            null,
            "Binance USD",
            'BUSD',
            require('~/assets/images/icons/currencies/busd.png'),
            busdAmount,
            busdRate,
            false,
            metaverseID,
            metaWorldManager,
            await ContractService.getContract('BUSD', metaWorldManager, metaverseID)
        );
        const usdtAsset = new WalletAsset(
            null,
            "Tether USD",
            'USDT',
            require('~/assets/images/wallets/usdt.png'),
            usdtAmount,
            usdtRate,
            false,
            metaverseID,
            metaWorldManager,
            await ContractService.getContract('USDT', metaWorldManager, metaverseID)
        );
        const ethAsset = new ETHAsset(
            ethAmount,
            ethRate,
            false,
            metaWorldManager,
        );
        let moonbergAsset = null;
        if (ETH_CONTRACTS['Moonberg'][$config.chainId]) {
            moonbergAsset = new WalletAsset(
                null,
                "Moonberg",
                'MNBRG',
                require('~/assets/images/icons/moonberg.png'),
                moonbergAmount,
                moonbergRate,
                false,
                metaverseID,
                metaWorldManager,
                await ContractService.createErc20AssetContract(
                    ETH_CONTRACTS['Moonberg'][$config.chainId],
                    metaWorldManager,
                    ethWeb3,
                ),
                ETHAsset,
                EthereumNetwork.chainId.toLowerCase()
            );
        }
        const bitopexAsset = new WalletAsset(
            null,
            "Bitopex",
            'BPX',
            require('~/assets/images/icons/btx.svg'),
            Number(bitopexAmount),
            bitopexRate,
            false,
            metaverseID,
            metaWorldManager,
            await ContractService.getContract('BitopexToken', metaWorldManager, metaverseID)
        );
        const btcAsset = new WalletAsset(
            null,
            "Binance BTC",
            'BTC',
            require('~/assets/images/icons/currencies/btc.png'),
            btcAmount,
            btcRate,
            false,
            metaverseID,
            metaWorldManager,
            await ContractService.getContract('BTCB', metaWorldManager, metaverseID)
        );

        return new Set([
            bnbAsset,
            filecoinAsset,
            gymnetAsset,
            busdAsset,
            bitopexAsset,
            usdtAsset,
            btcAsset,
            ethAsset,
            moonbergAsset,
        ]);
    }

    public static async getUserAdditionalAssets(inventoryContainer: InventoryContainer, metaWorldManager: MetaWorldManager, metaverseID: number): Promise<Set<Asset>> {
        const utilityAmount = inventoryContainer?.minerRewardsData
                ?.minerRewards
                ?.minerRewardsUtilityInfo
                ?.realAvailable ?? 0;
        const subscriptionPointsAmount = inventoryContainer?.userCryptoBalance.subscriptionPoints ?? 0;
        const gymnetRate = await ExchangeService.getRate('GYMNET');
        const utilityAsset = new UtilityAsset(
            utilityAmount,
            gymnetRate,
            false,
            metaWorldManager,
            inventoryContainer,
        );
        const subscriptionPointsRate = 1;
        const subscriptionPointsAsset = new WalletAsset(
            null,
            "Product Autoship Points",
            'Product Autoship Points' as WalletCurrencyName,
            require('~/assets/images/gymstreet/currencies/Subscription.svg'),
            subscriptionPointsAmount,
            subscriptionPointsRate,
            false,
            metaverseID,
            metaWorldManager,
            await ContractService.getContract('USDT', metaWorldManager, metaverseID)
        );
        return new Set([
            utilityAsset,
            subscriptionPointsAsset,
        ]);
    }

    public static getUserImportedAssets(user: MetaUser, metaWorldManager: any): Set<Asset> {
        return WalletService.transformTokensToAssets(user.importedTokens, metaWorldManager);
    }

    public static async getUserCustomAssets(metaWorldManager: any): Promise<Set<Asset>> {
        const importedTokensResponse = await ApiService.get('/internal-wallet/imported-tokens');
        const tokens = importedTokensResponse.data?.tokens;
        const result = WalletService.transformTokensToAssets(tokens, metaWorldManager);

        return result;
    }

    public static async getSupportedAssets(metaWorldManager: any): Promise<Set<Asset>> {
        const supportedTokensResponse = await ApiService.get('/internal-wallet/supported-tokens');
        const tokens = supportedTokensResponse.data?.tokens;
        const result = WalletService.transformTokensToAssets(tokens, metaWorldManager);
        return result;
    }

    public static transformTokensToAssets(tokens: ImportedToken[], metaWorldManager: any): Set<Asset> {
        let result = new Set<Asset>();
        if(!tokens) return new Set<Asset>();
        tokens.forEach((token: ImportedToken) => {
            const asset = new WalletAsset(
                token.id,
                token.name,
                token.symbol as WalletCurrencyName,
                token.logo_url ?? require('~/assets/images/icons/landsUpgraded.svg'),
                token.balance ?? 0,
                0,
                true,
                metaWorldManager.metaverseId,
                metaWorldManager,
                ContractService.createErc20AssetContract(token.address, metaWorldManager)
            );
            result.add(asset);
        });
        return result;
    }

    /**
     * Import token to user wallet
     * @example
     * WalletService.importToken(tokenId: number) =>
     * @returns
     * ImportedToken
     * @throws
     * InvalidTokenImportedException
     * TokenAlreadyExistsException
     * APIServiceError
     */
    public static async importToken(tokenId: number, tokenAddress: string): Promise<ImportedToken> {
        try {
            const response = await ApiService.post('internal-wallet/import-token', {
                token_id: tokenId,
                token_address: tokenAddress,
            });
            if(response.status !== 200 || !response.data.success) {
                throw new InvalidTokenImportedException('Something went wrong while importing token');
            }
            return response.data.token;
        } catch(e: unknown) {
            if(e instanceof APIServiceError) {
                if(e.code === ERROR_STATUSES.INVALID_ARGS) {
                    throw new InvalidTokenImportedException(e.returnedMessage);
                } else if (e.code === ERROR_STATUSES.EXISTING_WALLET) {
                    throw new TokenAlreadyExistsException('Token is already imported');
                }
                throw new InvalidTokenImportedException('Something went wrong while importing token');
            }
            throw e;
        }
    }

    /**
     * Delete token from user wallet
     * @example
     * WalletService.deleteToken(tokenId: number) => delete token by id
     * @returns
     * void
     * @throws
     * InvalidTokenImportedException
     * TokenDoesNotExistException
     * APIServiceError
     */
    public static async deleteToken(tokenAddress: string): Promise<void> {
        try {
            const response = await ApiService.post('internal-wallet/delete-token', {
                token_address: tokenAddress
            });
            if(response.status !== 200 || !response.data.success) {
                throw new InvalidTokenImportedException('Something went wrong while importing token');
            }
        } catch(e: unknown) {
            if(e instanceof APIServiceError) {
                if(e.code === ERROR_STATUSES.INVALID_ARGS) {
                    throw new InvalidTokenImportedException(e.returnedMessage);
                } else if (e.code === ERROR_STATUSES.DATA_NOT_FOUND) {
                    throw new TokenDoesNotExistException(e.message);
                }
                throw new InvalidTokenImportedException('Something went wrong while deleting token');
            }
            throw e;
        }
    }

    public static async fetchAssetsBalances(walletAddress: string, addresses: string[], aggregatorContract: any): Promise<{
        bnb: number;
        others: number[];
    }> {
        const balances = await aggregatorContract.methods.getBalances(addresses, walletAddress).call();
        const others = balances[1].map((balance: string) => Number(fromWei(balance)));
        return {
            bnb: Number(fromWei(balances[0])),
            others,
        }
    }

    public static async getWalletTransactions(page: number = 1, limit: number = 10, filterBy?: string): Promise<InternalWalletActivities> {
        const response = await ApiService.query('internal-wallet/activities', {
            params: {
                page,
                limit,
                filterBy
            }
        });

        if(response.status !== 200 || !response.data.activities) {
            throw new Error('Something went wrong while fetching wallet transactions');
        }

        return {
            activities: response.data.activities,
            ...response.data.meta
        }
    }

    public static convert (fromAsset: Asset, toAsset: Asset): Asset {
        return toAsset.copy((fromAsset.value / fromAsset.rate) / toAsset.rate)
    }
}
