import { render, staticRenderFns } from "./Support.vue?vue&type=template&id=6bb8b650&scoped=true"
import script from "./Support.vue?vue&type=script&setup=true&lang=ts"
export * from "./Support.vue?vue&type=script&setup=true&lang=ts"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "6bb8b650",
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {BaseImageSvgThemedIcon: require('/root/dashboard/core/components/base/image/SvgThemedIcon.vue').default,BaseButtonFloatingRounded: require('/root/dashboard/core/components/base/button/FloatingRounded.vue').default})
