import { getChainById } from '~/constants/networks';
import { getClientRuntimeConfig } from '~/core/helpers/GlobalHelpers';
import { Blockchain } from '~/core/types/purchase-popup-2/Blockchain';
import { CryptoCurrency } from "~/core/types/purchase-popup-2/CryptoCurrency";

const $config = getClientRuntimeConfig();

export const Blockchains: Blockchain[] = [
    {
        label: 'Bitcoin',
        value: 'BTC',
        blockchainSymbol: "Bitcoin",
        icon: require('~/assets/images/icons/currencies/btc.png'),
        cryptocurrencies: [
            {
                name: 'BTC',
                code: 'BTC',
                icon: require('~/assets/images/icons/currencies/btc.png'),
                gasFee: 0.0005,
                isActive: true,
                tokenStandard: 'BTC',
                chain: null,
                chainName: 'Bitcoin',
                paymentTemplate: 'bitcoin:{TRANSFER_TO}?amount={AMOUNT}',
                isEvmBased: false,
                isNative: true
            }
        ]
    },
    {
        //disabled: true,
        //disabledTitle: 'Maintenance',
        //disabledReason: 'We apologize for the inconvenience. Our third-party provider is currently undergoing maintenance, and during this time, you cannot make payments using ETH. We appreciate your understanding.',
        label: 'Ethereum (ERC20)',
        value: 'ETH',
        blockchainSymbol: "ETH",
        icon: require('~/assets/images/icons/currencies/eth.png'),
        cryptocurrencies: [
            {
                name: 'Ether',
                code: 'ETH',
                icon: require('~/assets/images/icons/currencies/eth.png'),
                gasFee: 0.00126,
                isActive: true,
                tokenStandard: 'ERC20',
                chain: getChainById(parseFloat($config.chainId) === 56 ? 1 : 5), // If BSC mainnet is used then use ETH mainnet, otherwise use ETH Goerli testnet
                chainName: 'Ethereum (ERC20)',
                paymentTemplate: 'ethereum:{TRANSFER_TO}@{CHAIN_ID}?value={AMOUNT}',
                isEvmBased: true,
                isNative: true
            }
        ]
    },
    {
        label: 'Binance Smart Chain (BEP20)',
        value: 'BNB',
        blockchainSymbol: "BSC",
        icon: require('~/assets/images/icons/currencies/bnb.png'),
        cryptocurrencies: [
            {
                name: 'BNB',
                code: 'BNB',
                icon: require('~/assets/images/icons/currencies/bnb.png'),
                gasFee: 0.00126,
                isActive: true,
                tokenStandard: 'BEP20',
                chain: getChainById($config.chainId),
                chainName: 'Binance Smart Chain (BEP20)',
                paymentTemplate: 'ethereum:{TRANSFER_TO}@{CHAIN_ID}?value={AMOUNT}',
                isEvmBased: true,
                isNative: true
            },
            {
                name: 'USDT',
                code: 'USDT',
                icon: require('~/assets/images/wallets/usdt.png'),
                gasFee: 0.00126,
                isActive: false,
                tokenStandard: 'BEP20',
                chain: getChainById($config.chainId),
                chainName: 'Binance Smart Chain (BEP20)',
                paymentTemplate: 'ethereum:{TOKEN}@{CHAIN_ID}/transfer?address={TRANSFER_TO}&uint256={AMOUNT}', // TODO maybe hardcode the token address here
                isEvmBased: true,
                isNative: false
            },
        ]
    },
];

export const BlockchainsBUSD: CryptoCurrency = {
    name: 'BUSD',
    code: 'BUSD',
    icon: require('~/assets/images/icons/currencies/busd.png'),
    gasFee: 0.00126,
    isActive: false,
    tokenStandard: 'BEP20',
    chain: getChainById($config.chainId),
    chainName: 'Binance Smart Chain (BEP20)',
    paymentTemplate: 'ethereum:{TOKEN}@{CHAIN_ID}/transfer?address={TRANSFER_TO}&uint256={AMOUNT}', // TODO maybe hardcode the token address here
    isEvmBased: true,
    isNative: false
};
