import type { WalletWatchableAsset } from "~/core/types/affility/web3/WalletWatchableAsset";
import { ACTIONS } from "./names";
import { WALLET_ERROR_CODES } from "~/core/services/utils/Constants";
import { GETTERS as GETTERS_WEB3_CONNECTION } from "../connection/names";

export default {
    [ACTIONS.WATCH_ASSET]({ rootGetters }, asset: WalletWatchableAsset) {
        return new Promise<void>((resolve, reject) => {
            rootGetters[`affility/web3/connection/${GETTERS_WEB3_CONNECTION.PROVIDER}`].sendAsync({
                method: 'wallet_watchAsset',
                params: asset,
            }, (err, added) => {
                if (err) {
                    let message = 'Failed to add address to your wallet';
                    if(err.code === WALLET_ERROR_CODES.USER_REJECTED) {
                        message = 'User rejected the request';
                    }
                    reject(message);
                } else if (added) {
                    resolve();
                }
            });
        });
    },
};