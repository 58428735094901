import { GETTERS, STATE } from "./names";

export default {
    [GETTERS.MY_TEAM_GUIDE_STEP]: state => state[STATE.MY_TEAM_GUIDE_STEP],
    [GETTERS.ENROLLMENT_SELECT_VIEW]: state => state[STATE.ENROLLMENT_SELECT_VIEW],
    [GETTERS.ENROLLMENT_STATS_DATA]: state => state[STATE.ENROLLMENT_STATS_DATA],
    [GETTERS.ENROLLMENT_TREE_DATA]: state => state[STATE.ENROLLMENT_TREE_DATA],
    [GETTERS.ENROLLMENT_TREE_LOADER]: state => state[STATE.ENROLLMENT_TREE_LOADER],
    [GETTERS.ENROLLMENT_TABLE_DATA]: state => state[STATE.ENROLLMENT_TABLE_DATA],
    [GETTERS.ENROLLMENT_TABLE_LOADER]: state => state[STATE.ENROLLMENT_TABLE_LOADER],
    [GETTERS.CV_POINTS_DATA]: state => state[STATE.CV_POINTS_DATA],
    [GETTERS.CV_POINTS_DATA_LOADER]: state => state[STATE.CV_POINTS_DATA_LOADER],
    [GETTERS.BINARY_TREE_DATA_LOADER]: state => state[STATE.BINARY_TREE_DATA_LOADER],
    [GETTERS.BINARY_TREE_DATA]: state => state[STATE.BINARY_TREE_DATA],
    [GETTERS.EXTENDED_REFERRAL_LINK_DATA]: state => state[STATE.EXTENDED_REFERRAL_LINK_DATA],
    [GETTERS.CV_POINTS_SEARCH_VALUE]: state => state[STATE.CV_POINTS_SEARCH_VALUE],
}
