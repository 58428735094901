import { getClientRuntimeConfig } from "~/core/helpers/GlobalHelpers";

const $config = getClientRuntimeConfig();

const DEFAULTS = {
    user: {
        setToStore: true,
        storeDispatchPath: 'application/driver/changeAuthDataOfUser',
        property: 'user',
        autoFetch: true,
    },
    token: {
        url: $config.appHost + '/api/v1/auth/oauth',
        setApiServiceToken: true,
        setJwtServiceToken: true,
        storeDispatchPath: 'application/driver/changeAuthDataOfUser',
        type: '',
        property: 'token',
        required: true,
    },
};

export default DEFAULTS;