export enum PURCHASE_STEPS {
    LOADING,
    SELECT_PRODUCT_OWNER,
    SELECT_PAYMENT_METHOD,
    SELECT_CURRENCY,
    SELECT_BLOCKCHAIN_CURRENCY,
    PURCHASE_SUMMARY,
    INSUFFICIENT_FUNDS,
    CONNECT_WALLET,
    SCANNING,
    PAYMENT_SUCCESS,
    NO_USER,
    ERROR,
    TRANSACTION_REJECTED,
    MISSING_CRYPTO_AMMOUNT,
    SELECT_PAP_CURRENCY,
    PAP_PURCHASE_SUMMARY,
}
