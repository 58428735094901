import { UtilityGymnetPriceInfo } from "~/core/types/purchase-popup-2/UtilityGymnetPriceInfo";
import Asset from "~/pages/finances/wallet/models/Asset";
import BNBAsset from "~/pages/finances/wallet/models/BNBAsset";

export class PriceInfo {
    constructor(
        public totalAmount: Asset,
        public splitAmountUtility?: UtilityGymnetPriceInfo,
        public splitAmountGymnet?: UtilityGymnetPriceInfo,
        public gasAmount?: BNBAsset,
        public externalFee?: Asset,
    ) {}
}
