import { PeriodFilterValue } from "~/pages/finances/commissions/constants/PeriodFilterValue";
import { generateDailyEmptyChartData } from "./generateDailyEmptyChartData";
import { generateMontlyEmptyChartData } from "./generateMonthlyEmptyChartData";
import { generateWeeklyEmptyChartData } from "./generateWeeklyEmptyChartData";
import { generateYearlyEmptyChartData } from "./generateYearlyEmptyChartData";

export function generateEmptyChartData(period: PeriodFilterValue, months: number = 12) {
    if (period === PeriodFilterValue.ONE_DAY) {
        return generateDailyEmptyChartData();
    } else if (period === PeriodFilterValue.SEVEN_DAYS) {
        return generateWeeklyEmptyChartData();
    } else if (period === PeriodFilterValue.ONE_MONTH) {
        return generateMontlyEmptyChartData();
    } else if (period === PeriodFilterValue.ONE_YEAR) {
        return generateYearlyEmptyChartData();
    } else if (period === PeriodFilterValue.ALL) {
        return generateYearlyEmptyChartData(months);
    }
}
