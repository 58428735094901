import { PARCEL_TYPES } from "../utils/Constants";

export class MetaReservedArea {
    constructor(areaData) {
        this.id = areaData.id;
        this.name = areaData.name;
        this.image_path = areaData.image_path;
        this.description = areaData.description;
        this.info_video = areaData.info_video;
        this.info_image = areaData.info_image;
        this.partnershipDataUrl = areaData.partnership_data_url;
        this.link = areaData.link;
        this.affiliateLink = areaData.affiliate_link;
        this.partnerName = areaData.partner_name;
        this.partnershipId = areaData.partnership_id;
        this.parcels = [];
        this.x = null;
        this.y = null;
        this.x1 = null;
        this.y1 = null;
        if(this.image_path) {
            this.image = new Image();
            this.image.loading = 'lazy'; //not load images, until they are visible
            this.image.src = this.image_path;
        }
    }

    addParcel(metaParcel) {
        if(this.parcels.length === 0) {
            this.x = this.x1 = metaParcel.x;
            this.y = this.y1 = metaParcel.y;
        } else {
            this.x = Math.min(this.x, metaParcel.x);
            this.y = Math.min(this.y, metaParcel.y);
            this.x1 = Math.max(this.x1, metaParcel.x);
            this.y1 = Math.max(this.y1, metaParcel.y);
        }
        this.parcels.push(metaParcel);
    }

    getWidth() {
        return this.x1 - this.x + 1;
    }

    getHeight() {
        return this.y1 - this.y + 1;
    }

    hasImage() {
        return this.image_path !== null;
    }

    setHovered() {
        this.parcels.map(pc => pc.setHovered());
    }

    setSelected() {
        this.parcels.map(pc => pc.setSelected());
    }

    unselect() {
        this.parcels.map(pc => pc.setNormalState());
    }

    clear () {
        for(const parcel of this.parcels) {
            parcel.pt = PARCEL_TYPES.STANDARD;
            parcel.reservedArea = null;
            parcel.changeColor();
        }
        this.parcels = [];
    }
}
