export const STATE = {
    IS_TOAST_OPEN: 'isToastOpen',
    TOAST_TYPE: 'toastType',
    TOAST_TITLE: 'toastTitle',
    TOAST_DESCRIPTION: 'toastDescription',
    TOAST_DURATION: 'toastDuration',
} as const;

export const ACTIONS = {
    SHOW_INFO_TOAST: 'showInfoToast',
    SHOW_ERROR_TOAST: 'showErrorToast',
    CLOSE_TOAST: 'closeToast',
    CLOSE_IN_DURATION: 'closeInDuration',
} as const;

export const MUTATIONS = {
    SET_SHOW_INFO_TOAST: 'setShowInfoToast',
    SET_SHOW_ERROR_TOAST: 'setsShowErrorToast',
    SET_SHOW_TOAST: 'setsShowToast',
    SET_CLOSE_TOAST: 'setCloseToast',
    SET_TOAST_DURATION: 'setToastDuration',
} as const;

export const GETTERS = {
    IS_TOAST_OPEN: 'isToastOpen',
    TOAST_TYPE: 'toastType',
    TOAST_TITLE: 'toastTitle',
    TOAST_DESCRIPTION: 'toastDescription',
} as const;
