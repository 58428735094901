import { render, staticRenderFns } from "./MobileContractAddresses.vue?vue&type=template&id=0f0a8acd&scoped=true"
import script from "./MobileContractAddresses.vue?vue&type=script&setup=true&lang=ts"
export * from "./MobileContractAddresses.vue?vue&type=script&setup=true&lang=ts"
import style0 from "./MobileContractAddresses.vue?vue&type=style&index=0&id=0f0a8acd&prod&scoped=true&lang=scss"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "0f0a8acd",
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {ComposedMenuContractAddressesSubMenu: require('/root/dashboard/core/components/composed/menu/ContractAddressesSubMenu.vue').default,BaseSidebarSliding: require('/root/dashboard/core/components/base/sidebar/Sliding.vue').default,BaseOverlaySimple: require('/root/dashboard/core/components/base/overlay/Simple.vue').default})
