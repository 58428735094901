import BigNumber from "bignumber.js";
import {fromWei, getClientRuntimeConfig} from "~/core/helpers/GlobalHelpers";
import {MetaWorldManager} from "~/core/services/map/MetaWorldManager";
BigNumber.config({ DECIMAL_PLACES: 18 });

const $config = getClientRuntimeConfig();
const GYMNET_API_URL = $config.gymnetApi;

export const state = () => {
    return {
        address:"",
        levels: {
            level23:0,
            level24:0,
            level25:0,
        },
        userTotalClaimsFromDB:0.0000000001,
        currentPendingRewards22ETH:0,
        currentPendingRewards23ETH:0,
        currentPendingRewards24ETH:0,
        pendingRewardsTotal:0,
        totalClaimedRewards:0,
        totalParticipants:[],
        getUserPendingRewards:0,
        getUserTotalClaimedRewards:0,
        getUserUnclaimedRewards:0,
        IsUserActive:false,
        levelsVT: {
            level23:0,
            level24:0,
            level25:0,
        },
        levelsSP: {
            level23:0,
            level24:0,
            level25:0,
        },
        levelsLP: {
            level23:0,
            level24:0,
            level25:0,
        },
        isGSTurnoverPoolLoading: false,
        GSTurnoverPoolData: {},
        isGSLevelPoolParticipantsLoading: true,
        GSLevelPoolParticipantsData: {}
    };
};

export const actions = {
    async load({ commit, rootState,state }) {
        try {

            const address = MetaWorldManager.sharedInstance().getWalletAddressForFetchInfo();
            const IsUserActive = await this.$contracts.LevelPool.methods.isUserActive(address).call();
            const pendingRewardsTotal = ((28800 * 5.21876430414877 ) * 6 / 100).toFixed(2);
            const levels = {
                level23:(pendingRewardsTotal / 6).toFixed(2),
                level24:(pendingRewardsTotal /3).toFixed(2),
                level25:(pendingRewardsTotal /2).toFixed(2),
            };
            const dailyRewardsVault =  ((28800 * 0.528) * 6 / 100).toFixed(2);
            const levelsVT = {
                level23:(dailyRewardsVault / 6).toFixed(2),
                level24:(dailyRewardsVault /3).toFixed(2),
                level25:(dailyRewardsVault /2).toFixed(2),
            };
            const dailyRewardsSinglePool =  ((28800 * 3.539113099148764921) * 6 / 100).toFixed(2);
            const levelsSP = {
                level23:(dailyRewardsSinglePool / 6).toFixed(2),
                level24:(dailyRewardsSinglePool /3).toFixed(2),
                level25:(dailyRewardsSinglePool /2).toFixed(2),
            };
            const dailyRewardsFarming =  ((28800 * 1.052651205) * 6 / 100).toFixed(2);
            const levelsLP = {
                level23:(dailyRewardsFarming / 6).toFixed(2),
                level24:(dailyRewardsFarming /3).toFixed(2),
                level25:(dailyRewardsFarming /2).toFixed(2),
            };
            // getUserPendingRewards
            let getUserPendingRewards = 0;
            const totalParticipants = await this.$contracts.LevelPool.methods.calculateQualifiedCandidates().call();
            let getUserTotalClaimedRewards = await this.$contracts.LevelPool.methods.getUserTotalClaimedRewards(address).call();
            getUserTotalClaimedRewards = fromWei(getUserTotalClaimedRewards);

            let getUserUnclaimedRewards = await this.$contracts.LevelPool.methods.getUserUnclaimedRewards(address).call();
            getUserUnclaimedRewards = fromWei(getUserUnclaimedRewards);
            try{
                const convertFromWeiToEth = await this.$contracts.LevelPool.methods.getUserPendingRewards(address).call();
                getUserPendingRewards = fromWei(convertFromWeiToEth);
            }catch (e) {
                console.log('You are not Qualified for Level Pools');
            }

            const currentPendingRewards22WEI = await this.$contracts.LevelPool.methods.getPendingRewardsPerLevel(22).call()
            const currentPendingRewards22ETH = fromWei(currentPendingRewards22WEI[1])

            const currentPendingRewards23WEI = await this.$contracts.LevelPool.methods.getPendingRewardsPerLevel(23).call()
            const currentPendingRewards23ETH = fromWei(currentPendingRewards23WEI[1])

            const currentPendingRewards24WEI = await this.$contracts.LevelPool.methods.getPendingRewardsPerLevel(24).call()
            const currentPendingRewards24ETH = fromWei(currentPendingRewards24WEI[1])
            // const totalGold = totalParticipants.totalGold;
            let totalClaimedRewards = 0;


            if(address) {

                const userTotalClaims_WEI =await this.$contracts.LevelPool.methods.userTotalClaims(address).call()
                const userTotalClaims_ETH = fromWei(userTotalClaims_WEI)
                if(state.userTotalClaimsFromDB === 0.0000000001) {
                    const userTotalClaimsFromDB = await this.$api.get(`${GYMNET_API_URL}/api/get-user-level-pool-total-claims?address=${address}`);
                    if(userTotalClaimsFromDB.data.sum) {
                        commit("SET_DATA",{
                            userTotalClaimsFromDB:userTotalClaimsFromDB.data.sum
                        })
                    }
                }
                totalClaimedRewards = parseFloat(userTotalClaims_ETH)+state.userTotalClaimsFromDB
            }
            commit("SET_DATA", {
                address,
                levels,
                getUserPendingRewards,
                pendingRewardsTotal,
                totalParticipants,
                getUserTotalClaimedRewards,
                getUserUnclaimedRewards,
                IsUserActive,
                levelsVT,
                levelsSP,
                levelsLP,
                currentPendingRewards22ETH,
                currentPendingRewards23ETH,
                currentPendingRewards24ETH,
                totalClaimedRewards
            });
        } catch (error) { // TODO check usages and refactor
            console.error("Unknown error at store/user/levelpool.js load", error);
        }
    },
    claimAll({ dispatch }) {
        return dispatch(
            "transactions/send",
            {
                callback: this.$write.LevelPool.methods.claimAll(),
            },
            { root: true }
        ).then(() => {
            return dispatch("load");
        });
    },
    async getTurnoverPoolDataGymStreet({ commit, rootState }, address) {
        commit('SET_GS_TURNOVER_POOL_LOADING', true)
        try {
            const turnoverPool = await this.$api.get(`${GYMNET_API_URL}/api/gymstreet-level-pool-fifty-percent-rule?address=${address}`);
            const turnoverPools = turnoverPool?.data;
            commit('SET_GS_TURNOVER_POOLS', turnoverPools );
        } finally {
            commit('SET_GS_TURNOVER_POOL_LOADING', false)
        }
    },
    async getGymStreetLevelPoolParticipants({ commit, rootState }, address) {
        commit('SET_GS_LEVEL_POOL_PARTICIPANTS_LOADING', true);
        try {
            const GSParticipants = await this.$api.get(`${GYMNET_API_URL}/api/gymstreet-level-pool-participants?address=${address}`);
            const GSParticipantsData = GSParticipants?.data;
            commit('SET_GS_LEVEL_POOL_PARTICIPANTS_DATA', GSParticipantsData);
        } catch (e) {
            console.log(e)
        }finally {
            commit('SET_GS_LEVEL_POOL_PARTICIPANTS_LOADING', false)
        }
    },
};

export const mutations = {
    SET_DATA(state, data) {
        for (const key in data) {
            state[key] = data[key];
        }
    },
    SET_GS_TURNOVER_POOL_LOADING(state, value) {
        state.isGSTurnoverPoolLoading = value;
    },
    SET_GS_TURNOVER_POOLS(state, data) {
        state.GSTurnoverPoolData = data;
    },
    SET_GS_LEVEL_POOL_PARTICIPANTS_LOADING(state, value) {
        state.isGSLevelPoolParticipantsLoading = value;
    },
    SET_GS_LEVEL_POOL_PARTICIPANTS_DATA(state, data) {
        state.GSLevelPoolParticipantsData = data;
    }
};

export const getters = {
    data: (state) => state,
    isGSTurnoverPoolLoading: ({ isGSTurnoverPoolLoading }) => isGSTurnoverPoolLoading,
    GSTurnoverPoolData: ({ GSTurnoverPoolData }) => GSTurnoverPoolData,
    GSLevelPoolParticipantsData: ({ GSLevelPoolParticipantsData }) => GSLevelPoolParticipantsData,
    isGSLevelPoolParticipantsLoading: ({ isGSLevelPoolParticipantsLoading }) => isGSLevelPoolParticipantsLoading,
};
