import WalletAddress from "~/pages/finances/wallet/models/WalletAddress";
import Asset from './Asset';
import {toBN, toWei, fromWei, getHidedAccountAddress} from "~/core/helpers/GlobalHelpers";
import ApiService from "~/core/services/api-interaction/ApiService";
import { ethWeb3 } from "~/pages/finances/wallet/services/EthService";
import { MetaInventoryContainer } from "~/core/models/MetaInventoryContainer";
import { EthereumNetwork } from "~/pages/finances/wallet/constants/WalletNetworkConstants";
import { TranslationHelper } from "~/core/services/TranslationHelper";
const currency = 'ETH';
const icon = require('~/assets/images/icons/eth.svg');

export default class ETHAsset extends Asset {
    private isWeb3User: boolean = false;
    private userWallet: string = '';
    contractAddress: string = '';

    constructor (value: number, rate: number, isInsufficient: boolean, metaWorldManager: any) {
        super(null, null, currency, icon, value, rate, isInsufficient, metaWorldManager);
        const mic = MetaInventoryContainer.sharedInstance();
        this.isWeb3User = !mic.authUserData.isFullyWeb2User;
        this.userWallet = mic.authUserData.walletAddress;
        this.contractAddress = metaWorldManager.getWeb3Contract('ETH').options.address;
    }

    async estimateGas(
        gasPrice_placeholder: number,
        addressFrom: WalletAddress,
        addressTo: WalletAddress,
    ): Promise<ETHAsset> {
        const web3 = ethWeb3;
        const weiAmount = toWei(this._value.toString());
        const gasPrice = toBN(await web3.eth.getGasPrice());
        const transaction = {
            from: addressFrom,
            value: weiAmount,
            to: addressTo,
        };
        const gasEstimation = await web3.eth.estimateGas(transaction);
        const gasAmount = toBN(gasEstimation).mul(gasPrice);
        const amount = fromWei(gasAmount.toString());
        const parsedAmount = amount === 0 ? 0 : parseFloat(amount);
        return new ETHAsset(
            parsedAmount,
            this._rate,
            false,
            this._metaWorldManager
        );
    }

    async estimateSwapGas(
        path: string[],
        walletAddress: string,
        amount: number,
        amountOutMin: number = 0,
    ) {
        throw new Error('not implemented');
    }



    async send (to: WalletAddress): Promise<unknown> {
        const toAddress = to;
        if (this.isWeb3User) {
            const ethereum = this._metaWorldManager.ethereum();
            if (!ethereum) {
                throw new Error('No ethereum provider to send Ethereum');
            }
            if (ethereum.chainId !== EthereumNetwork.chainId) {
                throw new Error(TranslationHelper.translate('Select {n} blockchain to send the transaction', {n: EthereumNetwork.name}));
            }
            if (ethereum.selectedAddress?.toLowerCase() !== this.userWallet.toLowerCase()) {
                throw new Error(
                    TranslationHelper.translate("Your attached wallet is {walletAddress}. You have tried to make request with wallet {currentWallet}. Please change connected wallet to {walletAddress}", {
                        walletAddress: getHidedAccountAddress(this.userWallet.toLowerCase()),
                        currentWallet: getHidedAccountAddress(ethereum.selectedAddress?.toLowerCase()),
                    })
                );
            }
            const web3 = this._metaWorldManager.web3();
            const txParams = {
                from: this.userWallet,
                to: toAddress,
                value: toWei(this._value.toString()),
                gas: undefined,
                gasPrice: undefined,
            };
            const gasPrice = toBN(await ethWeb3.eth.getGasPrice());
            const gasEstimation = await ethWeb3.eth.estimateGas(txParams);
            txParams.gas = gasEstimation;
            txParams.gasPrice = gasPrice;
            return web3.eth.sendTransaction(txParams);
        } else {
            const amount = this._value.toString();
            const sendTransaction = { type: 'eth', amount, to, chainId: EthereumNetwork.chainId.toLowerCase() };
            return await ApiService.post('internal-wallet/send', sendTransaction);
        }
    }

    subtract(subtrahend: number): ETHAsset {
        const parentAmt = super.subtract(subtrahend);
        return new ETHAsset(
            subtrahend,
            this._rate,
            parentAmt.isInsufficient,
            this._metaWorldManager,
        );
    }

    copyRate(asset: ETHAsset): ETHAsset {
        return new ETHAsset(
            this.value,
            asset._rate,
            this._isInsufficient,
            this._metaWorldManager,
        );
    }
};
