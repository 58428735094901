import { NotificationTypes } from "./NotificationTypes";
import type { RawNotification } from "./RawNotification";

const typeToTitleMap = {
    [NotificationTypes.ACCOUNT_UPDATED_NOTIFICATION]: 'Your account was updated',
    [NotificationTypes.BALANCE_UPDATED_NOTIFICATION]: 'Your payment processing is complete. Your NFT balance has been updated!',
    [NotificationTypes.IMAGE_APPROVED_NOTIFICATION]: 'Your image was approved',
    [NotificationTypes.IMAGE_REJECTED_NOTIFICATION]: 'Your image was rejected',
};

export class Notification {
    public date: Date;
    public type: NotificationTypes;
    public id: string;
    public title: string;
    public values: Record<string, string>;
    public description: string;

    public imageId?: string | number;
    public userUpdatedData?: Record<string, string>;

    constructor(rawNotification: RawNotification) {
        this.date = new Date(rawNotification.date);
        this.type = rawNotification.type;
        this.id = rawNotification.uuid;
        this.title = typeToTitleMap[rawNotification.type] || rawNotification.text || rawNotification.template;
        this.values = rawNotification.values || {};
        this.description = rawNotification.reason;

        this.imageId = rawNotification.image_id;
        this.userUpdatedData = rawNotification.user_updated_data;
    }
};