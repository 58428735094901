import { ACTIONS, GETTERS, MUTATIONS } from "./names";
import { CommissionBalanceInfo } from "~/pages/finances/commissions/models/CommissionBalanceInfo";
import { CommissionsService } from "~/pages/finances/commissions/services/CommissionsService";
import type { RawCommissionBalanceInfo } from "~/pages/finances/commissions/types/RawCommissionBalanceInfo";
import { POLLING_STORE_FUNCTIONS } from "./polling";

export default {
    ...POLLING_STORE_FUNCTIONS.actions,
    async [ACTIONS.LOAD_PROCESS_AND_SAVE_COMMISSIONS_INFO_DATA] (
        { dispatch, commit, getters },
        { save }: {save?: () => boolean} = {},
    ) {
        if (getters[GETTERS.COMMISSIONS_INFO_DATA_LOADING]) {
            return getters[GETTERS.COMMISSIONS_INFO_DATA_LOADING_PROMISE];
        }

        commit(MUTATIONS.SET_COMMISSIONS_INFO_DATA_LOADING, true);
        let processedCommissionsInfoData = null;
        try {
            const loadingPromise = dispatch(ACTIONS.LOAD_AND_PROCESS_COMMISSIONS_INFO_DATA);
            commit(MUTATIONS.SET_COMMISSIONS_INFO_DATA_LOADING_PROMISE, loadingPromise);
            processedCommissionsInfoData = await loadingPromise;

            if (!save || save()) {
                await dispatch(ACTIONS.SAVE_COMMISSIONS_INFO_DATA, processedCommissionsInfoData);
            }

            commit(MUTATIONS.SET_COMMISSIONS_INFO_DATA_LOADING_ERROR, null);
        } catch (e) {
            console.log('Failed to fetch subtree info', e);
            commit(MUTATIONS.SET_COMMISSIONS_INFO_DATA_LOADING_ERROR, e);
        } finally {
            commit(MUTATIONS.SET_COMMISSIONS_INFO_DATA_LOADING, false);
            commit(MUTATIONS.SET_COMMISSIONS_INFO_DATA_LOADING_PROMISE, null);
            return processedCommissionsInfoData;
        }
    },
    async [ACTIONS.LOAD_AND_PROCESS_COMMISSIONS_INFO_DATA] ({ dispatch }) {
        const rawData = await dispatch(ACTIONS.LOAD_COMMISSIONS_INFO_DATA);
        return dispatch(ACTIONS.PROCESS_COMMISSIONS_INFO_DATA, rawData);
    },
    [ACTIONS.LOAD_COMMISSIONS_INFO_DATA] ({ commit }) {
        return CommissionsService.getCommissionsInfo(false);
    },
    [ACTIONS.PROCESS_COMMISSIONS_INFO_DATA] ({ commit }, rawData: RawCommissionBalanceInfo) {
        return new CommissionBalanceInfo(rawData);
    },
    [ACTIONS.SAVE_COMMISSIONS_INFO_DATA] ({ commit }, data: CommissionBalanceInfo) {
        commit(MUTATIONS.SET_BINARY_WEEKLY_CAP, data.binaryWeeklyCap);
        commit(MUTATIONS.SET_BINARY_CURRENT_PROGRESS, data.binaryCurrentProgress);
        commit(MUTATIONS.SET_TOTAL_BINARY_COMMISSION, data.totalBinaryCommission);
        commit(MUTATIONS.SET_CLAIMED_COMMISSIONS, data.claimedCommissions);
        commit(MUTATIONS.SET_AVAILABLE_COMMISSIONS, data.availableCommissions);
        commit(MUTATIONS.SET_COLLECTED_BINARY_BONUS, data.collectedBinaryBonus);
        commit(MUTATIONS.SET_COLLECTED_MATCHING_BONUS, data.collectedMatchingBonus);
    },
    [ACTIONS.CLEAR_COMMISSIONS_INFO_STATE] ({ commit, dispatch }) {
        commit(MUTATIONS.SET_BINARY_WEEKLY_CAP, null);
        commit(MUTATIONS.SET_BINARY_CURRENT_PROGRESS, null);
        commit(MUTATIONS.SET_TOTAL_BINARY_COMMISSION, null);
        commit(MUTATIONS.SET_CLAIMED_COMMISSIONS, null);
        commit(MUTATIONS.SET_AVAILABLE_COMMISSIONS, null);
        commit(MUTATIONS.SET_COLLECTED_BINARY_BONUS, null);
        commit(MUTATIONS.SET_COLLECTED_MATCHING_BONUS, null);
        dispatch(ACTIONS.STOP_POLLING);
    },
};