
import {defineComponent} from 'vue'

type SimpleColoredProgressBarComponent = {
    progress: number;
    color: string;
    readonly maxProgress: number;
};

export default defineComponent<keyof SimpleColoredProgressBarComponent, SimpleColoredProgressBarComponent>({
    name: 'SegmentedColoredProgressBar',
    props: {
        progress: {
            type: Number,
            default: 0
        },
        max: {
            type: Number,
            default: 100
        },
        current: {
            type: Number,
            default: 0
        },
        color: {
            type: String,
            default: '#97AB4C'
        },
        warnColor: {
            type: String,
            default: '#E2AB1C'
        }
    },
    computed: {
        items() {
            const {color, warnColor, max, current} = this.$props
            const warnState = (max * 0.33) > current
            const currentColor = warnState ? warnColor : color
            const segmentCount = 16
            const visiblePercent = max / segmentCount
            const items = Array.from(Array(segmentCount)).map((_, idx) => {
                const isVisible = idx * visiblePercent < current
                return {color: isVisible ? currentColor : 'transparent'}
            })
            return items
        }
    },
})

