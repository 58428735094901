import { render, staticRenderFns } from "./NotificationsEmpty.vue?vue&type=template&id=25de00cf"
var script = {}


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {ComposedPlaceholderStatus: require('/root/dashboard/core/components/composed/placeholder/Status.vue').default})
