
export default {
    name: "BlurCard",
    props: {
        background: {
            type: String,
            default: '#ffffff94',
        },
        backdropFilter: {
            type: String,
            default: 'blur(3px)',
        },
    },
}
