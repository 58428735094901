

import {defineComponent} from 'vue';

const DEFAULT_STEP_COUNT = 4;
const DEFAULT_CURRENT_STEP = 1;

export default defineComponent({
    name: 'StepIndicator',
    props: {
        stepsCount: {
            type: Number,
            default: DEFAULT_STEP_COUNT,
        },
        currentStep: {
            type: Number,
            default: DEFAULT_CURRENT_STEP,
        },
    },
    data() {
        return {
            active: 0,
        };
    },
    methods: {
        stepClass(step: number): string {
            const {currentStep} = this.$props;
            return step <= currentStep ? 'current-step' : '';
        },
        next() {
            if (this.active++ > 2) {
                this.active = 0;
            }
        },
    },
});
