import Hashids from "hashids";
import { HashIdService } from "~/core/services/HashIdService";

export default (ctx, inject) => {
    const h = new Hashids(ctx.$config.hashIdsSalt, 5, ctx.$config.hashIdsAlphabet);
    HashIdService.setHashIds(h);
    const saltForCryptoJS = ctx.$config.cryptoJsSalt;
    const $hashid = {
        encode: (n) => {
            return h.encode(n);
        },
        decode: (n) => {
            return h.decode(n)[0];
        }
    };
    const $hashString = {
        encode: (n) => {
            return btoa(unescape(encodeURIComponent(n + saltForCryptoJS)));
        },
        decode: (n) => {
            return  decodeURIComponent(escape(atob(n.replace(/-/g, '+').replace(/_/g, '/')))).slice(0, -saltForCryptoJS.length)
            ;
        }
    };

    ctx.$hashid = $hashid;
    ctx.$hashString = $hashString;

    inject("hashid", $hashid);
    inject("hashString", $hashString);
};
