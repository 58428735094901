export const STATE = {
    PROMOS: 'promos',
    PROMOS_LOADING: 'promosLoading',
} as const;

export const ACTIONS = {
    ON_PROMOS_LOADING: 'onPromosLoading',
    GET_PROMOS: 'getPromos',
    CLEAR_PROMOS: 'clearPromos',
} as const;

export const MUTATIONS = {
    SET_PROMOS: 'setPromos',
    SET_PROMOS_LOADING: 'setPromosLoading',
    SET_CLEAR_PROMOS: 'setClearPromos',
} as const;

export const GETTERS = {
    PROMOS_DATA_CONFIG: 'promosDataConfig',
    PROMOS: 'PROMOS',
    PROMOS_LOADING: 'promosLoading',
} as const;
