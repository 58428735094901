import { ACTIONS, GETTERS, MUTATIONS } from "./names";
import {
    ACTIONS as GENERAL_NOTIFICATIONS_ACTIONS,
    GETTERS as GENERAL_NOTIFICATIONS_GETTERS,
} from "../names";
import type { Notification } from "../types/Notification";
import type { RawNotification } from "../types/RawNotification";
import ApiService from "~/core/services/api-interaction/ApiService";
import { callAndRetryOnError } from "~/core/utilities/callAndRetryOnError";

type ProcessedNotificationsResult = {
    processed: Notification[],
    total: number,
    notifications: RawNotification[],
};

export default {
    async [ACTIONS.LOAD_MORE_NOTIFICATIONS_HISTORY](
        { dispatch, commit, rootGetters, getters },
        payload,
    ): Promise<ProcessedNotificationsResult | null> {
        if (getters[GETTERS.NOTIFICATIONS_HISTORY_LOADING_PROMISE]) {
            return getters[GETTERS.NOTIFICATIONS_HISTORY_LOADING_PROMISE];
        }
        commit(MUTATIONS.SET_NOTIFICATIONS_HISTORY_LOADING, true);
        commit(MUTATIONS.SET_NOTIFICATIONS_HISTORY_LOADING_ERROR, null);
        const fetchOffsetGetterPath = `affility/user/notifications/${GENERAL_NOTIFICATIONS_GETTERS.ALL_NOTIFICATIONS}`;
        const fetchOffset = rootGetters[fetchOffsetGetterPath].length;
        let processedNotifications = null;
        try {
            const loadingPromise = callAndRetryOnError(
                () => dispatch(ACTIONS.FETCH_AND_PROCESS_NOTIFICATIONS_HISTORY, payload),
                3, // Count of requests.
                1000 // Delay in milliseconds (1 second)
            );
            commit(MUTATIONS.SET_NOTIFICATIONS_HISTORY_LOADING_PROMISE, loadingPromise);
            processedNotifications = await loadingPromise;

            if (fetchOffset === rootGetters[fetchOffsetGetterPath].length) {
                const {
                    processed,
                    total,
                } = processedNotifications;
                await dispatch(ACTIONS.SAVE_NOTIFICATIONS_HISTORY, { notifications: processed, total });
            }

            commit(MUTATIONS.SET_NOTIFICATIONS_HISTORY_LOADING_ERROR, null);
        } catch (e) {
            console.warn('Failed to load more notifications history', e);
            commit(MUTATIONS.SET_NOTIFICATIONS_HISTORY_LOADING_ERROR, e);
        } finally {
            commit(MUTATIONS.SET_NOTIFICATIONS_HISTORY_LOADING, false);
            commit(MUTATIONS.SET_NOTIFICATIONS_HISTORY_LOADING_PROMISE, null);
            return processedNotifications;
        }
    },
    async [ACTIONS.FETCH_AND_PROCESS_NOTIFICATIONS_HISTORY](
        { dispatch },
        payload = {},
    ): Promise<ProcessedNotificationsResult | null> {
        const notifications = await dispatch(ACTIONS.FETCH_NOTIFICATION_HISTORY, payload);
        if (notifications) {
            const processed = await dispatch(ACTIONS.PROCESS_NOTIFICATIONS_HISTORY, notifications.notifications);
            return {
                ...notifications,
                processed,
            };
        }
        return null;
    },
    async [ACTIONS.FETCH_NOTIFICATION_HISTORY](
        { getters, rootGetters },
        { count, offset } = {} as { count?: number; offset?: number }
    ): Promise<{ notifications: RawNotification[]; total: number } | null> {
        const internalCount = typeof count === 'number' ? count : getters[GETTERS.NOTIFICATIONS_HISTORY_LOAD_COUNT];
        const internalOffset = typeof offset === 'number' ? offset : rootGetters[`affility/user/notifications/${GENERAL_NOTIFICATIONS_GETTERS.ALL_NOTIFICATIONS}`].length;
        ApiService.setHeader();
        const notificationsResponse = await ApiService.query('user/notifications/history', {
            params: { count: internalCount, offset: internalOffset },
        });
        let res = null;
        if (notificationsResponse.data) {
            res = {
                notifications: notificationsResponse.data.notifications,
                total: notificationsResponse.data.total,
            };
        }
        return res;
    },
    [ACTIONS.PROCESS_NOTIFICATIONS_HISTORY](
        { dispatch },
        notifications: RawNotification[],
    ): Notification[] {
        return dispatch(
            `affility/user/notifications/${GENERAL_NOTIFICATIONS_ACTIONS.PROCESS_NOTIFICATIONS}`,
            notifications,
            { root: true }
        );
    },
    [ACTIONS.SAVE_NOTIFICATIONS_HISTORY](
        { commit },
        {
            notifications,
            total,
        } = {} as { notifications: Notification[]; total?: number }) {
        commit(MUTATIONS.ADD_NOTIFICATIONS_TO_HISTORY, { notifications });
        if (typeof total === 'number') {
            commit(MUTATIONS.SET_TOTAL_NOTIFICATIONS_COUNT, total);
        }
    },
    [ACTIONS.CLEAR_NOTIFICATIONS_HISTORY_STATE]({ commit }) {
        commit(MUTATIONS.SET_NOTIFICATION_HISTORY, []);
        commit(MUTATIONS.SET_NOTIFICATIONS_HISTORY_LOADING_ERROR, null);
        commit(MUTATIONS.SET_NOTIFICATIONS_HISTORY_LOADING_PROMISE, null);
        commit(MUTATIONS.SET_TOTAL_NOTIFICATIONS_COUNT, 0);
        commit(MUTATIONS.SET_NOTIFICATIONS_HISTORY_LOAD_COUNT, 10);
    },
};