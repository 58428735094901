import { getStorePollingNames } from "~/core/utilities/store-polling/getStorePollingNames";

export const UNIQUE_IDENTIFIER = 'binary-progress';
export const POLLING_NAMES = getStorePollingNames(UNIQUE_IDENTIFIER);

export const STATE = {
    LEFT_LEG_PARTNERS_COUNT: 'leftLegPartnersCount',
    RIGHT_LEG_PARTNERS_COUNT: 'rightLegPartnersCount',
    LEFT_LEG_TURNOVER: 'leftLegTurnover',
    RIGHT_LEG_TURNOVER: 'rightLegTurnover',
    LEFT_LEG_MIN_PURCHASE_SATISFIED: 'leftLegMinPurchaseSatisfied',
    RIGHT_LEG_MIN_PURCHASE_SATISFIED: 'rightLegMinPurchaseSatisfied',
    UNILEVEL_RANKS_BY_LEFT_LEG_SATISFIED: "unilevelRanksByLeftLegSatisfied",
    UNILEVEL_RANKS_BY_RIGHT_LEG_SATISFIED: "unilevelRanksByRightLegSatisfied",
    TOTAL_OWN_PURCHASE: "totalOwnPurchase",
    BINARY_PROGRESS_LOADING: 'binaryProgressLoading',
    BINARY_PROGRESS_LOADING_ERROR: 'binaryProgressLoadingError',
    BINARY_PROGRESS_LOADING_PROMISE: 'binaryProgressLoadingPromise',
    ...POLLING_NAMES.state,
} as const;

export const ACTIONS = {
    CLEAR_PROGRESS_STATE: 'clearProgressState',
    LOAD_PROCESS_AND_SAVE_BINARY_DATA: 'loadProcessSaveBinaryData',
    LOAD_AND_PROCESS_BINARY_PROGRESS_DATA: 'loadAndProcessBinaryProgressData',
    LOAD_BINARY_PROGRESS_DATA: 'loadBinaryProgressData',
    PROCESS_BINARY_PROGRESS_DATA: 'processBinaryProgressData',
    SAVE_BINARY_PROGRESS_DATA: 'saveBinaryProgressData',
    ...POLLING_NAMES.actions,
} as const;

export const MUTATIONS = {
    SET_LEFT_LEG_PARTNERS_COUNT: 'SET_LEFT_LEG_PARTNERS_COUNT',
    SET_RIGHT_LEG_PARTNERS_COUNT: 'SET_RIGHT_LEG_PARTNERS_COUNT',
    SET_LEFT_LEG_TURNOVER: 'SET_LEFT_LEG_TURNOVER',
    SET_RIGHT_LEG_TURNOVER: 'SET_RIGHT_LEG_TURNOVER',
    SET_LEFT_LEG_MIN_PURCHASE_SATISFIED: 'SET_LEFT_LEG_MIN_PURCHASE_SATISFIED',
    SET_RIGHT_LEG_MIN_PURCHASE_SATISFIED: 'SET_RIGHT_LEG_MIN_PURCHASE_SATISFIED',
    SET_UNILEVEL_RANKS_BY_LEFT_LEG_SATISFIED: 'SET_UNILEVEL_RANKS_BY_LEFT_LEG_SATISFIED',
    SET_UNILEVEL_RANKS_BY_RIGHT_LEG_SATISFIED: 'SET_UNILEVEL_RANKS_BY_RIGHT_LEG_SATISFIED',
    TOTAL_OWN_PURCHASE: 'TOTAL_OWN_PURCHASE',
    SET_BINARY_PROGRESS_LOADING: 'SET_BINARY_PROGRESS_LOADING',
    SET_BINARY_PROGRESS_LOADING_ERROR: 'SET_BINARY_PROGRESS_LOADING_ERROR',
    SET_BINARY_PROGRESS_LOADING_PROMISE: 'SET_BINARY_PROGRESS_LOADING_PROMISE',
    ...POLLING_NAMES.mutations,
} as const;

export const GETTERS = {
    LEFT_LEG_PARTNERS_COUNT: 'leftLegPartnersCount',
    RIGHT_LEG_PARTNERS_COUNT: 'rightLegPartnersCount',
    LEFT_LEG_TURNOVER: 'leftLegTurnover',
    RIGHT_LEG_TURNOVER: 'rightLegTurnover',
    LEFT_LEG_MIN_PURCHASE_SATISFIED: 'leftLegMinPurchaseSatisfied',
    RIGHT_LEG_MIN_PURCHASE_SATISFIED: 'rightLegMinPurchaseSatisfied',
    CURRENT_TURNOVER_IN_WEAK_LEG: 'currentTurnoverInWeakLeg',
    UNILEVEL_RANKS_BY_LEFT_LEG_SATISFIED: 'uniLevelRanksByLeftLegSatisfied',
    UNILEVEL_RANKS_BY_RIGHT_LEG_SATISFIED: 'uniLevelRanksByRightLegSatisfied',
    TOTAL_OWN_PURCHASE: 'totalOwnPurchase',
    BINARY_PROGRESS_LOADING: 'binaryProgressLoading',
    BINARY_PROGRESS_LOADING_ERROR: 'binaryProgressLoadingError',
    BINARY_PROGRESS_LOADING_PROMISE: 'binaryProgressLoadingPromise',
    ...POLLING_NAMES.getters,
} as const;