import { MysteryBoxPrizeType } from "./MysteryBoxPrizeType";
import { BUNDLE_TYPE_PARCELS_MINERS_1, BUNDLE_TYPE_STARTER_BUNDLE, BUNDLE_TYPE_SUPER_BUNDLE_R10, BUNDLE_TYPE_SUPER_BUNDLE_R15, BUNDLE_TYPE_SUPER_BUNDLE_R20, BUNDLE_TYPE_SUPER_BUNDLE_R22_M, BUNDLE_TYPE_SUPER_BUNDLE_R22_S, BUNDLE_TYPE_SUPER_BUNDLE_R6, BUNDLE_TYPE_SUPER_BUNDLE_R8, SINGLE_PARCEL } from "~/core/services/utils/Constants";

export const PrizeBundleIdByType: Record<MysteryBoxPrizeType, number> = {
    [MysteryBoxPrizeType.PRIZE_LAND]: SINGLE_PARCEL,
    [MysteryBoxPrizeType.PRIZE_LAND_AND_MINER]: BUNDLE_TYPE_STARTER_BUNDLE,
    [MysteryBoxPrizeType.PRIZE_BASIC_BUNDLE]: BUNDLE_TYPE_PARCELS_MINERS_1,
    [MysteryBoxPrizeType.PRIZE_SUPER_BUNDLE_R6]: BUNDLE_TYPE_SUPER_BUNDLE_R6,
    [MysteryBoxPrizeType.PRIZE_SUPER_BUNDLE_R8]: BUNDLE_TYPE_SUPER_BUNDLE_R8,
    [MysteryBoxPrizeType.PRIZE_SUPER_BUNDLE_R10]: BUNDLE_TYPE_SUPER_BUNDLE_R10,
    [MysteryBoxPrizeType.PRIZE_SUPER_BUNDLE_R15]: BUNDLE_TYPE_SUPER_BUNDLE_R15,
    [MysteryBoxPrizeType.PRIZE_SUPER_BUNDLE_R20]: BUNDLE_TYPE_SUPER_BUNDLE_R20,
    [MysteryBoxPrizeType.PRIZE_SUPER_BUNDLE_R22S]: BUNDLE_TYPE_SUPER_BUNDLE_R22_S,
    [MysteryBoxPrizeType.PRIZE_SUPER_BUNDLE_R22M]: BUNDLE_TYPE_SUPER_BUNDLE_R22_M,
    [MysteryBoxPrizeType.PRIZE_SUPER_BUNDLE_R22M_2X]: BUNDLE_TYPE_SUPER_BUNDLE_R22_M,
    [MysteryBoxPrizeType.PRIZE_SUPER_BUNDLE_R22M_3X]: BUNDLE_TYPE_SUPER_BUNDLE_R22_M,
    [MysteryBoxPrizeType.PRIZE_SUPER_BUNDLE_R22M_7X]: BUNDLE_TYPE_SUPER_BUNDLE_R22_M,
};