import { MysteryBoxPrizeType } from "~/core/components/flows/mystery-box/constants/MysteryBoxPrizeType";
import { MetaInventoryContainer } from "~/core/models/MetaInventoryContainer";
import { MetaBundle } from "~/core/models/MetaBundle";

export class MysteryBoxStatistics {
    totalStats: any = {
        boxesOpened: 0,
        participants: 0,
        rewardsClaimed: 0,
        totalRewardValue: 0,
    };
    myRewards: any[] = [];
    rewardsCommunityClaimed: any[] = [];

    constructor(data: any) {
        if (data) {
            this.totalStats.boxesOpened = parseFloat(data.participationCount || 0);
            this.totalStats.participants = parseFloat(data.participants || 0);
            this.totalStats.rewardsClaimed = parseFloat(data.rewardClaimed || 0);
            this.totalStats.totalRewardValue = Math.round(parseFloat(data.rewardValue || 0));
            this.setMyRewardsData(data);
            this.setRewardsCommunityClaimed(data);
        }
    }

    setRewardsCommunityClaimed(data: any) {
        let totalData = new Map();

        if (data.totalHistory && data.totalHistory.length) {
            let countR22m = 0;
            data.totalHistory.forEach((item: any) => {
                const name = MysteryBoxRewardsNames[item.prize_type]?.name;
                if (name) {
                    switch (item.prize_type) {
                        case MysteryBoxPrizeType.PRIZE_SUPER_BUNDLE_R22M:
                            countR22m = countR22m + item.count;
                            break;
                        case MysteryBoxPrizeType.PRIZE_SUPER_BUNDLE_R22M_2X:
                            countR22m = countR22m + (item.count * 2);
                            break;
                        case MysteryBoxPrizeType.PRIZE_SUPER_BUNDLE_R22M_3X:
                            countR22m = countR22m + (item.count * 3);
                            break;
                        case MysteryBoxPrizeType.PRIZE_SUPER_BUNDLE_R22M_7X:
                            countR22m = countR22m + (item.count * 7);
                            break;
                        default:
                            totalData.set(item.prize_type, { name, count: item.count });
                    }
                }
            })

            if (countR22m) {
                totalData.set(MysteryBoxPrizeType.PRIZE_SUPER_BUNDLE_R22M, {
                    name: MysteryBoxRewardsNames[MysteryBoxPrizeType.PRIZE_SUPER_BUNDLE_R22M].name,
                    count: countR22m
                });
            }
        }
        this.rewardsCommunityClaimed = Array.from(totalData.values());
    }

    setMyRewardsData(data: any) {
        if (data.userHistory && data.userHistory.length) {
            let rewards = [];

            data.userHistory.forEach((item: any) => {
                switch (item.prize_type) {
                    case MysteryBoxPrizeType.PRIZE_LAND:
                    case MysteryBoxPrizeType.PRIZE_LAND_AND_MINER:
                        return rewards.push({
                            id: item.prize_type,
                            name: MysteryBoxRewardsNames[item.prize_type].name,
                            count: Number(item.count),
                        });
                    default:
                        rewards.push({
                            id: item.prize_type,
                            name: MysteryBoxRewardsNames[item.prize_type].name,
                            count: Number(item.count),
                        });
                }
            });

            this.myRewards = rewards;
        }
    }
}

export const MysteryBoxRewardsNames = {
    [MysteryBoxPrizeType.PRIZE_LAND]: { name: 'Parcel', smartContractId: null },
    [MysteryBoxPrizeType.PRIZE_LAND_AND_MINER]: { name: 'Parcel and Dual Miner', smartContractId: null },
    [MysteryBoxPrizeType.PRIZE_BASIC_BUNDLE]: { name: 'Basic Bundle', smartContractId: 0 },
    [MysteryBoxPrizeType.PRIZE_SUPER_BUNDLE_R6]: { name: 'Super Bundle R6', smartContractId: 13 },
    [MysteryBoxPrizeType.PRIZE_SUPER_BUNDLE_R8]: { name: 'Super Bundle R8', smartContractId: 25 },
    [MysteryBoxPrizeType.PRIZE_SUPER_BUNDLE_R10]: { name: 'Super Bundle R10', smartContractId: 14 },
    [MysteryBoxPrizeType.PRIZE_SUPER_BUNDLE_R15]: { name: 'Super Bundle R15', smartContractId: 15 },
    [MysteryBoxPrizeType.PRIZE_SUPER_BUNDLE_R20]: { name: 'Super Bundle R20', smartContractId: 16 },
    [MysteryBoxPrizeType.PRIZE_SUPER_BUNDLE_R22S]: { name: 'Super Bundle R22S', smartContractId: 17 },
    [MysteryBoxPrizeType.PRIZE_SUPER_BUNDLE_R22M]: { name: 'Super Bundle R22M', smartContractId: 18 },
    [MysteryBoxPrizeType.PRIZE_SUPER_BUNDLE_R22M_2X]: { name: 'Super Bundle R22M 2X', smartContractId: 18 },
    [MysteryBoxPrizeType.PRIZE_SUPER_BUNDLE_R22M_3X]: { name: 'Super Bundle R22M 3X', smartContractId: 18 },
    [MysteryBoxPrizeType.PRIZE_SUPER_BUNDLE_R22M_7X]: { name: 'Super Bundle R22M 7X', smartContractId: 18 },
}
