export default (ctx, inject) => {
    const key = ctx.$config.zendesk.key;
    const zendeskScript = document.createElement('script');
    zendeskScript.setAttribute('id', 'ze-snippet');
    zendeskScript.setAttribute('src', `https://static.zdassets.com/ekr/snippet.js?key=${key}`);
    document.head.appendChild(zendeskScript);
    const zendeskPromise = new Promise((resolve) => {
        zendeskScript.onload = () => {
            inject('zendesk', window.zE);
            resolve(window.zE);
        };
    });
    inject('zendesk', zendeskPromise);
};
