const persistance = new Map();

export default {
    name: "Persist",
    props: {
        persistKey: {
            type: String,
            required: true
        },
    },
    created() {
        const key = this.persistKey;
        const persistedState = persistance.get(key) || { usedBy: [], slotNodes: null };
        persistedState.usedBy.push(this);
        persistance.set(key, persistedState);
    },
    destroyed() {
        const key = this.persistKey;
        const persistedState = persistance.get(key);
        persistedState.usedBy = persistedState.usedBy.filter((component) => component !== this);
        if (persistedState.usedBy.length === 0) {
            persistedState?.slotNodes?.forEach((vnode) => {
                vnode.data.keepAlive = false;
                vnode.componentInstance.$destroy()
            });
            persistance.delete(key);
        }
    },
    setup(props, { slots }) {
        return () => {
            const key = props.persistKey;
            const persistedState = persistance.get(key);
            let vnodes = persistedState?.slotNodes;
            if (!vnodes || vnodes.length === 0) {
                const slotChildren = slots.default();
                slotChildren.forEach((slotChild) => slotChild.data.keepAlive = true);
                persistedState.slotNodes = slotChildren;
                vnodes = slotChildren;
            }
            return vnodes;
        }
    },
}