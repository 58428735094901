
import BlurCard from "~/components/utils/cards/blur-card/BlurCard.vue";
import CustomLoader from "~/components/utils/CustomLoader.vue";

export default {
    name: "BlurLoader",
    components: {
        BlurCard,
        CustomLoader,
    },
    props: {
        backdropFilter: {
            type: String,
            default: 'blur(3px)',
        },
        background: {
            type: String,
            default: '#ffffff94',
        },
    },
}
