import { MINER_HASH_POWER, PARCEL_MINERS_COUNT } from "../services/utils/Constants";

export class UserInventoryInfoContainer {
    constructor(obj) {
        this.setParcels(obj?.lands);

        this.setData(obj);
    }

    get upgradedUsedSlots() {
        return Math.min(this.upgradedSlots, this.activeMinersCount);
    }

    get upgradedFreeSlots() {
        return this.upgradedSlots - this.upgradedUsedSlots;
    }

    get standardUsedSlots() {
        return this.activeMinersCount - this.upgradedUsedSlots;
    }

    get standardFreeSlots() {
        return this.standardSlots - this.standardUsedSlots;
    }

    get attachableMinersCount() {
        const claimedParcels = this.lands.size;
        const upgradedClaimedParcelsCount = Math.min(claimedParcels, this.upgradedParcelsCount);
        const allAttachableMinersCount = upgradedClaimedParcelsCount * 6 + claimedParcels * 4;
        return allAttachableMinersCount - this.activeMinersCount;
    }

    get dualMinersHash() {
        return this.dualMiners * MINER_HASH_POWER;
    }

    get totalStandardMinersCount() {
        return this.totalMinersCount > 0 ? this.totalMinersCount - this.dualMiners : 0;
    }

    get totalStandardHash() {
        return this.totalHash > 0 ? this.totalHash - this.dualMinersHash : 0;
    }

    get standardFreeMiners() {
        return this.freeMinersCount > 0 ? this.freeMinersCount - this.dualMinersInactive : 0;
    }

    get standardActiveMiners() {
        return this.totalStandardMinersCount > 0 ? this.totalStandardMinersCount - this.standardFreeMiners: 0;
    }

    hasMiners() {
        return this.totalMinersCount > 0;
    }

    hasInactiveMiners() {
        return this.freeMinersCount > 0;
    }

    getInactiveMinersCount() {
        return this.freeMinersCount;
    }

    getAvailableRoomsForMiners() {
        return this.freeSlots;
    }

    getParcelsCount() {
        return this.totalParcelsCount;
    }

    getNotUpgradedParcelsCount() {
        return this.totalParcelsCount - this.upgradedParcelsCount;
    }

    getClaimableParcelsCount() {
        return this.totalParcelsCount - this.lands.size;
    }

    addParcels(parcels) {
        parcels.forEach(this.lands.add, this.lands);
    }

    setParcels(parcels) {
        this.lands = new Set(parcels);
    }

    setData(data) {
        this.totalParcelsCount = Number(data?.totalParcelsCount || 0);
        this.standardParcelsCount = Number(data?.standardParcelsCount || 0);
        this.upgradedParcelsCount = Number(data?.upgradedParcelsCount || 0);
        this.totalSlots = Number(data?.totalSlots || 0);
        this.freeSlots = Number(data?.freeSlots || 0);
        this.claimedOnMap = Number(data?.claimedOnMap || 0);
        this.upgradedSlots = Number(data?.upgradedSlots || 0);
        this.standardSlots = Number(data?.standardSlots || 0);

        this.totalMinersCount = Number(data?.totalMinersCount || 0);
        this.freeMinersCount = Number(data?.freeMinersCount || 0);
        this.activeMinersCount = Number(data?.activeMinersCount || 0);
        this.totalHash = Number(data?.totalHash || 0);
        this.freeHash = Number(data?.freeHash || 0);

        this.dualMiners = Number(data?.dualMinersTotal || 0);
        this.dualMinersInactive = Number(data?.dualMinersInactive || 0);
        this.dualMinersActive = Number(data?.dualMinersActive || 0);
        this.activeDualMinersForAllMetaverses = Number(data?.activeDualMinersForAllMetaverses || 0);
        this.dualMinersHashpower = Number(data?.dualMinersHashpower || 0);
        this.dualMinersHashpowerForAllMetaverses = Number(data?.dualMinersHashpowerForAllMetaverses || 0);
    }

    clear() {
        this.setParcels([]);
        this.setData({});
    }
}
