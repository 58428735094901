export const STATE = {
} as const;

export const ACTIONS = {
    CLEAR_USER_STATE: 'clearUserState',
} as const;

export const MUTATIONS = {
    CLEAR_LOCAL_USER_STATE: 'CLEAR_LOCAL_USER_STATE',
} as const;

export const GETTERS = {
} as const;