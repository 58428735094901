import { MUTATIONS, STATE } from "./names";
import { ToastNotificationEnum } from "./types";

export default {
    [MUTATIONS.SET_SHOW_INFO_TOAST](state) {
        state[STATE.TOAST_TYPE] = ToastNotificationEnum.INFO;
    },
    [MUTATIONS.SET_SHOW_ERROR_TOAST](state) {
        state[STATE.TOAST_TYPE] = ToastNotificationEnum.ERROR;
    },
    [MUTATIONS.SET_TOAST_DURATION](state, duration) {
        if (!duration) return;
        state[STATE.TOAST_DURATION] = duration;
    },
    [MUTATIONS.SET_SHOW_TOAST](state, { title, description }) {
        state[STATE.IS_TOAST_OPEN] = true;
        state[STATE.TOAST_TITLE] = title;
        state[STATE.TOAST_DESCRIPTION] = description;
    },
    [MUTATIONS.SET_CLOSE_TOAST](state) {
        state[STATE.TOAST_TYPE] = null;
        state[STATE.TOAST_TITLE] = '';
        state[STATE.TOAST_DESCRIPTION] = '';
        state[STATE.IS_TOAST_OPEN] = false;
    }
}
