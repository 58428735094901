import { MUTATIONS, STATE } from "./names";
import { POLLING_STORE_FUNCTIONS } from "./polling";
import type { DurationFilter, State } from "./types";

export default {
    [MUTATIONS.SET_DURATION_FILTER](state: State, payload: DurationFilter) {
        state[STATE.DURATION_FILTER] = payload;
    },
    [MUTATIONS.SET_LEFT_LEG_PARTNERS_COUNT](state: State, payload: number | null) {
        state[STATE.LEFT_LEG_PARTNERS_COUNT] = payload;
    },
    [MUTATIONS.SET_RIGHT_LEG_PARTNERS_COUNT](state: State, payload: number | null) {
        state[STATE.RIGHT_LEG_PARTNERS_COUNT] = payload;
    },
    [MUTATIONS.SET_ACTIVE_PARTNERS_COUNT](state: State, payload: number | null) {
        state[STATE.ACTIVE_PARTNERS_COUNT] = payload;
    },
    [MUTATIONS.SET_INACTIVE_PARTNERS_COUNT](state: State, payload: number | null) {
        state[STATE.INACTIVE_PARTNERS_COUNT] = payload;
    },
    [MUTATIONS.SET_DIRECT_PARTNERS_COUNT](state: State, payload: number | null) {
        state[STATE.DIRECT_PARTNERS_COUNT] = payload;
    },
    [MUTATIONS.SET_TEAM_DATA_LOADING](state: State, payload: boolean) {
        state[STATE.TEAM_DATA_LOADING] = payload;
    },
    [MUTATIONS.SET_TEAM_DATA_LOADING_ERROR](state: State, payload: string) {
        state[STATE.TEAM_DATA_LOADING_ERROR] = payload;
    },
    [MUTATIONS.SET_TEAM_DATA_LOADING_PROMISE](state: State, payload: Promise<void>) {
        state[STATE.TEAM_DATA_LOADING_PROMISE] = payload;
    },
    ...POLLING_STORE_FUNCTIONS.mutations,
}