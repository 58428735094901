import { getClientRuntimeConfig } from "~/core/helpers/GlobalHelpers";

const $config = getClientRuntimeConfig();
const BSC_MAINNET_ID = 56;
const filecoinMainnetId = '0x13a';
const filecoinCalibrationId = '0x4cb2f';
const ethMainnet = '0x1';
const ethSepolia = '0xaa36a7';

export const FileCoinNetwork = {
    chainId: $config.chainId === BSC_MAINNET_ID ? filecoinMainnetId : filecoinCalibrationId,
    name: $config.chainId === BSC_MAINNET_ID ? 'Filecoin Mainnet' : 'Filecoin Calibration',
    nativeCurrency: {
        name: 'Filecoin',
        symbol: $config.chainId === BSC_MAINNET_ID ? 'FIL' : 'tFIL',
        decimals: 18,
    },
    url: $config.chainId === BSC_MAINNET_ID ? 'https://api.node.glif.io' : 'https://rpc.ankr.com/filecoin_testnet',
    blockExplorerUrl: $config.chainId === BSC_MAINNET_ID ? 'https://filfox.info' : 'https://calibration.filfox.info',
};

export const BinanceSmartChainNetwork = {
    chainId: $config.chainId,
    name: $config.chainId === BSC_MAINNET_ID ? 'Binance Smart Chain Mainnet' : 'Binance Smart Chain Testnet',
    nativeCurrency: {
        name: 'BNB',
        symbol: $config.chainId === BSC_MAINNET_ID ? 'BNB' : 'tBNB',
        decimals: 18,
    },
    url: $config.chainId === BSC_MAINNET_ID ? 'https://bsc.meowrpc.com' : 'https://endpoints.omniatech.io/v1/bsc/testnet/public',
    blockExplorerUrl: $config.chainId === BSC_MAINNET_ID ? 'https://bscscan.com' : 'https://testnet.bscscan.com',
};

export const EthereumNetwork = {
    chainId: $config.chainId === BSC_MAINNET_ID ? ethMainnet : ethSepolia,
    name: $config.chainId === BSC_MAINNET_ID ? 'Ethereum Mainnet' : 'Ethereum Sepolia',
    nativeCurrency: {
        name: 'Ether',
        symbol: $config.chainId === BSC_MAINNET_ID ? 'ETH' : 'tETH',
        decimals: 18,
    },
    url: $config.chainId === BSC_MAINNET_ID ? 'https://ethereum-rpc.publicnode.com' : 'https://ethereum-sepolia-rpc.publicnode.com',
    blockExplorerUrl: $config.chainId === BSC_MAINNET_ID ? 'https://etherscan.io' : 'https://sepolia.etherscan.io',
};
