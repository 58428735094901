var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c(_setup.tag,_vm._b({tag:"component",staticClass:"menu-link d-flex align-items-center",class:[{ 
        [`${_setup.theme}`]: !!_setup.theme,
    }],style:({
        '--base-link-menu-hover-background': _setup.hoverBackground,
    }),attrs:{"active":_setup.active,"external":_setup.external,"target":_setup.target,"rel":_setup.noRel ? null : _setup.rel,"prefetched-class":_setup.prefetchedClass,"prefetch":_setup.prefetch,"disabled":_setup.isAnyhowDisabled,"disabled-with-individual-opacity":_setup.disabledWithIndividualOpacity,"canonical":_setup.canonical,"absolute":_setup.absolute,"with-base":_setup.withBase}},'component',{
        ...(_setup.isAnyhowDisabled ? { 'aria-disabled': true, href: '', to: '', } : { href: _vm.href || '', to: _vm.to || '', }),
    },false),[_vm._t("default")],2)
}
var staticRenderFns = []

export { render, staticRenderFns }