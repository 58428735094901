export const STATE = {
} as const;

export const ACTIONS = {
} as const;

export const MUTATIONS = {
} as const;

export const GETTERS = {
    ID: 'ID',
} as const;