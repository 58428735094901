import metamask from "@/assets/images/wallets/metamask.svg";
// import coinbase from "@/assets/images/wallets/coinbase.svg";
import walletconnect from "@/assets/images/wallets/walletconnect.svg";
import trust from "@/assets/images/wallets/trust.svg";
// import math from "@/assets/images/wallets/math.svg";

const PROVIDERS = [
    {
        name: "Metamask",
        logo: metamask,
        providerName: "injected",
        supportedChains: true,
        identifier: "isMetaMask",
        isInstalled: () => process.client ? !!window.ethereum : false,
    },
    {
        logo: trust,
        name: "Trust",
        providerName: "injected",
        supportedChains: true,
        identifier: "isTrust",
        isInstalled: () => process.client ?  !!window.ethereum : false,
    },
    // {
    //   logo: math,
    //   name: "Math Wallet",
    //   providerName: "injected",
    //   supportedChains: true,
    //   identifier: "isMetamask",
    // },
    {
        logo: walletconnect,
        name: "Wallet Connect",
        providerName: "walletconnect",
        identifier: 'isWalletConnect',
        supportedChains: [56, 97],
    },
    // {
    //   logo: coinbase,
    //   name: "Coinbase",
    //   providerName: "walletlink",
    //   supportedChains: [1],
    // },
];

export default PROVIDERS;
