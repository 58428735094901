
export class MetaOwnedArea {
    constructor(jsonData) {
        this.id = jsonData.id
        this.owner_wallet_address = jsonData.owner_wallet_address;
        this.image_path = jsonData.image_path;
        this.coordX = jsonData.x;
        this.coordY= jsonData.y;
        this.lt = jsonData.lt;
        this.imagesInSelection = jsonData.imagesInSelection;
        this.selectedParcels = jsonData.selectedParcels;
    }
}
