import {DEFAULT_PARCEL_SIZE, diagLog} from "~/core/services/utils/Constants";
import {MetaWorldManager} from "~/core/services/map/MetaWorldManager";
import {GlobalMakerService} from "~/core/services/GlobalMakerService";

const state = () => {
    return {
        imageToAssign: null,
        zoomLevel: null,
        metaverseId: GlobalMakerService.metaverseId || 1,
    };
};

// Actions
const actions = {
    async mintAndClaimParcels({commit, dispatch}, payload) {
        diagLog(this, 'mintAndClaimParcels PAYLOAD = ', payload);
        return await MetaWorldManager.sharedInstance().mintAndClaimParcels(payload);
    },
    selectImageToAssign({commit}, payload) {
        commit('SET_IMAGE_TO_ASSIGN', payload);
    },
    setZoomLevel({commit}, payload) {
        commit('SET_ZOOM_LEVEL', payload);
    },
};

// Mutations
const mutations = {
    SET_IMAGE_TO_ASSIGN(state, image) {
        state.imageToAssign = image;
    },
    SET_ZOOM_LEVEL(state, zoomLevel) {
        state.zoomLevel = zoomLevel;
        localStorage.setItem('zoomLevel', zoomLevel);
    },
    SET_METAVERSE_ID(state, metaverseId) {
        state.metaverseId = metaverseId;
    },
};

// Getters
const getters = {
    imageToAssign: (state) => state.imageToAssign,
    zoomLevel: (state) => {
        return state.zoomLevel ? state.zoomLevel
            : parseInt(localStorage.getItem('zoomLevel')) ? parseInt(localStorage.getItem('zoomLevel'))
            : DEFAULT_PARCEL_SIZE;
    },
    metaverseId: (state) => state.metaverseId,
};

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations
}

