import { render, staticRenderFns } from "./LanguagesSubMenu.vue?vue&type=template&id=29e72ac1&scoped=true"
import script from "./LanguagesSubMenu.vue?vue&type=script&setup=true&lang=ts"
export * from "./LanguagesSubMenu.vue?vue&type=script&setup=true&lang=ts"
import style0 from "./LanguagesSubMenu.vue?vue&type=style&index=0&id=29e72ac1&prod&scoped=true&lang=scss"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "29e72ac1",
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {ComposedListLanguagesSwitch: require('/root/dashboard/core/components/composed/list/LanguagesSwitch.vue').default,ComposedMenuSubMenuWithOptionalBack: require('/root/dashboard/core/components/composed/menu/SubMenuWithOptionalBack.vue').default})
