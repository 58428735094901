export const OCEAN_COLORS = {
    NORMAL: 'rgb(241, 223, 182)',
    HOVERED: 'rgb(193, 178, 146)',
    SELECTED: 'rgb(179, 98, 217)',
    DISABLED: 'rgb(0, 0, 0)',
};

export const NEAR_OCEAN_COLORS = {
    NORMAL: 'rgb(194, 221, 130)',
    HOVERED: 'rgb(155, 177, 104)',
    SELECTED: 'rgb(179, 98, 217)',
    DISABLED: 'rgb(0, 0, 0)',
};

export const INLAND_COLORS = {
    NORMAL: 'rgb(120, 181, 78)',
    HOVERED: 'rgb(96, 145, 62)',
    SELECTED: 'rgb(179, 98, 217)',
    DISABLED: 'rgb(0, 0, 0)',
};

export const RESERVED_COLORS = {
    NORMAL: 'rgb(199, 206, 215)',
    HOVERED: 'rgb(189, 195, 201)',
    SELECTED: 'rgb(159, 165, 172)',
    DISABLED: 'rgb(0, 0, 0)',
};

export const BUSINESS_COLORS = {
    NORMAL: 'rgb(222, 181, 37)',
    HOVERED: 'rgb(96, 145, 62)',
    SELECTED: 'rgb(179, 98, 217)',
    DISABLED: 'rgb(0, 0, 0)',
}

export const OWNED_COLORS = {
    NORMAL: 'rgb(70, 163, 184)',
    HOVERED: 'rgb(26, 108, 153)',
    SELECTED: 'rgb(179, 98, 217)',
    DISABLED: 'rgb(0, 0, 0)',
};

export const SELF_OWNED_COLORS = {
    NORMAL: 'rgb(26, 108, 153)',
    HOVERED: 'rgb(70, 163, 184)',
    SELECTED: 'rgb(179, 98, 217)',
    DISABLED: 'rgb(0, 0, 0)',
};