
export default {
    name: 'CustomLoader',
    props: {
        textVisible: {
            type: Boolean,
            default: true
        }
    }
}
