
import CustomLoader from '~/components/utils/CustomLoader.vue';
export default {
    name: "GymNewButton",
    components: {
        CustomLoader,
    },
    props: {
        type: {
            type: 'primary' | 'secondary' | 'trietary' | 'primary-white',
            default: 'primary',
        },
        iconOnly: {
            type: Boolean,
            default: false,
        },
        margin: {
            type: String,
            default: null,
        },
        marginTop: {
            type: String,
            default: null,
        },
        marginBottom: {
            type: String,
            default: null,
        },
        marginLeft: {
            type: String,
            default: null,
        },
        marginRight: {
            type: String,
            default: null,
        },
        padding: {
            type: String,
            default: null,
        },
        paddingTop: {
            type: String,
            default: '8px',
        },
        paddingBottom: {
            type: String,
            default: '8px',
        },
        paddingLeft: {
            type: String,
            default: '8px',
        },
        paddingRight: {
            type: String,
            default: '8px',
        },
        icon: {
            type: String,
            default: null,
        },
        align: {
            type: String,
            default: 'center',
        },
        iconGap: {
            type: String,
            default: '16px',
        },
        iconPosition: {
            type: String,
            default: 'left',
        },
        ellipsis: {
            type: Boolean,
            default: false,
        },
        width: {
            type: String,
            default: '100%',
        },
        height: {
            type: String,
            default: '44px',
        },
        corners: {
            type: Boolean,
            default: false,
        },
        borderRadius: {
            type: String,
            default: '8px',
        },
        loading: {
            type: Boolean,
            default: false,
        },
        loadingWithText: {
            type: Boolean,
            default: false,
        },
        buttonType: {
            type: String,
            default: 'submit',
        },
    },
    emits: [
        'click',
    ],
    data () {
        return {

        };
    },
};
