import ApiService from "~/core/services/api-interaction/ApiService";

// States
export const state = () => {
    return {
        productAutoshipBonusLoading: true,
        productAutoshipBonus: {
            turnoversPerMilestone: {}
        },
        productAutoshipBonusHistoryLoading: true,
        productAutoshipBonusHistory: {
            data: [],
            total: 0,
        }
    }
}

// Actions
export const actions = {
    async getProductAutoshipBonus( { commit } ) {
        try {
            const res = await ApiService.get(`${this.$config.binaryTreeApi}/api/incomes-from-subscription/status`);
            commit('SET_PRODUCT_AUTOSHIP_BONUS_DATA', res.data);
        } catch(e) {
            console.log(e);
        }
    },
    async getProductAutoshipBonusHistory( { commit }, { page, perPage } ) {
        commit('SET_PRODUCT_AUTOSHIP_BONUS_HISTORY_LOADING', true);
        try {
            const res = await ApiService.query(`${this.$config.binaryTreeApi}/api/incomes-from-subscription/history`, {
                params: { page, perPage }
            });
            commit('SET_PRODUCT_AUTOSHIP_BONUS_HISTORY_DATA', res.data);
        } catch(e) {
            console.log(e);
        } finally {
            commit('SET_PRODUCT_AUTOSHIP_BONUS_HISTORY_LOADING', false);
        }
    },
}

// Mutations
export const mutations = {
    SET_PRODUCT_AUTOSHIP_BONUS_LOADING: (state, payload) => {
        state.productAutoshipBonusLoading = payload;
    },
    SET_PRODUCT_AUTOSHIP_BONUS_DATA: (state, payload) => {
        const getLeftToQualifyNumber = (required, generated) => {
            return generated > required ? 0 : required - generated;
        }

        (payload?.turnoversPerMilestone || turnoversPerMilestoneDefaultData).forEach((turnover) => {
            let turnoverData = {
                leadingDirectPartner: {
                    noData: true,
                    generatedTurnover: 0,
                    requiredTurnover: parseFloat(turnover.milestone) / 2,
                    leftToQualify: 0,
                    percentage: 0,
                },
                remainingDirectPartner: {
                    noData: true,
                    generatedTurnover: 0,
                    requiredTurnover: parseFloat(turnover.milestone) / 2,
                    leftToQualify: 0,
                    partnersData: [],
                    percentage: 0,
                }
            }

            const descendingSort = turnover.perUser.sort((a, b) => b.generatedTurnover - a.generatedTurnover);

            if (descendingSort.length) {
                let leadingDirectPartnerPercentage = parseFloat(descendingSort[0].generatedTurnover)*100/(parseFloat(turnover?.milestone) / 2);
                turnoverData.leadingDirectPartner = {
                    noData: false,
                    generatedTurnover: descendingSort[0].generatedTurnover,
                    requiredTurnover: parseFloat(turnover?.milestone) / 2,
                    leftToQualify: getLeftToQualifyNumber(parseFloat(turnover?.milestone) / 2, descendingSort[0].generatedTurnover),
                    username: descendingSort[0].username,
                    walletAddress: descendingSort[0].walletAddress,
                    percentage: (leadingDirectPartnerPercentage >= 100 ? 100 : leadingDirectPartnerPercentage) / 2
                };

                let remainingDirectPartner = descendingSort.slice(1);

                if (remainingDirectPartner.length) {
                    const sumGeneratedTurnover = remainingDirectPartner.reduce((acc, obj) => acc + obj.generatedTurnover, 0);
                    let remainingDirectPartnerPercentage = parseFloat(sumGeneratedTurnover)*100/(parseFloat(turnover?.milestone) / 2);

                    turnoverData.remainingDirectPartner = {
                        noData: false,
                        generatedTurnover: sumGeneratedTurnover,
                        requiredTurnover: parseFloat(turnover?.milestone) / 2,
                        leftToQualify: getLeftToQualifyNumber(parseFloat(turnover?.milestone) / 2, sumGeneratedTurnover),
                        percentage: (remainingDirectPartnerPercentage >= 100 ? 100 : remainingDirectPartnerPercentage) / 2,
                        partnersData: remainingDirectPartner.map(partner => {
                            return {
                                generatedTurnover: partner.generatedTurnover,
                                username: partner.username,
                                walletAddress: partner.walletAddress,
                            }
                        })
                    }
                }
            }

            state.productAutoshipBonus.turnoversPerMilestone[`m${turnover?.milestone}`] = {
                ...turnoverData,
                generatedTurnover: turnover.generatedTurnover,
                qualifiedTurnover: turnover.qualifiedTurnover,
            };
        })
    },
    SET_PRODUCT_AUTOSHIP_BONUS_HISTORY_LOADING: (state, payload) => {
        state.productAutoshipBonusHistoryLoading = payload;
    },
    SET_PRODUCT_AUTOSHIP_BONUS_HISTORY_DATA: (state, payload) => {
        state.productAutoshipBonusHistory.data = payload.data;
        state.productAutoshipBonusHistory.total = payload.meta.total;
    },
}

// Getters
export const getters = {
    productAutoshipBonusLoading: ({ productAutoshipBonusLoading }) => productAutoshipBonusLoading,
    productAutoshipBonus: ({ productAutoshipBonus }) => productAutoshipBonus,
    productAutoshipBonusHistory: ({ productAutoshipBonusHistory }) => productAutoshipBonusHistory,
    productAutoshipBonusHistoryLoading: ({ productAutoshipBonusHistoryLoading }) => productAutoshipBonusHistoryLoading,
}

const turnoversPerMilestoneDefaultData = [
    { milestone: 500, generatedTurnover: 0, qualifiedTurnover: 0, perUser: [] },
    { milestone: 1000, generatedTurnover: 0, qualifiedTurnover: 0, perUser: [] },
    { milestone: 5000, generatedTurnover: 0, qualifiedTurnover: 0, perUser: [] },
    { milestone: 10000, generatedTurnover: 0, qualifiedTurnover: 0, perUser: [] },
    { milestone: 25000, generatedTurnover: 0, qualifiedTurnover: 0, perUser: [] },
]
