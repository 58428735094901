import { IMaintenance } from "./IMaintenance";

export class Maintenance {
    constructor(
        public start: Date,
        public end: Date,
        public alertStart: Date,
        public alertEnd: Date,
        public message: string,
        public isGlobalAlert: boolean,
        public isOnlyForAuth: boolean,
    ) {}

    public static fromJson(json: IMaintenance): Maintenance {
        return new Maintenance(
            new Date(json.maintenance_start),
            new Date(json.maintenance_end),
            new Date(json.countdown_start),
            new Date(json.countdown_end),
            json.message,
            json.global_alert,
            json.is_for_auth_users,
        );
    }

    public get isActive() {
        return this.start <= new Date() && new Date() <= this.end;
    }

    public get isAlertActive() {
        return this.alertStart <= new Date() && new Date() <= this.alertEnd;
    }

    public get countdownEndInMilliseconds() {
        return this.end.getTime() - new Date().getTime();
    }

    public get alertEndInMilliseconds() {
        return this.alertEnd.getTime() - new Date().getTime();
    }
}