import { GalleryImage } from "./GalleryImage";

export class Gallery {
    constructor() {
        this.approved = [];
        this.pending = [];
    }

    update(galleryData) {
        this.approved = [];
        this.pending = [];
        for(const imageData of galleryData) {
            const image = new GalleryImage(imageData);
            this[image.getFolder()].push(image);
        }
    }

    add(image) {
        const folder = image.getFolder();
        this[folder].unshift(image);
    }

    remove(image) {
        const folder = image.getFolder();
        const indexInFolder = this[folder].indexOf(image);
        this[folder].splice(indexInFolder, 1);
    }

    approveImage(imageId) {
        const image = this.getImageById(imageId);
        if (image) {
            const folder = image.getFolder();
            const indexInFolder = this[folder].indexOf(image);
            this[folder].splice(indexInFolder, 1);
            this.approved.unshift(image);
        }
    }

    rejectImage(imageId) {
        const image = this.getImageById(imageId);
        if (image) {
            const folder = image.getFolder();
            const indexInFolder = this[folder].indexOf(image);
            this[folder].splice(indexInFolder, 1);
        }

    }

    getImageById(imageId) {
        let image = this.pending.find(img => img.id === imageId);
        if (!image) {
            image = this.approved.find(img => img.id === imageId);
        }

        return image;
    }
}
