import { MetaBundle } from "~/core/models/MetaBundle";
import { MysteryBoxPrizeType } from "../constants/MysteryBoxPrizeType";
import { PrizeNameById } from "../constants/PrizeNameById";
import { PRODUCT_TYPES } from "~/core/services/utils/Constants";

export class MysteryBoxPrize {
    public name: typeof PrizeNameById[keyof typeof PrizeNameById];

    constructor (
        public chance: number,
        public id: MysteryBoxPrizeType,
        public quantity: number = 1,
        public usdPrice: number = 0,
        public bundle: MetaBundle | null = null,
    ) {
        this.name = PrizeNameById[this.id];
    }

    get nftType() {
        if (this.id === MysteryBoxPrizeType.PRIZE_LAND) {
            return PRODUCT_TYPES.PARCEL;
        }
        return PRODUCT_TYPES.BUNDLE;
    }

    get parcelCount() {
        if (this.id === MysteryBoxPrizeType.PRIZE_LAND) {
            return this.quantity;
        }
        return this.bundle?.numberOfParcels * this.quantity;
    }

    get minerCount() {
        if (this.id === MysteryBoxPrizeType.PRIZE_LAND) {
            return 0;
        }
        return this.bundle?.numberOfMiners * this.quantity;
    }
}