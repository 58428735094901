import {MetaWorldManager} from "~/core/services/map/MetaWorldManager";
import {PopupHelper} from "~/core/helpers/PopupHelper";
import InvalidWalletAddressError from "~/core/helpers/InvalidWalletAddressError";

const GAS_FEE_MULTIPLIER = 1.5;

export const state = () => {
    return {
        transactions: {},
        isConfirmed: false,
        txHash: '',
        isTxSuccess: false
    };
};

export const mutations = {
    SET_TRANSACTION(state, {txhash, status, error}) {
        state.transactions = {
            ...state.transactions,
            [txhash]: {
                ...state.transactions[txhash],
                status,
                error,
            },
        };
    },

    SET_IS_CONFIRMED(state, data){
        state.isConfirmed = data
    },

    SET_TX_HASH(state, data){
        state.txHash = data
    },
    SET_SUCCESS_TX(state, value) {
        state.isTxSuccess = value;
    }
};

export const actions = {
    send({commit, rootState, state, dispatch }, {callback, ...options}) {
        const mwm = MetaWorldManager.sharedInstance();

        this.$web3().eth.getAccounts(function(err, accounts){ // TODO check error handling and usages and refactor
            if (err != null) console.error("An error occurred: "+err);
            else if (accounts.length === 0) {
                alert('You were logged out. Please log in to continue using our services');
                return;
            }
        });
        let txhash;
        mwm.showLoading();
        return mwm.assertWallet()
            .then((txAllowanceData) => {
                if (options.gasPrice) {
                    return txAllowanceData;
                } else {
                    const txConfig = {
                        from: txAllowanceData.userWallet,
                        ...options,
                    };
                    const readWeb3 = mwm.readWeb3();
                    return callback.estimateGas(txConfig).then((estimatedGas) => {
                        options.gas = Math.floor(estimatedGas * GAS_FEE_MULTIPLIER);
                    }).then(() => {
                        return readWeb3.eth.getGasPrice();
                    }).then((gasPrice) => {
                        options.gasPrice = gasPrice;
                        return txAllowanceData;
                    });
                }
            })
            .then((txAllowanceData) => {
                if (!txAllowanceData.isAllowed) return;

                return callback
                    .send({
                        from: txAllowanceData.userWallet,
                        ...options,
                    })
                    .on("transactionHash", (hash) => {
                        txhash = hash;
                        commit("SET_IS_CONFIRMED", true)
                        commit("SET_TRANSACTION", {txhash, status: "PENDING", error: null});
                    })
            })
            .then(() => {
                console.log('test in then ')
                commit('SET_SUCCESS_TX', true);
                commit("SET_TRANSACTION", {txhash, status: "CONFIRMED", error: null});
                commit('SET_TX_HASH', txhash);
            })
            .catch(async(error) => {
                if(error instanceof InvalidWalletAddressError) {
                    PopupHelper.showErrorAlert(error.message);
                } else {
                    if(!error.code && txhash) {
                        const tx = await this.$web3().eth.getTransaction(txhash)
                        try {
                            await this.$web3().eth.call(tx, tx.blockNumber);
                        } catch (e) {
                            error = e;
                        }
                    }
                    PopupHelper.showErrorAlert(error.message);
                    throw error;
                }
            })
            .finally(() => {
                MetaWorldManager.sharedInstance().hideLoading();
                commit('SET_SUCCESS_TX', false);
            });
    },
};

export const getters = {
    all: ({transactions}) => transactions,
    isConfirmed: ({isConfirmed}) => isConfirmed,
    txHash: ({txHash}) => txHash,
    isTxSuccess: ({isTxSuccess}) => isTxSuccess,
};
