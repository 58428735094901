import { getStorePollingNames } from "~/core/utilities/store-polling/getStorePollingNames";

export const UNIQUE_IDENTIFIER = 'income' as const;
export const POLLING_NAMES = getStorePollingNames(UNIQUE_IDENTIFIER);

export const STATE = {
    TOTAL_INCOME_LOADING: 'totalIncomeLoading',
    TOTAL_INCOME_LOADING_ERROR: 'totalIncomeLoadingError',
    TOTAL_INCOME_LOADING_PROMISE: 'totalIncomeLoadingPromise',
    TOTAL_BINARY_INCOME_LEFT: 'totalBinaryIncomeLeft',
    TOTAL_BINARY_INCOME_RIGHT: 'totalBinaryIncomeRight',
    BINARY_VALUE: 'binaryValue',
    MATCHING_BONUS_VALUE: 'matchingBonusValue',
    DIRECT_SALES_VALUE: 'directSalesValue',
    ...POLLING_NAMES.state,
} as const;

export const ACTIONS = {
    LOAD_PROCESS_AND_SAVE_TOTAL_INCOME: 'loadProcessAndSaveTotalIncome',
    LOAD_AND_PROCESS_TOTAL_INCOME: 'loadAndProcessTotalIncome',
    LOAD_TOTAL_INCOME: 'loadTotalIncome',
    PROCESS_TOTAL_INCOME: 'processTotalIncome',
    SAVE_TOTAL_INCOME: 'saveTotalIncome',
    CLEAR_TOTAL_INCOME_STATE: 'clearTotalIncomeState',
    ...POLLING_NAMES.actions,
} as const;

export const MUTATIONS = {
    SET_TOTAL_INCOME_LOADING: 'setTotalIncomeLoading',
    SET_TOTAL_INCOME_LOADING_ERROR: 'setTotalIncomeLoadingError',
    SET_TOTAL_INCOME_LOADING_PROMISE: 'setTotalIncomeLoadingPromise',
    SET_TOTAL_BINARY_INCOME_LEFT: 'setTotalBinaryIncomeLeft',
    SET_TOTAL_BINARY_INCOME_RIGHT: 'setTotalBinaryIncomeRight',
    SET_BINARY_VALUE: 'setBinaryValue',
    SET_MATCHING_BONUS_VALUE: 'setMatchingBonusValue',
    SET_DIRECT_SALES_VALUE: 'setDirectSalesValue',
    ...POLLING_NAMES.mutations,
} as const;

export const GETTERS = {
    TOTAL_INCOME_LOADING: 'totalIncomeLoading',
    TOTAL_INCOME_LOADING_ERROR: 'totalIncomeLoadingError',
    TOTAL_INCOME_LOADING_PROMISE: 'totalIncomeLoadingPromise',
    TOTAL_BINARY_INCOME_LEFT: 'totalBinaryIncomeLeft',
    TOTAL_BINARY_INCOME_RIGHT: 'totalBinaryIncomeRight',
    BINARY_VALUE: 'binaryValue',
    MATCHING_BONUS_VALUE: 'matchingBonusValue',
    DIRECT_SALES_VALUE: 'directSalesValue',
    ...POLLING_NAMES.getters,
} as const;
