import { fromWei } from "~/core/helpers/GlobalHelpers";
import { MetaWorldManager } from "~/core/services/map/MetaWorldManager";

export default class GYMNETService {
    /**
     * Get current rate for converting gymnet to usd
     * @example
     * await GYMNETService.getRate(MetaWorldManager.sharedInstance()) => 10
     * @arguments
     * MetaWorldManager instance
     * @result
     * {Number} rate
     */
    public static async getRate(metaWorldManager: any): Promise<number> {
        const tokenPrice = await metaWorldManager.contracts.GymNetwork.methods.getGYMNETPrice().call();
        const rate = fromWei(tokenPrice).toString();
        return parseFloat(rate);
    }

    public static async getBalance(walletAddress: string, metaWorldManager: MetaWorldManager): Promise<string | 0> {
        const contract = metaWorldManager.contracts.GymNetwork;
        const balance = await contract.methods.balanceOf(walletAddress).call();
        return fromWei(balance);
    }
}
