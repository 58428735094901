
import { ACTIONS as BINARY_PROGRESS_ACTIONS } from "../binary/progress/names";
import { ACTIONS as UNILEVEL_PROGRESS_ACTIONS } from "../unilevel/progress/names";
import { ACTIONS, GETTERS, MUTATIONS } from "./names";
import { POLLING_STORE_FUNCTIONS } from "./polling";

export default {
    ...POLLING_STORE_FUNCTIONS.actions,

    async [ACTIONS.LOAD_PROCESS_AND_SAVE_RANK_DATA] (
        { dispatch, commit, getters },
        { save }: {save?: () => boolean} = {},
    ) {
        if (getters[GETTERS.RANK_DATA_LOADING]) {
            return getters[GETTERS.RANK_DATA_LOADING_PROMISE];
        }
        commit(MUTATIONS.SET_RANK_DATA_LOADING, true);
        let processedRankData = null;
        try {
            const loadingPromise = dispatch(ACTIONS.LOAD_AND_PROCESS_RANK_DATA, save);
            commit(MUTATIONS.SET_RANK_DATA_LOADING_PROMISE, loadingPromise);
            processedRankData = await loadingPromise;
      
            commit(MUTATIONS.SET_RANK_DATA_LOADING_ERROR, null);
        } catch (e) {
            console.warn('Failed to fetch rank info', e);
            commit(MUTATIONS.SET_RANK_DATA_LOADING_ERROR, e);
        } finally {
            commit(MUTATIONS.SET_RANK_DATA_LOADING, false);
            commit(MUTATIONS.SET_RANK_DATA_LOADING_PROMISE, null);
            return processedRankData;
        }
    },
    async [ACTIONS.LOAD_AND_PROCESS_RANK_DATA] ({ dispatch }, save?: () => boolean) {
        const binaryAndUnilevelProgressData = await dispatch(ACTIONS.LOAD_RANK_DATA, save);
        return dispatch(ACTIONS.PROCESS_RANK_DATA, binaryAndUnilevelProgressData);
    },
    async [ACTIONS.LOAD_RANK_DATA] ({ dispatch }, save?: () => boolean) {
        const [
            binaryProgressData,
            unilevelProgressData,
        ] = await Promise.all([
            dispatch(
                `affility/user/binary/progress/${BINARY_PROGRESS_ACTIONS.LOAD_PROCESS_AND_SAVE_BINARY_DATA}`,
                { save },
                { root: true },
            ),
            dispatch(
                `affility/user/unilevel/progress/${UNILEVEL_PROGRESS_ACTIONS.LOAD_PROCESS_AND_SAVE_PROGRESS_DATA_BY_UNILEVEL}`,
                { save },
                { root: true },
            ),
        ]);
        return {
            binaryProgressData,
            unilevelProgressData,
        };
    },
    [ACTIONS.PROCESS_RANK_DATA] ({ commit }, data) {
        const {
            binaryProgressData,
            unilevelProgressData,
        } = data;

        return {
            user: {
                ownInvest: binaryProgressData?.totalOwnPurchaseAmount,
                hasIgnoredBonus: !!unilevelProgressData?.haveIgnoredBonus,
            },
            currentOwnInvest: binaryProgressData?.totalOwnPurchaseAmount,
            binaryPartners: {
                left: Number(binaryProgressData?.leftLegRanks || 0),
                right: Number(binaryProgressData?.rightLegRanks || 0),
            },
            minPurchase: {
                left: binaryProgressData?.leftLegMinPurchase,
                right: binaryProgressData?.rightLegMinPurchase,
            },
            unilevelRanks: {
                left: binaryProgressData?.leftUnilevelRanks,
                right: binaryProgressData?.rightUnilevelRanks,
            },
            unilevelTurnover: {
                left: Number(unilevelProgressData?.left || 0),
                right: Number(unilevelProgressData?.right || 0),
            },
            binaryTurnover: {
                left: Number(binaryProgressData?.leftValue || 0),
                right: Number(binaryProgressData?.rightValue || 0),
                weakLeg: Number(binaryProgressData?.binaryCycleSum || 0),
            },
        };
    },
    [ACTIONS.CLEAR_RANK_STATE] ({ commit, dispatch }) {
        commit(MUTATIONS.SET_RANK_DATA_LOADING, false);
        commit(MUTATIONS.SET_RANK_DATA_LOADING_ERROR, null);
        commit(MUTATIONS.SET_RANK_DATA_LOADING_PROMISE, null);
        dispatch(ACTIONS.STOP_POLLING);
    },
};