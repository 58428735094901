
// Components
import {mapGetters} from "vuex";
import GymNewButton from "@/components/utils/form-components/GymNewButton";
import {toNumericFormat, toBN, isMobileDevice} from "@/core/helpers/GlobalHelpers";
import {MetaInventoryContainer} from "@/core/models/MetaInventoryContainer";
import {PopupHelper} from "@/core/helpers/PopupHelper";
import BNBService from "@/pages/finances/wallet/services/BNBService";
import {MetaWorldManager} from "@/core/services/map/MetaWorldManager";
import CustomLoader from "@/components/utils/CustomLoader";

const BUSD_FEE_VALUE = 3;
const GAS_FEE_MULTIPLIER_FOR_INTERNAL = 1.3;

export default {
    name: "DepositWeb2Modal",
    components: {CustomLoader, GymNewButton},
    props: {
        modalTitle: {
            type: String,
            default: '',
        },
        showPopup: Boolean,
        popupData: Object,
        contractName: String,
    },
    data () {
        return {
            gasFeeBnbDollarValue: 0,
            maxGasFee: 0,
            totalAmountBnb: 0,
            totalAmountDollarValue: 0,
            totalAmountAndGasFee: 0,
            bnbPriceInETH: 0,
            toNumericFormat,
            GASInBNB: 0,
            GASPriceInUSD: 0,
            reRenderComponent: false,
            txObject: null,
            isMobile: isMobileDevice(),
            estimatingGas: false,
            bnbBalance: null,
            BUSD_FEE_VALUE,
            resizeHandler: () => { this.isMobile = isMobileDevice(); },
            userBnbBalance: 0
        };
    },
    async mounted () {
        this.bnbPriceInETH = await this.$contracts[this.$metaverseId()].GymNetwork.methods.getBNBPrice().call();
        this.userBnbBalance = await BNBService.getBalance(this.authUser.walletAddress, MetaWorldManager.sharedInstance());

        setInterval (async () => {
            this.userBnbBalance = await BNBService.getBalance(this.authUser.walletAddress, MetaWorldManager.sharedInstance());
        }, 5000)
    },
    computed: {
        ...mapGetters({
            authUser: "application/driver/authUser",
            address: "auth/address",
            isSendTxLoading: "application/driver/isSendTxLoading",
            isSendTxSuccess: "application/driver/isSendTxSuccess"
        }),
        ...mapGetters('map/mapModule', ['metaverseId']),
        getBaseURL () {
            if (process.client) {
                return window.location.origin;
            }
            return null;
        },
        getAmount () {
            return this.popupData?.amount || 0;
        },
        getPurchaseAmount () {
            return this.popupData?.purchaseAmount || 0;
        },
        getTXArguments () {
            return this.popupData?.transactionArguments;
        },
        getContractAddressName () {
            return this.separateAndUpperCase(this.popupData?.method);
        },
        getContactAddress () {
            const cntAddress = this.$contracts[this.metaverseId].SinglePool._address;
            return cntAddress.slice(0, 6) + "..." + cntAddress.slice(-5, cntAddress.length);
        },
        isAmount0 () {
            return this.getAmount === 0;
        },
        isRefundable () {
            return this.popupData?.method === 'payBinaryPass';
        },
        currencyForGasFeeText () {
            if(this.isRefundable) {
                return 'USDT ' + this.$t('Or').toLowerCase() + ' BNB';
            }
            return 'BNB';
        },
        amountAndCurrencyForGasFeeText () {
            if(this.isRefundable) {
                return BUSD_FEE_VALUE + ' USDT ' + this.$t('Or').toLowerCase() + ' ' + this.GASInBNB + ' BNB';
            }
            return this.GASInBNB + ' BNB';
        },
        hasEnoughBNBFeeBalance () {
            return this.userBnbBalance && this.userBnbBalance >= this.GASInBNB;
        },
        hasEnoughBUSDFeeBalance () {
            const isBNBSufficient = this.hasEnoughBNBFeeBalance;
            const gymnetBalance = this.getUserCryptoBalance;
            const amount = this.getAmount;
            const amountWithFee = amount + BUSD_FEE_VALUE;
            return gymnetBalance && (isBNBSufficient && gymnetBalance >= amount ||
                                    !isBNBSufficient && gymnetBalance >= amountWithFee);
        },
        getUserCryptoBalance () {
            return MetaInventoryContainer.sharedInstance().userCryptoBalance?.gymnet ?? 0;
        },
    },
    watch: {
        popupData: {
            handler (value) {
                if (value) {
                    if (this.showPopup) {
                        this.reRenderComponent = true;
                        setTimeout(() => {
                            this.reRenderComponent = false;
                            this.transactionObject();
                        },2000);
                    }

                }
            },
        },
        showPopup: {
            handler (value) {
                if (!value) {
                    this.handleCloseDepositModal();
                }
                if(value) {
                    this.transactionObject();
                    window.addEventListener('resize', this.resizeHandler);
                    this.isMobile = isMobileDevice();
                } else {
                    window.removeEventListener('resize', this.resizeHandler);
                }
            },
            immediate: true,
            deep: true,
        },
        getUserCryptoBalance: {
            handler (value) {
                if (value >= this.getPurchaseAmount) {
                    return this.transactionObject();
                }
            },
        },
    },
    methods: {
        handleCloseDepositModal () {
            this.$store.dispatch('application/popup-control/showWeb2DepositToSPPopup', false);
            this.$store.dispatch('application/popup-control/web2TransactionDepositToSPPopup', null);
            this.GASInBNB = 0;
            this.GASPriceInUSD = 0;
        },
        separateAndUpperCase (str) {
            if (str) {
                // use regex to find all uppercase letters that are not at the beginning of the string
                // and add a space before them
                const separated = str.replace(/([a-z0-9])([A-Z])/g, '$1 $2');

                // convert the separated string to uppercase
                return separated?.toUpperCase();
            }
        },
        async handleConfirm () {
            this.$store.dispatch('application/popup-control/showLoadingPopup', {title: 'Please Wait'}, {root: true});
            this.$store.dispatch('user/subscription/setClosedEvent', false);
            try {
                await this.$store.dispatch('application/driver/internalWalletSendTx', this.txObject);

                if (this.isSendTxSuccess) {
                    PopupHelper.showSuccessNotification(this.$t('Transaction Success'));
                }
            } catch (e) {
                console.log(e);
            } finally {
                this.$store.dispatch('application/popup-control/hideLoadingPopup');
                this.$store.dispatch('user/subscription/setClosedEvent', true);
                if (!this.popupData.doNotResetSendTxSuccess) {
                    this.$store.commit('application/driver/SET_SEND_TX_SUCCESS', false);
                }
                this.handleCloseDepositModal();
            }
        },
        handleReject () {
            this.handleCloseDepositModal();
            this.$emit('handleCloseClaimModal');
        },
        async transactionObject () {
            if(this.getTXArguments) {
                const txArguments = [...this.getTXArguments];
                const transaction = this.$contracts[this.$metaverseId()].SinglePool.methods.depositWithEOP(txArguments[0], txArguments[1]);
                try {
                    this.estimatingGas = true;

                    const gasWithoutMultiplier = await transaction.estimateGas({from: this.authUser.walletAddress});

                    const GASEstimated =  toBN(Math.ceil(gasWithoutMultiplier * GAS_FEE_MULTIPLIER_FOR_INTERNAL));

                    const gasPrice = toBN(await this.$readWeb3().eth.getGasPrice());

                    const bnbAmount = toBN(await this.$readWeb3().eth.getBalance(this.authUser.walletAddress));

                    const totalAmountWithGas = GASEstimated.mul(gasPrice);

                    const GasEstimatedInBNB = this.$readWeb3().utils.fromWei(totalAmountWithGas.toString());

                    this.GASInBNB = GasEstimatedInBNB;

                    this.GASPriceInUSD = parseFloat(GasEstimatedInBNB) * this.bnbPriceInETH;

                    this.bnbBalance = parseFloat(this.$readWeb3().utils.fromWei(bnbAmount));

                    this.txObject = {
                        to: this.$contracts[this.$metaverseId()].SinglePool._address,
                        value: 0,
                        data: transaction.encodeABI(),
                    };
                } finally {
                    this.estimatingGas = false;
                }
            }
        },
    },
};
