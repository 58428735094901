import { TermsAndConditionVersions } from "~/core/constants/affility/store/user/flows/terms-and-conditions/TermsAndConditionVersions";
import { ACTIONS, GETTERS, MUTATIONS, STATE } from "./names";
import { ACTIONS as AUTH_ACTIONS } from '~/store/affility/user/auth/names';
import { OpenTermsAndConditionsByTypeParams, TermsAndConditionsSpecificPopupOpenParams } from "./types";
import ApiService from "~/core/services/api-interaction/ApiService";

export default {
    [ACTIONS.CLEAR_TERMS_AND_CONDITIONS_STATE]({ dispatch }) {
        return dispatch(ACTIONS.CLOSE_TERMS_AND_CONDITIONS);
    },
    [ACTIONS.OPEN_CARDS_TERMS_AND_CONDITIONS](
        { dispatch },
        { acceptable = true, standalone = false, closable = true, contentProps }: TermsAndConditionsSpecificPopupOpenParams = {}) {
        return dispatch(ACTIONS.OPEN_TERMS_AND_CONDITIONS_BY_TYPE, {
            type: TermsAndConditionVersions.Cards,
            acceptable,
            standalone,
            closable,
            contentProps,
        })
    },
    [ACTIONS.OPEN_GENERAL_TERMS_AND_CONDITIONS](
        { dispatch },
        { acceptable = true, standalone = false, closable = true, contentProps }: TermsAndConditionsSpecificPopupOpenParams = {}) {
        return dispatch(ACTIONS.OPEN_TERMS_AND_CONDITIONS_BY_TYPE, {
            type: TermsAndConditionVersions.General,
            acceptable,
            standalone,
            closable,
            contentProps,
        })
    },
    [ACTIONS.OPEN_AFFILIATE_TERMS_AND_CONDITIONS](
        { dispatch },
        { acceptable = true, standalone = false, closable = true, contentProps }: TermsAndConditionsSpecificPopupOpenParams = {}) {
        return dispatch(ACTIONS.OPEN_TERMS_AND_CONDITIONS_BY_TYPE, {
            type: TermsAndConditionVersions.Affiliate,
            acceptable,
            standalone,
            closable,
            contentProps,
        });
    },
    async [ACTIONS.OPEN_TERMS_AND_CONDITIONS_BY_TYPE](
        { commit, dispatch },
        { type, acceptable, standalone, closable, contentProps }: OpenTermsAndConditionsByTypeParams
    ) {
        let resolver, rejecter;
        const modalResultPromise = new Promise((resolve, reject) => {
            resolver = resolve;
            rejecter = reject;
        }).then(() => true).catch(() => false);

        const allClosedPromise = dispatch(ACTIONS.AWAIT_FOR_ALL_CLOSED);
        commit(MUTATIONS.ADD_TERMS_AND_CONDITIONS_PROMISE, modalResultPromise);
        await allClosedPromise;

        commit(MUTATIONS.SET_TERMS_AND_CONDITIONS_TYPE, type);
        commit(MUTATIONS.SET_IS_POPUP_ACCEPTABLE, !!acceptable);
        commit(MUTATIONS.SET_IS_POPUP_STANDALONE, !!standalone);
        commit(MUTATIONS.SET_IS_POPUP_CLOSABLE, !!closable);
        commit(MUTATIONS.SET_TERMS_AND_CONDITIONS_POPUP_CONTENT_PROPS, contentProps);
        commit(MUTATIONS.SET_TERMS_AND_CONDITIONS_POPUP_OPEN, true);
        commit(MUTATIONS.SET_TERMS_AND_CONDITIONS_REJECTER, rejecter);
        commit(MUTATIONS.SET_TERMS_AND_CONDITIONS_RESOLVER, resolver);

        return modalResultPromise;
    },
    async [ACTIONS.CALL_ACCEPT_ACTION_AND_CLOSE](context, status: boolean) {
        const { commit, dispatch, getters } = context;

        if (status) {
            const details = getters[GETTERS.TERMS_AND_CONDITIONS_POPUP_DETAILS];
            commit(MUTATIONS.SET_DEFAULT_ACCEPT_HANDLER_LOADING, true);
            try {
                await details?.acceptDefaultHandler?.(context);
            } catch (e: unknown) {
                commit(MUTATIONS.SET_DEFAULT_ACCEPT_HANDLER_ERROR, e);
                return;
            } finally {
                commit(MUTATIONS.SET_DEFAULT_ACCEPT_HANDLER_LOADING, false);
            }
        }

        return dispatch(ACTIONS.CLOSE_TERMS_AND_CONDITIONS, status);
    },
    [ACTIONS.CLOSE_TERMS_AND_CONDITIONS]({ commit, state }, status: boolean) {
        const key = status ? STATE.TERMS_AND_CONDITIONS_RESOLVER : STATE.TERMS_AND_CONDITIONS_REJECTER;
        state[key]?.();

        commit(MUTATIONS.SET_TERMS_AND_CONDITIONS_TYPE, null);
        commit(MUTATIONS.SET_IS_POPUP_ACCEPTABLE, true);
        commit(MUTATIONS.SET_IS_POPUP_STANDALONE, false);
        commit(MUTATIONS.SET_IS_POPUP_CLOSABLE, true);
        commit(MUTATIONS.SET_TERMS_AND_CONDITIONS_POPUP_CONTENT_PROPS, null);
        commit(MUTATIONS.SET_TERMS_AND_CONDITIONS_POPUP_OPEN, false);
        commit(MUTATIONS.REMOVE_TERMS_AND_CONDITIONS_PROMISE);
        commit(MUTATIONS.SET_TERMS_AND_CONDITIONS_REJECTER, null);
        commit(MUTATIONS.SET_TERMS_AND_CONDITIONS_RESOLVER, null);
    },
    [ACTIONS.AWAIT_FOR_CLOSED]({ state }) {
        return state[STATE.TERMS_AND_CONDITIONS_PROMISES][0];
    },
    [ACTIONS.AWAIT_FOR_ALL_CLOSED]({ state }) {
        return Promise.all(state[STATE.TERMS_AND_CONDITIONS_PROMISES]);
    },
    async [ACTIONS.ACCEPT_GENERAL_TERMS_AND_CONDITIONS_AND_UPDATE_USER]({ dispatch }) {
        await dispatch(ACTIONS.ACCEPT_GENERAL_TERMS_AND_CONDITIONS);
        return dispatch(`affility/user/auth/${AUTH_ACTIONS.UPDATE_USER_AUTH_DATA}`, {
            isGlobalTmsConfirmed: true,
        }, { root: true });
    },
    async [ACTIONS.ACCEPT_GENERAL_TERMS_AND_CONDITIONS]() {
        return ApiService.post('user/update', { is_global_tms_confirmed: true });
    },
    async [ACTIONS.ACCEPT_AFFILIATE_TERMS_AND_CONDITIONS_AND_UPDATE_USER]({ dispatch }) {
        await dispatch(ACTIONS.ACCEPT_AFFILIATE_TERMS_AND_CONDITIONS);
        return dispatch(`affility/user/auth/${AUTH_ACTIONS.UPDATE_USER_AUTH_DATA}`, {
            isAffiliateTmsConfirmed: true,
        }, { root: true });
    },
    async [ACTIONS.ACCEPT_AFFILIATE_TERMS_AND_CONDITIONS]() {
        return ApiService.post('auth/confirm-affiliate-tos', {});
    },
    async [ACTIONS.ACCEPT_CARDS_TERMS_AND_CONDITIONS_AND_UPDATE_USER]({ dispatch }) {
        await dispatch(ACTIONS.ACCEPT_CARDS_TERMS_AND_CONDITIONS);
        return dispatch(`affility/user/auth/${AUTH_ACTIONS.UPDATE_USER_AUTH_DATA}`, {
            isCashFtTermsAndConditionsAccepted: true,
        }, { root: true });
    },
    async [ACTIONS.ACCEPT_CARDS_TERMS_AND_CONDITIONS]() {
        return ApiService.post('/kyc/approved-seen', { type: 'cashftTerms' });
    },
};