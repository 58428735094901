
import {defineComponent} from 'vue';
import {mapGetters} from 'vuex';
import VueCountdown from '@chenfengyuan/vue-countdown'
import SegmentedColoredProgressBar
    from '~/components/utils/progress-bar/segmented-colored-progress-bar/SegmentedColoredProgressBar.vue'
import GymNewButton from '~/components/utils/form-components/GymNewButton.vue'
import PurchaseCountdown from '../../components/purchase-countdown/PurchaseCountdown.vue'
import {ExternalWalletPayment} from '~/core/models/purchase-popup-2/ExternalWalletPayment'
import {getClientRuntimeConfig} from '~/core/helpers/GlobalHelpers'
import {PaymentService} from '~/core/services/PaymentService'
import {PaymentStatus} from '~/core/types/purchase-popup-2/PaymentStatus'
import {RawExternalWalletPayment} from '~/core/types/purchase-popup-2/RawExternalWalletPayment'

const $config = getClientRuntimeConfig();
const EXTERNAL_WALLET_PURCHASE_TIME_MS = $config.externalWalletPurchaseTimeMS ? Number($config.externalWalletPurchaseTimeMS) : 3 * 60 * 60 * 1000;
const PAYMENT_UPDATE_INTERVAL = 5000;

export default defineComponent({
    name: 'ExternalWalletTxScanning',
    components: {
        PurchaseCountdown,
        GymNewButton,
        VueCountdown,
        SegmentedColoredProgressBar
    },
    emits: [
        'onGoToMyOrders',
        'onMissingCryptoAmount',
        'onSuccessFullPayment',
    ],
    props: {
        transactionObject: {
            type: [ExternalWalletPayment],
            required: true,
        },
        interval: {
            type: Number,
            default: EXTERNAL_WALLET_PURCHASE_TIME_MS,
        }
    },
    data() {
        return {
            scanInterval: null,
        };
    },
    computed: {
        ...mapGetters('application/purchase-control', [
            'externalPayment',
        ]),
    },
    mounted() {
        this.setupScanInterval();
    },
    beforeDestroy() {
        this.scanInterval = false;
    },
    methods: {
        handleGoToMyOrders() {
            this.$emit('onGoToMyOrders')
        },
        async setupScanInterval() {
            this.scanInterval = true;
            const paymentUpdateGenerator = PaymentService.listenForPayment(
                this.transactionObject.paymentId,
                PAYMENT_UPDATE_INTERVAL,
                () => this.scanInterval,
            );
            for await (const payment of paymentUpdateGenerator) {
                if (payment) {
                    const rawPayment = payment as RawExternalWalletPayment;
                    if (rawPayment.status === PaymentStatus.EXPIRED ||
                        rawPayment.status === PaymentStatus.CREATED && Number(rawPayment.deviation_amount) < 0 ||
                        rawPayment.grant_transaction_hash !== null) {
                        this.scanInterval = false;
                        if (this.externalPayment || this.transactionObject === this.externalPayment) {
                            this.$store.commit('application/purchase-control/SET_EXTERNAL_PAYMENT',
                                ExternalWalletPayment.fromPaymentAndNewRawData(
                                    this.transactionObject,
                                    rawPayment,
                                )
                            );
                        } else {
                            this.transactionObject.rawPaymentData = rawPayment;
                        }
                        if (rawPayment.status === PaymentStatus.EXPIRED ||
                            rawPayment.status === PaymentStatus.CREATED && Number(rawPayment.deviation_amount) < 0) {
                            this.$emit('onMissingCryptoAmount');
                        } else if (rawPayment.grant_transaction_hash !== null) {
                            this.$emit('onSuccessFullPayment');
                        }
                    }
                }
            }
        },
    },
})
