import { AUTH_FLOW_STEPS } from "~/core/services/utils/Constants";

const state = () => {
    return {
    };
};

// Actions
const actions = {
    async handleAction ({dispatch}, action) {
        const actions = await dispatch('parseAction', action);
        for(const action of actions) {
            await dispatch(action.action, action.params);
        }
    },
    parseAction (_ctx, actionString) {
        const actions = actionString.split(';');
        const result = [];
        for(const action of actions) {
            const oneAction = {};
            const actionAndParams = action.split(':');
            oneAction.action = actionAndParams[0];
            oneAction.params = actionAndParams[1]?.split(',') || [];
            result.push(oneAction);
        }
        return result;
    },
    login (_ctx, params) {
        const [withRedirect] = params || [];
        if(withRedirect) {
            this.$router.push({ path: '/auth/login' });
        } else {
            this.dispatch('application/driver/setAuthStep', AUTH_FLOW_STEPS.SIGN_IN_WITH_EMAIL);
            this.dispatch('application/popup-control/changeConnectWalletPopupStatus', true);
        }
    },
    async goToCashFT ({dispatch}, params) {
        const [path] = params || [];
        if(!path) { 
            const token = await dispatch('user/easyLoginToCashFT', null, {root: true});
            if (token) {
                window.open(
                    `https://cashft.io/verify-easy-login/?token=${token}`,
                    '_blank',
                );
            }
        } else {
            window.open(
                `https://cashft.io${path}`,
                '_blank',
            );
        }
    },
    async redirectTo ({dispatch}, params) {
        const [path, scrollTo, offset, timeout] = params;
        const initialRoutePath = this.$router.currentRoute.path.replace(/\/$/, '');
        if(scrollTo) {
            if(path !== initialRoutePath) {
                const removeScrollTo = this.$router.afterEach(async () => {
                    await dispatch('scrollTo', [scrollTo, offset, timeout]);
                    removeScrollTo();
                });
            }
        }
        await this.$router.push({ path });
        if(path === initialRoutePath) {
            await dispatch('scrollTo', [scrollTo, offset, timeout]);
        }
    },
    scrollTo (_ctx, params) {
        const [elementSelector, offset, timeout] = params;
        setTimeout(() => {
            const anchorElement = document.querySelector(elementSelector);
            if (anchorElement) {
                let position = anchorElement.offsetTop;
                if(offset) {
                    position += Number(offset ?? 0);
                }
                window.scrollTo({
                    top: position,
                    behavior: 'smooth',
                });
            }
        }, timeout || 0);
    },
    scrollIntoView (_ctx, params) {
        const [elementSelector, timeout, block, inline, behavior] = params;
        setTimeout(() => {
            const anchorElement = document.querySelector(elementSelector);
            if (anchorElement) {
                anchorElement.scrollIntoView({
                    behavior: behavior || 'smooth',
                    block: block || 'start',
                    inline: inline || 'nearest',
                });
            }
        }, timeout || 0);
    },
    openMysteryBox ({dispatch}, params) {
        dispatch('application/mystery-box/setMysteryBoxOptionsPopupVisible', true, {root: true});
    },
};

// Mutations
const mutations = {};

// Getters
const getters = {};

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations,
};

