import {CurrencyName} from '~/pages/finances/wallet/models/CurrencyName';
import {PaymentService} from "~/core/services/PaymentService";
import {MetaWorldManager} from "~/core/services/map/MetaWorldManager";
import GYMNETService from '~/pages/finances/wallet/services/GYMNETService';

export default class ExchangeService {
    private static rates: Map<CurrencyName, number> = new Map();
    /*
     * Store currency rate and try to fetch if empty
     * @example
     * ExchangeService.getRate('RUB'); //0.5
     * @return
     * {number} rate
     */
    public static async getRate(currency: CurrencyName): Promise<number> {
        try {
            if (ExchangeService.rates.has(currency)) {
                // take cached rates
                return ExchangeService.rates.get(currency) ?? 0; //TODO same as up
            }
            if (currency === 'GYMNET') {
                const rate = await GYMNETService.getRate(MetaWorldManager.sharedInstance());
                ExchangeService.rates.set(currency, rate);
            } else if (currency === 'BNB') {
                const {data: {rate}} = await PaymentService.fetchExchangeRate(currency) ?? {data: {rate: 0}} //TODO fix with undefined or wrong response
                ExchangeService.rates.set(currency, 1 / rate)
            } else if (['BPX', 'BUSD', 'USDT', 'FIL', 'ETH', 'MNBRG', 'BTC'].includes(currency)) {
                const rate = await PaymentService.getTokenPriceUsd(currency);
                ExchangeService.rates.set(currency, rate);
            } else {
                const {data: {rate}} = await PaymentService.fetchExchangeRate(currency) ?? {data: {rate: 0}}; //TODO fix with undefined or wrong response
                ExchangeService.rates.set(currency, rate);
            }
            return ExchangeService.rates.get(currency) ?? 0; //TODO same as up
        } catch (e) {
            return 0;
        }
    }

}
