import { MUTATIONS, STATE } from "./names";

export default {
    [MUTATIONS.SET_IS_SUCCESS_MODAL_OPEN](state: any, isOpen: boolean) {
        state[STATE.IS_SUCCESS_MODAL_OPEN] = isOpen;
    },
    [MUTATIONS.SET_SUCCESS_MODAL_DESC](state: any, desc: string) {
        state[STATE.SUCCESS_MODAL_DESC] = desc;
    },
    [MUTATIONS.SET_SUCCESS_MODAL_TITLE](state: any, title: string) {
        state[STATE.SUCCESS_MODAL_TITLE] = title;
    },
    [MUTATIONS.SET_SUCCESS_MODAL_BTN_TEXT](state: any, text: string) {
        state[STATE.SUCCESS_MODAL_BTN_TEXT] = text;
    },
    [MUTATIONS.SET_SUCCESS_MODAL_BUTTON_VISIBLE](state: any, isVisible: boolean) {
        state[STATE.SUCCESS_MODAL_BUTTON_VISIBLE] = isVisible;
    },
    [MUTATIONS.SET_SUCCESS_MODAL_CLOSE_BY_CLICK_MODAL](state: any, close: boolean) {
        state[STATE.SUCCESS_MODAL_CLOSE_BY_CLICK_MODAL] = close;
    },
    [MUTATIONS.SET_SUCCESS_MODAL_CLOSE_ON_PRESS_ESCAPE](state: any, close: boolean) {
        state[STATE.SUCCESS_MODAL_CLOSE_ON_PRESS_ESCAPE] = close;
    },
    [MUTATIONS.SET_SUCCESS_MODAL_TIMEOUT_ID](state: any, id: number) {
        state[STATE.SUCCESS_MODAL_TIMEOUT_ID] = id;
    },
    [MUTATIONS.ADD_TO_QUEUE] (state:any, modalData:any) {
        state[STATE.QUEUE].push(modalData);
    },
    [MUTATIONS.REMOVE_FROM_QUEUE] (state:any) {
        state[STATE.QUEUE].shift();
    },
    [MUTATIONS.SET_CURRENT_MODAL] (state:any, modalData:any) {
        state[STATE.SUCCESS_MODAL_TITLE] = modalData.title;
        state[STATE.SUCCESS_MODAL_DESC] = modalData.desc;
        state[STATE.SUCCESS_MODAL_BTN_TEXT] = modalData.buttonText;
        state[STATE.SUCCESS_MODAL_BUTTON_VISIBLE] = modalData.buttonVisible;
        state[STATE.SUCCESS_MODAL_CLOSE_BY_CLICK_MODAL] = modalData.closeByClickOutside;
        state[STATE.SUCCESS_MODAL_CLOSE_ON_PRESS_ESCAPE] = modalData.closeByEsc;
    },
};