
import {defineComponent} from 'vue';
import {toCurrencyFormat} from '@/core/helpers/GlobalHelpers';
import GymNewButton from '~/components/utils/form-components/GymNewButton.vue';
import Asset from '~/pages/finances/wallet/models/Asset';
import {toNumericFormat} from '~/core/helpers/GlobalHelpers';
import {UserBalance} from '~/core/models/purchase-popup-2/UserBalance';
import MunicipalityTx from '~/core/models/purchase-popup-2/MunicipalityTx';
import Web3MunicipalityTx from '~/core/models/purchase-popup-2/Web3MunicipalityTx';
import {UseExtraBalancesTypes} from "~/core/models/purchase-popup-2/UseExtraBalancesTypes";

export type StepSelectCurrencyComponent = {
    userBalance: UserBalance,
    transactionObject: MunicipalityTx | Web3MunicipalityTx,
    mustUseUtility: boolean;
    mustUseGymnet: boolean;
    useUtilityBalance: boolean;
    toCurrencyFormat: typeof toCurrencyFormat;
    usdtAsset: Asset,
    utilityAsset: Asset,
    handleContinue: () => void;
}

export default defineComponent<keyof StepSelectCurrencyComponent, StepSelectCurrencyComponent>({
    name: 'CurrencySelection',
    components: {GymNewButton},
    props: {
        userBalance: {
            type: UserBalance,
            required: true,
        },
        transactionObject: {
            type: [MunicipalityTx, Web3MunicipalityTx],
            required: true,
        },
        mustUseUtility: {
            type: Boolean,
            required: true,
        },
        mustUseGymnet: {
            type: Boolean,
            required: true,
        },
        activeAccount: {
            type: String,
            required: true,
        }
    },
    emits: [
        'onCurrencySelected',
        'onClickBack',
        'onStartLoading',
        'onStopLoading',
    ],
    data() {
        return {
            toCurrencyFormat,
            toNumericFormat,
            useUtilityBalance: false,
            utilityCanBeUnused: false,
            selectedExtraBalance: null,
            useExtraBalances: false,
            UseExtraBalancesTypes,
        };
    },
    computed: {
        usdtAsset() {
            switch (this.selectedExtraBalance) {
                case UseExtraBalancesTypes.BALANCE_GYMNET:
                    return this.transactionObject.priceInfo.splitAmountGymnet.usdt;
                case UseExtraBalancesTypes.BALANCE_UTILITY:
                    return this.transactionObject.priceInfo.splitAmountUtility.usdt;
                default:
                    return this.transactionObject.priceInfo.totalAmount
            }
        },
        extraBalanceAsset() {
            switch (this.selectedExtraBalance) {
                case UseExtraBalancesTypes.BALANCE_GYMNET:
                    return this.transactionObject.priceInfo.splitAmountGymnet.gymnet;
                case UseExtraBalancesTypes.BALANCE_UTILITY:
                    return this.transactionObject.priceInfo.splitAmountUtility.gymnet;
            }
        },
        utilityAsset() {
            return this.transactionObject.priceInfo.splitAmountUtility.gymnet;
        },
        gymnetAsset() {
            return this.transactionObject.priceInfo.splitAmountGymnet.gymnet;
        },
        isUtilityBalanceSufficient() {
            return this.userBalance.utility.value >= this.utilityAsset.value;
        },
        isGymnetBalanceSufficient() {
            return this.userBalance.gymnet.value >= this.gymnetAsset.value;
        },
        mustUseExtraBalancesValueUpdates() {
            return `${this.mustUseUtility} - ${this.mustUseGymnet}`;
        }
    },
    watch: {
        mustUseExtraBalancesValueUpdates: {
            handler() {
                this.onMustUseValueChange();
            },
            immediate: true,
        },
        activeAccount: {
            handler() {
                this.onMustUseValueChange();
            },
        },
    },
    methods: {
        async handleContinue(): Promise<void> {
            try {
                this.$emit('onStartLoading');
                this.transactionObject.setExtraBalance(this.selectedExtraBalance);
                const amountToCheck = this.usdtAsset.value;
                await this.transactionObject.checkAllowance(amountToCheck);
                if (this.transactionObject.isUsingExtraBalance && this.transactionObject._isUsingExtraBalance === UseExtraBalancesTypes.BALANCE_GYMNET) {
                    const gymnetAmountToCheck = this.transactionObject.priceInfo.splitAmountGymnet.gymnet.value;
                    await this.transactionObject.checkGymnetAllowance(gymnetAmountToCheck);
                }
                this.$emit('onCurrencySelected');
                this.$emit('onStopLoading');
            } catch (e) {
                this.$emit('onStopLoading', e);
            }
        },
        onMustUseValueChange() {
            const canUseGymnetOrUtility = this.transactionObject.canUseGymnetOrUtility;
            if (canUseGymnetOrUtility &&
                ((this.mustUseUtility && this.isUtilityBalanceSufficient) || (this.mustUseGymnet && this.isGymnetBalanceSufficient))
            ) {
                this.useExtraBalances = true;
                this.handleExtraBalanceChange();
            }
        },
        handleExtraBalanceChange() {
            if (this.useExtraBalances) {
                if (this.isUtilityBalanceSufficient && this.transactionObject.canUseUtility) {
                    this.selectedExtraBalance = UseExtraBalancesTypes.BALANCE_UTILITY;
                } else if (this.isGymnetBalanceSufficient) {
                    this.selectedExtraBalance = UseExtraBalancesTypes.BALANCE_GYMNET;
                }
            } else {
                this.selectedExtraBalance = null;
            }
        }
    }
});

