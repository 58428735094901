import WalletAddress from './WalletAddress';
import { CurrencyName } from '~/pages/finances/wallet/models/CurrencyName';
import ApiService from '~/core/services/api-interaction/ApiService';

export default class Asset {
    public _value: number;
    public _rate = 0;
    public _metaWorldManager: any; // TODO: fix it
    public _isInsufficient = false;
    public _network = 'BEP20'; // TODO fix network name

    constructor (
        public id: number | null,
        public name: string | null | undefined,
        public currency: CurrencyName,
        public icon: string,
        value: number,
        rate: number,
        isInsufficient: boolean,
        metaWorldManager: any,
        network?: string
    ) {
        this._value = (Number.isNaN(value)) ? 0 : value;
        this._rate = rate;
        this._isInsufficient = isInsufficient;
        Object.defineProperty(this, '_metaWorldManager', {
            enumerable: false,
            writable: true,
            configurable: true
        }); // Fixes Vuex store hangs if Asset gets into store with this property
        this._metaWorldManager = metaWorldManager;
        if (network) {
            this._network = network;
        }
    }

    async send (to: WalletAddress): Promise<unknown> {
        const transaction = this._getSendTransaction();
        const amount = this._value.toString();
        const sendTransaction = { amount, to, ...transaction };
        return await ApiService.post('internal-wallet/send', sendTransaction);
    }

    _getSendTransaction (): object {
        throw new Error('not implemented');
    }

    get usd (): number {
        return this._value * this._rate;
    }

    get value (): number {
        return this._value;
    }

    get isInsufficient (): boolean {
        return this._isInsufficient;
    }

    subtract (subtrahend: number): Asset {
        const isInsufficient = this._value - subtrahend < 0;
        return new Asset(
            this.id,
            this.name,
            this.currency,
            this.icon,
            subtrahend,
            this._rate,
            isInsufficient,
            this._metaWorldManager
        );
    }

    copyRate (_: Asset): Asset {
        throw new Error('Parent method not implemented');
    }

    get isImported (): boolean {
        return this._rate === 0; // TODO: fix it
    }

    copy (value: number): Asset {
        return new Asset(this.id, this.name, this.currency, this.icon, value, this._rate, this.isInsufficient, this._metaWorldManager, this._network);
    }

    get network (): string {
        return this._network;
    }

    get rate (): number {
        return this._rate;
    }
}
