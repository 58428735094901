
import LoadingIcon from "~/assets/images/icons/loading.svg?inline";

export default {
    name: "LoadingPopup",
    components: {
        LoadingIcon,
    },
    props: {
        popupVisible: Boolean,
        popupData: Object
    },
}
