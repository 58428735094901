import { ACTIONS, GETTERS, MUTATIONS } from "./names";
import { POLLING_STORE_FUNCTIONS } from "./polling";
import ApiService from "~/core/services/api-interaction/ApiService";

export default {
    ...POLLING_STORE_FUNCTIONS.actions,

    async [ACTIONS.LOAD_PROCESS_AND_SAVE_TOTAL_INCOME] (
        { dispatch, commit, getters },
        { save }: {save?: () => boolean } = {},
    ) {
        if (getters[GETTERS.TOTAL_INCOME_LOADING]) {
            return getters[GETTERS.TOTAL_INCOME_LOADING_PROMISE];
        }
        commit(MUTATIONS.SET_TOTAL_INCOME_LOADING, true);
        let processedTeamData = null;
        try {
            const loadingPromise = dispatch(ACTIONS.LOAD_AND_PROCESS_TOTAL_INCOME);
            commit(MUTATIONS.SET_TOTAL_INCOME_LOADING_PROMISE, loadingPromise);
            processedTeamData = await loadingPromise;

            if (!save || save()) {
                await dispatch(ACTIONS.SAVE_TOTAL_INCOME, processedTeamData);
            }

            commit(MUTATIONS.SET_TOTAL_INCOME_LOADING_ERROR, null);
        } catch (e) {
            console.warn('Failed to fetch team info', e);
            commit(MUTATIONS.SET_TOTAL_INCOME_LOADING_ERROR, e);
        } finally {
            commit(MUTATIONS.SET_TOTAL_INCOME_LOADING, false);
            commit(MUTATIONS.SET_TOTAL_INCOME_LOADING_PROMISE, null);
            return processedTeamData;
        }
    },
    async [ACTIONS.LOAD_AND_PROCESS_TOTAL_INCOME] ({ dispatch }) {
        const data = await dispatch(ACTIONS.LOAD_TOTAL_INCOME);
        return dispatch(ACTIONS.PROCESS_TOTAL_INCOME, data);
    },
    async [ACTIONS.LOAD_TOTAL_INCOME] (_ctx) {
        const response = await ApiService.get(`${this.$config.binaryTreeApi}/api/affility/total-income`);
        return response.data;
    },
    [ACTIONS.PROCESS_TOTAL_INCOME] (_ctx, data) {
        return {
            binaryIncome: Number(data?.binaryIncome) || 0,
            directIncome: Number(data?.directIncome) || 0,
            matchingIncome: Number(data?.matchingIncome) || 0,
            turnoverLeft: Number(data?.turnover?.left) || 0,
            turnoverRight: Number(data?.turnover?.right) || 0,
        };
    },
    [ACTIONS.SAVE_TOTAL_INCOME] (
        { commit },
        {
            binaryIncome = 0,
            matchingIncome = 0,
            directIncome = 0,
            turnoverLeft = 0,
            turnoverRight = 0,
        } = {}) {
        commit(MUTATIONS.SET_BINARY_VALUE, binaryIncome);
        commit(MUTATIONS.SET_MATCHING_BONUS_VALUE, matchingIncome);
        commit(MUTATIONS.SET_DIRECT_SALES_VALUE, directIncome);
        commit(MUTATIONS.SET_TOTAL_BINARY_INCOME_LEFT, turnoverLeft);
        commit(MUTATIONS.SET_TOTAL_BINARY_INCOME_RIGHT, turnoverRight);
    },
    [ACTIONS.CLEAR_TOTAL_INCOME_STATE] ({ commit, dispatch }) {
        commit(MUTATIONS.SET_TOTAL_BINARY_INCOME_LEFT, null);
        commit(MUTATIONS.SET_TOTAL_BINARY_INCOME_RIGHT, null);
        commit(MUTATIONS.SET_BINARY_VALUE, null);
        commit(MUTATIONS.SET_MATCHING_BONUS_VALUE, null);
        commit(MUTATIONS.SET_DIRECT_SALES_VALUE, null);
        dispatch(ACTIONS.STOP_POLLING);
        commit(MUTATIONS.SET_TOTAL_INCOME_LOADING, false);
        commit(MUTATIONS.SET_TOTAL_INCOME_LOADING_ERROR, false);
        commit(MUTATIONS.SET_TOTAL_INCOME_LOADING_PROMISE, null);
    },
};
