var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('ul',{staticClass:"d-flex flex-wrap",class:[
        `flex-${_setup.direction}`,
        {
            'align-items-center': _setup.direction === 'row',
            'align-items-stretch': _setup.direction === 'column',
        }
    ]},_vm._l((_setup.contracts),function(contract){return _c('li',{key:contract.address,staticClass:"mw-100"},[_c('BaseLinkMenu',{staticClass:"flex-fill p-0 w-100",attrs:{"tag":"button","disabledWithIndividualOpacity":!contract.clickable},nativeOn:{"click":function($event){contract.clickable && _vm.$emit('click', contract)}}},[_c('ComposedCardContract',{staticClass:"w-100",attrs:{"not-disabled-styled":"","icon":contract.icon,"title":contract.title,"address":contract.address,"copiable":contract.copiable}})],1)],1)}),0)
}
var staticRenderFns = []

export { render, staticRenderFns }