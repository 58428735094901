import { MUTATIONS, STATE } from "./names";
import { POLLING_STORE_FUNCTIONS } from "./polling";
import type { State } from "./types";

export default {
    [MUTATIONS.SET_RANK_DATA_LOADING](state: State, payload: boolean) {
        state[STATE.RANK_DATA_LOADING] = payload;
    },
    [MUTATIONS.SET_RANK_DATA_LOADING_ERROR](state: State, payload: string) {
        state[STATE.RANK_DATA_LOADING_ERROR] = payload;
    },
    [MUTATIONS.SET_RANK_DATA_LOADING_PROMISE](state: State, payload: Promise<void>) {
        state[STATE.RANK_DATA_LOADING_PROMISE] = payload;
    },
    ...POLLING_STORE_FUNCTIONS.mutations,
}