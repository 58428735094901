
import PROVIDERS from "~/constants/providers";
import GymNewButton from "~/components/utils/form-components/GymNewButton.vue";
import {PopupHelper} from "~/core/helpers/PopupHelper";
import MunicipalityTx from "~/core/models/purchase-popup-2/MunicipalityTx";
import Web3MunicipalityTx from "~/core/models/purchase-popup-2/Web3MunicipalityTx";
import { ExternalWalletPayment } from "~/core/models/purchase-popup-2/ExternalWalletPayment";

export default {
    name: 'Web3WalletConnection',
    components: {
        GymNewButton,
    },
    props: {
        canBuyFor: {
            type: Function,
            default: null,
        },
        transactionObject: {
            type: [MunicipalityTx, Web3MunicipalityTx, ExternalWalletPayment],
            required: true,
        },
    },
    emits: [
        'onStartLoading',
        'onStopLoading',
        'onWalletConnectionDone',
        'onClickBack',
    ],
    data() {
        return {
            isWalletIncorrect: false,
            walletConnectionError: null,
        };
    },
    computed: {
        providers () {
            let identifier = null;
            return PROVIDERS.filter((provider) => {
                if (provider.isInstalled && !provider.isInstalled()) {
                    return false;
                }
                if (window.ethereum && provider.identifier) {
                    identifier = window.ethereum[provider.identifier];
                }
                return (
                    provider.providerName !== "injected" || identifier
                );
            });
        },
    },
    methods: {
        handleOpenGuide (index: number) {
            if(index === 0) {
                this.$store.dispatch('application/popup-control/openMetaMaskInstallationPopup', true);
            }
        },
        async handleConnect (index) {
            const { providerName } = this.providers[index];
            this.$emit('onStartLoading');
            let provider = null;
            let addresses = null;
            try {
                try {
                    const providerData = await this.$createWeb3Provider(providerName, false);
                    provider = providerData.providerObject;
                    addresses = await this.$enableProvider(providerData);
                    if (this.canBuyFor) {
                        try {
                            const canBuyFor = await this.canBuyFor(this.transactionObject.owner, addresses[0]);
                            if (!canBuyFor) {
                                this.isWalletIncorrect = true;
                                this.$emit('onStopLoading');
                                return;
                            }
                        } catch (e) {
                            this.isWalletIncorrect = true;
                            this.walletConnectionError = e.message;
                            this.$emit('onStopLoading');
                            return;
                        }
                    }
                } catch (e) {
                    if (e.code !== 4001) {
                        throw e;
                    } else {
                        this.$emit('onStopLoading');
                        return;
                    }
                }
                this.$emit('onWalletConnectionDone', addresses[0], provider);
            } catch (e) {
                PopupHelper.showErrorAlert(this.$t('Something went wrong, please refresh the page and try again'));
                this.$emit('onStopLoading');
            }
        },
    },
}
