export enum OrderPaymentMethod {
    INTERNAL= "internal",
    SUBSCRIPTION_POINTS = 'pap',
    WEB3 = 'web3',
    EXTERNAL = 'external',
};

export const  OrderPaymentType = {
    10: 'Internal wallet',
    20: 'Internal wallet',
    30: 'PAP Balance',
    40: 'Web3 Wallet',
    50: 'External wallet',
    60: 'Internal wallet',
};
