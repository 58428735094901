export const STATE = {
} as const;

export const ACTIONS = {
    SHOW_ERROR_MODAL: 'showErrorModal',
    SHOW_INFO_MODAL: 'showInfoModal',
    SHOW_SUCCESS_MODAL: 'showSuccessModal',
    SHOW_CONFIRM_MODAL: 'showConfirmModal',
} as const;

export const MUTATIONS = {
} as const;

export const GETTERS = {
} as const;