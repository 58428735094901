import { MUTATIONS, STATE } from "./names";
import { POLLING_STORE_FUNCTIONS } from "./polling";
import type { State } from "./types";

export default {
    [MUTATIONS.SET_LEFT_LEG_MIN_PURCHASE_SATISFIED] (state: State, payload: boolean) {
        state[STATE.LEFT_LEG_MIN_PURCHASE_SATISFIED] = payload;
    },
    [MUTATIONS.SET_RIGHT_LEG_MIN_PURCHASE_SATISFIED] (state: State, payload: boolean) {
        state[STATE.RIGHT_LEG_MIN_PURCHASE_SATISFIED] = payload;
    },
    [MUTATIONS.SET_LEFT_LEG_PARTNERS_COUNT] (state: State, payload: number) {
        state[STATE.LEFT_LEG_PARTNERS_COUNT] = payload;
    },
    [MUTATIONS.SET_RIGHT_LEG_PARTNERS_COUNT] (state: State, payload: number) {
        state[STATE.RIGHT_LEG_PARTNERS_COUNT] = payload;
    },
    [MUTATIONS.SET_LEFT_LEG_TURNOVER] (state: State, payload: number) {
        state[STATE.LEFT_LEG_TURNOVER] = payload;
    },
    [MUTATIONS.SET_RIGHT_LEG_TURNOVER] (state: State, payload: number) {
        state[STATE.RIGHT_LEG_TURNOVER] = payload;
    },
    [MUTATIONS.SET_UNILEVEL_RANKS_BY_RIGHT_LEG_SATISFIED] (state: State, payload: boolean) {
        state[STATE.UNILEVEL_RANKS_BY_RIGHT_LEG_SATISFIED] = payload;
    },
    [MUTATIONS.SET_UNILEVEL_RANKS_BY_LEFT_LEG_SATISFIED] (state: State, payload: boolean) {
        state[STATE.UNILEVEL_RANKS_BY_LEFT_LEG_SATISFIED] = payload;
    },
    [MUTATIONS.TOTAL_OWN_PURCHASE] (state: State, payload: number) {
        state[STATE.TOTAL_OWN_PURCHASE] = payload;
    },
    [MUTATIONS.SET_BINARY_PROGRESS_LOADING] (state: State, payload: boolean) {
        state[STATE.BINARY_PROGRESS_LOADING] = payload;
    },
    [MUTATIONS.SET_BINARY_PROGRESS_LOADING_ERROR] (state: State, payload: Error) {
        state[STATE.BINARY_PROGRESS_LOADING_ERROR] = payload;
    },
    [MUTATIONS.SET_BINARY_PROGRESS_LOADING_PROMISE] (state: State, payload: Promise<unknown>) {
        state[STATE.BINARY_PROGRESS_LOADING_PROMISE] = payload;
    },
    ...POLLING_STORE_FUNCTIONS.mutations,
};