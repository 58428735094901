import { CashFtBundleNamesById } from "../constants/CashFtBundleNamesById";
import { MetaBundle } from "~/core/models/MetaBundle";

export class CashFtCardBundle extends MetaBundle {
    public readonly isCashFtBundle = true;
    public name: string;

    constructor(
        public readonly id: string,
        public readonly numberOfParcels: number,
        public readonly isCurrentlyActive: boolean,
        public totalPurchasedCount: number,
        public price: number,
        public minimumGymnetDeposit: number,
    ) {
        super({
            name: CashFtBundleNamesById[id],
            sc_id: id,
            number_of_parcels: numberOfParcels,
            price,
            discounted_price: price,
        });
        this.name = CashFtBundleNamesById[id];
    }
}