import { MUTATIONS, STATE } from "./names";
import { PromoItemType, PromosNamesEnum } from "~/store/affility/promos/types";

export default {
    [MUTATIONS.SET_PROMOS] (state, {combinedResponse,promosData}) {
        const promosResult = [];

        promosData.forEach((value) => {
            const reward = combinedResponse[value.id];
            const currentTime = Date.now();
            const dateInMilliseconds = value?.expiresAt || 0;
            const status = dateInMilliseconds > currentTime || value.id === PromosNamesEnum.RANK_ACHIEVEMENT;

            if (reward || status) {
                promosResult.push({
                    ...value,
                    rewards: reward,
                    status,
                });
            }
        });
        state[STATE.PROMOS] = sortPromos(promosResult);
    },
    [MUTATIONS.SET_PROMOS_LOADING](state, payload) {
        state[STATE.PROMOS_LOADING] = payload;
    },
    [MUTATIONS.SET_CLEAR_PROMOS](state) {
        state[STATE.PROMOS_LOADING] = true;
        state[STATE.PROMOS] = [];
    }
}

function sortPromos(array: PromoItemType[]) {
    const newArray: PromoItemType[] = [...array];
    return newArray.sort((a: any, b: any) => {
        return b.status - a.status;
    });
}
