var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c(_setup.componentType,{tag:"component",staticClass:"icon",class:[`icon-${_setup.size}${_setup.sizeOnlySide ? `-${_setup.sizeOnlySide}` : '' }`, {
        [`${_setup.theme}`]: !!_setup.theme,
        activatable: !!_setup.activatable,
        active: !!_setup.active,
    }],style:({
        '--icon-color': `var(--${_setup.color})`,
    }),attrs:{"fontControlled":false}})
}
var staticRenderFns = []

export { render, staticRenderFns }