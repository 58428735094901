
export class GlobalMakerService {
    static $cookies: any;
    static $store: any;
    static $gtag: any;
    static $auth: any;
    static $broadcast: BroadcastChannel;
    static $i18n: any;
    static $config: any;
    static metaverseId: number;
    static $router: any;

    static init({store, cookies, gtag, auth, broadcast, i18n, config, metaverseId, router}: any) {
        this.$store = store;
        this.$cookies = cookies;
        this.$gtag = gtag;
        this.$auth = auth;
        this.$broadcast = broadcast;
        this.$i18n = i18n;
        this.$config = config;
        this.metaverseId = metaverseId;
        this.$router = router;
    }

    static setCookies(value: any) {
        GlobalMakerService.$cookies = value;
    }

    static setStore(value: any) {
        GlobalMakerService.$store = value;
    }

    static setGtag(value: any) {
        GlobalMakerService.$gtag = value;
    }

    static setAuth(value: any) {
        GlobalMakerService.$auth = value;
    }

    static setBroadcast(value: BroadcastChannel) {
        GlobalMakerService.$broadcast = value;
    }

    static setI18n(value: any) {
        GlobalMakerService.$i18n = value;
    }

    static setConfig(value: any) {
        GlobalMakerService.$config = value;
    }

    static setMetaverseId(value: number) {
        GlobalMakerService.metaverseId = value;
    }

    static setRouter(value: any) {
        GlobalMakerService.$router = value;
    }

    static async showLoadingIndicator(callback: any, exception: any) : Promise<any> {
        await this.$store.dispatch('application/popup-control/showLoadingPopup', {
            title: 'Please Wait',
            message: '',
            showIcon: true,
            type: 'success',
        }, {root: true});
        let retVal = null;
        try {
            await callback();
            await this.$store.dispatch('application/popup-control/hideLoadingPopup', {}, {root: true});
        } catch (e) {
            await this.$store.dispatch('application/popup-control/hideLoadingPopup', {}, {root: true});
            if(exception) {
                await exception(e);
            } else {
                throw e;
            }
        }
        return retVal;
    }
}
