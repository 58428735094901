import Asset from '~/pages/finances/wallet/models/Asset'
import { CurrencyName } from '~/pages/finances/wallet/models/CurrencyName'

const icons = {
    BTC: require('~/assets/images/icons/currencies/btc.png'),
    ETH: require('~/assets/images/icons/currencies/eth.png'),
    BNB: require('~/assets/images/icons/currencies/bnb.png'),
    BUSD: require('~/assets/images/icons/currencies/busd.png'),
    USDT: require('~/assets/images/icons/currencies/usdt.png'),
}

export default class TokenAsset extends Asset {
    constructor (id:number, name: string, currency: CurrencyName, value: number, rate: number, isInsufficient: boolean, metaWorldManager: any, network: string) {
        super(id, name, currency, icons[currency], value, rate, isInsufficient, metaWorldManager, network)
    }

    get network () {
        return this._network // TODO is this needed it is implemented in super class?
    }
}
