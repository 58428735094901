export const STATE = {
    DEFAULT_ACCEPT_HANDLER_LOADING: 'defaultAcceptHandlerLoading',
    DEFAULT_ACCEPT_HANDLER_ERROR: 'defaultAcceptHandlerError',
    TERMS_AND_CONDITIONS_PROMISES: 'termsAndConditionsPromises',
    TERMS_AND_CONDITIONS_RESOLVER: 'termsAndConditionsResolver',
    TERMS_AND_CONDITIONS_REJECTER: 'termsAndConditionsRejecter',
    IS_TERMS_AND_CONDITIONS_POPUP_OPEN: 'isTermsAndConditionsPopupOpen',
    TERMS_AND_CONDITIONS_TYPE: 'termsAndConditionsType',
    IS_POPUP_ACCEPTABLE: 'isPopupAcceptable',
    IS_POPUP_STANDALONE: 'isPopupStandalone',
    IS_POPUP_CLOSABLE: 'isPopupClosable',
    TERMS_AND_CONDITIONS_POPUP_CONTENT_PROPS: 'termsAndConditionsPopupContentProps',
} as const;

export const ACTIONS = {
    CLEAR_TERMS_AND_CONDITIONS_STATE: 'clearTermsAndConditionsState',
    OPEN_AFFILIATE_TERMS_AND_CONDITIONS: 'openAffiliateTermsAndConditions',
    OPEN_CARDS_TERMS_AND_CONDITIONS: 'openCardsTermsAndConditions',
    OPEN_GENERAL_TERMS_AND_CONDITIONS: 'openGeneralTermsAndConditions',
    OPEN_TERMS_AND_CONDITIONS_BY_TYPE: 'openTermsAndConditionsByType',
    CALL_ACCEPT_ACTION_AND_CLOSE: 'callAcceptActionAndClose',
    CLOSE_TERMS_AND_CONDITIONS: 'closeTermsAndConditions',
    AWAIT_FOR_CLOSED: 'awaitForClosed',
    AWAIT_FOR_ALL_CLOSED: 'awaitForAllClosed',
    ACCEPT_GENERAL_TERMS_AND_CONDITIONS_AND_UPDATE_USER: 'acceptGeneralTermsAndConditionsAndUpdateUser',
    ACCEPT_GENERAL_TERMS_AND_CONDITIONS: 'acceptGeneralTermsAndConditions',
    ACCEPT_CARDS_TERMS_AND_CONDITIONS_AND_UPDATE_USER: 'acceptCardsTermsAndConditionsAndUpdateUser',
    ACCEPT_CARDS_TERMS_AND_CONDITIONS: 'acceptCardsTermsAndConditions',
    ACCEPT_AFFILIATE_TERMS_AND_CONDITIONS_AND_UPDATE_USER: 'acceptAffiliateTermsAndConditionsAndUpdateUser',
    ACCEPT_AFFILIATE_TERMS_AND_CONDITIONS: 'acceptAffiliateTermsAndConditions',
} as const;

export const MUTATIONS = {
    SET_TERMS_AND_CONDITIONS_POPUP_OPEN: 'SET_TERMS_AND_CONDITIONS_POPUP_OPEN',
    SET_TERMS_AND_CONDITIONS_TYPE: 'SET_TERMS_AND_CONDITIONS_TYPE',
    SET_IS_POPUP_ACCEPTABLE: 'SET_IS_POPUP_ACCEPTABLE',
    SET_IS_POPUP_STANDALONE: 'SET_IS_POPUP_STANDALONE',
    SET_IS_POPUP_CLOSABLE: 'SET_IS_POPUP_CLOSABLE',
    SET_TERMS_AND_CONDITIONS_POPUP_CONTENT_PROPS: 'SET_TERMS_AND_CONDITIONS_POPUP_CONTENT_PROPS',
    SET_DEFAULT_ACCEPT_HANDLER_LOADING: 'SET_DEFAULT_ACCEPT_HANDLER_LOADING',
    SET_DEFAULT_ACCEPT_HANDLER_ERROR: 'SET_DEFAULT_ACCEPT_HANDLER_ERROR',
    ADD_TERMS_AND_CONDITIONS_PROMISE: 'ADD_TERMS_AND_CONDITIONS_PROMISE',
    REMOVE_TERMS_AND_CONDITIONS_PROMISE: 'REMOVE_TERMS_AND_CONDITIONS_PROMISE',
    SET_TERMS_AND_CONDITIONS_RESOLVER: 'SET_TERMS_AND_CONDITIONS_RESOLVER',
    SET_TERMS_AND_CONDITIONS_REJECTER: 'SET_TERMS_AND_CONDITIONS_REJECTER',
} as const;

export const GETTERS = {
    IS_TERMS_AND_CONDITIONS_POPUP_OPEN: 'isTermsAndConditionsPopupOpen',
    TERMS_AND_CONDITIONS_TYPE: 'termsAndConditionsType',
    IS_POPUP_ACCEPTABLE: 'isPopupAcceptable',
    IS_POPUP_STANDALONE: 'isPopupStandalone',
    IS_POPUP_CLOSABLE: 'isPopupClosable',
    TERMS_AND_CONDITIONS_POPUP_CONTENT_PROPS: 'termsAndConditionsPopupContentProps',
    TERMS_AND_CONDITIONS_POPUP_DETAILS: 'termsAndConditionsPopupDetails',
    DEFAULT_ACCEPT_HANDLER_LOADING: 'defaultAcceptHandlerLoading',
    DEFAULT_ACCEPT_HANDLER_ERROR: 'defaultAcceptHandlerError',
    AGGREGATED_POPUP_PROPERTIES: 'aggregatedPopupProperties',
} as const;