var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c(_setup.tag,_vm._b({tag:"component",staticClass:"base-button-close",class:[
        _setup.size,
        _setup.styling,
        { 
            [`${_setup.theme}`]: !!_setup.theme,
        },
    ],attrs:{"disabled":_setup.disabled,"aria-label":_vm.$t('close')}},'component',_vm.$attrs,false),[_c('BaseImageSvgThemedIcon',{attrs:{"name":_setup.icon.name,"path":_setup.icon.path,"size":_setup.icon.size || _setup.size,"theme":_setup.icon.theme || _setup.theme,"color":_setup.icon.color,"active":_setup.icon.active,"activatable":_setup.icon.activatable}})],1)
}
var staticRenderFns = []

export { render, staticRenderFns }