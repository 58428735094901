import { ACTIONS, GETTERS, MUTATIONS } from "./names";
import { POLLING_STORE_FUNCTIONS } from "./polling";
import { DurationFilter } from "./types";
import ApiService from "~/core/services/api-interaction/ApiService";

export default {
    ...POLLING_STORE_FUNCTIONS.actions,

    [ACTIONS.CHANGE_DURATION_FILTER] ({ commit }, duration: DurationFilter) {
        commit(MUTATIONS.SET_DURATION_FILTER, duration);
    },
    async [ACTIONS.LOAD_PROCESS_AND_SAVE_TEAM_DATA] (
        { dispatch, commit, getters },
        { save, duration }: {save?: () => boolean, duration?: DurationFilter } = {},
    ) {
        if (getters[GETTERS.TEAM_DATA_LOADING]) {
            return getters[GETTERS.TEAM_DATA_LOADING_PROMISE];
        }
        commit(MUTATIONS.SET_TEAM_DATA_LOADING, true);
        let processedTeamData = null;
        try {
            const loadingPromise = dispatch(ACTIONS.LOAD_AND_PROCESS_TEAM_DATA, {
                duration: duration || DurationFilter.ALL,
            });
            commit(MUTATIONS.SET_TEAM_DATA_LOADING_PROMISE, loadingPromise);
            processedTeamData = await loadingPromise;

            if (!save || save()) {
                await dispatch(ACTIONS.SAVE_TEAM_DATA, processedTeamData);
            }

            commit(MUTATIONS.SET_TEAM_DATA_LOADING_ERROR, null);
        } catch (e) {
            console.warn('Failed to fetch team info', e);
            commit(MUTATIONS.SET_TEAM_DATA_LOADING_ERROR, e);
        } finally {
            commit(MUTATIONS.SET_TEAM_DATA_LOADING, false);
            commit(MUTATIONS.SET_TEAM_DATA_LOADING_PROMISE, null);
            return processedTeamData;
        }
    },
    async [ACTIONS.LOAD_AND_PROCESS_TEAM_DATA] ({ dispatch }, { duration }: { duration?: DurationFilter } = { duration: DurationFilter.ALL }) {
        const data = await dispatch(ACTIONS.LOAD_TEAM_DATA, { duration });
        return dispatch(ACTIONS.PROCESS_TEAM_DATA, data);
    },
    async [ACTIONS.LOAD_TEAM_DATA] (_ctx, { duration }: { duration?: DurationFilter } = { duration: DurationFilter.ALL }) {
        const response = await ApiService.query(`${this.$config.binaryTreeApi}/api/users/affility-team-statistics`, {
            params: {
                duration,
            },
        });
        return response.data;
    },
    [ACTIONS.PROCESS_TEAM_DATA] (_ctx, data) {
        return {
            left: Number(data?.leftPartners) || 0,
            right: Number(data?.rightPartners) || 0,
            direct: Number(data?.directPartners) || 0,
            active: Number(data?.activePartners) || 0,
            inactive: Number(data?.inactivePartners) || 0,
        };
    },
    [ACTIONS.SAVE_TEAM_DATA] (
        { commit },
        {
            active = 0,
            inactive = 0,
            direct = 0,
            left = 0,
            right = 0,
        } = {}) {
        commit(MUTATIONS.SET_ACTIVE_PARTNERS_COUNT, active);
        commit(MUTATIONS.SET_INACTIVE_PARTNERS_COUNT, inactive);
        commit(MUTATIONS.SET_DIRECT_PARTNERS_COUNT, direct);
        commit(MUTATIONS.SET_LEFT_LEG_PARTNERS_COUNT, left);
        commit(MUTATIONS.SET_RIGHT_LEG_PARTNERS_COUNT, right);
    },
    [ACTIONS.CLEAR_TEAM_STATE] ({ commit, dispatch }) {
        commit(MUTATIONS.SET_ACTIVE_PARTNERS_COUNT, null);
        commit(MUTATIONS.SET_INACTIVE_PARTNERS_COUNT, null);
        commit(MUTATIONS.SET_DIRECT_PARTNERS_COUNT, null);
        commit(MUTATIONS.SET_LEFT_LEG_PARTNERS_COUNT, null);
        commit(MUTATIONS.SET_RIGHT_LEG_PARTNERS_COUNT, null);
        commit(MUTATIONS.SET_DURATION_FILTER, DurationFilter.ALL);
        dispatch(ACTIONS.STOP_POLLING);
        commit(MUTATIONS.SET_TEAM_DATA_LOADING, false);
        commit(MUTATIONS.SET_TEAM_DATA_LOADING_ERROR, false);
        commit(MUTATIONS.SET_TEAM_DATA_LOADING_PROMISE, null);
    },
};