import { render, staticRenderFns } from "./ProfileMenu.vue?vue&type=template&id=6fff80a6&scoped=true"
import script from "./ProfileMenu.vue?vue&type=script&setup=true&lang=ts"
export * from "./ProfileMenu.vue?vue&type=script&setup=true&lang=ts"
import style0 from "./ProfileMenu.vue?vue&type=style&index=0&id=6fff80a6&prod&scoped=true&lang=scss"
import style1 from "./ProfileMenu.vue?vue&type=style&index=1&id=6fff80a6&prod&lang=scss"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "6fff80a6",
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {ComposedImageBlockiesAvatar: require('/root/dashboard/core/components/composed/image/BlockiesAvatar.vue').default,ComposedMenuProfileContent: require('/root/dashboard/core/components/composed/menu/ProfileContent.vue').default,ComposedMenuContractAddressesSubMenu: require('/root/dashboard/core/components/composed/menu/ContractAddressesSubMenu.vue').default,ComposedMenuLanguagesSubMenu: require('/root/dashboard/core/components/composed/menu/LanguagesSubMenu.vue').default,ComposedDropdownHeader: require('/root/dashboard/core/components/composed/dropdown/Header.vue').default})
