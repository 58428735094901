import Vue from "vue";
import VueGtag from "vue-gtag";
import { getClientRuntimeConfig } from "~/core/helpers/GlobalHelpers";

const $config = getClientRuntimeConfig();

Vue.use(VueGtag, {
    config: { id: $config.gtagId }
});

export default (ctx, inject) => {
    ctx.$gtag = Vue.$gtag;
    inject("gtag", Vue.$gtag);
};