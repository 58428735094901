import { render, staticRenderFns } from "./Language.vue?vue&type=template&id=0ddfe06a&scoped=true"
import script from "./Language.vue?vue&type=script&setup=true&lang=ts"
export * from "./Language.vue?vue&type=script&setup=true&lang=ts"
import style0 from "./Language.vue?vue&type=style&index=0&id=0ddfe06a&prod&scoped=true&lang=scss"
import style1 from "./Language.vue?vue&type=style&index=1&id=0ddfe06a&prod&lang=scss"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "0ddfe06a",
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {BaseImageSvgThemedIcon: require('/root/dashboard/core/components/base/image/SvgThemedIcon.vue').default,BaseLinkHeader: require('/root/dashboard/core/components/base/link/Header.vue').default,ComposedListLanguagesSwitch: require('/root/dashboard/core/components/composed/list/LanguagesSwitch.vue').default,BaseDropdownSimple: require('/root/dashboard/core/components/base/dropdown/Simple.vue').default})
