import { render, staticRenderFns } from "./Desktop.vue?vue&type=template&id=2e488f50&scoped=true"
import script from "./Desktop.vue?vue&type=script&setup=true&lang=ts"
export * from "./Desktop.vue?vue&type=script&setup=true&lang=ts"
import style0 from "./Desktop.vue?vue&type=style&index=0&id=2e488f50&prod&scoped=true&lang=scss"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "2e488f50",
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {ComposedHeaderBalanceSection: require('/root/dashboard/core/components/composed/header/BalanceSection.vue').default,BaseContainerPointerScrollable: require('/root/dashboard/core/components/base/container/PointerScrollable.vue').default,ComposedMainContractAddresses: require('/root/dashboard/core/components/composed/main/ContractAddresses.vue').default,ComposedSelectLanguage: require('/root/dashboard/core/components/composed/select/Language.vue').default,ComposedActionAuthButtons: require('/root/dashboard/core/components/composed/action/AuthButtons.vue').default,ComposedSwitchTheme: require('/root/dashboard/core/components/composed/switch/Theme.vue').default,ComposedButtonNotificationToggle: require('/root/dashboard/core/components/composed/button/NotificationToggle.vue').default,ComposedDropdownProfileMenu: require('/root/dashboard/core/components/composed/dropdown/ProfileMenu.vue').default})
