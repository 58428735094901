import ApiService from "~/core/services/api-interaction/ApiService";
import { getClientRuntimeConfig } from "~/core/helpers/GlobalHelpers";

const $config = getClientRuntimeConfig();
const BitcoinServer = $config.bitcoin;

// State
const state = () => {
    return {
        userHasBitcoinBundleLoading: true,
        userHasBitcoinBundle: false,
    };
};

// Actions
const actions = {
    async checkUserBitcoinBundle({ commit }) {
        commit('SET_CHECK_USER_BITCOIN_BUNDLE_LOADING', true);

        try {
            const response = await ApiService.post(`${BitcoinServer}/api/v1/user-check`, {});

            commit('SET_CHECK_USER_BITCOIN_BUNDLE', !!response?.data?.result);
        } finally {
            commit('SET_CHECK_USER_BITCOIN_BUNDLE_LOADING', false);
        }
    },
};

// Mutations
const mutations = {
    SET_CHECK_USER_BITCOIN_BUNDLE(state, payload) {
        state.userHasBitcoinBundle = payload;
    },
    SET_CHECK_USER_BITCOIN_BUNDLE_LOADING(state, payload) {
        state.userHasBitcoinBundleLoading = payload;
    },
};

// Getters
const getters = {
    userHasBitcoinBundle: ({ userHasBitcoinBundle }) => userHasBitcoinBundle,
    userHasBitcoinBundleLoading: ({ userHasBitcoinBundleLoading }) => userHasBitcoinBundleLoading,
};

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations
}
