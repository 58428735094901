var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c(_setup.tag,_vm._b({tag:"component",staticClass:"button",class:[_setup.size, { 
        [`${_setup.theme}`]: !!_setup.theme,
        'equally-padded': !!_vm.equallyPadded,
    }]},'component',{
        ..._setup.props,
        ...(_setup.disabled ? { 'aria-disabled': true, href: '', to: '', } : { href: _vm.href, to: _vm.to }),
    },false),[_c('div',[_vm._t("default")],2)])
}
var staticRenderFns = []

export { render, staticRenderFns }