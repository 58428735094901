
import {defineComponent} from "vue";
import {UserBalance} from "~/core/models/purchase-popup-2/UserBalance";
import MunicipalityTx from "~/core/models/purchase-popup-2/MunicipalityTx";
import Web3MunicipalityTx from "~/core/models/purchase-popup-2/Web3MunicipalityTx";
import {ExternalWalletPayment} from "~/core/models/purchase-popup-2/ExternalWalletPayment";
import NftImageComponent
    from "~/core/components/flows/purchase/steps/purchase-summary/components/nft-image/NftImageComponent.vue";
import {toCurrencyFormat, toNumericFormat} from "~/core/helpers/GlobalHelpers";
import GymNewButton from "~/components/utils/form-components/GymNewButton.vue";

export default defineComponent({
    name: "PapPurchaseSummary",
    components: {
        GymNewButton,
        NftImageComponent
    },
    emits: ['onClickBack', 'onStartLoading', 'onSuccessFullPayment'],
    props: {
        purchase: {
            type: Object,
            required: true,
        },
        userBalance: {
            type: UserBalance,
            default: null,
        },
        transactionObject: {
            type: [MunicipalityTx, Web3MunicipalityTx, ExternalWalletPayment],
            required: true,
        },
    },
    data() {
        return {
            toCurrencyFormat,
            toNumericFormat,
        }
    },
    computed: {
        isPaymentStatusCompleted () {
            return this.transactionObject.isCompleted;
        },
    },
    methods: {
        async handlePay() {
            try {
                this.$emit('onStartLoading');
                await this.transactionObject.sendPAP();
                this.$emit('onSuccessFullPayment');
                this.$emit('onStopLoading');
            } catch (e) {
                this.$emit('onStopLoading', e);
            } finally {
                this.gasFeeVisible = true;
            }
        }
    }
})
