export const STATE = {
    NOTIFICATIONS_BAR_OPEN: 'notificationsBarOpen',
} as const;

export const ACTIONS = {
    OPEN_NOTIFICATIONS_LOAD_OR_UPDATE_FIRST_HISTORY_PAGE_IF_NEEDED: 'openNotificationsLoadOrUpdateFirstHistoryPageIfNeeded',
    CLOSE_NOTIFICATIONS_BAR_AND_READ: 'closeNotificationsBarAndRead',
    OPEN_NOTIFICATIONS_BAR: 'openNotificationsBar',
    CLOSE_NOTIFICATIONS_BAR: 'closeNotificationsBar',
    CLEAR_NOTIFICATIONS_BAR_STATE: 'clearNotificationsBarState',
} as const;

export const MUTATIONS = {
    SET_NOTIFICATIONS_BAR_OPEN: 'SET_NOTIFICATIONS_BAR_OPEN',
} as const;

export const GETTERS = {
    NOTIFICATIONS_BAR_OPEN: 'notificationsBarOpen',
} as const;