import { STATE } from "./names";
import { POLLING_STORE_FUNCTIONS } from "./polling";

export default () => {
    return {
        [STATE.UNILEVEL_RANKS_BY_RIGHT_LEG_SATISFIED]: null as boolean | null,
        [STATE.UNILEVEL_RANKS_BY_LEFT_LEG_SATISFIED]: null as boolean | null,
        [STATE.LEFT_LEG_MIN_PURCHASE_SATISFIED]: null as boolean | null,
        [STATE.RIGHT_LEG_MIN_PURCHASE_SATISFIED]: null as boolean | null,
        [STATE.LEFT_LEG_PARTNERS_COUNT]: null as number | null,
        [STATE.RIGHT_LEG_PARTNERS_COUNT]: null as number | null,
        [STATE.LEFT_LEG_TURNOVER]: null as number | null,
        [STATE.RIGHT_LEG_TURNOVER]: null as number | null,
        [STATE.TOTAL_OWN_PURCHASE]: null as number | null,
        [STATE.BINARY_PROGRESS_LOADING]: false,
        [STATE.BINARY_PROGRESS_LOADING_ERROR]: null as Error | null,
        [STATE.BINARY_PROGRESS_LOADING_PROMISE]: null as Promise<unknown> | null,
        ...POLLING_STORE_FUNCTIONS.state,
    };
};