import { MUTATIONS, STATE } from "./names";
import type { State } from "./types";
import type { Notification } from "../types/Notification";

export default {
    [MUTATIONS.ADD_NOTIFICATIONS_TO_HISTORY](state: State, {
        notifications,
        fromStart = false,
    }) {
        if (fromStart) {
            state[STATE.NOTIFICATION_HISTORY].unshift(...notifications);
            return;
        }
        state[STATE.NOTIFICATION_HISTORY].push(...notifications);
    },
    [MUTATIONS.SET_NOTIFICATIONS_HISTORY_LOAD_COUNT](state: State, count: number) {
        state[STATE.NOTIFICATIONS_HISTORY_LOAD_COUNT] = count;
    },
    [MUTATIONS.SET_NOTIFICATION_HISTORY](state: State, notifications: Notification[]) {
        state[STATE.NOTIFICATION_HISTORY] = notifications;
    },
    [MUTATIONS.SET_TOTAL_NOTIFICATIONS_COUNT](state: State, count: number) {
        state[STATE.TOTAL_NOTIFICATIONS_COUNT] = count;
    },
    [MUTATIONS.ADD_TO_TOTAL_NOTIFICATIONS_COUNT](state: State, count: number) {
        state[STATE.TOTAL_NOTIFICATIONS_COUNT] += count;
    },
    [MUTATIONS.SET_NOTIFICATIONS_HISTORY_LOADING](state: State, loading: boolean) {
        state[STATE.NOTIFICATIONS_HISTORY_LOADING] = loading;
    },
    [MUTATIONS.SET_NOTIFICATIONS_HISTORY_LOADING_PROMISE](state: State, promise: Promise<unknown> | null) {
        state[STATE.NOTIFICATIONS_HISTORY_LOADING_PROMISE] = promise;
    },
    [MUTATIONS.SET_NOTIFICATIONS_HISTORY_LOADING_ERROR](state: State, error: unknown | null) {
        state[STATE.NOTIFICATIONS_HISTORY_LOADING_ERROR] = error;
    },
}