import type WalletAddress from '~/pages/finances/wallet/models/WalletAddress'

export default class BNBService {
    /**
     * Get balance from BNB account
     * @example
     * async BNBService.getBalance('0x0', MetaWorldManager.sharedInstance(); => 100
     * @return
     * {number} amount on wallet
     */
    public static async getBalance(walletAddress: WalletAddress, metaWorldManager: any): Promise<number> {
        if (!walletAddress) return 0;
        const web3 = metaWorldManager.readWeb3();
        const balance = await web3.eth.getBalance(walletAddress);
        return web3.utils.fromWei(balance);
    }
}
