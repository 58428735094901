import { ACTIONS, GETTERS, MUTATIONS } from "./names";
import { ACTIONS as SIDEBAR_ACTIONS } from "./sidebar/names";
import { ACTIONS as UNREAD_NOTIFICATIONS_ACTIONS } from "./unread/names";
import { ACTIONS as HISTORY_NOTIFICATIONS_ACTIONS } from "./history/names";
import type { RawNotification } from "./types/RawNotification";
import { Notification } from "./types/Notification";

export default {
    [ACTIONS.PROCESS_NOTIFICATIONS](_ctx, payload: RawNotification[]) {
        return payload.map((notification) => new Notification(notification));
    },
    [ACTIONS.CLEAR_NOTIFICATIONS_STATE]({ dispatch }) {
        dispatch(`affility/user/notifications/sidebar/${SIDEBAR_ACTIONS.CLEAR_NOTIFICATIONS_BAR_STATE}`, null, { root: true });
        dispatch(`affility/user/notifications/unread/${UNREAD_NOTIFICATIONS_ACTIONS.CLEAR_UNREAD_NOTIFICATIONS_STATE}`, null, { root: true });
        dispatch(`affility/user/notifications/history/${HISTORY_NOTIFICATIONS_ACTIONS.CLEAR_NOTIFICATIONS_HISTORY_STATE}`, null, { root: true });
    },
};