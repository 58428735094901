import { render, staticRenderFns } from "./SignUpWithEmail.vue?vue&type=template&id=f6cbed6e&scoped=true"
import script from "./SignUpWithEmail.vue?vue&type=script&lang=ts&setup=true"
export * from "./SignUpWithEmail.vue?vue&type=script&lang=ts&setup=true"
import style0 from "./SignUpWithEmail.vue?vue&type=style&index=0&id=f6cbed6e&prod&scoped=true&lang=scss"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "f6cbed6e",
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {BaseLoaderAffility: require('/root/dashboard/core/components/base/loader/Affility.vue').default,FlowsSigninComponentsSignUpEnterEmail: require('/root/dashboard/core/components/flows/signin/components/SignUpEnterEmail.vue').default,FlowsSigninComponentsSignUpCreatePassword: require('/root/dashboard/core/components/flows/signin/components/SignUpCreatePassword.vue').default,FlowsSigninComponentsSignUpAcceptTerms: require('/root/dashboard/core/components/flows/signin/components/SignUpAcceptTerms.vue').default,BaseButtonSimple: require('/root/dashboard/core/components/base/button/Simple.vue').default})
