import JwtService from "~/services/JwtService";
import Vue from 'vue';

const apiService = ({$axios, redirect}, inject) => {
  const api = $axios.create();

  api.onRequest((config) => {
    if (JwtService.getToken()) config.headers.common['Authorization'] = `Bearer ${JwtService.getToken()}`
  })

  api.onError((error) => {
    const code = parseInt(error.response && error.response.status)
    const errorText = code
      ? `A request failed with status code ${code}`
      : `Blockchain BSC is not responding`

    console.log(error.response, 'error.response')
    if (process.client) {
      // Vue.$toast.error(errorText)
    }
  })

  api.onResponse((res) => {
    return res
  })

  inject('api', api)

}
export default apiService
