import { getStorePollingNames } from "~/core/utilities/store-polling/getStorePollingNames";

export const UNIQUE_IDENTIFIER = 'team' as const;
export const POLLING_NAMES = getStorePollingNames(UNIQUE_IDENTIFIER);

export const STATE = {
    DURATION_FILTER: 'durationFilter',
    LEFT_LEG_PARTNERS_COUNT: 'leftLegCount',
    RIGHT_LEG_PARTNERS_COUNT: 'rightLegCount',
    ACTIVE_PARTNERS_COUNT: 'activePartnersCount',
    INACTIVE_PARTNERS_COUNT: 'inactivePartnersCount',
    DIRECT_PARTNERS_COUNT: 'directPartnersCount',
    TEAM_DATA_LOADING: 'teamDataLoading',
    TEAM_DATA_LOADING_ERROR: 'teamDataLoadingError',
    TEAM_DATA_LOADING_PROMISE: 'teamDataLoadingPromise',
    ...POLLING_NAMES.state,
} as const;

export const ACTIONS = {
    LOAD_PROCESS_AND_SAVE_TEAM_DATA: 'loadProcessAndSaveTeamData',
    LOAD_AND_PROCESS_TEAM_DATA: 'loadAndProcessTeamData',
    LOAD_TEAM_DATA: 'loadTeamData',
    CHANGE_DURATION_FILTER: 'changeDurationFilter',
    PROCESS_TEAM_DATA: 'processTeamData',
    SAVE_TEAM_DATA: 'saveTeamData',
    CLEAR_TEAM_STATE: 'clearTeamState',
    ...POLLING_NAMES.actions,
} as const;

export const MUTATIONS = {
    SET_LEFT_LEG_PARTNERS_COUNT: 'SET_LEFT_LEG_PARTNERS_COUNT',
    SET_RIGHT_LEG_PARTNERS_COUNT: 'SET_RIGHT_LEG_PARTNERS_COUNT',
    SET_ACTIVE_PARTNERS_COUNT: 'SET_ACTIVE_PARTNERS_COUNT',
    SET_INACTIVE_PARTNERS_COUNT: 'SET_INACTIVE_PARTNERS_COUNT',
    SET_DIRECT_PARTNERS_COUNT: 'SET_DIRECT_PARTNERS_COUNT',
    SET_DURATION_FILTER: 'SET_DURATION_FILTER',
    SET_TEAM_DATA_LOADING: 'SET_TEAM_DATA_LOADING',
    SET_TEAM_DATA_LOADING_ERROR: 'SET_TEAM_DATA_LOADING_ERROR',
    SET_TEAM_DATA_LOADING_PROMISE: 'SET_TEAM_DATA_LOADING_PROMISE',
    ...POLLING_NAMES.mutations,
} as const;

export const GETTERS = {
    LEFT_LEG_PARTNERS_COUNT: 'leftLegCount',
    RIGHT_LEG_PARTNERS_COUNT: 'rightLegCount',
    ACTIVE_PARTNERS_COUNT: 'activePartnersCount',
    INACTIVE_PARTNERS_COUNT: 'inactivePartnersCount',
    DIRECT_PARTNERS_COUNT: 'directPartnersCount',
    DURATION_FILTER: 'durationFilter',
    TEAM_DATA_LOADING: 'teamDataLoading',
    TEAM_DATA_LOADING_ERROR: 'teamDataLoadingError',
    TEAM_DATA_LOADING_PROMISE: 'teamDataLoadingPromise',
    ...POLLING_NAMES.getters,
} as const;