import { SECONDS_FOR_30_DAYS } from '~/core/services/utils/Constants';
import AuthService from "~/core/services/api-interaction/AuthService";

// Stats
export const state = () => ({
    id: null,
    level: null,
    additionalLevel: null,
    monthlyNFTPurchaseExpiration: null,
    referrer: null,
    referrerId: null,
    easyLoginToCashFTToken: '',
    isEasyLoginLoading: false,
    lastEasyLoginDate: null,
});

// Mutations
export const mutations = {
    SET_DATA(state, data) {
        for (const key in data) {
            state[key] = data[key];
        }
    },
    RESET_DATA(state) {
        state.id = null;
        state.level = null;
        state.monthlyNFTPurchaseExpiration = null;
        state.easyLoginToCashFTToken = null;
        state.lastEasyLoginDate = null;
        state.isEasyLoginLoading = null;
    },
    SET_EASY_LOGIN_CASHFT_TOKEN(state, value) {
        state.easyLoginToCashFTToken = value;
    },
    SET_EASY_LOGIN_LOADING(state, value) {
        state.isEasyLoginLoading = value;
    },
    SET_LAST_EASY_LOGIN(state, value) {
        state.lastEasyLoginDate = value;
    },
};

// Actions
export const actions = {
    resetUserData({commit}) {
        commit('RESET_DATA');
    },
    async addressToId({commit}, address) {
        let id = null;
        if (address) {
            id = await this.$contracts[this.$metaverseId()].MLM.methods.addressToId(address).call();
        }
        commit("SET_DATA", {
            id
        });
        return id;
    },
    async addressToLevel({rootState, commit, dispatch}, {address, isWithNFTs = false}) {
        let level = null;
        let additionalLevel = 0;
        if (address) {
            const method = isWithNFTs ? 'getUserCurrentLevel' : 'getUserLevelWOHasNFT';
            level = await this.$contracts[this.$metaverseId()].NetGymStreet.methods[method](address).call();
            additionalLevel = await this.$contracts[this.$metaverseId()].NetGymStreet.methods.additionalLevel(address).call();
        }
        commit("SET_DATA", {
            level,
            additionalLevel
        });
        return level;
    },
    async easyLoginToCashFT({ state, commit }) {
        commit('SET_EASY_LOGIN_LOADING', true);
        try {
            const four_minutes = 4 * 60 * 1000;
            const now = new Date();
            if(!state.lastEasyLoginDate || now - state.lastEasyLoginDate > four_minutes) {
                const token = await AuthService.getCashFTEasyLoginToken();
                commit('SET_EASY_LOGIN_CASHFT_TOKEN', token);
                commit('SET_LAST_EASY_LOGIN', now);
            }
        } catch (error) {
            commit('SET_EASY_LOGIN_CASHFT_TOKEN', null);
            throw error;
        } finally {
            commit('SET_EASY_LOGIN_LOADING', false);
        }
        return state.easyLoginToCashFTToken;
    },
    async isSixDayActiveOnContract({rootState, state, dispatch}, address) {
        const timestamp = await this.$contracts[this.$metaverseId()].NetGymStreet.methods.termsAndConditionsTimestamp(address).call();
        if(timestamp === '0') {
            return true;
        }
        const expiration = Number(timestamp) + SECONDS_FOR_30_DAYS;
        const now = Math.floor(Date.now() / 1000);
        return now < expiration;
    },
};

// Getters
export const getters = {
    id: ({id}) => id,
    level: ({level}) => level,
    additionalLevel: ({additionalLevel}) => additionalLevel,
    monthlyNFTPurchaseExpiration: ({monthlyNFTPurchaseExpiration}) => monthlyNFTPurchaseExpiration,
    referrer: ({referrer}) => referrer,
    referrerId: ({referrerId}) => referrerId,
    easyLoginToCashFTToken: ({easyLoginToCashFTToken}) => easyLoginToCashFTToken,
    isEasyLoginLoading: ({isEasyLoginLoading}) => isEasyLoginLoading,
    lastEasyLoginDate: ({lastEasyLoginDate}) => lastEasyLoginDate,
    data: (state) => state,
};
