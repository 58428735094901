
import { mapGetters } from "vuex";
import GYMButton from "~/components/utils/buttons/GYMButton";
import { PopupHelper } from "~/core/helpers/PopupHelper";
import CustomLoader from "../utils/CustomLoader.vue";

export default {
    name: "AcceptAffiliateTOSPopup",
    components: { GYMButton, CustomLoader },
    emits: ['onPopupClose', 'onAcceptTermsAndConditions'],
    props: {
        popupVisible: Boolean,
        popupData: Object,
        showCloseIcon: {
            type: Boolean,
            default: false
        }
    },
    watch: {
        popupVisible() {
            this.termsAndConditionsAccepted = false;
            this.loading = false;
        },
    },
    data() {
        return {
            termsAndConditionsAccepted: false,
            loading: false,
        }
    },
    computed: {
        ...mapGetters({
            authUser: 'application/driver/authUser',
        }),
    },
    destroyed() {
        this.termsAndConditionsAccepted = false;
        this.loading = false;
    },
    methods: {
        handleModalClose() {
            this.$emit('onPopupClose');
            this.$store.dispatch('application/popup-control/setAffiliateTmsPopupData', null);
        },
        async handleAcceptTermsAndConditions() {
            if (this.termsAndConditionsAccepted) {
                this.loading = true;
                try {
                    const address = this.authUser?.walletAddress || this.authUser?.relatedWalletAddress;
                    await this.$store.dispatch('application/driver/acceptAffiliateTOS', { address });
                    this.$store.commit('application/driver/UPDATE_AUTH_USER', {isAffiliateTmsConfirmed: true});
                    this.handleModalClose();
                } catch(e) {
                    console.error('Unknown error at AcceptAffiliateTOSPopup -> handleAcceptTermsAndConditions -> e', e);
                    this.handleModalClose();
                    PopupHelper.showErrorAlert(this.$t('Failed to accept Affiliate TMS'));
                }
            }
        },
    }
}
