export const STATE = {
} as const;

export const ACTIONS = {
    PROCESS_NOTIFICATIONS: 'processNotifications',
    CLEAR_NOTIFICATIONS_STATE: 'clearNotificationsState',
} as const;

export const MUTATIONS = {
} as const;

export const GETTERS = {
    ALL_NOTIFICATIONS: 'allNotifications',
} as const;