export const STATE = {
} as const;

export const ACTIONS = {
    WATCH_ASSET: 'watchAsset',
} as const;

export const MUTATIONS = {
} as const;

export const GETTERS = {
    MAIN_CONTRACT_ADDRESSES: 'mainContractAddresses',
} as const;