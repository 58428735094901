import BigNumber from "bignumber.js";
import {fromWei, getClientRuntimeConfig} from "~/core/helpers/GlobalHelpers";
import {
    turnoverPoolsData, turnoverPoolsMonthlyData, turnoverPoolsSixMonthData} from "~/constants/constants";
import {MetaWorldManager} from "~/core/services/map/MetaWorldManager";
import {MetaInventoryContainer} from "~/core/models/MetaInventoryContainer";

BigNumber.config({DECIMAL_PLACES: 18});

const $config = getClientRuntimeConfig();
const GYMNET_API_URL = $config.gymnetApi;

export const state = () => {
    return {
        turnoverPoolLoading: false,
        turnoverPools: [],
        turnoverData: {},
        turnoverStatisticsLoading: false,
        turnoverStatisticsDataHourly: [],
        turnoverStatisticsDataWeekly: [],
        turnoverStatisticsDataMonthly: [],
        isUserQualificationLoading: false,
        userQualifications: [],
        turnoverStatisticsChartData: [],
        turnoverPool: {
            totalClaims: 0,
            pendingGYMNET: 0,
            pendingBUSD: 0
        },
        turnoverPoolSnapshotLoading: false,
        turnoverPoolSnapshotData: [],
        turnoverPoolMonthlyTaxSnapshotLoading: false,
        turnoverPoolMonthlyTaxSnapshotData: [],
        turnoverPoolSixMonthLoading: false,
        turnoverPoolsSixMonth: [],
        turnoverDataSixMonth: {},
        sixMoPoolForCurrentMoLoading: false,
        sixMoPoolForCurrentMoData: {},
        monthlyPoolForCurrentMoLoading: false,
        monthlyPoolForCurrentMoData: {},
        totalClaimedBUSD_ETH: 0,
        totalClaimedGYMNETETH: 0,
        isNewTurnoverLoading: false,
        newTurnoverData: {},
        turnoverPoolParticipant: {
            totalPoolRewards: 0,
            rewardsPerPool: [],
            rewardsPerUser: [],
            countOfParticipants: []
        },
        isTurnoverPoolParticipantsLoading: false,
        newTurnoverPoolsData: [],
        newTurnoverPoolsMonthlyData: [],
        newTurnoverPoolsSixData: [],
        monthlyPoolLoading: true,
        newTurnoverDataMonthly: {},
        newTurnoverDataSix: {},
        isMonthlyPoolLoading: false,
        turnoverPoolParticipantMonthly: {
            totalPoolRewards: 0,
            rewardsPerPool: [],
            rewardsPerUser: [],
            countOfParticipants: []
        },
        turnoverPoolParticipantSix: {
            totalPoolRewards: 0,
            rewardsPerPool: [],
            rewardsPerUser: [],
            countOfParticipants: []
        },
        monthlyTaxPool: {
            totalClaims: 0,
            pendingGYMNET: 0,
        },
        monthlyTaxPoolTotalClaimedGYMNETETH: 0,
        isSixMonthPoolLoading: false
    };
};

export const actions = {
    async load({commit}) {
        try {

            const address = MetaWorldManager.sharedInstance().getWalletAddressForFetchInfo();
            if (address) {
                const turnoverPoolData      = await this.$contracts[this.$metaverseId()].TurnoverPool.methods.userRewardInfo(address).call();
                const totalClaimedGYMNETWEI = turnoverPoolData.gymnetClaimedAmount;
                const totalClaimedGYMNETETH = fromWei(totalClaimedGYMNETWEI);

                let GYMPrice = MetaInventoryContainer.sharedInstance().minerRewardsData.globalStatistics.price
                const totalCLaimedGymnetInUSD = totalClaimedGYMNETETH * GYMPrice;
                const totalClaimedBUSD_WEI    = turnoverPoolData.busdClaimedAmount
                const totalClaimedBUSD_ETH    = fromWei(totalClaimedBUSD_WEI);
                const totalClaimedDollarValue = totalClaimedBUSD_ETH + totalCLaimedGymnetInUSD;

                const pendingRewardsGymnet_WEI = turnoverPoolData.gymnetAmount;
                const pendingRewardsGymnet_ETH = fromWei(pendingRewardsGymnet_WEI);

                const pendingRewardsBUSD_WEI = turnoverPoolData.busdAmount;
                const pendingRewardsBUSD_ETH = fromWei(pendingRewardsBUSD_WEI);
                const turnoverPool           = {
                    totalClaims: parseFloat(totalClaimedDollarValue).toFixed(2),
                    pendingGYMNET: parseFloat(pendingRewardsGymnet_ETH).toFixed(2),
                    pendingBUSD: parseFloat(pendingRewardsBUSD_ETH).toFixed(2)
                }

                // Monthly tax pool
                const monthlyTaxPoolData                  = await this.$contracts[this.$metaverseId()].MonthlyTaxPool.methods.userRewardInfo(address).call();
                const monthlyTaxPoolTotalClaimedGYMNETWEI = monthlyTaxPoolData.gymnetClaimedAmount;
                const monthlyTaxPoolTotalClaimedGYMNETETH = fromWei(monthlyTaxPoolTotalClaimedGYMNETWEI);

                const monthlyTaxPoolTotalClaimedDollarValue = monthlyTaxPoolTotalClaimedGYMNETETH * GYMPrice;

                const monthlyTaxPoolPendingRewardsGymnet_WEI = monthlyTaxPoolData.gymnetAmount;
                const monthlyTaxPoolPendingRewardsGymnet_ETH = fromWei(monthlyTaxPoolPendingRewardsGymnet_WEI);

                const monthlyTaxPool = {
                    totalClaims: parseFloat(monthlyTaxPoolTotalClaimedDollarValue).toFixed(2),
                    pendingGYMNET: parseFloat(monthlyTaxPoolPendingRewardsGymnet_ETH).toFixed(2),
                }

                commit("SET_DATA", {
                    turnoverPool,
                    totalClaimedBUSD_ETH,
                    totalClaimedGYMNETETH,
                    monthlyTaxPool,
                    monthlyTaxPoolTotalClaimedGYMNETETH
                });
            }
        } catch (error) { // TODO check usages and refactor
            console.error("Unknown error at store/user/turnoverPool load", error);
        }
    },

    async getTurnoverPoolParticipants({commit, rootState}, address) {
        commit('SET_TURNOVER_POOL_PARTICIPANTS_LOADING', true);
        try {
            const turnoverPoolParticipant  = await this.$api.get(`${GYMNET_API_URL}/api/turnover-pool-participants?address=${address}`);
            const turnoverPoolParticipants = turnoverPoolParticipant?.data;
            commit('SET_TURNOVER_POOL_PARTICIPANTS_DATA', turnoverPoolParticipants);
        } finally {
            commit('SET_TURNOVER_POOL_PARTICIPANTS_LOADING', false)
        }
    },

    async getNewTurnoverPoolData({commit, rootState}, address) {
        commit('SET_NEW_TURNOVER_POOL_LOADING', true)
        try {
            const turnoverPool  = await this.$api.get(`${GYMNET_API_URL}/api/get-new-turnover-pool-fifty-percent-rule?address=${address}`);
            const turnoverPools = turnoverPool?.data;
            commit('SET_NEW_TURNOVER_POOLS', turnoverPools);
        } finally {
            commit('SET_NEW_TURNOVER_POOL_LOADING', false)
        }
    },
    async getNewTurnoverPoolDataSixMonthTaxPool({commit, rootState}, address) {
        commit('SET_NEW_TURNOVER_MONTHLY_POOL_LOADING', true);
        try {
            const turnoverPool  = await this.$api.get(`${GYMNET_API_URL}/api/get-tax-pool-qualification?address=${address}`);
            const turnoverPools = turnoverPool?.data;
            commit('SET_NEW_TURNOVER_POOLS_MONTHLY', turnoverPools);
        } finally {
            commit('SET_NEW_TURNOVER_MONTHLY_POOL_LOADING', false)
        }
    },
    async getUserTurnoverStatistics({commit}, address) {
        commit('SET_TURNOVER_STATISTICS_LOADING', true);
        try {
            const turnoverStatistics          = await this.$api.get(`${GYMNET_API_URL}/api/get-new-user-turnover-statistics?address=${address}`);
            const turnoverStatisticsData      = turnoverStatistics?.data;
            const turnoverStatisticsChartData = turnoverStatistics?.data;

            const turnoverStatisticsDataHourly  = turnoverStatistics?.data?.hourly;
            const turnoverStatisticsDataWeekly  = turnoverStatistics?.data?.weekly;
            const turnoverStatisticsDataMonthly = turnoverStatistics?.data?.monthly;

            commit('SET_DATA', {
                turnoverStatisticsData,
                turnoverStatisticsDataHourly,
                turnoverStatisticsDataWeekly,
                turnoverStatisticsDataMonthly,
                turnoverStatisticsChartData
            })
        } catch (err) {
            console.log('turnover error', error)
        } finally {
            commit('SET_TURNOVER_STATISTICS_LOADING', false);
        }
    },
    async getUserNFTRankRewards({commit, rootState}, address) {
        commit('SET_USER_QUALIFICATION_LOADING', true);

        try {
            const userQualification      = await this.$api.get(`${GYMNET_API_URL}/api/get-user-nft-rank-rewards?address=${address}`);
            const userQualificationsData = userQualification?.data?.userQualifications;
            commit('SET_USER_QUALIFICATIONS', userQualificationsData);
        } finally {
            commit('SET_USER_QUALIFICATION_LOADING', false)
        }
    },
    async getTurnoverPoolSnapshots({commit, rootState}, address) {
        commit('SET_TP_SNAPSHOT_LOADING', true);

        try {
            const tpSnapshot = await this.$api.get(`${GYMNET_API_URL}/api/get-user-turnover-pool-snapshots?address=${address}`);
            const userData   = tpSnapshot?.data?.userData;
            commit('SET_TP_DATA', userData);
        } finally {
            commit('SET_TP_SNAPSHOT_LOADING', false)
        }
    },
    async getTurnoverPoolMonthlyTaxSnapshots({commit, rootState}, address) {
        commit('SET_TP_MONTHLY_TAX_SNAPSHOT_LOADING', true);

        try {
            const tpMonthlyTaxSnapshot = await this.$api.get(`${GYMNET_API_URL}/api/get-user-monthly-tax-pool-snapshots?address=${address}`);
            const userData             = tpMonthlyTaxSnapshot?.data?.userData;
            commit('SET_TP_MONTHLY_TAX_DATA', userData);
        } finally {
            commit('SET_TP_MONTHLY_TAX_SNAPSHOT_LOADING', false)
        }
    },
    claimAll({dispatch}) {
        return dispatch(
            "transactions/send",
            {
                callback: this.$write[this.$metaverseId()].TurnoverPool.methods.claimAll(),
            },
            {root: true}
        ).then(() => {
            return dispatch("load");
        });
    },
    claimAllMonthlyTaxPoolRewards({dispatch}) {
        return dispatch(
            "transactions/send",
            {
                callback: this.$write[this.$metaverseId()].MonthlyTaxPool.methods.claim(),
            },
            {root: true}
        ).then(() => {
            return dispatch("load");
        });
    },
    async getSixMonthTurnoverPoolData({commit, rootState}, address) {
        commit('SET_SIX_MONTH_TURNOVER_POOL_LOADING', true)
        try {
            const turnoverPool = await this.$api.get(`${GYMNET_API_URL}/api/calcualte-fifty-percent-rule-six-month-pool?address=${address}`);
            const turnoverData = turnoverPool?.data;
            commit('SET_SIX_MONTH_TURNOVER_DATA', turnoverData );
        } finally {
            commit('SET_SIX_MONTH_TURNOVER_POOL_LOADING', false)
        }
    },
    async getSixMonthPoolRewardsForCurrentMonth({commit}) {
        commit('GET_SM_POOL_REWARDS_CURRENT_MONTH_LOADING', true);

        try {
            const currentMonthSixMonthPool        = await this.$api.get(`${GYMNET_API_URL}/api/get-six-month-pool-rewards-for-current-month`);
            const currentMonthSixMonthPoolRewards = currentMonthSixMonthPool?.data?.pools;
            // commit('SET_SIX_MONTH_REWARDS_POOLS_CURRENT_MONTH', currentMonthSixMonthPoolRewards );
        } catch (e) {
            console.log('error six month pool rewards', e);
        } finally {
            commit('GET_SM_POOL_REWARDS_CURRENT_MONTH_LOADING', false)
        }
    },
    async getMonthlyPoolRewardsForCurrentMonth({commit}) {
        commit('GET_MONTHLY_REWARDS_CURRENT_MONTH_LOADING', true);

        try {
            const currentMonthMonthlyPool        = await this.$api.get(`${GYMNET_API_URL}/api/get-monthly-pool-rewards-for-current-month`);
            const currentMonthMonthlyPoolRewards = currentMonthMonthlyPool?.data?.pools;
            commit('SET_MONTHLY_REWARDS_POOLS_CURRENT_MONTH', currentMonthMonthlyPoolRewards);
        } catch (e) {
            console.log('error monthly pool rewards', e);
        } finally {
            commit('GET_MONTHLY_REWARDS_CURRENT_MONTH_LOADING', false)
        }
    },
    async getMonthlyPoolData({commit}) {
        commit('SET_MONTHLY_POOLS_LOADING', true);

        try {
            const monthlyPool      = await this.$api.get(`${GYMNET_API_URL}/api/monthly-tax-turnover-pool-participants`);
            const monthlyPoolsData = monthlyPool?.data;
            commit('SET_MONTHLY_POOL_PARTICIPANTS_DATA', monthlyPoolsData);
        } catch (e) {
            console.log('error monthly pool', e);
        } finally {
            commit('SET_MONTHLY_POOLS_LOADING', false)
        }
    },
    async getSixMonthPoolData({commit}) {
        commit('SET_SIX_MONTH_POOLS_LOADING', true);

        try {
            const sixMonthPool      = await this.$api.get(`${GYMNET_API_URL}/api/six-month-tax-turnover-pool-participants`);
            const sixMonthPoolsData = sixMonthPool?.data;
            commit('SET_SIX_MONTH_POOL_PARTICIPANTS_DATA', sixMonthPoolsData);
        } catch (e) {
            console.log('error monthly pool', e);
        } finally {
            commit('SET_SIX_MONTH_POOLS_LOADING', false)
        }
    },
    async getNewUserTurnoverStatistics ({__}, address) {
        const userAddress = MetaWorldManager.sharedInstance().getWalletAddressForFetchInfo();

        if (userAddress) {
            const statisticsData = await this.$api.get(`${GYMNET_API_URL}/api/get-new-user-turnover-statistics?address=${address}`);
            return statisticsData.data;
        }
    },
};

export const mutations = {
    SET_DATA(state, data) {
        for (const key in data) {
            state[key] = data[key];
        }
    },
    SET_TURNOVER_STATISTICS_LOADING(state, value) {
        state.turnoverStatisticsLoading = value
    },
    SET_USER_QUALIFICATION_LOADING(state, value) {
        state.isUserQualificationLoading = value;
    },
    SET_USER_QUALIFICATIONS(state, data) {
        state.userQualifications = data;
    },
    SET_TP_SNAPSHOT_LOADING(state, value) {
        state.turnoverPoolSnapshotLoading = value;
    },
    SET_TP_DATA(state, value) {
        state.turnoverPoolSnapshotData = value;
    },
    SET_TP_MONTHLY_TAX_SNAPSHOT_LOADING(state, value) {
        state.turnoverPoolMonthlyTaxSnapshotLoading = value;
    },
    SET_TP_MONTHLY_TAX_DATA(state, value) {
        state.turnoverPoolMonthlyTaxSnapshotData = value;
    },
    SET_SIX_MONTH_TURNOVER_POOL_LOADING(state, value) {
        state.turnoverPoolSixMonthLoading = value;
    },
    SET_SIX_MONTH_TURNOVER_DATA(state, data) {
        state.newTurnoverDataSix = data;
    },
    GET_SM_POOL_REWARDS_CURRENT_MONTH_LOADING(state, value) {
        state.sixMoPoolForCurrentMoLoading = value
    },
    SET_SIX_MONTH_REWARDS_POOLS_CURRENT_MONTH(state, data) {
        state.sixMoPoolForCurrentMoData = data;
    },
    GET_MONTHLY_REWARDS_CURRENT_MONTH_LOADING(state, value) {
        state.monthlyPoolForCurrentMoLoading = value
    },
    SET_MONTHLY_REWARDS_POOLS_CURRENT_MONTH(state, data) {
        state.monthlyPoolForCurrentMoData = data;
    },
    SET_NEW_TURNOVER_POOL_LOADING(state, value) {
        state.isNewTurnoverLoading = value;
    },
    SET_NEW_TURNOVER_POOLS(state, data) {
        state.newTurnoverData = data;
    },
    SET_NEW_TURNOVER_POOLS_MONTHLY(state, data) {
        state.newTurnoverDataMonthly = data;
    },
    SET_TURNOVER_POOL_PARTICIPANTS_LOADING(state, value) {
        state.isTurnoverPoolParticipantsLoading = value;
    },
    SET_NEW_TURNOVER_MONTHLY_POOL_LOADING(state, value) {
        state.monthlyPoolLoading = value;
    },
    SET_TURNOVER_POOL_PARTICIPANTS_DATA(state, data) {
        turnoverPoolsData.forEach((item, index) => {
            item.participants      = data?.countOfParticipants[index];
            item.salesContribution = data?.rewardsPerPool[index];
            item.rewardsPerUser    = data?.rewardsPerUser[index];
        })
        state.newTurnoverPoolsData                        = turnoverPoolsData;
        state.newTurnoverPoolsMonthlyData                 = turnoverPoolsMonthlyData;
        state.turnoverPoolParticipant.rewardsPerPool      = data?.rewardsPerPool;
        state.turnoverPoolParticipant.rewardsPerUser      = data?.rewardsPerUser;
        state.turnoverPoolParticipant.countOfParticipants = data?.countOfParticipants;

    },
    SET_MONTHLY_POOLS_LOADING(state, value) {
        state.isMonthlyPoolLoading = value;
    },
    SET_SIX_MONTH_POOLS_LOADING(state, value) {
        state.isSixMonthPoolLoading = value;
    },

    SET_MONTHLY_POOL_PARTICIPANTS_DATA(state, data) {
        turnoverPoolsMonthlyData.forEach((item, index) => {
            item.participants      = data?.countOfParticipants[index];
            item.salesContribution = data?.rewardsPerPool[index];
            item.rewardsPerUser    = data?.rewardsPerUser[index];
        })
        state.newTurnoverPoolsMonthlyData                        = turnoverPoolsMonthlyData;
        state.turnoverPoolParticipantMonthly.rewardsPerPool      = data?.rewardsPerPool;
        state.turnoverPoolParticipantMonthly.rewardsPerUser      = data?.rewardsPerUser;
        state.turnoverPoolParticipantMonthly.countOfParticipants = data?.countOfParticipants;
    },

    SET_SIX_MONTH_POOL_PARTICIPANTS_DATA(state, data) {
        turnoverPoolsSixMonthData.forEach((item, index) => {
            item.participants      = data?.countOfParticipants[index];
            item.salesContribution = data?.rewardsPerPool[index];
            item.rewardsPerUser    = data?.rewardsPerUser[index];
        })
        state.newTurnoverPoolsSixData                        = turnoverPoolsSixMonthData;
        state.turnoverPoolParticipantSix.rewardsPerPool      = data?.rewardsPerPool;
        state.turnoverPoolParticipantSix.rewardsPerUser      = data?.rewardsPerUser;
        state.turnoverPoolParticipantSix.countOfParticipants = data?.countOfParticipants;
    }
};

export const getters = {
    data: (state) => state,
    turnoverPoolLoading: ({turnoverPoolLoading}) => turnoverPoolLoading,
    turnoverPools: ({turnoverPools}) => turnoverPools,
    turnoverStatisticsDataHourly: ({turnoverStatisticsDataHourly}) => turnoverStatisticsDataHourly,
    turnoverStatisticsDataWeekly: ({turnoverStatisticsDataWeekly}) => turnoverStatisticsDataWeekly,
    turnoverStatisticsDataMonthly: ({turnoverStatisticsDataMonthly}) => turnoverStatisticsDataMonthly,
    turnoverStatisticsChartData: ({turnoverStatisticsChartData}) => turnoverStatisticsChartData,
    turnoverStatisticsLoading: ({turnoverStatisticsLoading}) => turnoverStatisticsLoading,
    userQualifications: ({userQualifications}) => userQualifications,
    isUserQualificationLoading: ({isUserQualificationLoading}) => isUserQualificationLoading,
    turnoverPoolSnapshotLoading: ({turnoverPoolSnapshotLoading}) => turnoverPoolSnapshotLoading,
    turnoverPoolSnapshotData: ({turnoverPoolSnapshotData}) => turnoverPoolSnapshotData,
    turnoverPoolMonthlyTaxSnapshotLoading: ({turnoverPoolMonthlyTaxSnapshotLoading}) => turnoverPoolMonthlyTaxSnapshotLoading,
    turnoverPoolMonthlyTaxSnapshotData: ({turnoverPoolMonthlyTaxSnapshotData}) => turnoverPoolMonthlyTaxSnapshotData,
    turnoverPoolsSixMonth: ({turnoverPoolsSixMonth}) => turnoverPoolsSixMonth,
    turnoverDataSixMonth: ({turnoverDataSixMonth}) => turnoverDataSixMonth,
    turnoverPoolSixMonthLoading: ({turnoverPoolSixMonthLoading}) => turnoverPoolSixMonthLoading,
    sixMoPoolForCurrentMoLoading: ({sixMoPoolForCurrentMoLoading}) => sixMoPoolForCurrentMoLoading,
    sixMoPoolForCurrentMoData: ({sixMoPoolForCurrentMoData}) => sixMoPoolForCurrentMoData,
    monthlyPoolForCurrentMoLoading: ({monthlyPoolForCurrentMoLoading}) => monthlyPoolForCurrentMoLoading,
    monthlyPoolForCurrentMoData: ({monthlyPoolForCurrentMoData}) => monthlyPoolForCurrentMoData,
    totalClaimedBUSD_ETH: ({totalClaimedBUSD_ETH}) => totalClaimedBUSD_ETH,
    totalClaimedGYMNETETH: ({totalClaimedGYMNETETH}) => totalClaimedGYMNETETH,
    isNewTurnoverLoading: ({isNewTurnoverLoading}) => isNewTurnoverLoading,
    newTurnoverData: ({newTurnoverData}) => newTurnoverData,
    turnoverPoolParticipant: ({turnoverPoolParticipant}) => turnoverPoolParticipant,
    newTurnoverPoolsData: ({newTurnoverPoolsData}) => newTurnoverPoolsData,
    monthlyPoolLoading: ({monthlyPoolLoading}) => monthlyPoolLoading,
    newTurnoverDataMonthly: ({newTurnoverDataMonthly}) => newTurnoverDataMonthly,
    newTurnoverPoolsMonthlyData: ({newTurnoverPoolsMonthlyData}) => newTurnoverPoolsMonthlyData,
    turnoverPoolParticipantMonthly: ({turnoverPoolParticipantMonthly}) => turnoverPoolParticipantMonthly,
    monthlyTaxPoolTotalClaimedGYMNETETH: ({monthlyTaxPoolTotalClaimedGYMNETETH}) => monthlyTaxPoolTotalClaimedGYMNETETH,
    newTurnoverDataSix: ({newTurnoverDataSix}) => newTurnoverDataSix,
    newTurnoverPoolsSixData: ({newTurnoverPoolsSixData}) => newTurnoverPoolsSixData,
};
