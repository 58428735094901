// State
import {fromWei, toWei} from "~/core/helpers/GlobalHelpers";
import {CONTRACT_ADDRESSES} from "@/core/services/utils/Constants";
import {MetaWorldManager} from "~/core/services/map/MetaWorldManager";
import ApiService from "~/core/services/api-interaction/ApiService";

const GYMNETWORK_API = 'https://api.gymnetwork.io';

export const state = () => {
    return {
        singlePoolUserInfo: {},
        totalDepositTokensETH: 0,
        poolInfo: 0,
        rewardPerBlock: 0,
        totalLockedUserTokens: 0,
        totalLocked: 0,
        totalGymnetUnlocked: 0,
        pendingRewardsData: [],
        totalRewardsPendingFromAllPools: [],
        APY: 0,
        totalGymnetClaimedWEI: 0,
        totalGymnetClaimedETH: 0,
        collectDeposits: [],
        totalgGymnetLockedETH: 0,
        sharesOfNFTRanks: 0,
        userTotalGGymnetLocked: 0,
        userTotalGymnetClaimtETH: 0,
        totalUnlockedETH: 0,
        totalDepositDollarValue: 0,
        isSPLoading: true,
        currentDepositValue: 0,
        minGYMNETForStaking: 0,
        totalSPCommissionsGYM: 0,
        isSPCommissionsLoading: false,
        spCommissionsData: {
            items: [],
            meta: []
        },
        isActiveObj: {
            1: true,
            2: true,
            3: true
        },
        pendingCommissionsSinglePool_ETH: 0,
        isCommissionsPendingRewardsLoading: false
    };
};

// Actions
export const actions = {
    async loadUserSinglePool({commit, rootState}) {
        let address       = CONTRACT_ADDRESSES.GYMNET;
        const userAddress = MetaWorldManager.sharedInstance().getWalletAddressForFetchInfo();
        if (userAddress) {
            address = userAddress;
        }
        commit('SET_SP_LOADING', true);
        try {
            const agregatedSPData       = await this.$contracts[this.$metaverseId()].GymAgregator.methods.getSinglePool(address).call();
            const poolInfo              = agregatedSPData[0];
            const rewardPerBlock        = agregatedSPData[1];
            const totalLocked           = agregatedSPData[2];
            const totalgGymnetLockedWEI = agregatedSPData[3];
            const totalgGymnetLockedETH = fromWei(totalgGymnetLockedWEI);

            const totalGymnetUnlocked = agregatedSPData[4];
            const totalLockedETH      = fromWei(totalLocked);
            const totalUnlockedETH    = fromWei(totalGymnetUnlocked);
            const gymPriceInUsdWEI    = agregatedSPData[5]
            const gymPriceInETH       = gymPriceInUsdWEI ? fromWei(gymPriceInUsdWEI) : 0;

            const APY                   = ((((parseFloat(gymPriceInETH) * rewardPerBlock) * 28000) * 365) / (parseFloat(totalLocked) * gymPriceInETH)) * 100;
            const totalGymnetClaimedWEI = agregatedSPData[6]
            const totalGymnetClaimedETH = fromWei(totalGymnetClaimedWEI);


            commit("SET_SP_DATA", {
                poolInfo,
                rewardPerBlock,
                totalLocked,
                totalGymnetUnlocked,
                totalUnlockedETH,
                totalLockedETH,
                APY,
                totalGymnetClaimedWEI,
                totalGymnetClaimedETH,
                totalgGymnetLockedETH
            });

            if (address) {
                const singlePoolUserInfo      = agregatedSPData[9];
                const totalDepositDollarValue = singlePoolUserInfo.totalDepositDollarValue;
                const totalDepositTokensETH   = fromWei(singlePoolUserInfo.totalDepositTokens);

                const userTotalGymnetClaimtETH = fromWei(singlePoolUserInfo.totalClaimt);

                const totalLockedUserTokens  = agregatedSPData[7];
                const userTotalGGymnetLocked = agregatedSPData[8];


                const userDepositsAmount = singlePoolUserInfo.depositId;

                const collectDeposits     = [];
                const spCollectedDeposits = [];

                const allPromises = [];

                for (let i = 0; i < userDepositsAmount; i++) {
                    const promise = (async () => {
                        const userDeposits         = await this.$contracts[this.$metaverseId()].SinglePool.methods.user_deposits(address, i).call();
                        userDeposits.pendingReward = await this.$contracts[this.$metaverseId()].SinglePool.methods.pendingReward(i, address).call();
                        userDeposits.id = i;
                        if (!userDeposits.is_finished) {
                            spCollectedDeposits.push(userDeposits)
                        }
                        collectDeposits.push(userDeposits);

                    })();
                    allPromises.push(promise);
                }

                await Promise.all(allPromises);

                collectDeposits.sort((a, b) => a.id - b.id);

                let currentDepositValue = 0;
                if (collectDeposits.length > 0) {
                    for (const deposit of spCollectedDeposits) {
                        currentDepositValue += (parseFloat(deposit.depositTokens) / 1e18) * parseFloat(gymPriceInETH)
                    }
                }


                const sharesOfNFTRanks = ((parseFloat(userTotalGGymnetLocked) / 1e18) / parseFloat(totalgGymnetLockedETH) * 100).toFixed(4)
                commit("SET_SP_DATA", {
                    singlePoolUserInfo,
                    totalDepositTokensETH,
                    totalLockedUserTokens,
                    totalLocked,
                    collectDeposits,
                    sharesOfNFTRanks,
                    userTotalGGymnetLocked,
                    userTotalGymnetClaimtETH: parseFloat(userTotalGymnetClaimtETH),
                    totalDepositDollarValue,
                    currentDepositValue
                });
            }

        } catch (e) {
            console.log(e)
        } finally {
            commit('SET_SP_LOADING', false);
        }
    },

    async loadSinglePoolCommissions ({commit}) {
        const userAddress = MetaWorldManager.sharedInstance().getWalletAddressForFetchInfo();
        commit('SET_SP_COMMISSIONS_LOADING', true);
        try {
            const isActivePendingCommissionsVAULT = await this.$contracts[this.$metaverseId()].PendingCommissionActivation.methods.getCommissionActivation(userAddress, 1).call();
            const isActivePendingCommissionsFarming = await this.$contracts[this.$metaverseId()].PendingCommissionActivation.methods.getCommissionActivation(userAddress, 2).call();
            const isActivePendingCommissionsSinglePool = await this.$contracts[this.$metaverseId()].PendingCommissionActivation.methods.getCommissionActivation(userAddress, 3).call();

            const isActiveObj = {
                1: isActivePendingCommissionsVAULT,
                2: isActivePendingCommissionsFarming,
                3: isActivePendingCommissionsSinglePool
            }
            const pendingCommissionsSinglePool_WEI = await this.$contracts[this.$metaverseId()].SinglePoolPendingCommission.methods.pendingReward(0, userAddress).call()
            const pendingCommissionsSinglePool_ETH = fromWei(pendingCommissionsSinglePool_WEI)
            commit('SET_SP_PENDING_COMMISSIONS', pendingCommissionsSinglePool_ETH);
            commit('SET_IS_ACTIVE_OBJECT', isActiveObj)
        } catch (e) {
            console.log('sp commissions error', e)
        } finally {
            commit('SET_SP_COMMISSIONS_LOADING', false);
        }

    },

    async getSPDepositTotalValue () {
        let address       = CONTRACT_ADDRESSES.GYMNET;
        const userAddress = MetaWorldManager.sharedInstance().getWalletAddressForFetchInfo();
        if (userAddress) {
            address = userAddress;
        }
        try {
            const spEOPDepositAmount       = await this.$contracts[this.$metaverseId()].SinglePool.methods.getEOPDepositAmount(address).call();
            return fromWei(spEOPDepositAmount);
        } catch {
            return 0
        }

    },

    claimAll({dispatch}) {
        return dispatch(
            "transactions/send",
            {
                callback: this.$write[this.$metaverseId()].SinglePool.methods.claimAll(),
            },
            {root: true}
        )
    },
    approveAllowance({dispatch}, amount) {
        return dispatch(
            "transactions/send",
            {
                callback: this.$write[this.$metaverseId()].GymNetwork.methods.approve(this.$contracts[this.$metaverseId()].SinglePool._address, toWei(amount)),
            },
            {root: true},
        );
    },
    deposit({dispatch, rootState}, {amount, referrerId}) {
        return dispatch(
            "transactions/send",
            {
                callback: this.$write[this.$metaverseId()].SinglePool.methods.depositWithEOP(
                    toWei(amount.toString()),
                    referrerId
                ),
            },
            {root: true}
        )
    },

    depositWithOrWithoutLockingPeriod({ dispatch, rootState }, {amount,periodId,isUnlocked, referrerId}) {
        return dispatch(
            "transactions/send",
            {
                callback: this.$write[this.$metaverseId()].SinglePool.methods.deposit(
                    toWei(amount.toString()),
                    periodId,
                    isUnlocked,
                    referrerId
                ),
            },
            { root: true }
        )
    },

    async pendingRewardById({commit, dispatch, rootState}, count) {
        const userAddress                   = MetaWorldManager.sharedInstance().getWalletAddressForFetchInfo();
        const pendingRewardsData            = [];
        let totalRewardsPendingFromAllPools = 0;
        await (async () => {

            for (let i = 0; i < count; i++) {
                const pendingReward   = await this.$contracts[this.$metaverseId()].SinglePool.methods.pendingReward(i, userAddress).call();
                const amount          = (parseFloat(pendingReward) / 1e18);
                totalRewardsPendingFromAllPools += parseFloat(amount);
                pendingRewardsData[i] = amount;
            }
        })();
        commit("SET_SP_DATA", {
            pendingRewardsData,
            totalRewardsPendingFromAllPools
        });
    },

    async getSinglePoolCommission({rootState, state, commit}, payload) {
        commit('SET_SINGLE_POOL_COMMISSION_LOADING', true);
        try {
            const commissionsSP = await ApiService.get(`${GYMNETWORK_API}/api/user-single-pool-commissions?address=${payload.address}&page=${payload.page}`)
            const claimedRewards_pool_0 = await this.$contracts[this.$metaverseId()].SinglePoolPendingCommission.methods.getUserTotalClaims(0, payload.address).call();
            const claimedRewards_pool_0_ETH = fromWei(claimedRewards_pool_0)
            const totalClaimsSum = parseFloat(claimedRewards_pool_0_ETH)
            let rewards = commissionsSP.data.totalGYM ? commissionsSP.data.totalGYM : 0
            const totalSPCommissionsGYM = rewards + totalClaimsSum
            commit('SET_TOTAL_SP_COMMISSION_DATA', totalSPCommissionsGYM)
            commit('SET_SINGLE_POOL_COMMISSION_DATA', commissionsSP.data.commissionsList);
        } finally {
            commit('SET_SINGLE_POOL_COMMISSION_LOADING', false)
        }
    },
    activatePendingCommissions({ dispatch }) {
        return dispatch(
            "transactions/send",
            {
                callback: this.$write[this.$metaverseId()].SinglePool.methods.activatePendingCommissions(),
            },
            { root: true }
        )
    },
    claimSPPendingCommissions({ dispatch }) {
        return dispatch(
            "transactions/send",
            {
                callback: this.$write[this.$metaverseId()].SinglePoolPendingCommission.methods.claimAll(),
            },
            { root: true }
        )
    },
    withdraw({dispatch}, id) {
        return dispatch(
            "transactions/send",
            {
                callback: this.$write[this.$metaverseId()].SinglePool.methods.withdraw(id),
            },
            {root: true}
        )
    },
    setGYMNETMinAmount({commit}, value) {
        commit('SET_MIN_GYMNET_AMOUNT', value);
    }

};

// Mutations
export const mutations = {
    SET_SP_DATA(state, data) {
        for (const key in data) {
            state[key] = data[key];
        }
    },
    SET_SP_LOADING(state, value) {
        state.isSPLoading = value;
    },
    SET_MIN_GYMNET_AMOUNT(state, value) {
        state.minGYMNETForStaking = value;
    },
    SET_SINGLE_POOL_COMMISSION_LOADING(state, value) {
        state.isSPCommissionsLoading = value;
    },
    SET_SINGLE_POOL_COMMISSION_DATA(state, data) {
        state.spCommissionsData.items = data.items;
        state.spCommissionsData.meta = data.meta;
    },
    SET_TOTAL_SP_COMMISSION_DATA(state, value) {
        state.totalSPCommissionsGYM = value;
    },
    SET_IS_ACTIVE_OBJECT(state, data) {
        state.isActiveObj = data;
    },
    SET_SP_PENDING_COMMISSIONS(state, value) {
        state.pendingCommissionsSinglePool_ETH = value;
    },
    SET_SP_COMMISSIONS_LOADING(state, value) {
        state.isCommissionsPendingRewardsLoading = value;
    }
};

// Getters
export const getters = {
    singlePoolData: (state) => state,
    isSPLoading: ({isSPLoading}) => isSPLoading,
    minGYMNETForStaking: ({minGYMNETForStaking}) => minGYMNETForStaking,
    totalSPCommissionsGYM: ({totalSPCommissionsGYM}) => totalSPCommissionsGYM,
    spCommissionsData: ({spCommissionsData}) => spCommissionsData,
    isSPCommissionsLoading: ({isSPCommissionsLoading}) => isSPCommissionsLoading,
    isActiveObj: ({isActiveObj}) => isActiveObj,
    pendingCommissionsSinglePool_ETH: ({pendingCommissionsSinglePool_ETH}) => pendingCommissionsSinglePool_ETH,
    isCommissionsPendingRewardsLoading: ({isCommissionsPendingRewardsLoading}) => isCommissionsPendingRewardsLoading,
};
