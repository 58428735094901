import { POLLING_STORE_FUNCTIONS } from "./polling";
import { STATE } from "./names";
import { DurationFilter } from "./types";

export default () => {
    return {
        [STATE.DURATION_FILTER]: DurationFilter.ALL as DurationFilter,
        [STATE.ACTIVE_PARTNERS_COUNT]: null as number | null,
        [STATE.INACTIVE_PARTNERS_COUNT]: null as number | null,
        [STATE.DIRECT_PARTNERS_COUNT]: null as number | null,
        [STATE.LEFT_LEG_PARTNERS_COUNT]: null as number | null,
        [STATE.RIGHT_LEG_PARTNERS_COUNT]: null as number | null,
        [STATE.TEAM_DATA_LOADING]: false,
        [STATE.TEAM_DATA_LOADING_ERROR]: null as string | null,
        [STATE.TEAM_DATA_LOADING_PROMISE]: null as Promise<void> | null,
        ...POLLING_STORE_FUNCTIONS.state,
    };
};