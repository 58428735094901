import { ACTIONS } from "./names";

export default {
    [ACTIONS.UPDATE_USER_AUTH_DATA]({ commit }, data: Record<string, unknown>) {
        const currentAuthUserData = this.$auth.user;
        this.$auth.$storage.setState('user', {
            ...currentAuthUserData,
            ...data,
        });
        commit("application/driver/UPDATE_AUTH_USER", {
            ...data,
        }, { root: true });
    },
    async [ACTIONS.LOG_OUT]({ commit, dispatch }) {
        await this.$auth.logout();
        await dispatch('application/driver/logOut', null, {root: true});
        await this.$router.push('/nfts/products');
    },
};