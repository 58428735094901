import { ACTIONS, MUTATIONS,GETTERS as PROMOS_GETTERS } from "./names";
import ApiService from "~/core/services/api-interaction/ApiService";

export default {
    [ACTIONS.ON_PROMOS_LOADING]({ commit }, payload) {
        commit(MUTATIONS.SET_PROMOS_LOADING, payload);
    },
    async [ACTIONS.GET_PROMOS]({ commit,rootGetters },payload) {
        const promosData = rootGetters[`affility/promos/${PROMOS_GETTERS.PROMOS_DATA_CONFIG}`];
        try {
            const firstResponse = await ApiService.get(`${this.$config.binaryTreeApi}/api/affility/promotions`);
            const secondResponse = await ApiService.get(`${this.$config.gymnetApi}/api/get-all-incentive-results?address=${payload}`);
            const combinedResponse = {...firstResponse.data, ...secondResponse.data};
            const data = {
                combinedResponse:combinedResponse ||  null,
                promosData,
            };
            commit(MUTATIONS.SET_PROMOS,data );
        } finally {
            commit(MUTATIONS.SET_PROMOS_LOADING, false);
        }
    },
    [ACTIONS.CLEAR_PROMOS]({ commit }) {
        commit(MUTATIONS.SET_CLEAR_PROMOS);
    }
};
