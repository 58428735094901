const intervalToHandlerMap = new Map<number, {
    interval: number,
    intervalId: ReturnType<typeof setInterval>,
    repetition: number;
    callbacks: ((repetition: number) => void)[];
}>();

function intervalHandler (intervalObject: ReturnType<typeof intervalToHandlerMap.get>) {
    intervalObject.repetition++;
    intervalObject.callbacks.forEach(callback => callback(intervalObject.repetition));
}

function removeCallbackFromInterval(intervalObject: ReturnType<typeof intervalToHandlerMap.get>, callback: () => void) {
    intervalObject.callbacks = intervalObject.callbacks.filter(cb => cb !== callback);
    if (intervalObject.callbacks.length === 0) {
        clearInterval(intervalObject.intervalId);
        intervalToHandlerMap.delete(intervalObject.interval);
    }
}

export function useSyncedUniqueInterval(interval: number, callback: () => void): () => void {
    let intervalObject = intervalToHandlerMap.get(interval);
    if (!intervalObject) {
        intervalObject = {
            interval,
            repetition: 0,
            callbacks: [],
            intervalId: null,
        };
        intervalObject.intervalId = setInterval(intervalHandler.bind(null, intervalObject), interval);
    }
    intervalObject.callbacks.push(callback);
    intervalToHandlerMap.set(interval, intervalObject);
    return removeCallbackFromInterval.bind(null, intervalObject, callback);
}