import { STATE } from "./names";

export default () => {
    return {
        [STATE.QUEUE]: [],
        [STATE.IS_SUCCESS_MODAL_OPEN]: false,
        [STATE.SUCCESS_MODAL_TITLE]: '',
        [STATE.SUCCESS_MODAL_DESC]: '',
        [STATE.SUCCESS_MODAL_BTN_TEXT]: 'ok',
        [STATE.SUCCESS_MODAL_BUTTON_VISIBLE]: true,
        [STATE.SUCCESS_MODAL_CLOSE_BY_CLICK_MODAL]: true,
        [STATE.SUCCESS_MODAL_CLOSE_ON_PRESS_ESCAPE]: true,
        [STATE.SUCCESS_MODAL_TIMEOUT_ID]: null,
    };
};