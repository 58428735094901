import { setupStorePolling } from "~/core/utilities/store-polling/setupStorePolling";
import { UNIQUE_IDENTIFIER, POLLING_NAMES, ACTIONS, GETTERS } from "./names";

export const POLLING_STORE_FUNCTIONS = setupStorePolling(
    POLLING_NAMES,
    (context) => {
        const { dispatch, getters } = context;
        const clearFunction = getters[GETTERS.CLEAR_FUNCTION];
        return dispatch(
            ACTIONS.LOAD_PROCESS_AND_SAVE_TEAM_DATA,
            { 
                save: () => !!getters[GETTERS.CLEAR_FUNCTION] &&
                            (!clearFunction || clearFunction === getters[GETTERS.CLEAR_FUNCTION]),
                duration: getters[GETTERS.DURATION_FILTER],
            }
        );
    },
    UNIQUE_IDENTIFIER,
);