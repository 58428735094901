import { getStorePollingNames } from "~/core/utilities/store-polling/getStorePollingNames";

export const UNIQUE_IDENTIFIER = 'commissions-info';
export const POLLING_NAMES = getStorePollingNames(UNIQUE_IDENTIFIER);

export const STATE = {
    BINARY_WEEKLY_CAP: 'binaryWeeklyCap',
    BINARY_CURRENT_PROGRESS: 'binaryCurrentProgress',
    TOTAL_BINARY_COMMISSION: 'totalBinaryCommission',
    CLAIMED_COMMISSIONS: 'claimedCommissions',
    AVAILABLE_COMMISSIONS: 'availableCommissions',
    COLLECTED_BINARY_BONUS: 'collectedBinaryBonus',
    COLLECTED_MATCHING_BONUS: 'collectedMatchingBonus',
    COMMISSIONS_INFO_DATA_LOADING: 'commissionsInfoDataLoading',
    COMMISSIONS_INFO_DATA_LOADING_ERROR: 'commissionsInfoDataLoadingError',
    COMMISSIONS_INFO_DATA_LOADING_PROMISE: 'commissionsInfoDataLoadingPromise',
    ...POLLING_NAMES.state,
} as const;

export const ACTIONS = {
    LOAD_PROCESS_AND_SAVE_COMMISSIONS_INFO_DATA: 'loadProcessAndSaveCommissionsInfoData',
    LOAD_AND_PROCESS_COMMISSIONS_INFO_DATA: 'loadAndProcessUnilevelCommissionsInfoData',
    LOAD_COMMISSIONS_INFO_DATA: 'loadCommissionsInfoData',
    PROCESS_COMMISSIONS_INFO_DATA: 'processCommissionsInfoData',
    SAVE_COMMISSIONS_INFO_DATA: 'saveCommissionsInfoData',
    CLEAR_COMMISSIONS_INFO_STATE: 'clearCommissionsInfoState',
    ...POLLING_NAMES.actions,
} as const; 

export const MUTATIONS = {
    SET_BINARY_WEEKLY_CAP: 'setBinaryWeeklyCap',
    SET_BINARY_CURRENT_PROGRESS: 'setBinaryCurrentProgress',
    SET_TOTAL_BINARY_COMMISSION: 'setTotalBinaryCommission',
    SET_CLAIMED_COMMISSIONS: 'setClaimedCommissions',
    SET_AVAILABLE_COMMISSIONS: 'setAvailableCommissions',
    SET_COLLECTED_BINARY_BONUS: 'setCollectedBinaryBonus',
    SET_COLLECTED_MATCHING_BONUS: 'setCollectedMatchingBonus',
    SET_COMMISSIONS_INFO_DATA_LOADING: 'SET_COMMISSIONS_INFO_DATA_LOADING',
    SET_COMMISSIONS_INFO_DATA_LOADING_ERROR: 'SET_COMMISSIONS_INFO_DATA_LOADING_ERROR',
    SET_COMMISSIONS_INFO_DATA_LOADING_PROMISE: 'SET_COMMISSIONS_INFO_DATA_LOADING_PROMISE',
    ...POLLING_NAMES.mutations,
} as const;

export const GETTERS = {
    BINARY_WEEKLY_CAP: 'binaryWeeklyCap',
    BINARY_CURRENT_PROGRESS: 'binaryCurrentProgress',
    TOTAL_BINARY_COMMISSION: 'totalBinaryCommission',
    CLAIMED_COMMISSIONS: 'claimedCommissions',
    AVAILABLE_COMMISSIONS: 'availableCommissions',
    COLLECTED_BINARY_BONUS: 'collectedBinaryBonus',
    COLLECTED_MATCHING_BONUS: 'collectedMatchingBonus',
    TOTAL_COMMISSIONS: 'totalCommissions',
    DEFAULT_DISTRIBUTION_DATE: 'defaultDitributionDate',
    BINARY_BONUS_DISTRIBUTION_TIME: 'binaryBonusDistributionTime',
    MATCHING_BONUS_DISTRIBUTION_TIME: 'matchingBonusDistributionTime',
    COMMISSIONS_INFO_DATA_LOADING: 'commissionsInfoDataLoading',
    COMMISSIONS_INFO_DATA_LOADING_ERROR: 'commissionsInfoDataLoadingError',
    COMMISSIONS_INFO_DATA_LOADING_PROMISE: 'commissionsInfoDataLoadingPromise',
    ...POLLING_NAMES.getters,
} as const;