var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('BaseSwitchMonochromeIconedToggle',{attrs:{"size":_setup.size,"theme":_setup.theme,"value":_setup.$colorMode.value === 'dark',"disabled":_setup.disabled,"onIcon":{
        name: 'light-mode',
        path: 'general',
    },"offIcon":{
        name: 'dark-mode',
        path: 'general',
    }},on:{"input":_setup.onModeChange}})
}
var staticRenderFns = []

export { render, staticRenderFns }