export const STATE = {
    IS_VERIFYING_EMAIL: 'isVerifyingEmail',
    EMAIL_SENT_NOTIFICATION_VISIBLE: 'emailSentNotificationVisible',
} as const;

export const ACTIONS = {
    CLEAR_EMAIL_VERIFICATION_STATE: 'clearEmailVerificationState',
    START_EMAIL_VERIFICATION: 'startEmailVerification',
    CANCEL_EMAIL_VERIFICATION: 'cancelEmailVerification',
    RESEND_LINK_MUTATE_USER_AND_SHOW_NOTIFICATION: 'resendLinkMutateUserAndShowNotification',
    RESEND_EMAIL_VERIFICATION_LINK_AND_MUTATE_USER: 'resendEmailVerificationLinkAndMutateUser',
    RESEND_EMAIL_VERIFICATION_LINK: 'resendEmailVerificationLink',
    CHANGE_EMAIL_MUTATE_USER_AND_SHOW_NOTIFICATION: 'changeEmailMutateUserAndShowNotification',
    CHANGE_EMAIL_AND_MUTATE_USER: 'changeEmailAndMutateUser',
    CHANGE_EMAIL: "changeEmail",
    SHOW_EMAIL_SENT_NOTIFICATION: 'showEmailSentNotification',
    SHOW_NOTIFICATION_AND_CLOSE_FLOW: 'showNotificationAndCloseFlow',
    HIDE_EMAIL_SENT_NOTIFICATION: 'hideEmailSentNotification',
    HIDE_EMAIL_SENT_NOTIFICATION_AND_OPEN_FLOW: 'hideEmailSentNotificationAndOpenFlow',
} as const;

export const MUTATIONS = {
    SET_IS_VERIFYING_EMAIL: 'SET_IS_VERIFYING_EMAIL',
    SET_EMAIL_SENT_NOTIFICATION_VISIBLE: 'SET_EMAIL_SENT_NOTIFICATION_VISIBLE',
} as const;

export const GETTERS = {
    IS_VERIFYING_EMAIL: 'isVerifyingEmail',
    IS_EMAIL_SENT_NOTIFICATION_VISIBLE: 'isEmailSentNotificationVisible',
    LAST_EMAIL_DATE: 'lastEmailDate',
} as const;