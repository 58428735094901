export const STATE = {
    TOTAL_NOTIFICATIONS_COUNT: 'totalNotificationsCount',
    NOTIFICATIONS_HISTORY_LOADING: 'notificationsHistoryLoading',
    NOTIFICATIONS_HISTORY_LOADING_PROMISE: 'notificationsHistoryLoadingPromise',
    NOTIFICATIONS_HISTORY_LOADING_ERROR: 'notificationsHistoryLoadingError',
    NOTIFICATION_HISTORY: 'notificationHistory',
    NOTIFICATIONS_HISTORY_LOAD_COUNT: 'notificationsHistoryLoadCount',
} as const;

export const ACTIONS = {
    LOAD_MORE_NOTIFICATIONS_HISTORY: 'loadMoreNotificationsHistory',
    FETCH_AND_PROCESS_NOTIFICATIONS_HISTORY: 'fetchAndProcessNotificationsHistory',
    FETCH_NOTIFICATION_HISTORY: 'fetchNotificationHistory',
    PROCESS_NOTIFICATIONS_HISTORY: 'processNotificationsHistory',
    SAVE_NOTIFICATIONS_HISTORY: 'saveNotificationsHistory',
    CLEAR_NOTIFICATIONS_HISTORY_STATE: 'clearNotificationsHistoryState',
} as const;

export const MUTATIONS = {
    ADD_NOTIFICATIONS_TO_HISTORY: 'ADD_NOTIFICATIONS_TO_HISTORY',
    SET_NOTIFICATION_HISTORY: 'SET_NOTIFICATION_HISTORY',
    SET_NOTIFICATIONS_HISTORY_LOAD_COUNT: 'SET_NOTIFICATIONS_HISTORY_LOAD_COUNT',
    SET_TOTAL_NOTIFICATIONS_COUNT: 'SET_TOTAL_NOTIFICATIONS_COUNT',
    ADD_TO_TOTAL_NOTIFICATIONS_COUNT: 'ADD_TO_TOTAL_NOTIFICATIONS_COUNT',
    SET_NOTIFICATIONS_HISTORY_LOADING: 'SET_NOTIFICATIONS_HISTORY_LOADING',
    SET_NOTIFICATIONS_HISTORY_LOADING_PROMISE: 'SET_NOTIFICATIONS_HISTORY_LOADING_PROMISE',
    SET_NOTIFICATIONS_HISTORY_LOADING_ERROR: 'SET_NOTIFICATIONS_HISTORY_LOADING_ERROR',
} as const;

export const GETTERS = {
    NOTIFICATION_HISTORY: 'notificationHistory',
    TOTAL_NOTIFICATIONS_COUNT: 'totalNotificationsCount',
    NOTIFICATIONS_HISTORY_LOAD_COUNT: 'notificationsHistoryLoadCount',
    NOTIFICATIONS_HISTORY_LOADING: 'notificationsHistoryLoading',
    NOTIFICATIONS_HISTORY_LOADING_PROMISE: 'notificationsHistoryLoadingPromise',
    NOTIFICATIONS_HISTORY_LOADING_ERROR: 'notificationsHistoryLoadingError',
} as const;