
import { defineComponent } from 'vue';
import VueCountdown from '@chenfengyuan/vue-countdown';

export default defineComponent({
    name: 'PurchaseCountdown',
    components: {
        VueCountdown
    },
    props: {
        endDate: {
            type: Number,
            default: null
        }
    },
    computed: {
        countdownTime () {
            const { endDate } = this.$props;
            return endDate - Date.now();
        }
    },
    methods: {
        addZero (time: Record<string, number>) {
            const formattedTime = {};

            Object.entries(time).forEach(([key, value]) => {
                formattedTime[key] = value < 10 ? `0${value}` : String(value);
            });

            return formattedTime;
        }
    }
});
