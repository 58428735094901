var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',[_c('ComposedCardProfile',{staticClass:"card-profile",attrs:{"username":_setup.user.username,"wallet-address":_setup.user.anyWalletAddress,"clickable":false}}),_vm._v(" "),_c('BaseDividerSimple'),_vm._v(" "),_c('ul',{staticClass:"menu-section"},[_c('ComposedMenuItemCollapsible',{attrs:{"to":"/account","title":_vm.$t('profile'),"prefixIcon":{
                name: 'profile',
                path: 'activatable',
                activatable: true,
            },"active":_setup.route.path === '/account'}}),_vm._v(" "),_c('ComposedMenuItemContractAddressesOpener',{on:{"open":function($event){return _setup.$emit('contract-addresses')}}})],1),_vm._v(" "),_c('BaseDividerSimple'),_vm._v(" "),_c('ul',{staticClass:"menu-section"},[_c('ComposedMenuItemThemeSwitcher'),_vm._v(" "),_c('ComposedMenuItemTwoFASwitcher'),_vm._v(" "),_c('ComposedMenuItemLanguagesOpener',{on:{"open":function($event){return _setup.$emit('languages')}}}),_vm._v(" "),_c('ComposedMenuItemCollapsible',{attrs:{"linkTag":"a","linkTagProps":{
                href: 'https://docs.gymstreet.io/',
                target: '_blank',
            },"title":_vm.$t('learn'),"prefixIcon":{
                name: 'learn',
                path: 'activatable',
                activatable: true,
            }}}),_vm._v(" "),_c('ComposedMenuItemCollapsible',{attrs:{"linkTag":"a","linkTagProps":{
                href: 'mailto:support@gymstreet.io',
                target: '_blank',
            },"title":_vm.$t('help_center'),"prefixIcon":{
                name: 'help-center',
                path: 'activatable',
                activatable: true,
            }}})],1),_vm._v(" "),_c('BaseDividerSimple'),_vm._v(" "),_c('ul',{staticClass:"menu-section"},[_c('ComposedMenuItemCollapsible',{attrs:{"link-tag":"button","to":"#","title":_vm.$t('log_out'),"prefixIcon":{
                name: 'log-out',
                path: 'activatable',
                activatable: true,
            }},nativeOn:{"click":function($event){return _setup.logout.apply(null, arguments)}}})],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }