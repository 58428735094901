import { STATE, GETTERS } from "./names";
import { POLLING_STORE_FUNCTIONS } from "./polling";
import type { State } from "./types/State";

export default {
    [GETTERS.LEFT_CV]: (state: State) => state[STATE.LEFT_CV],
    [GETTERS.RIGHT_CV]: (state: State) => state[STATE.RIGHT_CV],
    [GETTERS.LEFT_SUBTREE_SIZE]: (state: State) => state[STATE.LEFT_SUBTREE_SIZE],
    [GETTERS.RIGHT_SUBTREE_SIZE]: (state: State) => state[STATE.RIGHT_SUBTREE_SIZE],
    [GETTERS.LEFT_SUBTREE_ROOT]: (state: State) => state[STATE.LEFT_SUBTREE_ROOT],
    [GETTERS.RIGHT_SUBTREE_ROOT]: (state: State) => state[STATE.RIGHT_SUBTREE_ROOT],
    [GETTERS.BINARY_PATH]: (state: State) => state[STATE.BINARY_PATH],
    [GETTERS.BINARY_RANK_NAME]: (state: State) => state[STATE.BINARY_RANK_NAME],
    [GETTERS.SUBTREE_DATA_LOADING]: (state: State) => state[STATE.SUBTREE_DATA_LOADING],
    [GETTERS.SUBTREE_DATA_LOADING_ERROR]: (state: State) => state[STATE.SUBTREE_DATA_LOADING_ERROR],
    [GETTERS.SUBTREE_DATA_LOADING_PROMISE]: (state: State) => state[STATE.SUBTREE_DATA_LOADING_PROMISE],
    ...POLLING_STORE_FUNCTIONS.getters,
};