import type { Ref } from "vue";

export async function* getPollingGenerator(
    callback: Function,
    interval: Ref | number,
    stillRunning: Ref,
) {
    let count = 0;
    let result = null;
    while (stillRunning.value) {
        try {
            result = await callback();
            yield { result };
        } catch (error) { 
            yield { error, result };
        } 
        count++;

        const intervalValue = typeof interval === "number" ? interval : interval.value;

        await new Promise(resolve => setTimeout(resolve, intervalValue));
    }
    return result;
}