import { render, staticRenderFns } from "./BalanceSection.vue?vue&type=template&id=98a5b50a&scoped=true"
import script from "./BalanceSection.vue?vue&type=script&setup=true&lang=ts"
export * from "./BalanceSection.vue?vue&type=script&setup=true&lang=ts"
import style0 from "./BalanceSection.vue?vue&type=style&index=0&id=98a5b50a&prod&scoped=true&lang=scss"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "98a5b50a",
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {ComposedDropdownAssetBalances: require('/root/dashboard/core/components/composed/dropdown/AssetBalances.vue').default,ComposedListMultipleAssetAmounts: require('/root/dashboard/core/components/composed/list/MultipleAssetAmounts.vue').default})
