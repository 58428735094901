import { render, staticRenderFns } from "./Notifications.vue?vue&type=template&id=2e1f6176&scoped=true"
import script from "./Notifications.vue?vue&type=script&setup=true&lang=ts"
export * from "./Notifications.vue?vue&type=script&setup=true&lang=ts"
import style0 from "./Notifications.vue?vue&type=style&index=0&id=2e1f6176&prod&scoped=true&lang=scss"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "2e1f6176",
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {BaseButtonClose: require('/root/dashboard/core/components/base/button/Close.vue').default,BaseIndicatorBadge: require('/root/dashboard/core/components/base/indicator/Badge.vue').default,BaseButtonSimple: require('/root/dashboard/core/components/base/button/Simple.vue').default,ComposedPlaceholderNotificationsEmpty: require('/root/dashboard/core/components/composed/placeholder/NotificationsEmpty.vue').default,ComposedCardNotification: require('/root/dashboard/core/components/composed/card/Notification.vue').default,BaseDividerSimple: require('/root/dashboard/core/components/base/divider/Simple.vue').default,BaseLoaderAffility: require('/root/dashboard/core/components/base/loader/Affility.vue').default,ComposedPlaceholderNotificationsLoadingError: require('/root/dashboard/core/components/composed/placeholder/NotificationsLoadingError.vue').default,BaseSidebarSliding: require('/root/dashboard/core/components/base/sidebar/Sliding.vue').default,BaseOverlaySimple: require('/root/dashboard/core/components/base/overlay/Simple.vue').default})
