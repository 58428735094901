import {getClientRuntimeConfig} from "~/core/helpers/GlobalHelpers";
import ApiService from "~/core/services/api-interaction/ApiService";

const $config = getClientRuntimeConfig();

export const state = () => {
    return {
        histroyData: [],
        isHistoryAPIError: false,
        isHistoryAPILoading: false,
        ranksStats: [],
        ignoredRanks: [],
        isRankAPIError: false,
        isRankAPILoading: false,
    }
}

export const actions = {
    async getHistoryData({ commit }) {
        commit('SET_HISTORY_DATA_LOADING', true);
        try {
            const responseBonusHistory = await ApiService.get(`${$config.binaryTreeApi}/api/cash-bonus/history?newVersion=true`);
            commit('SET_HISTORY_DATA', responseBonusHistory?.data || []);
        } catch (err) {
            commit('SET_HISTORY_DATA_ERROR', true);
        } finally {
            commit('SET_HISTORY_DATA_LOADING', false);
        }
    },
    async getRanksStatistics({ commit }) {
        commit('SET_RANKS_DATA_LOADING', true);
        try {
            const responseRankStats = await ApiService.get(`${$config.binaryTreeApi}/api/cash-bonus/rank-statistics?newVersion=true`);
            console.log(responseRankStats.data);
            commit('SET_RANKS_STATS', responseRankStats?.data?.result || []);
            commit('SET_IGNORED_RANKS', responseRankStats?.data?.ignoredRanks || []);
        } catch (err) {
            console.log(err);
            commit('SET_RANKS_DATA_ERROR', true);
        } finally {
            commit('SET_RANKS_DATA_LOADING', false);
        }
    },
}

export const mutations = {
    SET_HISTORY_DATA(state, data) {
        if (!data) {
            return;
        }
        state.histroyData = data;
    },
    SET_HISTORY_DATA_ERROR(state, value) {
        state.isHistoryAPIError = value;
    },
    SET_HISTORY_DATA_LOADING(state, value) {
        state.isHistoryAPILoading = value;
    },
    SET_RANKS_DATA_LOADING(state, value) {
        state.isRanksAPILoading = value;
    },
    SET_RANKS_STATS(state, data) {
        if (!data) {
            return;
        }
        state.ranksStats = data;
    },
    SET_IGNORED_RANKS(state, data) {
        if (!data) {
            return;
        }
        state.ignoredRanks = data;
    },
    SET_RANKS_DATA_ERROR(state, value) {
        state.isRankAPIError = value;
    },
}

export const getters = {
    historyData: ({ histroyData }) => histroyData,
    isHistoryAPIError: ({ isHistoryAPIError }) => isHistoryAPIError,
    isHistoryAPILoading: ({ isHistoryAPILoading }) => isHistoryAPILoading,
    ranksStats: ({ ranksStats }) => ranksStats,
    ignoredRanks: ({ ignoredRanks }) => ignoredRanks,
    isRankAPIError: ({ isRankAPIError }) => isRankAPIError,
    isRanksAPILoading: ({ isRanksAPILoading }) => isRanksAPILoading,
}
