export const STATE = {
} as const;

export const ACTIONS = {
    APPROVE_IMAGE: 'approveImage',
    REJECT_IMAGE: 'rejectImage',
} as const;

export const MUTATIONS = {
} as const;

export const GETTERS = {
} as const;