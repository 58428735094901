export const STATE = {
    MOBILE_SIDEBAR_OPEN: 'mobileSidebarOpen',
    MOBILE_PROFILE_SIDEBAR_OPEN: 'mobileProfileSidebarOpen',
    MOBILE_CONTRACT_ADDRESSES_SIDEBAR_OPEN: 'mobileContractAddressesSidebarOpen',
    MOBILE_LANGUAGES_SIDEBAR_OPEN: 'mobileLanguagesSidebarOpen',
    OPENED_SUBMENU: 'openedSubmenu',
    ACTIVE_ITEM_PATH: 'activeItemPath',
} as const;

export const ACTIONS = {
    OPEN_SUBMENU: 'openSubmenu',
    CLOSE_SUBMENU: 'closeSubmenu',
    OPEN_MOBILE_SIDEBAR: 'openMobileSidebar',
    CLOSE_MOBILE_SIDEBAR: 'closeMobileSidebar',
    CLOSE_MOBILE_SIDEBAR_AND_OPEN_CONTRACT_ADDRESSES: 'closeMobileSidebarAndOpenContractAddresses',
    CLOSE_MOBILE_SIDEBAR_AND_OPEN_LANGUAGES: 'closeMobileSidebarAndOpenLanguages',
    OPEN_MOBILE_PROFILE_SIDEBAR: 'openMobileProfileSidebar',
    CLOSE_MOBILE_PROFILE_SIDEBAR: 'closeMobileProfileSidebar',
    CLOSE_PROFILE_AND_OPEN_CONTRACT_ADDRESSES_SIDEBAR: 'closeProfileAndOpenContractAddressesSidebar',
    CLOSE_PROFILE_AND_OPEN_LANGUAGES_SIDEBAR: 'closeProfileAndOpenLanguagesSidebar',
    OPEN_MOBILE_CONTRACT_ADDRESSES_SIDEBAR: 'openMobileContractAddressesSidebar',
    CLOSE_MOBILE_CONTRACT_ADDRESSES_SIDEBAR: 'closeMobileContractAddressesSidebar',
    CLOSE_CONTRACT_ADDRESSES_AND_OPEN_PROFILE_SIDEBAR: 'closeContractAddressesAndOpenProfileSidebar',
    CLOSE_CONTRACT_ADDRESSES_AND_OPEN_GENERAL_SIDEBAR: 'closeContractAddressesAndOpenGeneralSidebar',
    OPEN_MOBILE_LANGUAGES_SIDEBAR: 'openMobileLanguagesSidebar',
    CLOSE_MOBILE_LANGUAGES_SIDEBAR: 'closeMobileLanguagesSidebar',
    CLOSE_LANGUAGES_AND_OPEN_PROFILE_SIDEBAR: 'closeLanguagesAndOpenProfileSidebar',
    CLOSE_LANGUAGES_AND_OPEN_GENERAL_SIDEBAR: 'closeLanguagesAndOpenGeneralSidebar',
    REFRESH_ACTIVE_ITEM: 'refreshActiveItem',
} as const;

export const MUTATIONS = {
    SET_MOBILE_SIDEBAR_OPEN: 'SET_MOBILE_SIDEBAR_OPEN',
    SET_OPEN_SUB_MENU: 'SET_OPEN_SUB_MENU',
    SET_ACTIVE_ITEM_PATH: 'SET_ACTIVE_ITEM_PATH',
    SET_MOBILE_PROFILE_SIDEBAR_OPEN: 'SET_MOBILE_PROFILE_SIDEBAR_OPEN',
    SET_MOBILE_CONTRACT_ADDRESSES_SIDEBAR_OPEN: 'SET_MOBILE_CONTRACT_ADDRESSES_SIDEBAR_OPEN',
    SET_MOBILE_LANGUAGES_SIDEBAR_OPEN: 'SET_MOBILE_LANGUAGES_SIDEBAR_OPEN',
} as const;

export const GETTERS = {
    CONFIG: 'config',
    OPENED_SUBMENU: 'openedSubmenu',
    MOBILE_SIDEBAR_OPEN: 'mobileSidebarOpen',
    MOBILE_PROFILE_SIDEBAR_OPEN: 'mobileProfileSidebarOpen',
    MOBILE_CONTRACT_ADDRESSES_SIDEBAR_OPEN: 'mobileContractAddressesSidebarOpen',
    MOBILE_LANGUAGES_SIDEBAR_OPEN: 'mobileLanguagesSidebarOpen',
    ACTIVE_ITEM_PATH: 'activeItemPath',
} as const;