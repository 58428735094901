import { ACTIONS, MUTATIONS } from "./names";
import { ACTIONS as USER_AUTH_ACTIONS } from "./auth/names";
import { ACTIONS as USER_BINARY_ACTIONS } from "./binary/names";
import { ACTIONS as USER_UNILEVEL_ACTIONS } from "./unilevel/names";
import { ACTIONS as USER_RANK_ACTIONS } from "./rank/names";
import { ACTIONS as NOTIFICATION_ACTIONS } from "./notifications/names";
import { ACTIONS as TEAM_ACTIONS } from "./team/names";
import { ACTIONS as FLOWS_ACTIONS } from "./flows/names";

export default {
    [ACTIONS.CLEAR_USER_STATE]({ dispatch, commit }) {
        const commonPrefix = `affility/user/`;
        const root = { root: true };
        commit(MUTATIONS.CLEAR_LOCAL_USER_STATE);
        // dispatch(commonPrefix + 'auth/' + USER_AUTH_ACTIONS.CLEAR_AUTH_STATE);
        dispatch(commonPrefix + 'flows/' + FLOWS_ACTIONS.CLEAR_FLOWS_STATE, null, root);
        dispatch(commonPrefix + 'rank/' + USER_RANK_ACTIONS.CLEAR_RANK_STATE, null, root);
        dispatch(commonPrefix + 'binary/' + USER_BINARY_ACTIONS.CLEAR_BINARY_STATE, null, root);
        dispatch(commonPrefix + 'unilevel/' + USER_UNILEVEL_ACTIONS.CLEAR_UNILEVEL_STATE, null, root);
        dispatch(commonPrefix + 'notifications/' + NOTIFICATION_ACTIONS.CLEAR_NOTIFICATIONS_STATE, null, root);
        dispatch(commonPrefix + 'team/' + TEAM_ACTIONS.CLEAR_TEAM_STATE, null, root);
    },
};