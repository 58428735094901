import axios, { Axios } from "axios";
import { ThirdPartyApiServiceError } from "~/core/exceptions/ThirdPartyApiServiceError";

class ThirdPartyApiService {
    private static _instance: Axios;

    public static init() {
        ThirdPartyApiService._instance = axios.create();
        ThirdPartyApiService._instance.interceptors.response.use(
            response => response,
            error => {
                throw new ThirdPartyApiServiceError(error);
            }
        );
    }

    public static get(url: string, params?: any) {
        return ThirdPartyApiService._instance.get(url, params);
    }

    public static post(url: string, params?: any) {
        return ThirdPartyApiService._instance.post(url, params);
    }

    public static put(url: string, params?: any) {
        return ThirdPartyApiService._instance.put(url, params);
    }

    public static delete(url: string, params?: any) {
        return ThirdPartyApiService._instance.delete(url, params);
    }

    public static patch(url: string, params?: any) {
        return ThirdPartyApiService._instance.patch(url, params);
    }

    public static head(url: string, params?: any) {
        return ThirdPartyApiService._instance.head(url, params);
    }

    public static options(url: string, params?: any) {
        return ThirdPartyApiService._instance.options(url, params);
    }
}

ThirdPartyApiService.init();

export default ThirdPartyApiService;