/**
 * Color that is going to be used with the parcels.
 */
export class MetaColor {
    constructor(normalColor, hoveredColor, selectedColor, disabledColor) {
        this.NORMAL = normalColor;
        this.HOVERED = hoveredColor;
        this.SELECTED = selectedColor;
        this.DISABLED = disabledColor;
    }
}
