
import CustomLoader from '~/components/utils/CustomLoader.vue';

let turnstileCaptchaCount = 0;

export default {
    name: "CloudflareTurnstile",
    components: {
        CustomLoader,
    },
    props: {
        siteKey: {
            type: String,
            required: true,
        },
    },
    emits: ['load', 'error', 'verify', 'expired'],
    data() {
        return {
            isTurnstileLoaded: false,
            id: `cf-turnstile-${turnstileCaptchaCount++}`,
            widgetId: null,
        };
    },
    async mounted() {
        await this.$turnstileLoadPromise;
        this.isTurnstileLoaded = true;
        await this.$nextTick();
        if (!this.$refs.container) {
            return;
        }
        this.$emit('load');
        this.widgetId = this.$cfTurnstile.render('#' + this.id, {
            sitekey: this.siteKey,
            callback: (token) => {
                this.$emit('verify', token);
            },
            'expired-callback': () => {
                this.$emit('expired');
            },
        });
    },
    beforeUnmount() {
        this.$cfTurnstile.remove(this.widgetId);
    },
    methods: {
        isExpired() {
            return this.$cfTurnstile.isExpired(this.widgetId);
        },
        getResponse() {
            return this.$cfTurnstile.getResponse(this.widgetId);
        },
        reset() {
            this.$cfTurnstile.reset(this.widgetId);
        },
    },
};

