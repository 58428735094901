import { STATE } from "./names";
import type { TermsAndConditionVersions } from "~/core/constants/affility/store/user/flows/terms-and-conditions/TermsAndConditionVersions";
import type { TermsAndConditionsSpecificPopupOpenParams } from "./types";

export default () => {
    return {
        [STATE.DEFAULT_ACCEPT_HANDLER_LOADING]: false,
        [STATE.DEFAULT_ACCEPT_HANDLER_ERROR]: null,
        [STATE.TERMS_AND_CONDITIONS_PROMISES]: [] as Promise<boolean>[],
        [STATE.TERMS_AND_CONDITIONS_RESOLVER]: null,
        [STATE.TERMS_AND_CONDITIONS_REJECTER]: null,
        [STATE.IS_POPUP_ACCEPTABLE]: true,
        [STATE.IS_POPUP_STANDALONE]: false,
        [STATE.IS_POPUP_CLOSABLE]: true,
        [STATE.IS_TERMS_AND_CONDITIONS_POPUP_OPEN]: false,
        [STATE.TERMS_AND_CONDITIONS_TYPE]: null as TermsAndConditionVersions,
        [STATE.TERMS_AND_CONDITIONS_POPUP_CONTENT_PROPS]: null as TermsAndConditionsSpecificPopupOpenParams['contentProps'],
    };
};