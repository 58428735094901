import { DEFAULT_LOGO } from "~/core/services/utils/ImageConstants";

export const METAVERSE_IDS = {
    GYMSTREET: 1,
    KABUTOCHO: 2,
    // CASHFT: 3,
};

export const METAVERSE_ABI_FOLDERS = {
    [METAVERSE_IDS.GYMSTREET]: 'gymstreet',
    [METAVERSE_IDS.KABUTOCHO]: 'kabutocho',
    // [METAVERSE_IDS.CASHFT]: 'cashft',
};

export const METAVERSES = {
    [METAVERSE_IDS.GYMSTREET]: {
        id: METAVERSE_IDS.GYMSTREET,
        metaverse_name: 'GymStreet',
        metaverse_name_short: 'GS',
        map_name: 'Manhattan',
        files: [
            'https://mapdev.gymstreet.io/manhattan/parcels_1.json'
        ],
        image: 'manhattan.jpg',
        reserved_area_image: 'https://images.unsplash.com/photo-1568515387631-8b650bbcdb90?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=2070&q=80',
        standard_parcels_count: 210000,
        tokenShift: 0n,
        initial_point: {
            x: 0,
            y: 1350,
        },
        logo: DEFAULT_LOGO,
    },
    [METAVERSE_IDS.KABUTOCHO]: {
        id: METAVERSE_IDS.KABUTOCHO,
        metaverse_name: 'Kabutocho',
        metaverse_name_short: 'KB',
        map_name: 'Kabutocho',
        files: [
            'https://mapdev.gymstreet.io/kabutocho/parcels_1.json'
        ],
        image: 'kabutocho.jpg',
        reserved_area_image: 'https://www.japan-guide.com/g18/3064_01a.jpg',
        standard_parcels_count: 320000,
        tokenShift: 8n,
        initial_point: {
            x: 435,
            y: 457,
        },
        logo: require('~/assets/images/logos/kabutocho/kabutocho.svg'),
    },
    [METAVERSE_IDS.CASHFT]: {
        id: METAVERSE_IDS.CASHFT,
        metaverse_name: 'CashFT',
        map_name: 'Silicon Valley',
        files: [
            'https://mapdev.gymstreet.io/syliconvalley/parcels_1.json'
        ],
        image: 'syliconvalley.jpg',
        reserved_area_image: 'https://www.japan-guide.com/g18/3064_01a.jpg',
        standard_parcels_count: 210000,
        coming_soon: true,
        logo: require('~/assets/images/logos/cashft/cashft.svg'),
    },
}
