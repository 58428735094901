
import {defineComponent} from 'vue'
import TokenAsset from '~/core/models/purchase-popup-2/TokenAsset';
import {toCurrencyFormat} from '~/core/helpers/GlobalHelpers'
import {CryptoCurrency} from '~/core/types/purchase-popup-2/CryptoCurrency';
import {Blockchains} from '~/core/constants/flows/purchase/Blockchains';
import GymNewButton from '~/components/utils/form-components/GymNewButton.vue';
import CustomBoxLoader from '~/components/utils/CustomBoxLoader.vue'
import {PaymentService} from '~/core/services/PaymentService'
import {MetaWorldManager} from '~/core/services/map/MetaWorldManager'
import WalletService from '~/pages/finances/wallet/services/WalletService';
import {MetaUser} from '~/core/models/MetaUser';
import MunicipalityTx from '~/core/models/purchase-popup-2/MunicipalityTx';
import Web3MunicipalityTx from '~/core/models/purchase-popup-2/Web3MunicipalityTx';
import {BuyCryptoRequest} from '~/core/models/purchase-popup-2/BuyCryptoRequest';
import {ExternalWalletPayment} from '~/core/models/purchase-popup-2/ExternalWalletPayment';
import InfoTooltip from '~/components/utils/InfoTooltip.vue';

const WEB2_USER_FEE_FOR_SELF = 0.55;

export default defineComponent({
    name: 'ExternalWalletCurrencySelection',
    components: {
        CustomBoxLoader,
        GymNewButton,
        InfoTooltip,
    },
    props: {
        user: {
            type: MetaUser,
            required: true,
        },
        transactionObject: {
            type: [MunicipalityTx, Web3MunicipalityTx],
            required: true
        },
    },
    emits: [
        'onExternalPaymentCreated',
        'onStartLoading',
        'onStopLoading',
        'onClickBack'
    ],
    data() {
        return {
            availableChains: Blockchains,
            selectedBlockchain: null,
            selectedCurrency: null,
            isCurrencyValueLoading: false,
            isError: false,
            selectedCurrencyAsset: null,
            convertedPriceAsset: null,
            toCurrencyFormat,
        }
    },
    computed: {
        isOwnerCurrentUser() {
            const userWallet = this.user.walletAddress ?? this.user.relatedWalletAddress;
            return this.transactionObject.owner === userWallet;
        },
        availableCurrencies() {
            return this.selectedBlockchain
                ?.cryptocurrencies;
        },
        priceAsset() {
            const totalAmount = this.transactionObject.priceInfo.totalAmount;
            let currentPrice = totalAmount.value;
            if (this.user.isFullyWeb2User && this.isOwnerCurrentUser) {
                currentPrice += WEB2_USER_FEE_FOR_SELF;
            }
            return totalAmount.copy(currentPrice);
        }
    },
    watch: {
        selectedCurrency(newVal) {
            this.getTokenPriceUsd(newVal);
        }
    },
    created() {
        this.selectedBlockchain = this.availableChains[0];
        this.selectedCurrency = this.availableCurrencies[0];
        this.getTokenPriceUsd(this.selectedBlockchain.cryptocurrencies[0])
    },
    methods: {
        async handleContinue() {
            this.$emit('onStartLoading');
            try {
                const buyData: BuyCryptoRequest = {
                    bundle_sc_id: this.transactionObject.bundleType,
                    product_type: this.transactionObject.productType,
                    quantity: this.transactionObject.quantity,
                    currency: this.selectedCurrency?.code,
                };
                let rawExternalPayment = null;
                if (this.isOwnerCurrentUser) {
                    rawExternalPayment = await PaymentService.buyCrypto(buyData)
                } else {
                    rawExternalPayment = await PaymentService.buyCryptoForWallet({
                        ...buyData,
                        wallet_address: this.transactionObject.owner,
                    });
                }
                const externalPayment = new ExternalWalletPayment(
                    rawExternalPayment,
                    this.selectedCurrencyAsset,
                    this.selectedCurrency,
                );
                this.$emit('onExternalPaymentCreated', externalPayment);
                this.$emit('onStopLoading');
            } catch (e) {
                this.$emit('onStopLoading', e);
            }
        },
        handleSelectBlockchain() {
            this.selectedCurrency = this.availableCurrencies[0];
        },
        async getTokenPriceUsd(selectedCryptocurrency: CryptoCurrency) {
            this.isError = false;
            this.isCurrencyValueLoading = true;
            try {
                const selectedTokenPrice = await PaymentService.getTokenPriceUsd(selectedCryptocurrency.code, true);
                if (this.selectedCurrency.code === selectedCryptocurrency.code) {
                    this.selectedCurrencyAsset = new TokenAsset(
                        null, // TODO: fix it
                        selectedCryptocurrency.name,
                        selectedCryptocurrency.code,
                        1,
                        selectedTokenPrice,
                        false,
                        MetaWorldManager.sharedInstance(),
                        this.selectedBlockchain.label,
                    );
                    this.convertedPriceAsset = WalletService.convert(this.priceAsset, this.selectedCurrencyAsset);
                }
            } catch {
                this.isError = true;
            } finally {
                if (this.selectedCurrency.code === selectedCryptocurrency.code || this.isError) {
                    this.isCurrencyValueLoading = false;
                }
            }
        },
    }
})

