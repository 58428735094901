import {AxiosResponse} from 'axios';
import {AUTH_FLOW_ERRORS, diagLog, ERROR_STATUSES} from '../utils/Constants';
import APIServiceError from '../../helpers/APIServiceError';
import ApiService from './ApiService';
import {GlobalMakerService} from "~/core/services/GlobalMakerService";
import InvalidWalletAddressError from "~/core/helpers/InvalidWalletAddressError";

declare global {
    interface Window {
        returnAuthCode?: (codeAndState: CodeAndState) => void;
        returnOAuthError?: (error: Error) => void;
    }
}

interface SignUpData {
    email: string;
    password: string;
}

interface CodeAndState {
    code: string;
    state: string;
}

export class OAuthError extends Error {
    constructor (message: string, public code: number) {
        super(message);
    }
}

export class AuthError extends Error {
    constructor (
        message: string,
        public code: number,
        public failedAttempts?: number,
        public restrictedUntil?: number,
    ) {

        super(message);
    }
}


export default class AuthService {
    public static async signUpWithEmail (data: SignUpData) {
        try {
            const response: AxiosResponse = await ApiService.post('/auth/register-user-email', data);
            if(response?.data) {
                return response.data;
            } else {
                throw new AuthError('Something went wrong, please refresh the page and try again',
                    AUTH_FLOW_ERRORS.SOMETHING_WENT_WRONG);
            }
        } catch(e: unknown) {
            if ((e as APIServiceError).code === ERROR_STATUSES.INVALID_EMAIL_ADDRESS) {
                throw new AuthError('Provided email is already in use.',
                    AUTH_FLOW_ERRORS.EMAIL_ALREADY_IN_USE);
            }
            if ((e as APIServiceError).code === ERROR_STATUSES.EXISTING_USERNAME) {
                throw new AuthError('Provided username is already taken.',
                    AUTH_FLOW_ERRORS.EMAIL_ALREADY_IN_USE);
            }
            throw new AuthError('Something went wrong, please refresh the page and try again',
                AUTH_FLOW_ERRORS.SOMETHING_WENT_WRONG);
        }
    }

    public static async changeEmail (email: string) {
        try {
            await ApiService.post('/user/email-change', { new_email: email });
            return true;
        } catch(e: unknown) {
            if((e as APIServiceError).code === ERROR_STATUSES.EXISTING_EMAIL) {
                throw new AuthError('Provided email is already in use.',
                    AUTH_FLOW_ERRORS.EMAIL_ALREADY_IN_USE);
            }
            throw new AuthError('Something went wrong, please refresh the page and try again',
                AUTH_FLOW_ERRORS.SOMETHING_WENT_WRONG);
        }
    }

    public static async verifyEmail (verification_code: string) {
        try {
            const response = await ApiService.post('/user/confirm', { verification_code });
            if(response?.data?.user && response?.data?.token) {
                return response.data;
            }
            throw new Error('Invalid response from server');
        } catch(e: unknown) {
            if((e as APIServiceError).code === ERROR_STATUSES.INVALID_VERIFICATION_CODE) {
                throw new AuthError('Invalid or expired verification code',
                    AUTH_FLOW_ERRORS.INVALID_VERIFICATION_CODE);
            }
            throw new AuthError('Something went wrong, please refresh the page and try again',
                AUTH_FLOW_ERRORS.SOMETHING_WENT_WRONG);
        }
    }

    public static async recoverPassword (email: string) {
        try {
            await ApiService.post('/auth/forgot-password', { email });
            return true;
        } catch (e: unknown) {
            throw new AuthError('Something went wrong, please refresh the page and try again',
                AUTH_FLOW_ERRORS.SOMETHING_WENT_WRONG);
        }
    }

    public static async resetPassword (password: string, token: string) {
        try {
            await ApiService.post('/auth/reset-password', { password, token });
            return true;
        } catch(e: unknown) {
            throw new AuthError('Something went wrong, please refresh the page and try again',
                AUTH_FLOW_ERRORS.SOMETHING_WENT_WRONG);
        }
    }

    public static async isPasswordResetTokenValid (token: string) {
        try {
            await ApiService.post('/auth/reset-password', { token });
            return true;
        } catch(e: unknown) {
            if ((e as APIServiceError).code === ERROR_STATUSES.INVALID_ARGS ||
                (e as APIServiceError).code === ERROR_STATUSES.INVALID_TOKEN) {
                return false;
            }
            throw new AuthError('Something went wrong, please refresh the page and try again',
                AUTH_FLOW_ERRORS.SOMETHING_WENT_WRONG);
        }
    }

    public static async resendVerificationEmail (email: string) {
        try {
            await ApiService.post('/user/email-resend', {});
            return true;
        } catch(e: unknown) {
            if((e as APIServiceError).code === ERROR_STATUSES.EXCEPTION) {
                throw new AuthError('You have already verified your email address',
                    AUTH_FLOW_ERRORS.EMAIL_ALREADY_VERIFIED);
            }
            throw new AuthError('Something went wrong, please refresh the page and try again',
                AUTH_FLOW_ERRORS.SOMETHING_WENT_WRONG);
        }
    }


    public static async openOAuthWindow (url: string, windowFeatures = "width=500,height=600,popup=true"): Promise<CodeAndState> { // TODO remove this if it is not needed
        if(!windowFeatures.includes("noopener")) {
            const oAuthWindow: Window = window.open(url, "_blank", windowFeatures)!;

            let fulfilled = false;

            const removePromiseCallbacksAndFullfill = () => {
                delete window.returnAuthCode;
                delete window.returnOAuthError;
                fulfilled = true;
            };

            const resultPromise: Promise<CodeAndState> = new Promise<CodeAndState>((resolve, reject) => {
                window.returnAuthCode = resolve;
                window.returnOAuthError = reject;
            }).then((codeAndState: CodeAndState) => {
                removePromiseCallbacksAndFullfill();
                return codeAndState;
            }).catch((error) => {
                removePromiseCallbacksAndFullfill();
                return Promise.reject(error);
            });

            const windowAliveInterval = setInterval(() => {
                if (oAuthWindow.closed) {
                    clearInterval(windowAliveInterval);
                    const error = new OAuthError("User closed the window", ERROR_STATUSES.USER_CLOSED_OAUTH_WINDOW);
                    if(!fulfilled) {
                        window.returnOAuthError!(error);
                    }
                }
            }, 500);

            return resultPromise;
        }
        throw new Error("OAuth window sends code to the parent window, so it must be opened without 'noopener' feature");
    }

    public static async getTokensForOAuth (code: string,
        provider: string,
        referral_id: string | undefined,
        attach = false,
        url = '/auth/oauth',
        attachUrl = '/user/account/attach'): Promise<AxiosResponse> {
        try {
            return await ApiService.post(attach ? attachUrl : url, { code, provider, referral_id});
        } catch(e: unknown) {
            if(!attach) {
                if((e as APIServiceError).code === ERROR_STATUSES.EXISTING_EMAIL) {
                    throw new AuthError('This social account is used by another user. Verify your email first or contact Customer Support',
                        AUTH_FLOW_ERRORS.EMAIL_ALREADY_IN_USE);
                } else if((e as APIServiceError).code === ERROR_STATUSES.INVALID_EMAIL_ADDRESS) {
                    throw new AuthError('Email associated with this social account is already associated with an account in GymStreet. Please login with it or contact Customer Support.',
                        AUTH_FLOW_ERRORS.EMAIL_NOT_VERIFIED);
                }
            } else if (attach && (e as APIServiceError).code === ERROR_STATUSES.INVALID_USER_UPDATE) {
                throw new AuthError('This wallet address is already associated with another user account. Please use another one!',
                    AUTH_FLOW_ERRORS.EMAIL_ALREADY_IN_USE);
            }
            throw new AuthError('Something went wrong, please refresh the page and try again',
                AUTH_FLOW_ERRORS.SOMETHING_WENT_WRONG);
        }
    }

    public static async signUMUser (signature: string, token: string, address: string) {
        diagLog(this, 'AuthService signUMUser', {signature, token, address});
        try {
            return await ApiService.post('um/sign', {sig: signature, token, address});
        } catch (e) {
            if(e instanceof APIServiceError) {
                if(e.code === ERROR_STATUSES.EXISTING_WALLET) {
                    throw new InvalidWalletAddressError("This wallet address is already associated with another user account. Please use another one!");
                } else if (e.code === ERROR_STATUSES.INVALID_TOKEN) {
                    throw new AuthError('Your session has expired. Please refresh the page and try again', AUTH_FLOW_ERRORS.TOKEN_EXPIRED);
                }
            }
            throw new AuthError('Something went wrong, please refresh the page and try again', AUTH_FLOW_ERRORS.SOMETHING_WENT_WRONG);
        }
    }

    public static async completeRegisterUMUser () {
        try {
            return await ApiService.post('/um/complete', {});
        } catch(e) {
            if(e instanceof APIServiceError) {
                if(e.code === ERROR_STATUSES.INVALID_USER_UPDATE) {
                    throw new AuthError('You already connected another wallet address, it is currently being updated.', AUTH_FLOW_ERRORS.WALLET_PROCESSING);
                }
            }
            throw new AuthError('Something went wrong, please refresh the page and try again', AUTH_FLOW_ERRORS.SOMETHING_WENT_WRONG);
        }
    }

    public static async getAuthTokenFromCashFTToken (cashFtEasyLoginToken: string): Promise<AxiosResponse> {
        try {
            return await ApiService.post('/auth/cash-ft', {
                easy_login_token: cashFtEasyLoginToken,
            });
        } catch(e: unknown) {
            if((e as APIServiceError).code === ERROR_STATUSES.AUTHORIZATION_ERROR) {
                throw new AuthError('User not found',
                    AUTH_FLOW_ERRORS.INVALID_VERIFICATION_CODE);
            }
            throw new AuthError('Something went wrong, please refresh the page and try again',
                AUTH_FLOW_ERRORS.SOMETHING_WENT_WRONG);
        }
    }

    public static async getCashFTEasyLoginToken (): Promise<string> {
        try {
            const response = await ApiService.get(`/user/cash-ft-easy-login-token`);
            const token = response?.data?.cash_ft_easy_login_token;
            if (!token) {
                throw new Error('Invalid response');
            }
            return token;
        } catch(e: unknown) {
            if((e as APIServiceError).code === ERROR_STATUSES.PERMISSION_DENIED) {
                throw new AuthError('Please verify your email address to proceed to CashFT',
                    AUTH_FLOW_ERRORS.EMAIL_NOT_VERIFIED);

            }
            throw new AuthError('Something went wrong, please refresh the page and try again',
                AUTH_FLOW_ERRORS.SOMETHING_WENT_WRONG);
        }
    }

    public static async getAddressByEmail (email:string) {
        try {
            const escapedEmail = encodeURIComponent(email);
            const { data } = await ApiService.get(`/user/get-by?email=${escapedEmail}`);
            return data.wallet_address ?? data.related_wallet_address;
        } catch (e) {
            if ((e as APIServiceError).code === ERROR_STATUSES.DATA_NOT_FOUND) {
                throw new AuthError('Email does not exist',
                    AUTH_FLOW_ERRORS.EMAIL_DOESNT_EXIST);
            }
            throw new AuthError('Something went wrong, please refresh the page and try again',
                AUTH_FLOW_ERRORS.SOMETHING_WENT_WRONG);
        }
    }

    public static async getEmailByAddress (address:string) {
        try {
            const { data } = await ApiService.query(`/user/get-by`, {
                params: {
                    wallet_address: address,
                },
            });
            return data.email;
        } catch (e) {
            if((e as APIServiceError).code === ERROR_STATUSES.DATA_NOT_FOUND) {
                throw new AuthError('Wallet does not exist',
                    AUTH_FLOW_ERRORS.EMAIL_DOESNT_EXIST);
            }
            throw new AuthError('Something went wrong, please refresh the page and try again',
                AUTH_FLOW_ERRORS.SOMETHING_WENT_WRONG);
        }
    }
}
