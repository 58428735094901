import { GlobalMakerService } from "~/core/services/GlobalMakerService";
import { MetaWorldManager } from "~/core/services/map/MetaWorldManager";

export default (ctx, inject) => {
    let metaverseId = Number(localStorage.getItem('metaverse_id')) || 1;

    const setToStore = (id) => {
        if(GlobalMakerService.$store || ctx.store) {
            const store = GlobalMakerService.$store || ctx.store;
            store.commit('map/mapModule/SET_METAVERSE_ID', id);
        }
    };

    const getMetaverseId = () => {
        return metaverseId;
    };
    const setMetaverseId = (id) => {
        metaverseId = id;
        localStorage.setItem('metaverse_id', id);
        MetaWorldManager.sharedInstance().setMetaverseId(id);
        GlobalMakerService.setMetaverseId(id);
        setToStore(id);
        return true;
    };

    ctx.metaverseId = getMetaverseId;
    ctx.setMetaverseId = setMetaverseId;

    inject("metaverseId", getMetaverseId);
    inject("setMetaverseId", setMetaverseId);

    setMetaverseId(metaverseId);
};