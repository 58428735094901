export const STATE = {
    IS_UPGRADING_TO_AFFILIATE: 'isUpgradingToAffiliate',
} as const;

export const ACTIONS = {
    CLEAR_UPGRADING_TO_AFFILIATE_STATE: 'clearUpgradingToAffiliateState',
    START_UPGRADE_TO_AFFILIATE: 'startUpgradeToAffiliate',
    CANCEL_UPGRADE_TO_AFFILIATE: 'cancelUpgradeToAffiliate',
    UPGRADE_TO_AFFILIATE: 'upgradeToAffiliate',
    UPGRADE_TO_AFFILIATE_AND_UPDATE_USER: 'upgradeToAffiliateAndUpdateUser',
    UPGRADE_TO_AFFILIATE_UPDATE_USER_AND_SHOW_NOTIFICATION: 'upgradeToAffiliateAndUpdateUserAndShowNotification',
    CLOSE_AND_SHOW_UPGRADE_TO_AFFILIATE_NOTIFICATION: 'closeAndShowUpgradeToAffiliateNotification',
    SHOW_UPGRADE_TO_AFFILIATE_NOTIFICATION: 'showUpgradeToAffiliateNotification',
} as const;

export const MUTATIONS = {
    SET_IS_UPGRADING_TO_AFFILIATE: 'SET_IS_UPGRADING_TO_AFFILIATE',
} as const;

export const GETTERS = {
    IS_UPGRADING_TO_AFFILIATE: 'isUpgradingToAffiliate',
} as const;