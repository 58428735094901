import { STATE } from "./names";
import { POLLING_STORE_FUNCTIONS } from "./polling";

export default () => {
    return {
        [STATE.RANK_DATA_LOADING]: false,
        [STATE.RANK_DATA_LOADING_ERROR]: null as string | null,
        [STATE.RANK_DATA_LOADING_PROMISE]: null as Promise<void> | null,
        ...POLLING_STORE_FUNCTIONS.state,
    };
};