import { getExactMonth } from "./getExactMonth";

export function generateYearlyEmptyChartData(months: number = 11) {
    const result = new Array(months);
    const date = getExactMonth();
    for(let i = months; i >= 0; i--) {
        date.setMonth(date.getMonth() - 1);
        const dateString = date.toISOString();
        result[i] = {
            date: dateString,
            usdt: 0,
            gymnet: 0,
            utility: 0,
            y: 0,
            x: dateString,
        };
    }
    return result;
}
