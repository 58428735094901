import { OrderCurrency } from "../constants/OrderCurrency";
import { OrderPaymentType } from "../constants/OrderPaymentMethod";
import { RawOrderHistoryEntry } from "../types/RawOrderHistoryEntry";
import { BUNDLE_NAME_AUTOSHIP, BUNDLE_NAMES, BUNDLE_NAMES_CARDS, BUNDLE_NAMES_OTHERS } from "~/core/services/utils/Constants";

export class OrderHistoryEntry {
    public currencyAmountMap: Partial<Record<OrderCurrency, number>>;
    public bonusAmountMap: Partial<Record<OrderCurrency, number>>;
    public showCurrencies:Partial<Record<OrderCurrency, boolean>>;

    constructor (private rawData: RawOrderHistoryEntry) {
        this.currencyAmountMap = this.rawData.currencies?.reduce((acc, currency) => {
            acc[currency.currency] = currency.amount;
            return acc;
        }, {} as Partial<Record<OrderCurrency, number>>);
        this.bonusAmountMap = this.rawData.bonus?.reduce((acc, bonus) => {
            acc[bonus.currency] = bonus.amount;
            return acc;
        }, {} as Partial<Record<OrderCurrency, number>>);
        this.showCurrencies  = this.rawData.currencies?.reduce((acc,currency)=>{
            acc[currency.currency]  = true;
            return acc;
        },{} as Partial<Record<OrderCurrency, boolean>>);
    }

    public get txDate (): Date {
        return new Date(this.rawData.tx_date);
    }

    public get product () {
        const nameMapsToCheck = [
            BUNDLE_NAMES,
            BUNDLE_NAMES_CARDS,
            BUNDLE_NAMES_OTHERS,
            BUNDLE_NAME_AUTOSHIP,
        ] as Record<string | number, string>[];

        const productId = this.rawData.product_id;
        const quantity = this.rawData?.quantity;
        const shouldShowQuantity = Object.keys(BUNDLE_NAMES_OTHERS).map(p => parseFloat(p)).includes(productId);

        return nameMapsToCheck.map(nameMap => nameMap[productId]).filter(name =>
            !!name)[0] + ( shouldShowQuantity && quantity && quantity > 1 ? " " +  `(${quantity})` : '');
    }

    public get status () {
        return this.rawData.status;
    }

    public get purchasedBy () {
        if(this.rawData.buyer.toLowerCase() === this.rawData.recipient_wallet_address.toLowerCase() ){

            return  this.rawData.buyer_username || this.rawData.recipient_username  || this.rawData.buyer_email || this.rawData.recipient_email || this.rawData.buyer  || this.rawData.recipient_wallet_address;
        }
        return  this.rawData.buyer_username  || this.rawData.buyer_email || this.rawData.buyer;

    }

    public get isAdmin() {
        return this.rawData.buyer.toLowerCase() === 'admin';
    }

    public get purchasedFor () {
        return this.rawData.recipient_username ||  this.rawData.recipient_email || this.rawData.recipient_wallet_address;
    }

    public get txId () {
        return this.rawData.tx_id;
    }

    public get paymentMethod () {
        return  OrderPaymentType[this.rawData.payment_method];
    }
}
