export const STATE = {
    FULL_PAGE_LOADING_VISIBLE: 'fullPageLoadingVisible',
    FULL_PAGE_LOADING_TEXT: 'fullPageLoadingText',
} as const;

export const ACTIONS = {
    OPEN_FULL_PAGE_LOADING: 'openFullPageLoading',
    CLOSE_FULL_PAGE_LOADING: 'closeFullPageLoading',
} as const;

export const MUTATIONS = {
    SET_FULL_PAGE_LOADING: 'setFullPageLoading',
} as const;

export const GETTERS = {
    FULL_PAGE_LOADING_VISIBLE: 'fullPageLoadingVisible',
    FULL_PAGE_LOADING_TEXT: 'fullPageLoadingText',
} as const;
