export const GOOGLE_ANALYTICS_EVENT_NAMES = {
    BUY_SUPER_BUNDLE: 'buy_super_bundle',
    BUY_STARTER_BUNDLE: 'buy_starter_bundle',
    BUY_BUNDLE: 'buy_bundle',
    BUY_PARCEL: 'buy_parcel',
    BUY_MINER: 'buy_miner',
    OPEN_MYSTERY_BOX_OPTIONS: 'open_mystery_box_options',
    BUY_MYSTERY_BOX: 'buy_mystery_box',
    MYSTERY_BOX_PURCHASE_SUCCESS: 'mystery_box_purchase_success',
    TRY_ANOTHER_MYSTERY_BOX: 'try_another_mystery_box',
    MYSTERY_BOX_RESULT_CHECKING_PROBLEM: 'mystery_box_result_checking_problem',
    ACCEPT_GLOBAL_TOS: 'registration_comfirmed',
    PURCHASE: 'purchase',
    PAGE_VIEW: 'page_view',
    REGISTRATION: 'registration',
}

export const GOOGLE_ANALYTICS_EVENT_TYPES = {
    CLICK: 'click',
    SUCCESS: 'success',
    CANCEL: 'cancel',
    FAILED: 'failed',
    USER_CANCEL: 'user_cancel',
    SUCCESS_TRANSACTION: 'success_transaction',
    FAIL_TRANSACTION: 'fail_transaction',
    NOT_LOGGED_IN: 'not_logged_in',
}

export const FEATURE_PRIORITIZATION_GTAG_NAMES = {
    START_PRIORITIZING: 'start',
    CONTINUE_PRIORITIZING: 'continue',
    REPRIORITIZE: 'reprioritize',
    RULES: 'rules',
    STATISTICS: 'statistics',
    KEEP_PRIORITIZING: 'keep_prioritizing',
    REVIEW_AND_SUBMIT: 'review_and_submit',
}

export const FEATURE_PRIORITIZATION_GTAG_TYPES = {
    CLICK: 'click',
    SUBMIT: "submit",
    MODAL_VIEW: "modal_view",
}

export function createEventName(name, ...types) {
    const eventTypes = types.join('_');
    return `${eventTypes}_${name}`;
}
