import { STATE } from "./names";
import type { BalanceInfo } from "~/pages/finances/commissions/types/BalanceInfo";
import { POLLING_STORE_FUNCTIONS } from "./polling";

export default () => ({
    ...POLLING_STORE_FUNCTIONS.state,
    [STATE.BINARY_WEEKLY_CAP]: null as number | null,
    [STATE.BINARY_CURRENT_PROGRESS]: null as number | null,
    [STATE.TOTAL_BINARY_COMMISSION]: null as number | null,
    [STATE.CLAIMED_COMMISSIONS]: null as BalanceInfo | null,
    [STATE.AVAILABLE_COMMISSIONS]: null as BalanceInfo | null,
    [STATE.COLLECTED_BINARY_BONUS]: null as BalanceInfo | null,
    [STATE.COLLECTED_MATCHING_BONUS]: null as BalanceInfo | null,
    [STATE.COMMISSIONS_INFO_DATA_LOADING]: false,
    [STATE.COMMISSIONS_INFO_DATA_LOADING_ERROR]: null as Error | null,
    [STATE.COMMISSIONS_INFO_DATA_LOADING_PROMISE]: null as Promise<any> | null,
});