import { STATE, GETTERS } from "./names";
import { POLLING_STORE_FUNCTIONS } from "./polling";
import type { State } from "./types";

export default {
    [GETTERS.UNILEVEL_RANKS_BY_RIGHT_LEG_SATISFIED]: (state: State) => state[STATE.UNILEVEL_RANKS_BY_RIGHT_LEG_SATISFIED],
    [GETTERS.UNILEVEL_RANKS_BY_LEFT_LEG_SATISFIED]: (state: State) => state[STATE.UNILEVEL_RANKS_BY_LEFT_LEG_SATISFIED],
    [GETTERS.LEFT_LEG_MIN_PURCHASE_SATISFIED]: (state: State) => state[STATE.LEFT_LEG_MIN_PURCHASE_SATISFIED],
    [GETTERS.RIGHT_LEG_MIN_PURCHASE_SATISFIED]: (state: State) => state[STATE.RIGHT_LEG_MIN_PURCHASE_SATISFIED],
    [GETTERS.LEFT_LEG_PARTNERS_COUNT]: (state: State) => state[STATE.LEFT_LEG_PARTNERS_COUNT],
    [GETTERS.RIGHT_LEG_PARTNERS_COUNT]: (state: State) => state[STATE.RIGHT_LEG_PARTNERS_COUNT],
    [GETTERS.LEFT_LEG_TURNOVER]: (state: State) => state[STATE.LEFT_LEG_TURNOVER],
    [GETTERS.RIGHT_LEG_TURNOVER]: (state: State) => state[STATE.RIGHT_LEG_TURNOVER],
    [GETTERS.TOTAL_OWN_PURCHASE]: (state: State) => state[STATE.TOTAL_OWN_PURCHASE],
    [GETTERS.BINARY_PROGRESS_LOADING]: (state: State) => state[STATE.BINARY_PROGRESS_LOADING],
    [GETTERS.BINARY_PROGRESS_LOADING_ERROR]: (state: State) => state[STATE.BINARY_PROGRESS_LOADING_ERROR],
    [GETTERS.BINARY_PROGRESS_LOADING_PROMISE]: (state: State) => state[STATE.BINARY_PROGRESS_LOADING_PROMISE],
    [GETTERS.CURRENT_TURNOVER_IN_WEAK_LEG]: (state: State) => Math.min(state[STATE.RIGHT_LEG_TURNOVER], state[STATE.LEFT_LEG_TURNOVER]),
    ...POLLING_STORE_FUNCTIONS.getters,
};