import { GETTERS, STATE } from "./names";
import type { State } from "./types";

export default {
    [GETTERS.NOTIFICATION_HISTORY]: (state: State) => state[STATE.NOTIFICATION_HISTORY],
    [GETTERS.TOTAL_NOTIFICATIONS_COUNT]: (state: State) => state[STATE.TOTAL_NOTIFICATIONS_COUNT],
    [GETTERS.NOTIFICATIONS_HISTORY_LOAD_COUNT]: (state: State) => state[STATE.NOTIFICATIONS_HISTORY_LOAD_COUNT],
    [GETTERS.NOTIFICATIONS_HISTORY_LOADING]: (state: State) => state[STATE.NOTIFICATIONS_HISTORY_LOADING],
    [GETTERS.NOTIFICATIONS_HISTORY_LOADING_PROMISE]: (state: State) => state[STATE.NOTIFICATIONS_HISTORY_LOADING_PROMISE],
    [GETTERS.NOTIFICATIONS_HISTORY_LOADING_ERROR]: (state: State) => state[STATE.NOTIFICATIONS_HISTORY_LOADING_ERROR],
};