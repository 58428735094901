
import {defineComponent} from 'vue';
import PurchaseCore from '~/core/components/flows/purchase/PurchaseCore.vue';
import {MetaUser} from '~/core/models/MetaUser';
import {ExternalWalletPayment} from '~/core/models/purchase-popup-2/ExternalWalletPayment';
import {FinishReasonEnum} from '~/core/types/purchase-popup-2/FinishReasonEnum';
import {Purchase} from '~/core/types/purchase-popup-2/Purchase';
import {PURCHASE_STEPS} from '~/core/types/purchase-popup-2/PurchaseSteps';

export type PurchasePopupComponent = {
    popupVisible: boolean,
    user: MetaUser,
    handleClosePurchasePopup: () => void;
}

export default defineComponent<keyof PurchasePopupComponent, PurchasePopupComponent>({
    name: "PurchasePopup",
    components: {
        PurchaseCore,
    },
    props: {
        popupVisible: {
            type: Boolean,
            default: false,
        },
        user: {
            type: MetaUser,
            required: true,
        },
        purchase: {
            type: Object as () => Purchase,
            required: true,
        },
        externalPayment: {
            type: ExternalWalletPayment,
            default: null,
        },
    },
    emits: ['onPopupClose'],
    data() {
        return {
            previousStep: -1,
            currentStep: PURCHASE_STEPS.SELECT_PRODUCT_OWNER,
            finishReason: FinishReasonEnum.CLOSED_BY_USER,
        };
    },
    methods: {
        handleStepChanged(step: number) {
            this.previousStep = this.currentStep;
            this.currentStep = step;
        },
        handleClosePurchasePopup() {
            const finishReason = this.finishReason;
            let step = this.currentStep;
            if (this.currentStep === PURCHASE_STEPS.TRANSACTION_REJECTED) {
                step = this.previousStep;
            }

            this.$store.dispatch('application/purchase-control/hide', {
                finishReason,
                step,
            });

            this.$emit('onPopupClose');
        },
        updateFinishReason(reason: FinishReasonEnum) {
            this.finishReason = reason;
        },
    },
});
