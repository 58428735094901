import { ACTIONS, MUTATIONS } from "./names";

export default {
    [ACTIONS.OPEN_FULL_PAGE_LOADING]({ commit }, text = '') {
        commit(MUTATIONS.SET_FULL_PAGE_LOADING, { visible: true, text });
    },
    [ACTIONS.CLOSE_FULL_PAGE_LOADING]({ commit }) {
        commit(MUTATIONS.SET_FULL_PAGE_LOADING, { visible: false, text: '' });
    }
};
