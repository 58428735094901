export const MetaMaskInstallationStepsDesktop = [
    {
        step: 1,
        title: 'Install MetaMask',
        text: "First, let's set up your cryptocurrency wallet.",
        text2: 'Follow the next steps to continue the setup.',
        img: 'metaMaskInstallationLogo.svg',
        linkText: 'Install MetaMask here.',
        linkMethod: 'handleDownloadMetamask'
    },
    {
        step: 2,
        title: 'Get Started',
        text: "Once installed, you should see the below splash screen. Click the 'Get Started' button to begin creating your Ethereum wallet using MetaMask.",
        img: 'metaMaskInstallationStep_2.png',
    },
    {
        step: 3,
        title: 'Confirm on this screen',
        text: "You will then be asked if you want to help improve MetaMask. Click 'No, Thanks' if this doesn't interest you, otherwise click 'I agree'.",
        img: 'metaMaskInstallationStep_4.png',
    },
    {
        step: 4,
        title: 'Create a Wallet',
        text: "On the next step, tap on 'Create a wallet'.",
        img: 'metaMaskInstallationStep_3.png',
    },
    {
        step: 5,
        title: 'Create Password',
        text: 'Pick a password on the next step. This needs to be at least 8 symbols long.',
        img: 'metaMaskInstallationStep_5.png',
        infoText: "We recommend using a completely unique password that hasn't been used anywhere else, and contains a mixture of upper and lower case letters, symbols and numbers."
    },
    {
        step: 6,
        title: 'MetaMask Secret Phrase',
        text: 'MetaMask will then present you with your 12-word backup phrase. You will need to write this down in the same order displayed on your screen.',
        img: 'metaMaskInstallationStep_6.png',
        infoText: 'You will need it to recover your wallet if you lose access to your computer, and it should be kept in a safe place. Anyone who has access to your 12-word backup phrase will be able to recover your funds, so keep it a secret.'
    },
    {
        step: 7,
        title: 'Confirm Secret Phrase',
        text: "Put the words memorized in the previous step in order. Tap on 'Complete' button when you are finished with the backup.",
        img: 'metaMaskInstallationStep_7.png',
    },
    {
        step: 8,
        title: 'Wallet Ready',
        text: "You are almost finished with the MetaMask setup process. Just click 'All done' on the last page, and you will be automatically logged into MetaMask. If you ever log out, you can log back in by clicking on the icon added to your web browser (usually, it's next to the URL bar).",
        img: 'metaMaskInstallationStep_8.png',
    },
    {
        step: 9,
        title: 'Add Binance Smart Chain',
        text: "Click on the button below to add Binance Smart Chain Network to your MetaMask wallet.",
        img: 'metaMaskInstallationStep_9.png',
        btnText: 'Add Binance Smart Chain',
        btnMethod: 'handleAddBinanceSmartChain',
        linkText: 'Install here.',
        linkMethod: 'handleDownloadMetamask'
    },
    {
        step: 10,
        title: 'Add Network',
        text: 'Give permission to the platform to add a network.',
        img: 'metaMaskInstallationStep_00.png',
    },
    {
        step: 11,
        title: 'Confirm the connection request',
        text: "Click on the button 'Switch network' to allow the connection request.",
        img: 'metaMaskInstallationStep_10.png',
    },
    {
        step: 12,
        title: 'Congratulations!',
        text: 'You have successfully set up Your wallet.Welcome to the world of WEB3!',
        img: 'metaMaskInstallationStep_12.png',
        btnText: 'Get Started!',
        btnMethod: 'handleGetStarted'
    },
]

export const MetaMaskInstallationStepsMobile = [
    {
        step: 1,
        title: 'Install MetaMask',
        text: "First, let's set up your cryptocurrency wallet.",
        text2: 'Follow the next steps to continue the setup.',
        img: 'metaMaskInstallationLogo.svg',
        linkText: 'Install MetaMask here.',
        linkMethod: 'handleDownloadMetamask'
    },
    {
        step: 2,
        title: 'Get Started',
        text: "After Downloading and opening MetaMask, tap on 'Get Started'.",
        img: 'metaMaskInstallationStep_2_mobile.png',
    },
    {
        step: 3,
        title: 'Create a Wallet',
        text: "On the next step, tap on 'Create a new wallet'.",
        img: 'metaMaskInstallationStep_3_mobile.png',
    },
    {
        step: 4,
        title: 'Improve Metamask',
        text: "You will then be asked if you want to help improve MetaMask. Click 'No, Thanks' if this doesn't interest you, otherwise click 'I agree'.",
        img: 'metaMaskInstallationStep_4_mobile.png',
    },
    {
        step: 5,
        title: 'Create Password',
        text: 'Pick a password on the next step. This needs to be at least 8 symbols long.',
        img: 'metaMaskInstallationStep_5_mobile.png',
        infoText: "We recommend using a completely unique password that hasn't been used anywhere else, and contains a mixture of upper and lower case letters, symbols and numbers."
    },
    {
        step: 6,
        title: 'Secure Wallet',
        text: "On this step you can choose. We recommend you do this now as you might otherwise lose access to your wallet. Tap on 'Start' to continue.",
        img: 'metaMaskInstallationStep_6_mobile.png',
    },
    {
        step: 7,
        title: 'Secure Wallet',
        text: "On this step tap on 'Start' to continue.",
        img: 'metaMaskInstallationStep_7_mobile.png',
    },
    {
        step: 8,
        title: 'Confirm Password',
        text: 'Confirm your password to continue.',
        img: 'metaMaskInstallationStep_8_mobile.png',
    },
    {
        step: 9,
        title: 'MetaMask Secret Phrase',
        text: 'MetaMask will then present you with your 12-word backup phrase.',
        img: 'metaMaskInstallationStep_9_mobile.png',
        infoText: 'You will need it to recover your wallet if you lose access to your computer, and it should be kept in a safe place. Anyone who has access to your 12-word backup phrase will be able to recover your funds, so keep it a secret.'
    },
    {
        step: 10,
        title: 'Confirm Secret Phrase',
        text: "Put the words memorized in the previous step in order. Tap on 'Complete' button when you are finished with the backup.",
        img: 'metaMaskInstallationStep_10_mobile.png',
    },
    {
        step: 11,
        title: 'Congratulations!',
        text: 'Congratulations! You have set up your MetaMask wallet. Confirm the next few pop-ups on the screen to access your wallet.',
        img: 'metaMaskInstallationStep_11_mobile.png',
    },
    {
        step: 12,
        title: 'Import Binance Smart Chain',
        text: 'Tap on the burger menu of the app in the top left corner of the screen.',
        img: 'metaMaskInstallationStep_12_mobile.png',
    },
    {
        step: 13,
        title: 'Import Binance Smart Chain',
        text: 'Next, tap on Settings in the menu to the left.',
        img: 'metaMaskInstallationStep_13_mobile.png',
    },
    {
        step: 14,
        title: 'Import Binance Smart Chain',
        text: 'Next, tap on Networks.',
        img: 'metaMaskInstallationStep_14_mobile.png',
    },
    {
        step: 15,
        title: 'Import Binance Smart Chain',
        text: 'In the search field, type in BNB Smart Chain and add it to your network.',
        img: 'metaMaskInstallationStep_15_mobile.png',
    },
    {
        step: 16,
        title: 'Confirm the connection request',
        text: "Click on the button 'Approve' to allow the connection request.",
        img: 'metaMaskInstallationStep_19_mobile.png',
    },
    {
        step: 17,
        title: 'Copy NFT Smart Contract',
        text: 'Next, copy the NFT Smart Contract by tapping on the button below.',
        img: 'metaMaskInstallationStep_16_mobile.png',
        btnText: 'Copy NFT Contract',
        btnMethod: 'handleCopyNFTContract'
    },
    {
        step: 18,
        title: 'Import Tokens',
        text: "Tap on the 'Import Tokens' link to add a Custom Token.",
        img: 'metaMaskInstallationStep_17_mobile.png',
    },
    {
        step: 19,
        title: 'Import Tokens',
        text: 'Next, select Custom Token. Paste the address of the smart contract you copied into the token address field. In the Token Symbol field, type SP, and in the Token Decimal, put 0. Then click the \'Import\' button to complete your setup.',
        img: 'metaMaskInstallationStep_18_mobile.png',
    },
    {
        step: 20,
        title: 'Connect to Gymstreet',
        text: 'Now to access the Gym Street Metaverse, you need to open it with the MetaMask browser. Go to your MetaMask wallet app, choose \'Browser\' from the side navigation menu, search for Gym Street in the browser tab.',
        img: 'metaMaskInstallationStep_20_mobile.png',
    },
    {
        step: 21,
        title: 'Congratulations!',
        text: 'You have successfully set up Your wallet.Welcome to the world of WEB3!',
        img: 'metaMaskInstallationStep_12.png',
        btnText: 'Get Started!',
        btnMethod: 'handleGetStarted'
    },
]
