import { ref } from "@nuxtjs/composition-api";

const media = window.matchMedia('(max-width: 768px)');
const isMobile = ref(media.matches);
media.addEventListener('change', (e) => {
    isMobile.value = e.matches;
});


export function useMobile() {
    return isMobile;
}