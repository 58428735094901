import { GETTERS, STATE } from "./names";
import { POLLING_STORE_FUNCTIONS } from "./polling";
import type { State } from "./types";

export default {
    [GETTERS.DURATION_FILTER]: (state: State) => state[STATE.DURATION_FILTER],
    [GETTERS.LEFT_LEG_PARTNERS_COUNT]: (state: State) => state[STATE.LEFT_LEG_PARTNERS_COUNT],
    [GETTERS.RIGHT_LEG_PARTNERS_COUNT]: (state: State) => state[STATE.RIGHT_LEG_PARTNERS_COUNT],
    [GETTERS.ACTIVE_PARTNERS_COUNT]: (state: State) => state[STATE.ACTIVE_PARTNERS_COUNT],
    [GETTERS.INACTIVE_PARTNERS_COUNT]: (state: State) => state[STATE.INACTIVE_PARTNERS_COUNT],
    [GETTERS.DIRECT_PARTNERS_COUNT]: (state: State) => state[STATE.DIRECT_PARTNERS_COUNT],
    [GETTERS.TEAM_DATA_LOADING]: (state: State) => state[STATE.TEAM_DATA_LOADING],
    [GETTERS.TEAM_DATA_LOADING_ERROR]: (state: State) => state[STATE.TEAM_DATA_LOADING_ERROR],
    [GETTERS.TEAM_DATA_LOADING_PROMISE]: (state: State) => state[STATE.TEAM_DATA_LOADING_PROMISE],
    ...POLLING_STORE_FUNCTIONS.getters,
}