import WalletAddress from "~/pages/finances/wallet/models/WalletAddress";
import { fromWei, getClientRuntimeConfig } from "~/core/helpers/GlobalHelpers";
import Web3 from "web3";

const $config = getClientRuntimeConfig();
export const fileCoinWeb3 = new Web3(new Web3.providers.HttpProvider($config.filecoinNode, {
    keepAlive: true,
}));

export class FileCoinService {
    public static async getNativeBalance(walletAddress: WalletAddress): Promise<number> {
        const wei = await fileCoinWeb3.eth.getBalance(walletAddress);
        return Number(fromWei(wei));
    }
}
