import { MyCurrentRankService } from "~/pages/partner-area/career-status/services/MyCurrentRankService";
import { ACTIONS, MUTATIONS, GETTERS } from "./names";
import { POLLING_STORE_FUNCTIONS } from "./polling";

export default {
    ...POLLING_STORE_FUNCTIONS.actions,

    async [ACTIONS.LOAD_PROCESS_AND_SAVE_BINARY_DATA] (
        { dispatch, commit, getters },
        { save }: {save?: () => boolean} = {},
    ) {
        if (getters[GETTERS.BINARY_PROGRESS_LOADING]) {
            return getters[GETTERS.BINARY_PROGRESS_LOADING_PROMISE];
        }

        commit(MUTATIONS.SET_BINARY_PROGRESS_LOADING, true);
        let processedBinaryProgressData = null;
        try {
            const loadingPromise = dispatch(ACTIONS.LOAD_AND_PROCESS_BINARY_PROGRESS_DATA);
            commit(MUTATIONS.SET_BINARY_PROGRESS_LOADING_PROMISE, loadingPromise);
            processedBinaryProgressData = await loadingPromise;

            if (!save || save()) {
                await dispatch(ACTIONS.SAVE_BINARY_PROGRESS_DATA, processedBinaryProgressData);
            }
      
            commit(MUTATIONS.SET_BINARY_PROGRESS_LOADING_ERROR, null);
        } catch (e) {
            console.warn('Failed to fetch binary progress data info', e);
            commit(MUTATIONS.SET_BINARY_PROGRESS_LOADING_ERROR, e);
        } finally {
            commit(MUTATIONS.SET_BINARY_PROGRESS_LOADING, false);
            commit(MUTATIONS.SET_BINARY_PROGRESS_LOADING_PROMISE, null);
            return processedBinaryProgressData;
        }
    },
    async [ACTIONS.LOAD_AND_PROCESS_BINARY_PROGRESS_DATA] ({ dispatch, commit }) {
        const result = await dispatch(ACTIONS.LOAD_BINARY_PROGRESS_DATA);
        return dispatch(ACTIONS.PROCESS_BINARY_PROGRESS_DATA, result);
    },
    async [ACTIONS.LOAD_BINARY_PROGRESS_DATA] ({ dispatch, commit }) {
        return await MyCurrentRankService.getRankData();
    },
    [ACTIONS.PROCESS_BINARY_PROGRESS_DATA] (_ctx, data) {
        return {
            totalOwnPurchase: Number(data?.totalOwnPurchaseAmount || 0),
            leftLegMinPurchaseSatisfied: Boolean(data?.leftLegMinPurchase),
            rightLegMinPurchaseSatisfied: Boolean(data?.rightLegMinPurchase),
            leftLegPartnersCount: Number(data?.leftLegRanks || 0),
            rightLegPartnersCount: Number(data?.rightLegRanks || 0),
            leftLegTurnover: Number(data?.leftValue || 0),
            rightLegTurnover: Number(data?.rightValue || 0),
            unilevelRanksByLeftLegSatisfied: Boolean(data?.leftUnilevelRanks),
            unilevelRanksByRightLegSatisfied: Boolean(data?.rightUnilevelRanks),
        };
    },
    [ACTIONS.SAVE_BINARY_PROGRESS_DATA] ({ commit }, {
        totalOwnPurchase,
        leftLegMinPurchaseSatisfied,
        rightLegMinPurchaseSatisfied,
        leftLegPartnersCount,
        rightLegPartnersCount,
        leftLegTurnover,
        rightLegTurnover,
        unilevelRanksByLeftLegSatisfied,
        unilevelRanksByRightLegSatisfied,
    }) {
        commit(MUTATIONS.TOTAL_OWN_PURCHASE, totalOwnPurchase);
        commit(MUTATIONS.SET_LEFT_LEG_MIN_PURCHASE_SATISFIED, leftLegMinPurchaseSatisfied);
        commit(MUTATIONS.SET_RIGHT_LEG_MIN_PURCHASE_SATISFIED, rightLegMinPurchaseSatisfied);
        commit(MUTATIONS.SET_LEFT_LEG_PARTNERS_COUNT, leftLegPartnersCount);
        commit(MUTATIONS.SET_RIGHT_LEG_PARTNERS_COUNT, rightLegPartnersCount);
        commit(MUTATIONS.SET_LEFT_LEG_TURNOVER, leftLegTurnover);
        commit(MUTATIONS.SET_RIGHT_LEG_TURNOVER, rightLegTurnover);
        commit(MUTATIONS.SET_UNILEVEL_RANKS_BY_LEFT_LEG_SATISFIED, unilevelRanksByLeftLegSatisfied);
        commit(MUTATIONS.SET_UNILEVEL_RANKS_BY_RIGHT_LEG_SATISFIED, unilevelRanksByRightLegSatisfied);
    },
    [ACTIONS.CLEAR_PROGRESS_STATE] ({ commit, dispatch }) {
        commit(MUTATIONS.SET_BINARY_PROGRESS_LOADING, false);
        commit(MUTATIONS.SET_BINARY_PROGRESS_LOADING_ERROR, null);
        commit(MUTATIONS.SET_BINARY_PROGRESS_LOADING_PROMISE, null);
        dispatch(ACTIONS.STOP_POLLING);
        commit(MUTATIONS.SET_LEFT_LEG_MIN_PURCHASE_SATISFIED, null);
        commit(MUTATIONS.SET_RIGHT_LEG_MIN_PURCHASE_SATISFIED, null);
        commit(MUTATIONS.SET_LEFT_LEG_PARTNERS_COUNT, null);
        commit(MUTATIONS.SET_RIGHT_LEG_PARTNERS_COUNT, null);
        commit(MUTATIONS.SET_LEFT_LEG_TURNOVER, null);
        commit(MUTATIONS.SET_RIGHT_LEG_TURNOVER, null);
        commit(MUTATIONS.SET_UNILEVEL_RANKS_BY_RIGHT_LEG_SATISFIED, null);
        commit(MUTATIONS.SET_UNILEVEL_RANKS_BY_LEFT_LEG_SATISFIED, null);
    }
};