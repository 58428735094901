
export enum MysteryBoxPrizeType {
    PRIZE_LAND = 100, // 144$
    PRIZE_LAND_AND_MINER = 110, // 194$
    PRIZE_BASIC_BUNDLE = 120, // 327$
    PRIZE_SUPER_BUNDLE_R6 = 130, // 801$
    PRIZE_SUPER_BUNDLE_R8 = 140, // 1801$
    PRIZE_SUPER_BUNDLE_R10 = 150, // 4001$
    PRIZE_SUPER_BUNDLE_R15 = 160, // 8001$
    PRIZE_SUPER_BUNDLE_R20 = 170, // 12001$
    PRIZE_SUPER_BUNDLE_R22S = 180, // 18501$
    PRIZE_SUPER_BUNDLE_R22M = 190, // 35000$
    PRIZE_SUPER_BUNDLE_R22M_2X = 200, // 2 * 35000$ //  50001$
    PRIZE_SUPER_BUNDLE_R22M_3X = 210, // 3 * 35000$ // 100002$
    PRIZE_SUPER_BUNDLE_R22M_7X = 220, // 7 * 35000$ // 250000$
};