import { GETTERS, STATE } from "./names";
import { POLLING_STORE_FUNCTIONS } from "./polling";
import type { State } from "./types";

export default {
    [GETTERS.PROGRESS_BY_BINARY_LEFT]: (state: State) => state[STATE.PROGRESS_BY_BINARY_LEFT],
    [GETTERS.PROGRESS_BY_BINARY_RIGHT]: (state: State) => state[STATE.PROGRESS_BY_BINARY_RIGHT],
    [GETTERS.HAS_IGNORED_BONUS]: (state: State) => state[STATE.HAS_IGNORED_BONUS],
    [GETTERS.PROGRESS_DATA_LOADING]: (state: State) => state[STATE.PROGRESS_DATA_LOADING],
    [GETTERS.PROGRESS_DATA_LOADING_ERROR]: (state: State) => state[STATE.PROGRESS_DATA_LOADING_ERROR],
    [GETTERS.PROGRESS_DATA_LOADING_PROMISE]: (state: State) => state[STATE.PROGRESS_DATA_LOADING_PROMISE],
    ...POLLING_STORE_FUNCTIONS.getters,
}