import { MUTATIONS } from "./names";
import { POLLING_STORE_FUNCTIONS } from "./polling";
import type { State } from "./types";
import type { BalanceInfo } from "~/pages/finances/commissions/types/BalanceInfo";

export default {
    ...POLLING_STORE_FUNCTIONS.mutations,
    [MUTATIONS.SET_BINARY_WEEKLY_CAP](state: State, payload: number) {
        state.binaryWeeklyCap = payload;
    },
    [MUTATIONS.SET_BINARY_CURRENT_PROGRESS](state: State, payload: number) {
        state.binaryCurrentProgress = payload;
    },
    [MUTATIONS.SET_TOTAL_BINARY_COMMISSION](state: State, payload: number) {
        state.totalBinaryCommission = payload;
    },
    [MUTATIONS.SET_CLAIMED_COMMISSIONS](state: State, payload: BalanceInfo) {
        state.claimedCommissions = payload;
    },
    [MUTATIONS.SET_AVAILABLE_COMMISSIONS](state: State, payload: BalanceInfo) {
        state.availableCommissions = payload;
    },
    [MUTATIONS.SET_COLLECTED_BINARY_BONUS](state: State, payload: BalanceInfo) {
        state.collectedBinaryBonus = payload;
    },
    [MUTATIONS.SET_COLLECTED_MATCHING_BONUS](state: State, payload: BalanceInfo) {
        state.collectedMatchingBonus = payload;
    },
    [MUTATIONS.SET_COMMISSIONS_INFO_DATA_LOADING](state: State, payload: boolean) {
        state.commissionsInfoDataLoading = payload;
    },
    [MUTATIONS.SET_COMMISSIONS_INFO_DATA_LOADING_ERROR](state: State, payload: Error | null) {
        state.commissionsInfoDataLoadingError = payload;
    },
    [MUTATIONS.SET_COMMISSIONS_INFO_DATA_LOADING_PROMISE](state: State, payload: Promise<any> | null) {
        state.commissionsInfoDataLoadingPromise = payload;
    },
}