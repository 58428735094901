import { ACTIONS, MUTATIONS } from "./names";
import {
    GETTERS as UNREAD_NOTIFICATIONS_GETTERS,
    MUTATIONS as UNREAD_NOTIFICATIONS_MUTATIONS,
    ACTIONS as UNREAD_NOTIFICATIONS_ACTIONS,
} from "../unread/names";
import {
    ACTIONS as HISTORY_NOTIFICATIONS_ACTIONS,
    GETTERS as HISTORY_NOTIFICATIONS_GETTERS,
    MUTATIONS as HISTORY_NOTIFICATIONS_MUTATIONS,
} from "../history/names";


export default {
    [ACTIONS.OPEN_NOTIFICATIONS_LOAD_OR_UPDATE_FIRST_HISTORY_PAGE_IF_NEEDED]({ dispatch, rootGetters }) {
        if (!rootGetters[`affility/user/notifications/history/${HISTORY_NOTIFICATIONS_GETTERS.NOTIFICATION_HISTORY}`].length) {
            dispatch(
                `affility/user/notifications/history/${HISTORY_NOTIFICATIONS_ACTIONS.LOAD_MORE_NOTIFICATIONS_HISTORY}`,
                { count: 20 },
                { root: true },
            );
        } else {
            dispatch(
                `affility/user/notifications/unread/${UNREAD_NOTIFICATIONS_ACTIONS.FETCH_PROCESS_AND_SAVE_NOTIFICATIONS_READ_BY_OTHER_SESSIONS}`,
                null,
                { root: true },
            );
        }
        return dispatch(ACTIONS.OPEN_NOTIFICATIONS_BAR);
    },
    [ACTIONS.CLOSE_NOTIFICATIONS_BAR_AND_READ]({ commit, dispatch, rootGetters }) {
        dispatch(ACTIONS.CLOSE_NOTIFICATIONS_BAR);
        const actionsPrefix = 'affility/user/notifications';
        const unreadNotifications = rootGetters[`${actionsPrefix}/unread/${UNREAD_NOTIFICATIONS_GETTERS.UNREAD_NOTIFICATIONS}`];
        commit(
            `${actionsPrefix}/unread/${UNREAD_NOTIFICATIONS_MUTATIONS.SET_UNREAD_NOTIFICATIONS}`,
            [],
            { root: true },
        );
        commit(
            `${actionsPrefix}/history/${HISTORY_NOTIFICATIONS_MUTATIONS.ADD_NOTIFICATIONS_TO_HISTORY}`,
            { notifications: unreadNotifications, fromStart: true },
            { root: true },
        );
    },
    [ACTIONS.CLEAR_NOTIFICATIONS_BAR_STATE]({ dispatch }) {
        dispatch(ACTIONS.CLOSE_NOTIFICATIONS_BAR);
    },
    [ACTIONS.CLOSE_NOTIFICATIONS_BAR]({ commit }) {
        commit(MUTATIONS.SET_NOTIFICATIONS_BAR_OPEN, false);
    },
    [ACTIONS.OPEN_NOTIFICATIONS_BAR]({ commit }) {
        commit(MUTATIONS.SET_NOTIFICATIONS_BAR_OPEN, true);
    },
};