import { AxiosError } from 'axios';
import { GlobalMakerService } from '../services/GlobalMakerService';
import { AuthError } from '../services/api-interaction/AuthService';
import { AUTH_FLOW_ERRORS, ERROR_STATUSES } from '../services/utils/Constants';
import DEFAULTS from '~/core/auth/configs/common';
import { LocalScheme } from '~auth/runtime';
import ApiService from "~/core/services/api-interaction/ApiService";
import {getClientRuntimeConfig} from "~/core/helpers/GlobalHelpers";
import {DataService} from "~/core/services/DataService";
const $config = getClientRuntimeConfig();

export default class UserDataSyncLocalScheme extends LocalScheme {
    constructor ($auth, options, ...defaults) {
        super($auth, options, ...defaults, DEFAULTS);
    }

    async fetchUser () {
        await super.fetchUser();

        const token = this.token?.get();

        if(!token) {
            return;
        }

        if(this.options.token.setApiServiceToken) {
            GlobalMakerService.$store.dispatch(this.options.token.storeDispatchPath, {
                apiToken: token,
            });
        }

        if(this.options.token.setJwtServiceToken) {
            GlobalMakerService.$store.dispatch(this.options.token.storeDispatchPath, {
                jwtToken: token,
            });
        }

        const binaryUser = await this.binaryUsers();

        if(this.options.user.autoFetch && this.options.user.setToStore && this.$auth.user) {
            GlobalMakerService.$store.dispatch(this.options.user.storeDispatchPath, {
                user: {
                    ...this.$auth.user,
                    ...binaryUser,
                },
            });
        }
    }

    async login (_opts = {}) {
        try {
            await super.login(_opts);
            return DataService.removeFromLocalStorage('redirect');
        } catch (error) {
            if(error instanceof AxiosError) {
                const { response } = error;
                if(response?.status === 401 &&
                    response?.data?.code === ERROR_STATUSES.AUTHORIZATION_ERROR) {
                    throw new AuthError(
                        "Invalid credentials. Please try again.",
                        AUTH_FLOW_ERRORS.INVALID_EMAIL_OR_PASSWORD,
                        response.data?.data?.failedAttempts,
                        response.data?.data?.banned ? response.data?.data?.bannedUntil : null,
                    );
                } else if (response?.status === 403 &&
                    response?.data?.code === ERROR_STATUSES.PERMISSION_DENIED) {
                    throw new AuthError(
                        "Too many login attempts for this account. Please try agina later.",
                        AUTH_FLOW_ERRORS.TOO_MANY_REQUESTS,
                        response.data?.data?.failedAttempts,
                        response.data?.data?.banned ? response.data?.data?.bannedUntil : null,
                    );
                }
            }
            console.error('Unknown error at UserDataSyncLocalScheme.js login', error);
            throw new AuthError("An error occurred while logging in. Please try again.", AUTH_FLOW_ERRORS.SOMETHING_WENT_WRONG);
        }
    }

    async logout () {
        this.$auth.reset();
    }

    async binaryUsers () {
        try {
            const responseBinary = await ApiService.get(`${$config.binaryTreeApi}/api/users`);
            return {
                binaryUser: responseBinary?.data || {}
            }
        } catch (e) {
            console.log('Err Binary User', e)
        }
    }
}
