import { ACTIONS } from "./names";
import { ACTIONS as EMAIL_VERIFICATION_ACTIONS } from "./email-verification/names";
import { ACTIONS as UPGRADE_TO_AFFILIATE_ACTIONS } from "./upgrade-to-affiliate/names";
import { ACTIONS as TERMS_AND_CONDITIONS_ACTIONS } from "./terms-and-conditions/names";

export default {
    [ACTIONS.CLEAR_FLOWS_STATE]({ commit, dispatch }) {
        dispatch(`affility/user/flows/email-verification/${EMAIL_VERIFICATION_ACTIONS.CLEAR_EMAIL_VERIFICATION_STATE}`, null, { root: true });
        dispatch(`affility/user/flows/email-verification/${TERMS_AND_CONDITIONS_ACTIONS.CLEAR_TERMS_AND_CONDITIONS_STATE}`, null, { root: true })
        dispatch(`affility/user/flows/upgrade-to-affiliate/${UPGRADE_TO_AFFILIATE_ACTIONS.CLEAR_UPGRADING_TO_AFFILIATE_STATE}`, null, { root: true });
    },
};