import { STATE } from "./names";
import {
    EnrollmentTreeTabSelectViewTypes
} from "~/core/types/affility/components/my-team/EnrollmentTreeTabSelectViewTypes";
import { CVPointsTableData, EnrollmentStatsData, EnrollmentTableData, ExtendedRefLinkData } from "./types";

export default () => {
    return {
        [STATE.MY_TEAM_GUIDE_STEP]: '',
        [STATE.ENROLLMENT_SELECT_VIEW]: EnrollmentTreeTabSelectViewTypes.TREE as EnrollmentTreeTabSelectViewTypes,
        [STATE.ENROLLMENT_STATS_DATA]: {
            activePartnersCount: null,
            allPartnersCount: null,
            directPartnersCount: null,
            inactivePartnersCount: null,
        } as EnrollmentStatsData,
        [STATE.ENROLLMENT_TREE_DATA]: null,
        [STATE.ENROLLMENT_TREE_LOADER]: true,
        [STATE.ENROLLMENT_TABLE_LOADER]: true,
        [STATE.ENROLLMENT_TABLE_DATA]: {
            data: [] as EnrollmentTableData[],
            page: null,
            totalCount: 0 as number,
        },
        [STATE.CV_POINTS_DATA]: {
            data: [],
            page: 1,
            perPage: 10,
            totalCount: 0,
        } as CVPointsTableData,
        [STATE.CV_POINTS_DATA_LOADER]: true,
        [STATE.CV_POINTS_SEARCH_VALUE]: '',
        [STATE.BINARY_TREE_DATA_LOADER]: true,
        [STATE.BINARY_TREE_DATA]: null,
        [STATE.EXTENDED_REFERRAL_LINK_DATA]: {
            visible: false,
            username: '',
            leg: null,
        } as ExtendedRefLinkData,
    }
}
