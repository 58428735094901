import { POLLING_STORE_FUNCTIONS } from "./polling";
import { STATE } from "./names";

export default () => {
    return {
        [STATE.TOTAL_BINARY_INCOME_LEFT]: null as number | null,
        [STATE.TOTAL_BINARY_INCOME_RIGHT]: null as number | null,
        [STATE.BINARY_VALUE]: null as number | null,
        [STATE.MATCHING_BONUS_VALUE]: null as number | null,
        [STATE.DIRECT_SALES_VALUE]: null as number | null,
        [STATE.TOTAL_INCOME_LOADING]: false,
        [STATE.TOTAL_INCOME_LOADING_ERROR]: null as string | null,
        [STATE.TOTAL_INCOME_LOADING_PROMISE]: null as Promise<void> | null,
        ...POLLING_STORE_FUNCTIONS.state,
    };
};
