import { MUTATIONS, STATE } from "./names";
import {
    EnrollmentTreeTabSelectViewTypes
} from "~/core/types/affility/components/my-team/EnrollmentTreeTabSelectViewTypes";
import { RANKS_ORDER } from "~/constants/constants";
import {
    BinaryTreeData,
    BinaryTreeUser,
    EnrollmentTableData,
    EnrollmentTreeData,
    EnrollmentTreeUser,
    ExtendedRefLinkData
} from "~/store/affility/pages/my-team/types";

function mapApiResponseToEnrollmentTreeUser(
    apiResponse: any,
): EnrollmentTreeUser {
    return {
        id: apiResponse.id,
        rankName: apiResponse.rank_name,
        walletAddress: apiResponse.wallet_address,
        teamTurnover: parseFloat(apiResponse.team_turnover),
        ownPurchase: parseFloat(apiResponse.own_purchase),
        selectedLeg: apiResponse.leg,
        username: apiResponse.username,
        allPartners: parseFloat(apiResponse.all_partners),
        rank: RANKS_ORDER[apiResponse.rank_name],
        directPartnersCount: parseFloat(apiResponse.direct_partners || 0),
    };
}

function mapApiResponseToBinaryTreeUser(
    apiResponse: any,
): BinaryTreeUser {
    return {
        binaryPath: apiResponse.binary_path,
        leftChildrenCount: apiResponse.left_children_count,
        leftCVPoints: apiResponse.left_cv_points,
        rankName: apiResponse.rank,
        rightChildrenCount: apiResponse.right_children_count,
        rightCVPoints: apiResponse.right_cv_points,
        username: apiResponse.username,
        walletAddress: apiResponse.wallet_address,
        rank: RANKS_ORDER[apiResponse.rank],
    };
}

function mapNodeToBinaryTree(node, parent, nodeIndex) {
    if (!node) {
        return {
            currentLeg: nodeIndex === 0 ? 'left' : 'right',
            sponsorUsername: parent?.username,
            isCollapsed: false,
            user: null,
            partners: []
        };
    }

    return {
        currentLeg: nodeIndex === 0 ? 'left' : 'right',
        sponsorUsername: parent?.username,
        isCollapsed: !!(node.children || []).length,
        user: mapApiResponseToBinaryTreeUser(node),
        partners: (node.children || []).map((child, idx) => mapNodeToBinaryTree(child, node, idx))
    };
}

export default {
    [MUTATIONS.SET_MY_TEAM_GUIDE_STEP](state, value: string) {
        state[STATE.MY_TEAM_GUIDE_STEP] = value;
    },
    [MUTATIONS.SET_ENROLLMENT_SELECT_VIEW](state, value: EnrollmentTreeTabSelectViewTypes) {
        state[STATE.ENROLLMENT_SELECT_VIEW] = value;
    },
    [MUTATIONS.SET_ENROLLMENT_STATS_DATA](state, payload: any) {
        const allPartnersCount = payload?.allPartnersCount || 0;
        const activePartnersCount = payload?.activePartnersCount || 0;
        const inactivePartnersCount = payload?.inactivePartnersCount || 0;
        const activePartnersPer = allPartnersCount ? activePartnersCount / allPartnersCount * 100 : 0;
        const inactivePartnersPer = allPartnersCount ? inactivePartnersCount / allPartnersCount * 100 : 0;

        state[STATE.ENROLLMENT_STATS_DATA].allPartnersCount = String(allPartnersCount);
        state[STATE.ENROLLMENT_STATS_DATA].directPartnersCount = String(payload?.directPartnersCount || 0);
        state[STATE.ENROLLMENT_STATS_DATA].activePartnersCount = Number(activePartnersPer ? Number(activePartnersPer).toFixed(2) : '0');
        state[STATE.ENROLLMENT_STATS_DATA].inactivePartnersCount = Number(inactivePartnersPer ? Number(inactivePartnersPer).toFixed(2) : '0');
    },
    [MUTATIONS.SET_CLEAR_ENROLLMENT_STATS_DATA](state) {
        state[STATE.ENROLLMENT_STATS_DATA] = {
            activePartnersCount: null,
            allPartnersCount: null,
            directPartnersCount: null,
            inactivePartnersCount: null,
        };
    },
    [MUTATIONS.SET_ENROLLMENT_TREE_MAIN_DATA](state, value: any[]) {
        if (value && value.length) {
            state[STATE.ENROLLMENT_TREE_DATA] = {
                isCollapsed: true,
                user: mapApiResponseToEnrollmentTreeUser(value[0]),
                partners: value.slice(1).map((user) => {
                    return {
                        isCollapsed: false,
                        user: mapApiResponseToEnrollmentTreeUser(user),
                        partners: []
                    }
                })
            }
        } else {
            state[STATE.ENROLLMENT_TREE_DATA] = null
        }
    },
    [MUTATIONS.SET_ENROLLMENT_TREE_USER_UPDATE](
        state,
        { response, userObject, isCollapsed }: { response: any[]; userObject: EnrollmentTreeData, isCollapsed: boolean }
    ) {
        userObject.isCollapsed = isCollapsed;
        userObject.partners = isCollapsed ?
            response.slice(1).map((user) => {
                return {
                    isCollapsed: false,
                    user: mapApiResponseToEnrollmentTreeUser(user),
                    partners: []
                }
            })
        : []
    },
    [MUTATIONS.SET_ENROLLMENT_TREE_RESET](state) {
        state[STATE.ENROLLMENT_TREE_DATA] = null;
    },
    [MUTATIONS.SET_ENROLLMENT_TREE_MAIN_USER_RESET](state) {
        state[STATE.ENROLLMENT_TREE_DATA].partners?.forEach((user: EnrollmentTreeData) => {
            user.isCollapsed = false;
            user.partners = [];
        });
    },
    [MUTATIONS.SET_ENROLLMENT_TREE_LOADER](state, value) {
        state[STATE.ENROLLMENT_TREE_LOADER] = value;
    },
    [MUTATIONS.SET_ENROLLMENT_TABLE_LOADER](state, value) {
        state[STATE.ENROLLMENT_TABLE_LOADER] = value;
    },
    [MUTATIONS.SET_ENROLLMENT_TABLE_DATA](state, { response, page }: { response: { result: [], total: number }, page: number }) {
        state[STATE.ENROLLMENT_TABLE_DATA].totalCount = response.total;
        state[STATE.ENROLLMENT_TABLE_DATA].page = page;
        state[STATE.ENROLLMENT_TABLE_DATA].data = response.result.map((user) => {
            return {
                isCollapsed: false,
                user: mapApiResponseToEnrollmentTreeUser(user),
                partners: [],
                totalCount: 0,
            }
        });
    },
    [MUTATIONS.SET_ENROLLMENT_TABLE_PARTNERS](
        state,
        { response, userObject, isCollapsed }: { response: any; userObject: EnrollmentTableData, isCollapsed: boolean }
    ) {
        userObject.isCollapsed = isCollapsed;
        userObject.totalCount = parseFloat(response.total);
        userObject.partners = isCollapsed ?
            response.result.map((user) => {
                return {
                    isCollapsed: false,
                    user: mapApiResponseToEnrollmentTreeUser(user),
                    partners: [],
                    totalCount: 0,
                }
            })
        : []
    },
    [MUTATIONS.SET_ENROLLMENT_TABLE_SEARCH](state, { response, page }: { response: { result: any[], total: number }, page: number }) {
        if (response && response.result && response.result.length >= 1) {
            let searchedUserPartner: any[] = response.result[0];

            state[STATE.ENROLLMENT_TABLE_DATA].totalCount = 1;
            state[STATE.ENROLLMENT_TABLE_DATA].page = 1;
            state[STATE.ENROLLMENT_TABLE_DATA].data = [{
                isCollapsed: false,
                user: mapApiResponseToEnrollmentTreeUser(searchedUserPartner),
                partners: [],
                totalCount: 0,
            }]
        } else {
            state[STATE.ENROLLMENT_TABLE_DATA] = {
                data: [] as EnrollmentTableData[],
                page: null,
                totalCount: 0 as number,
            };
        }
    },
    [MUTATIONS.SET_ENROLLMENT_TABLE_DATA_RESET](state) {
        state[STATE.ENROLLMENT_TABLE_DATA] = {
            data: [] as EnrollmentTableData[],
            page: null,
            totalCount: 0 as number,
        };
    },
    [MUTATIONS.SET_CV_POINTS_SEARCH_VALUE](state, value: string) {
        state[STATE.CV_POINTS_SEARCH_VALUE] = value;
    },
    [MUTATIONS.SET_CV_POINTS_DATA](state, { data, meta }: { data: [], meta: { page: string, perPage: string, total: string } }) {
        state[STATE.CV_POINTS_DATA].page = parseFloat(meta.page);
        state[STATE.CV_POINTS_DATA].perPage = parseFloat(meta.perPage);
        state[STATE.CV_POINTS_DATA].totalCount = parseInt(meta.total);
        state[STATE.CV_POINTS_DATA].data = data || [];
    },
    [MUTATIONS.SET_CV_POINTS_DATA_RESET](state) {
        state[STATE.CV_POINTS_DATA] = {
            data: [],
            page: 1,
            perPage: 10,
            totalCount: 0,
        }
    },
    [MUTATIONS.SET_CV_POINTS_DATA_LOADER](state, payload) {
        state[STATE.CV_POINTS_DATA_LOADER] = payload;
    },
    [MUTATIONS.SET_BINARY_TREE_DATA_LOADER](state, payload) {
        state[STATE.BINARY_TREE_DATA_LOADER] = payload;
    },
    [MUTATIONS.SET_BINARY_TREE_DATA](state, payload) {
        if (payload) {
            state[STATE.BINARY_TREE_DATA] = {
                currentLeg: '',
                sponsorUsername: null,
                isCollapsed: true,
                user: mapApiResponseToBinaryTreeUser(payload),
                partners: (payload.children || []).map((child, idx) => mapNodeToBinaryTree(child, payload, idx))
            };
        } else {
            state[STATE.BINARY_TREE_DATA] = null;
        }
    },
    [MUTATIONS.SET_BINARY_TREE_USER_UPDATE](
        state,
        { response, userObject, isCollapsed }: { response: any; userObject: BinaryTreeData, isCollapsed: boolean }
    ) {
        userObject.isCollapsed = isCollapsed;
        userObject.partners = isCollapsed ?
            (response?.children || []).map((child, idx) => mapNodeToBinaryTree(child, response, idx))
            : []
    },
    [MUTATIONS.SET_BINARY_TREE_RESET](state) {
        state[STATE.BINARY_TREE_DATA] = null;
    },
    [MUTATIONS.SET_EXTENDED_REFERRAL_LINK_DATA](state, { visible, username = '', leg = null }: ExtendedRefLinkData) {
        state[STATE.EXTENDED_REFERRAL_LINK_DATA].visible = visible;
        state[STATE.EXTENDED_REFERRAL_LINK_DATA].username = username;
        state[STATE.EXTENDED_REFERRAL_LINK_DATA].leg = leg;
    },
}
