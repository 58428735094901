import { render, staticRenderFns } from "./Mobile.vue?vue&type=template&id=acbab97c&scoped=true"
import script from "./Mobile.vue?vue&type=script&setup=true&lang=ts"
export * from "./Mobile.vue?vue&type=script&setup=true&lang=ts"
import style0 from "./Mobile.vue?vue&type=style&index=0&id=acbab97c&prod&scoped=true&lang=scss"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "acbab97c",
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {ComposedSelectMetaverse: require('/root/dashboard/core/components/composed/select/Metaverse.vue').default,BaseDividerSimple: require('/root/dashboard/core/components/base/divider/Simple.vue').default,ComposedCountdownAutoship: require('/root/dashboard/core/components/composed/countdown/Autoship.vue').default,LayoutSidebarFrozenProductAutoship: require('/root/dashboard/core/components/layout/sidebar/FrozenProductAutoship.vue').default,ComposedMenuItemContractAddressesOpener: require('/root/dashboard/core/components/composed/menu-item/ContractAddressesOpener.vue').default,ComposedMenuItemThemeSwitcher: require('/root/dashboard/core/components/composed/menu-item/ThemeSwitcher.vue').default,ComposedMenuItemLanguagesOpener: require('/root/dashboard/core/components/composed/menu-item/LanguagesOpener.vue').default,ComposedActionAuthButtons: require('/root/dashboard/core/components/composed/action/AuthButtons.vue').default,BaseSidebarSliding: require('/root/dashboard/core/components/base/sidebar/Sliding.vue').default,BaseOverlaySimple: require('/root/dashboard/core/components/base/overlay/Simple.vue').default})
