import { ACTIONS, MUTATIONS, GETTERS } from "./names";
import { ACTIONS as PROGRESS_ACTIONS } from "./progress/names";
import { ACTIONS as COMMISSIONS_ACTIONS } from "./commissions/names";
import { BinaryTreeService } from "~/pages/my-team/services/BinaryTreeService";
import { BinaryTreeRawNode } from "./types/BinaryTreeRawNode";
import { POLLING_STORE_FUNCTIONS } from "./polling";

export default {
    ...POLLING_STORE_FUNCTIONS.actions,

    async [ACTIONS.LOAD_PROCESS_AND_SAVE_SUBTREE_DATA] (
        { dispatch, commit, getters },
        { save }: {save?: () => boolean} = {},
    ) {
        if (getters[GETTERS.SUBTREE_DATA_LOADING]) {
            return getters[GETTERS.SUBTREE_DATA_LOADING_PROMISE];
        }

        commit(MUTATIONS.SET_SUBTREE_DATA_LOADING, true);
        let processedSubTreeData = null;
        try {
            const loadingPromise = dispatch(ACTIONS.LOAD_AND_PROCESS_SUBTREE_DATA);
            commit(MUTATIONS.SET_SUBTREE_DATA_LOADING_PROMISE, loadingPromise);
            processedSubTreeData = await loadingPromise;

            if (!save || save()) {
                await dispatch(ACTIONS.SAVE_SUBTREE_DATA, processedSubTreeData);
            }

            commit(MUTATIONS.SET_SUBTREE_DATA_LOADING_ERROR, null);
        } catch (e) {
            console.log('Failed to fetch subtree info', e);
            commit(MUTATIONS.SET_SUBTREE_DATA_LOADING_ERROR, e);
        } finally {
            commit(MUTATIONS.SET_SUBTREE_DATA_LOADING, false);
            commit(MUTATIONS.SET_SUBTREE_DATA_LOADING_PROMISE, null);
            return processedSubTreeData;
        }
    },
    async [ACTIONS.LOAD_AND_PROCESS_SUBTREE_DATA] ({ dispatch }) {
        const rawSubTreeData = await dispatch(ACTIONS.LOAD_SUBTREE_DATA);
        return await dispatch(ACTIONS.PROCESS_SUBTREE_DATA, rawSubTreeData);
    },
    [ACTIONS.SAVE_SUBTREE_DATA] ({ commit }, processedSubTreeData) {
        commit(MUTATIONS.SET_BINARY_PATH, processedSubTreeData.binaryPath);
        commit(MUTATIONS.SET_BINARY_RANK_NAME, processedSubTreeData.rankName);
        commit(MUTATIONS.SET_LEFT_CV, processedSubTreeData.cv.left);
        commit(MUTATIONS.SET_RIGHT_CV, processedSubTreeData.cv.right);
        commit(MUTATIONS.SET_LEFT_SUBTREE_SIZE, processedSubTreeData.subtreeSize.left);
        commit(MUTATIONS.SET_RIGHT_SUBTREE_SIZE, processedSubTreeData.subtreeSize.right);
        commit(MUTATIONS.SET_LEFT_SUBTREE_ROOT, processedSubTreeData.subtree.left);
        commit(MUTATIONS.SET_RIGHT_SUBTREE_ROOT, processedSubTreeData.subtree.right);
    },
    [ACTIONS.PROCESS_SUBTREE_DATA] (_ctx, rawCvData: { subTree: BinaryTreeRawNode }) {
        return {
            cv: {
                left: rawCvData.subTree.left_cv_points || 0,
                right: rawCvData.subTree.right_cv_points || 0,
            },
            subtreeSize: {
                left: rawCvData.subTree.left_children_count || 0,
                right: rawCvData.subTree.right_children_count || 0,
            },
            subtree: {
                left: rawCvData.subTree.children[0],
                right: rawCvData.subTree.children[1],
            },
            rankName: rawCvData.subTree.rank,
            binaryPath: rawCvData.subTree.binary_path,
        }
    },
    [ACTIONS.LOAD_SUBTREE_DATA] ({ commit, getters }) {
        return BinaryTreeService.getBinaryTree();
    },
    [ACTIONS.CLEAR_BINARY_STATE] ({ commit, dispatch }) {
        commit(MUTATIONS.SET_SUBTREE_DATA_LOADING, false);
        commit(MUTATIONS.SET_SUBTREE_DATA_LOADING_ERROR, null);
        commit(MUTATIONS.SET_SUBTREE_DATA_LOADING_PROMISE, null);
        dispatch(ACTIONS.STOP_POLLING);
        commit(MUTATIONS.SET_BINARY_PATH, null);
        commit(MUTATIONS.SET_BINARY_RANK_NAME, null);
        commit(MUTATIONS.SET_LEFT_CV, null);
        commit(MUTATIONS.SET_RIGHT_CV, null);
        commit(MUTATIONS.SET_LEFT_SUBTREE_SIZE, null);
        commit(MUTATIONS.SET_RIGHT_SUBTREE_SIZE, null);
        commit(MUTATIONS.SET_LEFT_SUBTREE_ROOT, null);
        commit(MUTATIONS.SET_RIGHT_SUBTREE_ROOT, null);
        dispatch(`affility/user/binary/progress/${PROGRESS_ACTIONS.CLEAR_PROGRESS_STATE}`, null, { root: true });
        dispatch(`affility/user/binary/commissions/${COMMISSIONS_ACTIONS.CLEAR_COMMISSIONS_STATE}`, null, { root: true });
    },
};
