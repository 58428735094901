import Web3 from 'web3'
import { CurrencyName } from '~/pages/finances/wallet/models/CurrencyName'
import { METAVERSE_ABI_FOLDERS } from '@/core/services/utils/MetaverseConstants'
import WalletAddress from "~/pages/finances/wallet/models/WalletAddress";
import { CONTRACTS } from "~/contracts/addresses";
import { getClientRuntimeConfig } from '~/core/helpers/GlobalHelpers';

type WalletCurrencyName = Exclude<CurrencyName, 'BNB' | 'BTC' | 'BPX' | 'ETH' | 'ADA' | 'XRP' | 'SOL' | 'LUNC' | 'AVAX' | 'FIL' | 'ETH' | 'MNBRG'>
type ContractNames = {[currency in WalletCurrencyName]: string};

const $config = getClientRuntimeConfig();
const CURRENCY_TO_CONTRACT_MAP: ContractNames = {
    GYMNET: 'GymNetwork',
    BUSD: 'BUSD',
    USDT: 'USDT',
    PAP: 'PAP',
}

export default class ContractService {
    public static async getContract (neededContract: string, metaWorldManager: any, metaverseID: number, forceNewContract = false, provider: any = null) { // TODO: rename it!
        const contracts = metaWorldManager.allContracts[metaverseID]
        const contractName = CURRENCY_TO_CONTRACT_MAP[neededContract] || neededContract
        if (contracts[contractName] && !forceNewContract) {
            return contracts[contractName]
        }
        const web3 = provider ? new Web3(provider) : metaWorldManager.readWeb3()
        const { Contract } = web3.eth
        const contractAddress: WalletAddress = contracts[contractName]._address
        const ABI = await ContractService.getContractABI(contractName, metaverseID);
        return new Contract(
            ABI,
            contractAddress
        )
    }

    public static async getContractABI(contractName: string, metaverseID: number) {
        const metaverseContracts = Object.keys(CONTRACTS[metaverseID]);
        const abiFolder = METAVERSE_ABI_FOLDERS[metaverseID];

        if (metaverseContracts.includes(contractName)) {
            return await require(`@/contracts/abis/${abiFolder}/${contractName}.json`)
        } else {
            return await require(`@/contracts/abis/common/${contractName}.json`)
        }
    }

    public static createErc20AssetContract (address: string, metaWorldManager: any, web3?: Web3) {
        const { Contract } = (web3 || metaWorldManager.readWeb3()).eth;
        const ABISmethod = [{ inputs: [{ name: '_owner', type: 'address' }], name: 'balanceOf', outputs: [{ name: 'balance', type: 'uint256' }], payable: false, stateMutability: 'view', type: 'function' }, { constant: false, inputs: [{ name: '_spender', type: 'address' }, { name: '_value', type: 'uint256' }], name: 'approve', outputs: [{ name: 'success', type: 'bool' }], payable: false, stateMutability: 'nonpayable', type: 'function' }, { constant: true, inputs: [{ name: '_owner', type: 'address' }, { name: '_spender', type: 'address' }], name: 'allowance', outputs: [{ name: 'remaining', type: 'uint256' }], payable: false, stateMutability: 'view', type: 'function' }, { constant: false, inputs: [{ name: '_to', type: 'address' }, { name: '_value', type: 'uint256' }], name: 'transfer', outputs: [{ name: 'success', type: 'bool' }], payable: false, stateMutability: 'nonpayable', type: 'function' }];
        return new Contract(
            ABISmethod,
            address,
        );
    }
}
