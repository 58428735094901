import { ACTIONS, MUTATIONS } from "./names";
import { ACTIONS as PROGRESS_BY_UNILEVLEL_ACTIONS } from "./progress/names";

export default {
    [ACTIONS.CLEAR_UNILEVEL_STATE] ({ commit, dispatch }) {
        return dispatch(
            `affility/user/unilevel/progress/${PROGRESS_BY_UNILEVLEL_ACTIONS.CLEAR_PROGRESS_BY_UNILEVEL_STATE}`,
            null,
            { root: true },
        );
    },
};