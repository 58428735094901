import { MUTATIONS, STATE } from "./names";

export default {
    [MUTATIONS.SET_IS_POPUP_ACCEPTABLE](state, payload) {
        state[STATE.IS_POPUP_ACCEPTABLE] = payload;
    },
    [MUTATIONS.SET_IS_POPUP_STANDALONE](state, payload) {
        state[STATE.IS_POPUP_STANDALONE] = payload;
    },
    [MUTATIONS.SET_IS_POPUP_CLOSABLE](state, payload) {
        state[STATE.IS_POPUP_CLOSABLE] = payload;
    },
    [MUTATIONS.SET_TERMS_AND_CONDITIONS_POPUP_OPEN](state, payload) {
        state[STATE.IS_TERMS_AND_CONDITIONS_POPUP_OPEN] = payload;
    },
    [MUTATIONS.SET_TERMS_AND_CONDITIONS_TYPE](state, payload) {
        state[STATE.TERMS_AND_CONDITIONS_TYPE] = payload;
    },
    [MUTATIONS.SET_TERMS_AND_CONDITIONS_POPUP_CONTENT_PROPS](state, payload) {
        state[STATE.TERMS_AND_CONDITIONS_POPUP_CONTENT_PROPS] = payload;
    },
    [MUTATIONS.SET_DEFAULT_ACCEPT_HANDLER_LOADING](state, payload) {
        state[STATE.DEFAULT_ACCEPT_HANDLER_LOADING] = payload;
    },
    [MUTATIONS.SET_DEFAULT_ACCEPT_HANDLER_ERROR](state, payload) {
        state[STATE.DEFAULT_ACCEPT_HANDLER_ERROR] = payload;
    },
    [MUTATIONS.ADD_TERMS_AND_CONDITIONS_PROMISE](state, payload) {
        state[STATE.TERMS_AND_CONDITIONS_PROMISES].push(payload);
    },
    [MUTATIONS.REMOVE_TERMS_AND_CONDITIONS_PROMISE](state, payload) {
        state[STATE.TERMS_AND_CONDITIONS_PROMISES].shift(payload);
    },
    [MUTATIONS.SET_TERMS_AND_CONDITIONS_REJECTER](state, payload) {
        state[STATE.TERMS_AND_CONDITIONS_REJECTER] = payload;
    },
    [MUTATIONS.SET_TERMS_AND_CONDITIONS_RESOLVER](state, payload) {
        state[STATE.TERMS_AND_CONDITIONS_RESOLVER] = payload;
    }
}