import { TermsAndConditionsDetails } from "~/core/constants/affility/store/user/flows/terms-and-conditions/TermsAndConditionsDetails";
import { GETTERS, STATE } from "./names";

export default {
    [GETTERS.IS_POPUP_ACCEPTABLE]: (state) => state[STATE.IS_POPUP_ACCEPTABLE],
    [GETTERS.IS_POPUP_STANDALONE]: (state) => state[STATE.IS_POPUP_STANDALONE],
    [GETTERS.IS_POPUP_CLOSABLE]: (state) => state[STATE.IS_POPUP_CLOSABLE],
    [GETTERS.DEFAULT_ACCEPT_HANDLER_ERROR]: (state) => state[STATE.DEFAULT_ACCEPT_HANDLER_ERROR],
    [GETTERS.DEFAULT_ACCEPT_HANDLER_LOADING]: (state) => state[STATE.DEFAULT_ACCEPT_HANDLER_LOADING],
    [GETTERS.IS_TERMS_AND_CONDITIONS_POPUP_OPEN]: (state) => state[STATE.IS_TERMS_AND_CONDITIONS_POPUP_OPEN],
    [GETTERS.TERMS_AND_CONDITIONS_TYPE]: (state) => state[STATE.TERMS_AND_CONDITIONS_TYPE],
    [GETTERS.TERMS_AND_CONDITIONS_POPUP_CONTENT_PROPS]: (state) => state[STATE.TERMS_AND_CONDITIONS_POPUP_CONTENT_PROPS],
    [GETTERS.TERMS_AND_CONDITIONS_POPUP_DETAILS]: (state) => TermsAndConditionsDetails[state[STATE.TERMS_AND_CONDITIONS_TYPE]],
    [GETTERS.AGGREGATED_POPUP_PROPERTIES]: (_state, getters) => ({
        isVisible: getters[GETTERS.IS_TERMS_AND_CONDITIONS_POPUP_OPEN],
        isClosable: getters[GETTERS.IS_POPUP_CLOSABLE],
        isAcceptable: getters[GETTERS.IS_POPUP_ACCEPTABLE],
        isStandalone: getters[GETTERS.IS_POPUP_STANDALONE],
        defaultHandlerLoading: getters[GETTERS.DEFAULT_ACCEPT_HANDLER_LOADING],
        defaultHandlerError: getters[GETTERS.DEFAULT_ACCEPT_HANDLER_ERROR],
        type: getters[GETTERS.TERMS_AND_CONDITIONS_TYPE],
        details: getters[GETTERS.TERMS_AND_CONDITIONS_POPUP_DETAILS],
        contentProps: getters[GETTERS.TERMS_AND_CONDITIONS_POPUP_CONTENT_PROPS],
    }),
}