import { STATE } from "./names";
import { POLLING_STORE_FUNCTIONS } from "./polling";
import { BinaryTreeRawNode } from "./types/BinaryTreeRawNode";

export default () => {
    return {
        [STATE.LEFT_CV]: null as number | null,
        [STATE.RIGHT_CV]: null as number | null,
        [STATE.LEFT_SUBTREE_SIZE]: null as number | null,
        [STATE.RIGHT_SUBTREE_SIZE]: null as number | null,
        [STATE.LEFT_SUBTREE_ROOT]: null as BinaryTreeRawNode | null,
        [STATE.RIGHT_SUBTREE_ROOT]: null as BinaryTreeRawNode | null,
        [STATE.BINARY_PATH]: null as string | null,
        [STATE.BINARY_RANK_NAME]: null as string | null,
        [STATE.SUBTREE_DATA_LOADING]: false,
        [STATE.SUBTREE_DATA_LOADING_ERROR]: null as Error | null,
        [STATE.SUBTREE_DATA_LOADING_PROMISE]: null as Promise<void> | null,
        ...POLLING_STORE_FUNCTIONS.state,
    };
};