

import {defineComponent} from 'vue'
import GymNewButton from '~/components/utils/form-components/GymNewButton.vue';
import {WALLET_ERROR_CODES} from '~/core/services/utils/Constants';

export default defineComponent({
    name: 'FlowError',
    components: {
        GymNewButton,
    },
    emits: ['onTryAgain'],
    props: {
        error: {
            type: [Error],
            required: true,
        },
    },
    data() {
        return {
            title: null,
            message: null,
            isInfo: false,
        }
    },
    watch: {
        error: {
            handler(newErr) {
                this.isInfo = false;
                let title = 'Transaction Failed';
                let message = '';
                let code = null;
                if (typeof newErr === 'string') {
                    message = newErr;
                } else {
                    message = newErr.message;
                    code = newErr.code;
                }

                if (code === WALLET_ERROR_CODES.USER_REJECTED ||
                    code === WALLET_ERROR_CODES.REJECTED_BY_NETWORK ||
                    message.includes('rejected')) {
                    title = 'Transaction Rejected';
                    message = this.$t('Your transaction has been declined. Please attempt the transaction again.');
                } else if (code === WALLET_ERROR_CODES.METAMASK_INTERNAL_ERROR ||
                    message.includes('nonce mismatch') || message.includes('nonce too low')) {
                    this.isInfo = true;
                    title = 'Transaction in Process';
                    message = this.$t('Please wait for the ongoing transaction on the blockchain to complete before initiating a new one.');
                } else if (message.includes('insufficient funds for gas * price + value')) {
                    title = 'Transaction Rejected';
                    message = this.$t('Your transaction has been declined. Please add BNB to your wallet and attempt the transaction again.');
                } else if (message.includes('reverted by EVM') || message.includes('reverted')) {
                    title = 'Transaction Reverted';
                    message = this.$t('Blockchain Notification: Apologies, an error occurred on the blockchain side. Please try again.');
                } else {
                    title = 'Something went wrong';
                    message = 'Apologies, an unexpected error occurred. Please retry your request. If the issue persists, please contact support.';
                }
                this.title = title;
                this.message = message;
            },
            immediate: true,
        }
    },
    methods: {
        handleTryAgain() {
            this.$emit('onTryAgain')
        }
    }
})

