
import {UserBalance} from "~/core/models/purchase-popup-2/UserBalance";
import {toCurrencyFormat} from '@/core/helpers/GlobalHelpers';
import {defineComponent} from "vue";
import MunicipalityTx from "~/core/models/purchase-popup-2/MunicipalityTx";
import Web3MunicipalityTx from "~/core/models/purchase-popup-2/Web3MunicipalityTx";
import GymNewButton from "~/components/utils/form-components/GymNewButton.vue";
import WalletAsset from "~/pages/finances/wallet/models/WalletAsset";

export type StepSelectPapCurrencySelection = {
    userBalance: UserBalance,
    transactionObject: MunicipalityTx | Web3MunicipalityTx,
    papAsset: WalletAsset,
    handleContinue: () => void;
}

export default defineComponent<keyof StepSelectPapCurrencySelection, StepSelectPapCurrencySelection>({
    name: 'PapCurrencySelection',
    components: {
        GymNewButton
    },
    emits: ['onClickBack'],
    props: {
        userBalance: {
            type: UserBalance,
            required: true,
        },
        transactionObject: {
            type: [MunicipalityTx, Web3MunicipalityTx],
            required: true,
        },
    },
    data() {
        return {
            toCurrencyFormat
        }
    },
    computed: {
        papAsset() {
            return this.transactionObject.priceInfo.totalAmount;
        }
    },
    methods: {
        async handleContinue() {
            this.$emit('onCurrencySelected');
        },
    }
});
