import { MetaverseId } from "./MetaverseId";

export const Metaverses = {
    [MetaverseId.AFFILITY]: {
        id: MetaverseId.AFFILITY,
        name: 'Affility',
        icon: require('~/assets/affility/images/metaverses/affility.svg'),
    },
    [MetaverseId.KABUTOCHO]: {
        id: MetaverseId.KABUTOCHO,
        name: 'Kabutocho',
        icon: require('~/assets/affility/images/metaverses/kabutocho.svg'),
    },
};