import {getClientRuntimeConfig} from "~/core/helpers/GlobalHelpers";
import ApiService from "~/core/services/api-interaction/ApiService";
import {
    createEventName,
    FEATURE_PRIORITIZATION_GTAG_NAMES,
    FEATURE_PRIORITIZATION_GTAG_TYPES
} from "~/constants/gtag-events";
import {GlobalMakerService} from "~/core/services/GlobalMakerService";

const $config = getClientRuntimeConfig();

export const state = () => {
    return {
        isVotingAPILoading: false,
        voting: null,
        featureVotingData: null,
        featureVotingDataJSON: null,
        isVotingAPIError: false,
        votingEndDate: null,
        campaignData: null,
        autoSaveLoading: false,
        submitLoading: false,
        isStatisticsLoading: false,
        statisticsData: null,
        isStatisticsError: false,
        campaignEndDate: null,
    }
}

export const actions = {
    async getUserVote({ commit }) {
        if ($config.isEliteVoteDisabled) return;
        commit('SET_VOTING_CURRENT_LOADING', true);
        try {
            const result = await ApiService.get(`${$config.votingApi}/api/campaign/current`)

            commit('SET_VOTING_CURRENT_DATA', result?.data?.data);
        } catch {
            commit('SET_VOTING_CURRENT_ERROR', true);
        } finally {
            commit('SET_VOTING_CURRENT_LOADING', false);
        }
    },
    async onFeatureVoting({ commit, state }, { id, priority }) {
        if ($config.isEliteVoteDisabled) return;
        commit('SET_AUTO_SAVE_LOADING', true);
        commit('SET_ON_FEATURE_VOTING', { id, priority });
        const payload = state.featureVotingData
            .filter(item => item.priority)
            .map((item) => {
                return { featureId: item.id, priority: item.priority}
            })
        commit('SET_VOTING_TOTAL_COUNT', payload.length);
        try {
            await ApiService.post(`${$config.votingApi}/api/vote/autosave/`, {
                campaignId: state.campaignData?.id,
                voteData: payload
            });
        } catch (e) {
            console.error(e)
        } finally {
            commit('SET_AUTO_SAVE_LOADING', false);
        }
    },
    async onFeatureVotingSubmit({ commit, state, dispatch }) {
        if ($config.isEliteVoteDisabled) return;
        commit('SET_SUBMIT_LOADING', true);
        try {
            await ApiService.post(`${$config.votingApi}/api/vote/`, {
                campaignId: state.campaignData?.id,
            });
            await dispatch('setGoogleEvent', {
                type: FEATURE_PRIORITIZATION_GTAG_TYPES.SUBMIT,
                name: state.voting?.isSubmited
                    ? FEATURE_PRIORITIZATION_GTAG_NAMES.REPRIORITIZE
                    : FEATURE_PRIORITIZATION_GTAG_NAMES.START_PRIORITIZING
            });
            commit('SET_VOTING_DATA', {
                isSubmited: true,
                total: state.featureVotingData?.length || 0,
                voted: state.featureVotingData?.length || 0
            });
        } catch (e) {
            console.error(e)
        } finally {
            commit('SET_SUBMIT_LOADING', false);
        }
    },
    async getStatistics({ commit, state }) {
        if ($config.isEliteVoteDisabled) return;
        commit('SET_STATISTICS_LOADING', true);
        try {
            const result = await ApiService.get(`${$config.votingApi}/api/campaign/result`)
            commit('SET_STATISTICS_DATA', result?.data?.data?.result);
            commit('SET_CAMPAIGN_END_DATE', result?.data?.data?.campaignEndDate);

            return !!result?.data?.data?.result?.length
        } catch {
            commit('SET_STATISTICS_ERROR', true);
        } finally {
            commit('SET_STATISTICS_LOADING', false);
        }
    },
    setGoogleEvent({rootState}, { type, name, modalName = '' }) {
        if ($config.isEliteVoteDisabled) return;
        const names = {
            start: 'Start Prioritizing',
            continue: 'Continue Prioritizing',
            reprioritize: 'Reprioritize',
            rules: 'View rules',
            statistics: 'View statistics',
            keep_prioritizing: 'Keep',
            review_and_submit: 'Submit',
        }

        let eventName = `(${type}) Vote / ${names[name]}`;
        let eventNameTypes = ['elite_vote', type];
        if (modalName) {
            eventName = `(${type}) Vote / ${names[modalName]} Modal / Continue`;
            eventNameTypes.push(modalName)
        }

        const eventNameKey  = createEventName(name, ...eventNameTypes);

        GlobalMakerService.$gtag.event(eventNameKey, {
            feature_name: eventName,
            email: rootState.application.driver.authUser.email,
            user_id: rootState.application.driver.authUser.id,
        });
    },
}

export const mutations = {
    SET_VOTING_CURRENT_DATA(state, data) {
        if (data) {
            const campaignFeatures = data?.campaign?.campaignFeatures || [];
            let campaignDraft = new Map();
            (data?.draft || []).forEach(item => {
                campaignDraft.set(item.campaignFeatures.feature.id, {
                    id: item.campaignFeatures.feature.id,
                    priority: parseFloat(item.priority)
                })
            });
            let campaignFeaturesResult = [];
            state.featureVotingData = [];
            campaignFeatures.forEach(item => {
                const priorityDraft = campaignDraft.get(item.feature.id);
                campaignFeaturesResult.push({
                    id: item.feature.id,
                    name: item.feature.name,
                    description: item.feature.description,
                    priority: priorityDraft ? priorityDraft.priority : 0,
                    translate: {
                        en: {
                            name: item.feature.name,
                            description: item.feature.description
                        },
                        ...item.translate,
                    }
                });
            })
            state.featureVotingData = campaignFeaturesResult;
            state.featureVotingDataJSON = JSON.stringify(campaignFeaturesResult);
            state.votingEndDate = data?.campaign?.endDate;
            state.campaignData = data?.campaign;
            state.voting = data?.voting;
        }
    },
    SET_VOTING_CURRENT_ERROR(state, value) {
        state.isVotingAPIError = value;
    },
    SET_VOTING_CURRENT_LOADING(state, value) {
        state.isVotingAPILoading = value;
    },
    SET_ON_FEATURE_VOTING(state, { id, priority }) {
        const data = new Map();
        let findItem = 0;
        state.featureVotingData.forEach(item => {
            data.set(item.id, item);
            if (item.priority === priority) {
                findItem = item.id;
            }
        });
        let oldValue = data.get(id).priority;
        if (findItem) {
            data.get(findItem).priority = oldValue
        }
        data.get(id).priority = priority;
        state.featureVotingData = Array.from(data.values());
    },
    SET_VOTING_TOTAL_COUNT(state, count) {
        state.voting.voted = count;
    },
    SET_VOTING_DATA(state, { isSubmited, total, voted }) {
        state.voting = { isSubmited, total, voted };
    },
    SET_AUTO_SAVE_LOADING(state, payload) {
        state.autoSaveLoading = payload;
    },
    SET_SUBMIT_LOADING(state, payload) {
        state.submitLoading = payload;
    },
    SET_STATISTICS_LOADING(state, value) {
        state.isStatisticsLoading = value;
    },
    SET_STATISTICS_DATA(state, data) {
        state.statisticsData = data;
        const featuresStatistics = data || [];
        let featuresStatisticsResult = [];
        featuresStatistics.forEach(item => {
            featuresStatisticsResult.push({
                id: item.featureId,
                total: item.total,
                endReleaseDate: item.endReleaseDate,
                startReleaseDate: item.startReleaseDate,
                translation: {
                    en: {
                        name : item.featureName,
                        description: item.featureDescription
                    },
                    ...item.translation,
                }
            });
        })

        state.statisticsData = featuresStatisticsResult;

    },
    SET_STATISTICS_ERROR(state, value) {
        state.isStatisticsError = value;
    },
    SET_CAMPAIGN_END_DATE(state, value) {
        state.campaignEndDate = value;
    }
}

export const getters = {
    featureVotingData: ({ featureVotingData }) => featureVotingData,
    featureVotingDataJSON: ({ featureVotingDataJSON }) => featureVotingDataJSON,
    isVotingAPIError: ({ isVotingAPIError }) => isVotingAPIError,
    isVotingAPILoading: ({ isVotingAPILoading }) => isVotingAPILoading,
    voting: ({ voting }) => voting,
    votingEndDate: ({ votingEndDate }) => votingEndDate,
    autoSaveLoading: ({ autoSaveLoading }) => autoSaveLoading,
    submitLoading: ({ submitLoading }) => submitLoading,
    isStatisticsLoading: ({ isStatisticsLoading }) => isStatisticsLoading,
    statisticsData: ({ statisticsData }) => statisticsData,
    isStatisticsError: ({ isStatisticsError }) => isStatisticsError,
    campaignEndDate: ({ campaignEndDate }) => campaignEndDate,
}
