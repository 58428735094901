
export default {
    name: "GYMButton",
    emits: ['handleClick'],
    props: {
        type: String,
        text: String,
        customClass: String,
        containerCustomClass: String,
        textCustomClass: String,
        disabled: Boolean,
        icon: String,
        iconInFront: Boolean,
        loading: false
    },
    computed: {
        getClasses() {
            let classes = '';
            if (this.type) classes += 'btn btn-' + this.type;
            if (this.customClass) classes += ' ' + this.customClass;

            return classes;
        }
    }
}
