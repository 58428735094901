import { getStorePollingNames } from "~/core/utilities/store-polling/getStorePollingNames";

export const UNIQUE_IDENTIFIER = 'notifications-unread';
export const POLLING_NAMES = getStorePollingNames(UNIQUE_IDENTIFIER);

export const STATE = {
    ...POLLING_NAMES.state,
    UNREAD_NOTIFICATIONS: 'unreadNotifications',
    UNREAD_NOTIFICATIONS_HANDLERS: 'unreadNotificationsHandlers',
    UNREAD_NOTIFICATIONS_LOADING_PROMISE: 'unreadNotificationsLoadingPromise',
    UNREAD_NOTIFICATIONS_LOADING: 'unreadNotificationsLoading',
    UNREAD_NOTIFICATIONS_LOADING_ERROR: 'unreadNotificationsLoadingError',
    NOTIFICATIONS_READ_BY_OTHER_SESSIONS_LOADING_PROMISE: 'notificationsReadByOtherSessionsLoadingPromise',
    NOTIFICATIONS_READ_BY_OTHER_SESSIONS_LOADING: 'notificationsReadByOtherSessionsLoading',
    NOTIFICATIONS_READ_BY_OTHER_SESSIONS_LOADING_ERROR: 'notificationsReadByOtherSessionsLoadingError',
} as const;

export const ACTIONS = {
    ...POLLING_NAMES.actions,
    FETCH_PROCESS_AND_SAVE_NOTIFICATIONS_READ_BY_OTHER_SESSIONS: 'fetchProcessAndSaveNotificationsReadByOtherSessions',
    FETCH_AND_PROCESS_NOTIFICATIONS_READ_BY_OTHER_SESSIONS: 'fetchAndProcessNotificationsReadByOtherSessions',
    FETCH_NOTIFICATIONS_READ_BY_OTHER_SESSIONS: 'loadNotificationsReadByOtherSessions',
    GET_COUNT_OF_NOTIFICATIONS_READ_BY_OTHER_SESSIONS: 'getCountOfNotificationsReadByOtherSessions',
    FETCH_PROCESS_AND_SAVE_UNREAD_NOTIFICATIONS: 'fetchProcessAndSaveUnreadNotifications',
    FETCH_UNREAD_NOTIFICATIONS: 'fetchUnreadNotifications',
    FETCH_AND_PROCESS_UNREAD_NOTIFICATIONS: 'fetchAndProcessUnreadNotifications',
    PROCESS_UNREAD_NOTIFICATIONS: 'processUnreadNotifications',
    SAVE_UNREAD_NOTIFICATIONS: 'saveUnreadNotifications',
    ADD_UNREAD_NOTIFICATIONS_HANDLER: 'addUnreadNotificationsHandler',
    REMOVE_UNREAD_NOTIFICATIONS_HANDLER: 'removeUnreadNotificationsHandler',
    CLEAR_UNREAD_NOTIFICATIONS_HANDLERS: 'clearUnreadNotificationsHandlers',
    RUN_NOTIFICATION_HANDLERS: 'runNotificationHandlers',
    CLEAR_UNREAD_NOTIFICATIONS_STATE: 'clearUnreadNotificationsState',
} as const;

export const MUTATIONS = {
    ...POLLING_NAMES.mutations,
    SET_UNREAD_NOTIFICATIONS: 'SET_UNREAD_NOTIFICATIONS',
    ADD_UNREAD_NOTIFICATIONS: 'ADD_UNREAD_NOTIFICATIONS',
    SET_NOTIFICATIONS_READ_BY_OTHER_SESSIONS_LOADING_PROMISE: 'SET_NOTIFICATIONS_READ_BY_OTHER_SESSIONS_LOADING_PROMISE',
    SET_NOTIFICATIONS_READ_BY_OTHER_SESSIONS_LOADING: 'SET_NOTIFICATIONS_READ_BY_OTHER_SESSIONS_LOADING',
    SET_NOTIFICATIONS_READ_BY_OTHER_SESSIONS_LOADING_ERROR: 'SET_NOTIFICATIONS_READ_BY_OTHER_SESSIONS_LOADING_ERROR',
    SET_UNREAD_NOTIFICATIONS_LOADING_PROMISE: 'SET_UNREAD_NOTIFICATIONS_LOADING_PROMISE',
    SET_UNREAD_NOTIFICATIONS_LOADING: 'SET_UNREAD_NOTIFICATIONS_LOADING',
    SET_UNREAD_NOTIFICATIONS_LOADING_ERROR: 'SET_UNREAD_NOTIFICATIONS_LOADING_ERROR',
    ADD_UNREAD_NOTIFICATIONS_HANDLER: 'ADD_UNREAD_NOTIFICATIONS_HANDLER',
    REMOVE_UNREAD_NOTIFICATIONS_HANDLER: 'REMOVE_UNREAD_NOTIFICATIONS_HANDLER',
    CLEAR_UNREAD_NOTIFICATIONS_HANDLERS: 'CLEAR_UNREAD_NOTIFICATIONS_HANDLERS',
} as const;

export const GETTERS = {
    ...POLLING_NAMES.getters,
    UNREAD_NOTIFICATIONS: 'unreadNotifications',
    UNREAD_NOTIFICATIONS_LOADING_PROMISE: 'unreadNotificationsLoadingPromise',
    UNREAD_NOTIFICATIONS_LOADING: 'unreadNotificationsLoading',
    UNREAD_NOTIFICATIONS_LOADING_ERROR: 'unreadNotificationsLoadingError',
    NOTIFICATIONS_READ_BY_OTHER_SESSIONS_LOADING_PROMISE: 'notificationsReadByOtherSessionsLoadingPromise',
    NOTIFICATIONS_READ_BY_OTHER_SESSIONS_LOADING: 'notificationsReadByOtherSessionsLoading',
    NOTIFICATIONS_READ_BY_OTHER_SESSIONS_LOADING_ERROR: 'notificationsReadByOtherSessionsLoadingError',
} as const;