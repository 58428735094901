var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('ComposedMenuItemCollapsible',{attrs:{"link-tag":"button","to":"#","title":_vm.$t('contract_addresses'),"prefixIcon":{
        name: 'contract-addresses',
        path: 'activatable',
        activatable: true,
    },"suffixIcon":{
        name: 'right',
        path: 'arrows',
    }},on:{"input":function($event){return _vm.$emit('open')}}})
}
var staticRenderFns = []

export { render, staticRenderFns }