
import {mapGetters} from "vuex";
import GymNewButton from "~/components/utils/form-components/GymNewButton.vue";
import { KYCStatusTypes } from "~/pages/nfts/cards/types/KYCStatusTypes";

export default {
    name: 'CashFTPurchaseCardModal',
    components: {GymNewButton},
    props: {
        popupVisible: {
            type: Boolean,
            default: false
        },
        modalCardType: {
            type: String,
            default: 'mastery',
        },
    },
    data () {
        return {
            KYCStatusTypes,
            windowWidth: window.innerWidth,
        }
    },
    computed: {
        ...mapGetters({
            isKYCDataLoading: "cashFT/cashFT-KYC-verification/isKYCDataLoading",
            status_kyc: "cashFT/cashFT-KYC-verification/status_kyc",
            authUser: 'application/driver/authUser',
        }),
    },
    async created() {
        await this.$store.dispatch('cashFT/cashFT-KYC-verification/getKYCData', {});
    },
    methods: {
        handleModalClose() {
            this.$store.commit('cashFT/cashFT-KYC-verification/SET_CASHFT_SHOW_MODAL_STATUS', false);
            this.$store.commit('cashFT/cashFT-KYC-verification/SET_CASHFT_SHOW_MODAL_CARD_TYPE', null);
        },
        continueToKYC() {
            this.handleModalClose();
            this.$router.push('/KYC-verification');
        }
    }
}
