import ApiService from "~/core/services/api-interaction/ApiService";

export const thirdAnniversaryTypes = {
    MILESTONE_1: 'Milestone 1',
    MILESTONE_2: 'Milestone 2',
    MILESTONE_3: 'Milestone 3',
    MILESTONE_4: 'Milestone 4',
    MILESTONE_5: 'Milestone 5',
    MILESTONE_6: 'Milestone 6',
    MILESTONE_7: 'Milestone 7',
    MILESTONE_8: 'Milestone 8',
}

// States
export const state = () => {
    return {
        pageLoading: true,
        pools: {
            childTurnovers: [],
            milestonesQualifications: {
                [thirdAnniversaryTypes.MILESTONE_1]: 0,
                [thirdAnniversaryTypes.MILESTONE_2]: 0,
                [thirdAnniversaryTypes.MILESTONE_3]: 0,
                [thirdAnniversaryTypes.MILESTONE_4]: 0,
                [thirdAnniversaryTypes.MILESTONE_5]: 0,
                [thirdAnniversaryTypes.MILESTONE_6]: 0,
                [thirdAnniversaryTypes.MILESTONE_7]: 0,
                [thirdAnniversaryTypes.MILESTONE_8]: 0,
            }
        },
        poolRulesData: {
            requiredTurnover: 0,
            usersData: []
        },
        poolHistoryLoading: true,
        poolHistory: null,
    }
}

// Actions
export const actions = {
    async getThirdAnniversaryStatus( { commit } ) {
        try {
            const res = await ApiService.get(`${this.$config.binaryTreeApi}/api/incentives/third-anniversary/status`);
            commit('SET_THIRD_ANNIVERSARY_STATUS', res.data);
        } catch(e) {
            console.log('getThirdAnniversaryStatus ', e);
        } finally {
            commit('SET_PAGE_LOADERS', false);
        }
    },
    openRulesPopup({ commit }, { milestone, poolData }) {
        commit('SET_RULES_POPUP_DATA', { milestone, poolData });
    },
    async getThirdAnniversaryHistory( { commit } ) {
        try {
            const res = await ApiService.get(`${this.$config.binaryTreeApi}/api/incentives/third-anniversary/bonus-history`);
            commit('SET_THIRD_ANNIVERSARY_HISTORY_DATA', res.data?.data || null);
        } catch(e) {
            console.log(e);
        } finally {
            commit('SET_THIRD_ANNIVERSARY_HISTORY_LOADING', false);
        }
    },
}

// Mutations
export const mutations = {
    SET_PAGE_LOADERS: (state, payload) => {
        state.pageLoading = payload;
    },
    SET_THIRD_ANNIVERSARY_STATUS: (state, payload) => {
        state.pools.childTurnovers = payload.childTurnovers || [];
        (payload?.milestonesQualifications || []).forEach(( { milestone, turnover } ) => {
            state.pools.milestonesQualifications[milestone] = turnover || 0;
        });
    },
    SET_RULES_POPUP_DATA: (state, { milestone, poolData }) => {
        state.poolRulesData.usersData = [];
        state.poolRulesData.requiredTurnover = milestone;
        state.poolRulesData.poolData = poolData;
        const descendingSort = (state.pools.childTurnovers || []).sort((a, b) => b.turnover - a.turnover);
        descendingSort.forEach(( user ) => {
            const percentage = (parseFloat(user.turnover) * 100) / state.poolRulesData.requiredTurnover;
            const payload = {
                generatedTurnover: user.turnover,
                qualifiedTurnover: user.turnover >= (milestone / 2) ? (milestone / 2) : user.turnover,
                username: user.username,
                walletAddress: user.wallet,
                percentage: percentage < 50 ? percentage : 50,
            };
            state.poolRulesData.usersData.push(payload);
        });
    },
    SET_THIRD_ANNIVERSARY_HISTORY_LOADING(state, payload) {
        state.poolHistoryLoading = payload;
    },
    SET_THIRD_ANNIVERSARY_HISTORY_DATA(state, payload) {
        state.poolHistory = payload ? [payload] : [];
    },
}

// Getters
export const getters = {
    pageLoading: ({ pageLoading }) => pageLoading,
    pools: ({ pools }) => pools,
    poolRulesData: ({ poolRulesData }) => poolRulesData,
    poolHistory: ({ poolHistory }) => poolHistory,
    poolHistoryLoading: ({ poolHistoryLoading }) => poolHistoryLoading,
}
