import { render, staticRenderFns } from "./DecisionButtons.vue?vue&type=template&id=d27e30a6&scoped=true"
import script from "./DecisionButtons.vue?vue&type=script&setup=true&lang=ts"
export * from "./DecisionButtons.vue?vue&type=script&setup=true&lang=ts"
import style0 from "./DecisionButtons.vue?vue&type=style&index=0&id=d27e30a6&prod&scoped=true&lang=scss"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "d27e30a6",
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {BaseImageSvgThemedIcon: require('/root/dashboard/core/components/base/image/SvgThemedIcon.vue').default,BaseButtonSimple: require('/root/dashboard/core/components/base/button/Simple.vue').default})
