export const STATE = {
} as const;

export const ACTIONS = {
    UPDATE_USER_AUTH_DATA: "updateUserAuthData",
    LOG_OUT: "logOut",
} as const;

export const MUTATIONS = {
} as const;

export const GETTERS = {
    CURRENT_USER: "CURRENT_USER",
} as const;