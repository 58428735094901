import { GETTERS, STATE } from "./names";
import type { Notification } from "./types/Notification";
import type { State } from "./types/State";
import { GETTERS as UNREAD_GETTERS } from './unread/names';
import { GETTERS as HISTORY_GETTERS } from './history/names';

export default {
    [GETTERS.ALL_NOTIFICATIONS]: (_state: State, _getters, _rootState, rootGetters): Notification[] => {
        return [
            ...rootGetters[`affility/user/notifications/unread/${UNREAD_GETTERS.UNREAD_NOTIFICATIONS}`],
            ...rootGetters[`affility/user/notifications/history/${HISTORY_GETTERS.NOTIFICATION_HISTORY}`],
        ];
    },
}