export enum MYSTERY_BOX_PURCHASE_STEPS {
    LOADING,
    SELECT_PRODUCT_OWNER,
    SELECT_PAYMENT_METHOD,
    SELECT_BALANCE,
    PURCHASE_SUMMARY,
    INSUFFICIENT_FUNDS,
    CONNECT_WALLET,
    PAYMENT_SUCCESS,
    PRIZE_WON,
    PRIZE_LOST,
    NO_USER,
    ERROR,
    TRANSACTION_REJECTED,
    RESULT_CHECKING_ERROR,
}
