import {diagLog, PARCEL_LAND_TYPES, PARCEL_TYPES} from "../utils/Constants";
import {MetaParcel} from "~/core/services/map/MetaParcel";
import {MetaReservedArea} from "~/core/services/map/MetaReservedArea";

const PARCEL_SIZE_IN_PIXELS = 1;
export class MetaMatrixRow {
    constructor() {
        this.firstItem = null;
        this.lastItem = null;
        this.row = {};
        this.length = 0;
    }

    addBoundaryParcel(metaParcel) {
        let lastParcel = this.length > 0 ? this.last() : null;
        if(lastParcel && lastParcel.lt === PARCEL_LAND_TYPES.NEAR_OCEAN && metaParcel.lt === PARCEL_LAND_TYPES.NEAR_OCEAN) {
            this.closeBoundary(lastParcel.x, metaParcel.x, metaParcel.y);
        }
        if(!this.firstItem) {
            this.firstItem = metaParcel;
        }
        this.lastItem = metaParcel;
        this.row[metaParcel.x] = metaParcel;
        this.length++;
    }

    closeBoundary(start, end, y) {
        for(let i = start + 1; i < end; ++i) {
            let parcel = new MetaParcel({x: i, y: y, pt: PARCEL_TYPES.STANDARD, lt: PARCEL_LAND_TYPES.INLAND, up: false});
            this.row[parcel.x] = parcel;
            this.length++;
        }
    }

    itemAt(indx) {
        return this.row[indx];
    }

    first() {
        return this.firstItem;
    }

    last() {
        return this.lastItem;
    }

    allItems() {
        return Object.values(this.row);
    }
}

export class MetaMatrix {
    constructor(landBoundaries) {
        this.landBoundaries = landBoundaries;
        this.rows = [];
        this.reservedAreas = {};
        this.matrixWidth = 0;
    }

    createMap() {
        let matrixMinX = Infinity, matrixMaxX = 0;
        let currentRow = new MetaMatrixRow();
        let lastRowNum = null;
        for(let i = 0; i < this.landBoundaries.length; ++i) {
            let metaParcelJson = this.landBoundaries[i];
            let metaParcel = new MetaParcel(metaParcelJson);
            if(lastRowNum !== null && lastRowNum !== metaParcel.y) {
                this.rows.push(currentRow);
                currentRow = new MetaMatrixRow();
                currentRow.addBoundaryParcel(metaParcel);
            } else {
                currentRow.addBoundaryParcel(metaParcel);
            }
            lastRowNum = metaParcel.y;
            if(metaParcel.x < matrixMinX) {
                matrixMinX = metaParcel.x;
            }
            if(metaParcel.x > matrixMaxX) {
                matrixMaxX = metaParcel.x;
            }
        }
        this.matrixWidth = matrixMaxX - matrixMinX + 1;
        this.rows.push(currentRow);
    }

    parcelAt(row, col) {
        let p = null;
        if(row < this.rows.length) {
            let r = this.rows[row];
            if(r) {
                p = r.itemAt(col);
            }
        }
        return p;
    }

    addReservedArea(area) {
        this.reservedAreas[area.id] = new MetaReservedArea(area)
        return this.reservedAreas[area.id];
    }

    clearReservedAreas() {
        for(const area of Object.values(this.reservedAreas)) {
            area.clear();
        }
        this.reservedAreas = {};
    }

    getReservedArea(reservedAreaId) {
        return this.reservedAreas[reservedAreaId];
    }
}

export class MetaParcelsRegistry {
    // Private constructor
    constructor() {
        this.width = 0;
        this.height = 0;
        this.parcels = {};
        this.isInitialized = false;
        this.imageParcels = [];
        this.userOwnedParcels = {};
        this.bundlesHighLevelOverview = null;
    }

    static sharedInstance() {
        if (MetaParcelsRegistry.instance === null) {
            MetaParcelsRegistry.instance = new MetaParcelsRegistry();
        }
        return MetaParcelsRegistry.instance;
    }

    initialize(landBoundaries) {
        this.matrix = new MetaMatrix(landBoundaries);
        this.matrix.createMap();
        this.bundlesHighLevelOverview = document.createElement('canvas');
        this.bundlesHighLevelOverview.width = this.matrix.matrixWidth;
        this.bundlesHighLevelOverview.height = this.matrix.rows.length;
    }

    setupReservedAreas(reservedAreas, areaData) {
        if(this.matrix && reservedAreas && reservedAreas.data) {
            for (const area of reservedAreas.data) {
                this.matrix.addReservedArea(area);
            }

            for (const p of areaData.data) {
                const parcel = this.matrix.parcelAt(p.y, p.x);
                if (parcel) {
                    parcel.pt = PARCEL_TYPES.RESERVED;
                    const reservedArea = this.matrix.getReservedArea(p.reserved_area_id);
                    reservedArea.addParcel(parcel);
                    parcel.reservedArea = reservedArea;
                    parcel.changeColor();
                }                
            }
        }
    }

    setupBusinessParcels(businessParcels) {
        if(businessParcels && businessParcels.data) {
            for (let p of businessParcels.data) {
                let parcel = this.matrix.parcelAt(p.y, p.x);

                if (parcel) {
                    parcel.pt = PARCEL_TYPES.BUSINESS;
                    parcel.changeColor();

                }
            }
        }
    }

    setupImageParcels(imageParcels) {
        if(imageParcels && imageParcels.data) {
            for (let img of imageParcels.data) {
                for (let i = img.x; i < img.x + img.width; i++) {
                    for (let j = img.y; j < img.y + img.height; j++) {
                        let parcel = this.matrix.parcelAt(j, i);
                        if (parcel) {
                            parcel.image = img;
                        }
                    }
                }
                img.imageElement = new Image();
                img.imageElement.loading = 'lazy';
                img.imageElement.src = img.image_path.replace('.jpg', '_thumbnail.jpg');
                this.imageParcels.push(img);
            }
        }
    }

    clearReservedAreas() {
        if(this.matrix) {
            this.matrix.clearReservedAreas();
        }
    }

    clearUserOwnedParcels() {
        for(const parcel of Object.values(this.userOwnedParcels)) {
            parcel.selfOwned = false;
            parcel.id = null;
            parcel.changeColor();
        }
        this.userOwnedParcels = {};
    }

    registerUserOwnedParcel(parcel) {
        this.userOwnedParcels[`${parcel.x}_${parcel.y}`] = parcel;
    }

    setupOwnedParcels(ownedParcels) {
        if (ownedParcels && ownedParcels.data) {
            const mapOverviewContext = this.bundlesHighLevelOverview.getContext('2d');
            mapOverviewContext.save();
            mapOverviewContext.beginPath();
            for(const parcelData of ownedParcels.data) {
                const parcel = this.parcelAt(parcelData.y, parcelData.x);
                if(!parcel) {
                    console.error(`Unknown error at MetaParcelsRegistry setupOwnedParcels::Parcel not found: ${parcelData.y}:${parcelData.x}`);
                }
                parcel.ownerId = parcelData.owner_id;
                parcel.ownerWalletAddress = parcelData.wallet_address;
                parcel.changeColor();
                // mapOverviewContext.rect(parcel.x, parcel.y, PARCEL_SIZE_IN_PIXELS, PARCEL_SIZE_IN_PIXELS);
            }
            mapOverviewContext.closePath();
            mapOverviewContext.fillStyle = 'rgb(70, 163, 184)';
            mapOverviewContext.fill();
            mapOverviewContext.restore();
        }
    }

    parcelAt(row, col) {
        if(this.matrix) {
            return this.matrix.parcelAt(row, col);
        }
        return null;
    }

    destroy() {
        this.width = 0;
        this.height = 0;
        this.parcels = {};
        this.isInitialized = false;
        this.imageParcels = [];
        this.userOwnedParcels = {};
        if(this.bundlesHighLevelOverview) {
            this.bundlesHighLevelOverview.remove();
        }
        this.bundlesHighLevelOverview = null;
        this.matrix = null;
    }
}

MetaParcelsRegistry.instance = null;
