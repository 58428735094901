import { GalleryManager } from "~/core/services/gallery/GalleryManager";
import { ACTIONS } from "./names";

export default {
    [ACTIONS.APPROVE_IMAGE](_context, imageId: string | number) {
        GalleryManager.sharedInstance().gallery.approveImage(imageId);
    },
    [ACTIONS.REJECT_IMAGE](_context, imageId: string | number) {
        GalleryManager.sharedInstance().gallery.rejectImage(imageId);
    },
};