
import VueCountdown from '@chenfengyuan/vue-countdown';
import {DEFAULT_LOGO} from "@/core/services/utils/ImageConstants";

export default {
    name: "countdown",
    components: {
        VueCountdown
    },
    emits: ['end'],
    props: ['countdown'],
    data() {
        return {
            DEFAULT_LOGO
        }
    }
}

