import { MaintenanceService } from "~/core/services/MaintenanceService";

const state = () => {
    return {
        upcomingMaintenances: null,
    };
};

// Actions
const actions = {
    async loadUpcomingMaintenances({commit}, saveToState = true) {
        const maintenances = await MaintenanceService.loadMaintenanceConfigs();
        if (saveToState) {
            await commit("SET_UPCOMING_MAINTENANCE_DATA", maintenances);
        }
        return maintenances;
    },
};

// Mutations
const mutations = {
    SET_UPCOMING_MAINTENANCE_DATA(state, payload) {
        state.upcomingMaintenances = payload;
    },
};

// Getters
const getters = {
    upcomingMaintenances: ({upcomingMaintenances}) => upcomingMaintenances,
    nearestMaintenance: ({upcomingMaintenances}) => {
        if(!upcomingMaintenances?.length) return null;
        for(const maintenance of upcomingMaintenances) {
            if(maintenance.end >= new Date()) {
                return maintenance;
            }
        }
        return null;
    },
};

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations
}
