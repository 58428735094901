
export default {
    name: "InfoTooltip",
    props: {
        text: {
            type: String,
            default: ''
        },
        placement: {
            type: String,
            default: 'top'
        },
        customClass: {
            type: String,
            default: ''
        },
        isHtml: {
            type: Boolean,
            default: false
        },
        icon: {
            type: String,
            default: require('~/assets/images/icons/info-icon-small.svg')
        }
    }
}
