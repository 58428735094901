export default async (ctx, inject) => {
    const turnstileURL = 'https://challenges.cloudflare.com/turnstile/v0/api.js?render=explicit';
    const turnstileScript = document.createElement('script');
    turnstileScript.src = turnstileURL;
    turnstileScript.async = true;
    turnstileScript.defer = true;

    const turnstileLoadPromise = new Promise((resolve, reject) => {
        turnstileScript.onload = resolve;
        turnstileScript.onerror = reject;
    });

    document.head.appendChild(turnstileScript);

    inject('turnstileLoadPromise', turnstileLoadPromise);

    await turnstileLoadPromise;

    inject('cfTurnstile', window.turnstile);
};
