export const STATE = {
} as const;

export const ACTIONS = {
    CLEAR_UNILEVEL_STATE: 'clearUnilevelState',
} as const; 

export const MUTATIONS = {
} as const;

export const GETTERS = {
} as const;