import { ACTIONS } from "./names";
import { PopupHelper } from "~/core/helpers/PopupHelper";
import type { ConfirmModalParams, ErrorModalParams, InfoModalParams } from "./types";
import type { SuccessModalParams } from "./modals/success/types";
import { ACTIONS as SUCCESS_MODAL_ACTIONS } from "./modals/success/names";
import { time } from "vue-gtag";

export default {
    [ACTIONS.SHOW_INFO_MODAL](_ctx, payload: InfoModalParams) {
        return PopupHelper.showInfoAlert(
            payload.title,
            payload.text,
            payload.isHtml,
            payload.confirmButtonText,
            payload.showCancelButton,
            payload.customClass
        );
    },
    [ACTIONS.SHOW_ERROR_MODAL](_ctx, payload: ErrorModalParams) {
        return PopupHelper.showErrorAlert(
            payload.title,
            payload.text,
            payload.actionHandler,
        );
    },
    [ACTIONS.SHOW_CONFIRM_MODAL](_ctx, payload: ConfirmModalParams) {
        return PopupHelper.confirm(
            payload.title,
            payload.text,
            payload.confirmButtonText,
            payload.cancelButtonText,
            payload.customClass,
        );
    },
    [ACTIONS.SHOW_SUCCESS_MODAL]({ dispatch }, payload: Partial<SuccessModalParams> = {}) {
        dispatch(`modals/success/${SUCCESS_MODAL_ACTIONS.SHOW_SUCCESS_MODAL}`, {
            title: payload?.title || this.app.i18n.t('success'),
            desc: payload?.desc,
            buttonText: payload?.buttonText || this.app.i18n.t('ok'),
            buttonVisible: payload?.buttonVisible ?? true,
            closeByClickOutside: payload?.closeByClickOutside ?? true,
            closeByEsc: payload?.closeByEsc ?? true,
            timeout: payload?.timeout,
        });
        // returns a promise that resolves when the modal is closed
        return dispatch(`modals/success/${SUCCESS_MODAL_ACTIONS.AWAIT_FOR_CLOSED}`);
    },
};