import ApiService from "./api-interaction/ApiService";
import { AxiosResponse } from "axios";
import { IMaintenance } from "../models/maintenance/IMaintenance";
import { Maintenance } from "../models/maintenance/Maintenance";
import { MaintenanceServiceException } from "../exceptions/maintenance/MaintenanceServiceException";

export class MaintenanceService {

    static async loadMaintenanceConfigs(): Promise<Maintenance[]> {
        try {
            const upcomingMaintenancesResponse: AxiosResponse = await ApiService.query('/organization/maintenances', {});
            if(upcomingMaintenancesResponse.data?.maintenances) {
                const upcomingMaintenances: IMaintenance[] = upcomingMaintenancesResponse.data?.maintenances;
                return MaintenanceService.transformDataToMaintenanceConfigs(upcomingMaintenances);
            } else {
                throw new MaintenanceServiceException('Invalid response for maintenances');
            }
        } catch (e) {
            if(e instanceof MaintenanceServiceException) {
                throw e;
            }
            throw new MaintenanceServiceException('Something went wrong while loading maintenance configs.');
        }
    }

    static transformDataToMaintenanceConfigs(data: IMaintenance[]): Maintenance[] {
        return data.map((maintenance: IMaintenance) => {
            return Maintenance.fromJson(maintenance);
        });
    }
}
