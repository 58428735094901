import { render, staticRenderFns } from "./TwoFactorAuthenticationReminder.vue?vue&type=template&id=734da0ad&scoped=true"
import script from "./TwoFactorAuthenticationReminder.vue?vue&type=script&lang=ts&setup=true"
export * from "./TwoFactorAuthenticationReminder.vue?vue&type=script&lang=ts&setup=true"
import style0 from "./TwoFactorAuthenticationReminder.vue?vue&type=style&index=0&id=734da0ad&prod&scoped=true&lang=scss"
import style1 from "./TwoFactorAuthenticationReminder.vue?vue&type=style&index=1&id=734da0ad&prod&lang=scss"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "734da0ad",
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {BaseButtonClose: require('/root/dashboard/core/components/base/button/Close.vue').default,BaseButtonSimple: require('/root/dashboard/core/components/base/button/Simple.vue').default,ComposedPopupNoCloseButtonAffility: require('/root/dashboard/core/components/composed/popup/NoCloseButtonAffility.vue').default})
