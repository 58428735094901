import { STATE } from "./names";

export default () => {
    return {
        [STATE.IS_TOAST_OPEN]: false,
        [STATE.TOAST_TYPE]: null,
        [STATE.TOAST_TITLE]: null,
        [STATE.TOAST_DESCRIPTION]: null,
        [STATE.TOAST_DURATION]: 6000,
    };
};
