import { render, staticRenderFns } from "./Simple.vue?vue&type=template&id=464be8fc&scoped=true"
import script from "./Simple.vue?vue&type=script&setup=true&lang=ts"
export * from "./Simple.vue?vue&type=script&setup=true&lang=ts"
import style0 from "./Simple.vue?vue&type=style&index=0&id=464be8fc&prod&scoped=true&lang=scss"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "464be8fc",
  null
  
)

export default component.exports