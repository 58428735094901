import { MetaWorldManager } from "~/core/services/map/MetaWorldManager";
import WalletAsset, { WalletCurrencyName } from "./WalletAsset";
import { InventoryContainer } from "../services/WalletService";

export class UtilityAsset extends WalletAsset {
    contractAddress: string = '';

    constructor (
        value: number,
        rate: number,
        isInsufficient: boolean,
        metaWorldManager: MetaWorldManager,
        private inventoryContainer: InventoryContainer,
    ) {
        super(
            null,
            "Utility",
            "GYMNET (Utility)" as WalletCurrencyName,
            require('~/assets/images/gymstreet/currencies/utility-balance.svg'),
            value,
            rate,
            isInsufficient,
            metaWorldManager.metaverseId,
            metaWorldManager,
            metaWorldManager.contracts.GymNetwork,
        );

        this.contractAddress = metaWorldManager.contracts.GymNetwork.options.address;
    }

    public get value(): number {
        return this.inventoryContainer?.minerRewardsData
            ?.minerRewards
            ?.minerRewardsUtilityInfo
            ?.realAvailable ?? 0;
    }

    public get usd(): number {
        return this.rate * this.value;
    }

}