import { ACTIONS, GETTERS, MUTATIONS } from "./names";
import type { Route } from 'vue-router';
import type { SidebarItem } from "./types";

export default {
    [ACTIONS.OPEN_SUBMENU]({ commit }, menuItem: SidebarItem) {
        commit(MUTATIONS.SET_OPEN_SUB_MENU, menuItem);
    },
    [ACTIONS.CLOSE_SUBMENU]({ commit }) {
        commit(MUTATIONS.SET_OPEN_SUB_MENU, null);
    },
    [ACTIONS.OPEN_MOBILE_SIDEBAR]({ commit }) {
        commit(MUTATIONS.SET_MOBILE_SIDEBAR_OPEN, true);
    },
    [ACTIONS.CLOSE_MOBILE_SIDEBAR]({ commit }) {
        commit(MUTATIONS.SET_MOBILE_SIDEBAR_OPEN, false);
    },
    [ACTIONS.CLOSE_MOBILE_SIDEBAR_AND_OPEN_CONTRACT_ADDRESSES]({ dispatch }) {
        dispatch(ACTIONS.CLOSE_MOBILE_SIDEBAR);
        dispatch(ACTIONS.OPEN_MOBILE_CONTRACT_ADDRESSES_SIDEBAR);
    },
    [ACTIONS.CLOSE_MOBILE_SIDEBAR_AND_OPEN_LANGUAGES]({ dispatch }) {
        dispatch(ACTIONS.CLOSE_MOBILE_SIDEBAR);
        dispatch(ACTIONS.OPEN_MOBILE_LANGUAGES_SIDEBAR);
    },
    [ACTIONS.OPEN_MOBILE_PROFILE_SIDEBAR]({ commit }) {
        commit(MUTATIONS.SET_MOBILE_PROFILE_SIDEBAR_OPEN, true);
    },
    [ACTIONS.CLOSE_MOBILE_PROFILE_SIDEBAR]({ commit }) {
        commit(MUTATIONS.SET_MOBILE_PROFILE_SIDEBAR_OPEN, false);
    },
    [ACTIONS.CLOSE_PROFILE_AND_OPEN_CONTRACT_ADDRESSES_SIDEBAR]({ dispatch }) {
        dispatch(ACTIONS.CLOSE_MOBILE_PROFILE_SIDEBAR);
        dispatch(ACTIONS.OPEN_MOBILE_CONTRACT_ADDRESSES_SIDEBAR);
    },
    [ACTIONS.CLOSE_PROFILE_AND_OPEN_LANGUAGES_SIDEBAR]({ dispatch }) {
        dispatch(ACTIONS.CLOSE_MOBILE_PROFILE_SIDEBAR);
        dispatch(ACTIONS.OPEN_MOBILE_LANGUAGES_SIDEBAR);
    },
    [ACTIONS.OPEN_MOBILE_CONTRACT_ADDRESSES_SIDEBAR]({ commit }) {
        commit(MUTATIONS.SET_MOBILE_CONTRACT_ADDRESSES_SIDEBAR_OPEN, true);
    },
    [ACTIONS.CLOSE_MOBILE_CONTRACT_ADDRESSES_SIDEBAR]({ commit }) {
        commit(MUTATIONS.SET_MOBILE_CONTRACT_ADDRESSES_SIDEBAR_OPEN, false);
    },
    [ACTIONS.CLOSE_CONTRACT_ADDRESSES_AND_OPEN_PROFILE_SIDEBAR]({ dispatch }) {
        dispatch(ACTIONS.CLOSE_MOBILE_CONTRACT_ADDRESSES_SIDEBAR);
        dispatch(ACTIONS.OPEN_MOBILE_PROFILE_SIDEBAR);
    },
    [ACTIONS.CLOSE_CONTRACT_ADDRESSES_AND_OPEN_GENERAL_SIDEBAR]({ dispatch }) {
        dispatch(ACTIONS.CLOSE_MOBILE_CONTRACT_ADDRESSES_SIDEBAR);
        dispatch(ACTIONS.OPEN_MOBILE_SIDEBAR);
    },
    [ACTIONS.OPEN_MOBILE_LANGUAGES_SIDEBAR]({ commit }) {
        commit(MUTATIONS.SET_MOBILE_LANGUAGES_SIDEBAR_OPEN, true);
    },
    [ACTIONS.CLOSE_MOBILE_LANGUAGES_SIDEBAR]({ commit }) {
        commit(MUTATIONS.SET_MOBILE_LANGUAGES_SIDEBAR_OPEN, false);
    },
    [ACTIONS.CLOSE_LANGUAGES_AND_OPEN_PROFILE_SIDEBAR]({ dispatch }) {
        dispatch(ACTIONS.CLOSE_MOBILE_LANGUAGES_SIDEBAR);
        dispatch(ACTIONS.OPEN_MOBILE_PROFILE_SIDEBAR);
    },
    [ACTIONS.CLOSE_LANGUAGES_AND_OPEN_GENERAL_SIDEBAR]({ dispatch }) {
        dispatch(ACTIONS.CLOSE_MOBILE_LANGUAGES_SIDEBAR);
        dispatch(ACTIONS.OPEN_MOBILE_SIDEBAR);
    },
    [ACTIONS.REFRESH_ACTIVE_ITEM]({ getters, commit }, path: Route) {
        const findItem = (items: SidebarItem[], menuObjectsPath: SidebarItem[] = []): SidebarItem[] => {
            for (const item of items) {
                if (item.to?.match(path.matched[0].regex)) {
                    menuObjectsPath.push(item);
                    return menuObjectsPath;
                }
                if (item.children) {
                    const childrenPath = findItem(typeof item.children === 'function' ? item.children() : item.children);
                    if (childrenPath.length) {
                        menuObjectsPath.push(item);
                        menuObjectsPath.push(...childrenPath);
                        return menuObjectsPath;
                    }
                }
            }
            return menuObjectsPath;
        };
        commit(MUTATIONS.SET_ACTIVE_ITEM_PATH, findItem(getters[GETTERS.CONFIG]));
    },
};