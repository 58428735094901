import { MUTATIONS, STATE } from "./names";
import type { SidebarItem } from "./types";

export default {
    [MUTATIONS.SET_MOBILE_SIDEBAR_OPEN](state, value: boolean) {
        state[STATE.MOBILE_SIDEBAR_OPEN] = value;
    },
    [MUTATIONS.SET_OPEN_SUB_MENU](state, item: SidebarItem) {
        state[STATE.OPENED_SUBMENU] = item;
    },
    [MUTATIONS.SET_ACTIVE_ITEM_PATH](state, item: SidebarItem[]) {
        state[STATE.ACTIVE_ITEM_PATH] = item;
    },
    [MUTATIONS.SET_MOBILE_PROFILE_SIDEBAR_OPEN](state, value: boolean) {
        state[STATE.MOBILE_PROFILE_SIDEBAR_OPEN] = value;
    },
    [MUTATIONS.SET_MOBILE_CONTRACT_ADDRESSES_SIDEBAR_OPEN](state, value: boolean) {
        state[STATE.MOBILE_CONTRACT_ADDRESSES_SIDEBAR_OPEN] = value;
    },
    [MUTATIONS.SET_MOBILE_LANGUAGES_SIDEBAR_OPEN](state, value: boolean) {
        state[STATE.MOBILE_LANGUAGES_SIDEBAR_OPEN] = value;
    },
}