import { GETTERS } from "./names";
import { GETTERS as GETTERS_WEB3_CONNECTION } from "../connection/names";
import { MetaWorldManager } from "~/core/services/map/MetaWorldManager";
import { TranslationHelper } from "~/core/services/TranslationHelper";

export default {
    [GETTERS.MAIN_CONTRACT_ADDRESSES](_state, _getters, _rootState, rootGetters) {
        const mwm = MetaWorldManager.sharedInstance();
        const areContractsClickable = !!rootGetters[`affility/web3/connection/${GETTERS_WEB3_CONNECTION.PROVIDER}`];
        const usdtAddress = mwm.getWeb3Contract('USDT').options.address;
        const gymnetAddress = mwm.getWeb3Contract('GymNetwork').options.address;
        const bitopexAddress = mwm.getWeb3Contract('BitopexToken').options.address;

        return [
            {
                icon: {
                    name: 'gymnet',
                    path: 'currency',
                },
                title: areContractsClickable ? 
                    TranslationHelper.translate('Add TOKEN to wallet', {token: 'GYMNET'}) :
                    'GYMNET',
                address: gymnetAddress,
                clickable: areContractsClickable,
                walletInfo: {
                    type: 'ERC20',
                    options: {
                        address: gymnetAddress,
                        symbol: 'GYMNET',
                        decimals: 18,
                        image: 'https://images.gymstreet.io/gymnet.png',
                    }
                },
            },
            {
                icon: {
                    name: 'usdt',
                    path: 'currency',
                },
                title: areContractsClickable ?
                    TranslationHelper.translate('Add TOKEN to wallet', {token: 'USDT'}) :
                    'USDT',
                address: usdtAddress,
                clickable: areContractsClickable,
                walletInfo: {
                    type: 'ERC20',
                    options: {
                        address: usdtAddress,
                        symbol: 'USDT',
                        decimals: 18,
                        image: 'https://images.gymstreet.io/usdt.png',
                    }
                },
            },
            {
                icon: {
                    name: 'bitopex',
                    path: 'currency',
                },
                title: areContractsClickable ?
                    TranslationHelper.translate('Add TOKEN to wallet', {token: 'BITOPEX'}) :
                    'BITOPEX',
                address: bitopexAddress,
                clickable: areContractsClickable,
                walletInfo: {
                    type: 'ERC20',
                    options: {
                        address: bitopexAddress,
                        symbol: 'BPX',
                        decimals: 18,
                        image: 'https://images.gymstreet.io/btx.png',
                    }
                },
            },
        ];
    }
}