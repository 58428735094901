import {GlobalMakerService} from "~/core/services/GlobalMakerService";

export default (context, inject) => {
    GlobalMakerService.init({
        store: context.store,
        cookies: context.$cookies,
        gtag: context.$gtag,
        auth: context.$auth,
        broadcast: context.$broadcast,
        i18n: context.i18n,
        metaverseId: context?.$metaverseId?.(),
        config: context.$config,
        router: context.app.router,
    });
};
