import moment from 'moment'

import 'moment/locale/es'
import 'moment/locale/ja'
import 'moment/locale/ko'
import 'moment/locale/pt'
import 'moment/locale/ru'
import 'moment/locale/th'
import 'moment/locale/vi'
import 'moment/locale/zh-cn'

moment.locale('en')

export default (ctx, inject) => {
  ctx.$moment = moment
  inject('moment', moment)
}
