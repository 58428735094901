import Vue from 'vue'
import Router from 'vue-router'
import { normalizeURL, decode } from 'ufo'
import { interopDefault } from './utils'
import scrollBehavior from './router.scrollBehavior.js'

const _6f7d4b4e = () => interopDefault(import('../pages/account/index.vue' /* webpackChunkName: "pages/account/index" */))
const _e683c516 = () => interopDefault(import('../pages/dashboard/index.vue' /* webpackChunkName: "pages/dashboard/index" */))
const _5d0167b0 = () => interopDefault(import('../pages/KYC-verification/index.vue' /* webpackChunkName: "pages/KYC-verification/index" */))
const _70a7ffa6 = () => interopDefault(import('../pages/metaverse-switch/index.vue' /* webpackChunkName: "pages/metaverse-switch/index" */))
const _9edacc82 = () => interopDefault(import('../pages/my-team/index.vue' /* webpackChunkName: "pages/my-team/index" */))
const _3970e3c4 = () => interopDefault(import('../pages/partnerships/index.vue' /* webpackChunkName: "pages/partnerships/index" */))
const _8bba9e1c = () => interopDefault(import('../pages/password-protect/index.vue' /* webpackChunkName: "pages/password-protect/index" */))
const _ffe105c0 = () => interopDefault(import('../pages/suspicious-activity/index.vue' /* webpackChunkName: "pages/suspicious-activity/index" */))
const _7719542e = () => interopDefault(import('../pages/auth/cashft/index.vue' /* webpackChunkName: "pages/auth/cashft/index" */))
const _66aa8972 = () => interopDefault(import('../pages/auth/email-verify/index.vue' /* webpackChunkName: "pages/auth/email-verify/index" */))
const _dddc5652 = () => interopDefault(import('../pages/auth/login/index.vue' /* webpackChunkName: "pages/auth/login/index" */))
const _5e4013a8 = () => interopDefault(import('../pages/auth/one-time-token/index.vue' /* webpackChunkName: "pages/auth/one-time-token/index" */))
const _2eae59f5 = () => interopDefault(import('../pages/auth/password-recovery/index.vue' /* webpackChunkName: "pages/auth/password-recovery/index" */))
const _e6bae072 = () => interopDefault(import('../pages/finances/commissions/index.vue' /* webpackChunkName: "pages/finances/commissions/index" */))
const _c8dda396 = () => interopDefault(import('../pages/finances/miner-rewards/index.vue' /* webpackChunkName: "pages/finances/miner-rewards/index" */))
const _472ba620 = () => interopDefault(import('../pages/finances/single-pool/index.vue' /* webpackChunkName: "pages/finances/single-pool/index" */))
const _b33c78e0 = () => interopDefault(import('../pages/finances/wallet/index.vue' /* webpackChunkName: "pages/finances/wallet/index" */))
const _1d77c1d1 = () => interopDefault(import('../pages/metaworld/gallery/index.vue' /* webpackChunkName: "pages/metaworld/gallery/index" */))
const _39defd5b = () => interopDefault(import('../pages/metaworld/map/index.vue' /* webpackChunkName: "pages/metaworld/map/index" */))
const _3d634ee0 = () => interopDefault(import('../pages/nfts/cards/index.vue' /* webpackChunkName: "pages/nfts/cards/index" */))
const _6c5be316 = () => interopDefault(import('../pages/nfts/my-nfts/index.vue' /* webpackChunkName: "pages/nfts/my-nfts/index" */))
const _b090bfc6 = () => interopDefault(import('../pages/nfts/products/index.vue' /* webpackChunkName: "pages/nfts/products/index" */))
const _5d9c6259 = () => interopDefault(import('../pages/partner-area/career-status/index.vue' /* webpackChunkName: "pages/partner-area/career-status/index" */))
const _6d6e1d68 = () => interopDefault(import('../pages/partner-area/global-line-bonus/index.vue' /* webpackChunkName: "pages/partner-area/global-line-bonus/index" */))
const _47e46363 = () => interopDefault(import('../pages/partner-area/level-pool/index.vue' /* webpackChunkName: "pages/partner-area/level-pool/index" */))
const _37bb2e10 = () => interopDefault(import('../pages/partner-area/turnover-pool/index.vue' /* webpackChunkName: "pages/partner-area/turnover-pool/index" */))
const _b2d572f2 = () => interopDefault(import('../pages/promos/mystery-box/index.vue' /* webpackChunkName: "pages/promos/mystery-box/index" */))
const _3cc359aa = () => interopDefault(import('../pages/partner-area/career-status/feature-voting/index.vue' /* webpackChunkName: "pages/partner-area/career-status/feature-voting/index" */))
const _6644e5a2 = () => interopDefault(import('../pages/partner-area/career-status/statistics/index.vue' /* webpackChunkName: "pages/partner-area/career-status/statistics/index" */))
const _0c690c74 = () => interopDefault(import('../pages/partner-area/incentives/cash-promo/index.vue' /* webpackChunkName: "pages/partner-area/incentives/cash-promo/index" */))
const _05fec345 = () => interopDefault(import('../pages/partner-area/incentives/direct-sales-incentive/index.vue' /* webpackChunkName: "pages/partner-area/incentives/direct-sales-incentive/index" */))
const _60582730 = () => interopDefault(import('../pages/partner-area/incentives/fast-start/index.vue' /* webpackChunkName: "pages/partner-area/incentives/fast-start/index" */))
const _31e5e27d = () => interopDefault(import('../pages/partner-area/incentives/global-pool/index.vue' /* webpackChunkName: "pages/partner-area/incentives/global-pool/index" */))
const _525f67b4 = () => interopDefault(import('../pages/partner-area/incentives/product-autoship-incentive/index.vue' /* webpackChunkName: "pages/partner-area/incentives/product-autoship-incentive/index" */))
const _e54474ac = () => interopDefault(import('../pages/partner-area/incentives/spring-incentive/index.vue' /* webpackChunkName: "pages/partner-area/incentives/spring-incentive/index" */))
const _305756a3 = () => interopDefault(import('../pages/partner-area/incentives/third-anniversary-promo/index.vue' /* webpackChunkName: "pages/partner-area/incentives/third-anniversary-promo/index" */))
const _244b3a37 = () => interopDefault(import('../pages/partner-area/incentives/travel-incentive/index.vue' /* webpackChunkName: "pages/partner-area/incentives/travel-incentive/index" */))
const _1d2e5124 = () => interopDefault(import('../pages/partner-area/incentives/turnover-incentive/index.vue' /* webpackChunkName: "pages/partner-area/incentives/turnover-incentive/index" */))
const _dd1b8bc2 = () => interopDefault(import('../pages/finances/single-pool/commissions/CommissionsCard.vue' /* webpackChunkName: "pages/finances/single-pool/commissions/CommissionsCard" */))
const _c40d31c0 = () => interopDefault(import('../pages/finances/single-pool/commissions/PartnerActivityTable.vue' /* webpackChunkName: "pages/finances/single-pool/commissions/PartnerActivityTable" */))
const _692a2b1a = () => interopDefault(import('../pages/finances/single-pool/commissions/PendingCommissions.vue' /* webpackChunkName: "pages/finances/single-pool/commissions/PendingCommissions" */))
const _577cb6b0 = () => interopDefault(import('../pages/index.vue' /* webpackChunkName: "pages/index" */))

const emptyFn = () => {}

Vue.use(Router)

export const routerOptions = {
  mode: 'history',
  base: '/',
  linkActiveClass: 'nuxt-link-active',
  linkExactActiveClass: 'nuxt-link-exact-active',
  scrollBehavior,

  routes: [{
    path: "/account",
    component: _6f7d4b4e,
    name: "account"
  }, {
    path: "/dashboard",
    component: _e683c516,
    name: "dashboard"
  }, {
    path: "/KYC-verification",
    component: _5d0167b0,
    name: "KYC-verification"
  }, {
    path: "/metaverse-switch",
    component: _70a7ffa6,
    name: "metaverse-switch"
  }, {
    path: "/my-team",
    component: _9edacc82,
    name: "my-team"
  }, {
    path: "/partnerships",
    component: _3970e3c4,
    name: "partnerships"
  }, {
    path: "/password-protect",
    component: _8bba9e1c,
    name: "password-protect"
  }, {
    path: "/suspicious-activity",
    component: _ffe105c0,
    name: "suspicious-activity"
  }, {
    path: "/auth/cashft",
    component: _7719542e,
    name: "auth-cashft"
  }, {
    path: "/auth/email-verify",
    component: _66aa8972,
    name: "auth-email-verify"
  }, {
    path: "/auth/login",
    component: _dddc5652,
    name: "auth-login"
  }, {
    path: "/auth/one-time-token",
    component: _5e4013a8,
    name: "auth-one-time-token"
  }, {
    path: "/auth/password-recovery",
    component: _2eae59f5,
    name: "auth-password-recovery"
  }, {
    path: "/finances/commissions",
    component: _e6bae072,
    name: "finances-commissions"
  }, {
    path: "/finances/miner-rewards",
    component: _c8dda396,
    name: "finances-miner-rewards"
  }, {
    path: "/finances/single-pool",
    component: _472ba620,
    name: "finances-single-pool"
  }, {
    path: "/finances/wallet",
    component: _b33c78e0,
    name: "finances-wallet"
  }, {
    path: "/metaworld/gallery",
    component: _1d77c1d1,
    name: "metaworld-gallery"
  }, {
    path: "/metaworld/map",
    component: _39defd5b,
    name: "metaworld-map"
  }, {
    path: "/nfts/cards",
    component: _3d634ee0,
    name: "nfts-cards"
  }, {
    path: "/nfts/my-nfts",
    component: _6c5be316,
    name: "nfts-my-nfts"
  }, {
    path: "/nfts/products",
    component: _b090bfc6,
    name: "nfts-products"
  }, {
    path: "/partner-area/career-status",
    component: _5d9c6259,
    name: "partner-area-career-status"
  }, {
    path: "/partner-area/global-line-bonus",
    component: _6d6e1d68,
    name: "partner-area-global-line-bonus"
  }, {
    path: "/partner-area/level-pool",
    component: _47e46363,
    name: "partner-area-level-pool"
  }, {
    path: "/partner-area/turnover-pool",
    component: _37bb2e10,
    name: "partner-area-turnover-pool"
  }, {
    path: "/promos/mystery-box",
    component: _b2d572f2,
    name: "promos-mystery-box"
  }, {
    path: "/partner-area/career-status/feature-voting",
    component: _3cc359aa,
    name: "partner-area-career-status-feature-voting"
  }, {
    path: "/partner-area/career-status/statistics",
    component: _6644e5a2,
    name: "partner-area-career-status-statistics"
  }, {
    path: "/partner-area/incentives/cash-promo",
    component: _0c690c74,
    name: "partner-area-incentives-cash-promo"
  }, {
    path: "/partner-area/incentives/direct-sales-incentive",
    component: _05fec345,
    name: "partner-area-incentives-direct-sales-incentive"
  }, {
    path: "/partner-area/incentives/fast-start",
    component: _60582730,
    name: "partner-area-incentives-fast-start"
  }, {
    path: "/partner-area/incentives/global-pool",
    component: _31e5e27d,
    name: "partner-area-incentives-global-pool"
  }, {
    path: "/partner-area/incentives/product-autoship-incentive",
    component: _525f67b4,
    name: "partner-area-incentives-product-autoship-incentive"
  }, {
    path: "/partner-area/incentives/spring-incentive",
    component: _e54474ac,
    name: "partner-area-incentives-spring-incentive"
  }, {
    path: "/partner-area/incentives/third-anniversary-promo",
    component: _305756a3,
    name: "partner-area-incentives-third-anniversary-promo"
  }, {
    path: "/partner-area/incentives/travel-incentive",
    component: _244b3a37,
    name: "partner-area-incentives-travel-incentive"
  }, {
    path: "/partner-area/incentives/turnover-incentive",
    component: _1d2e5124,
    name: "partner-area-incentives-turnover-incentive"
  }, {
    path: "/finances/single-pool/commissions/CommissionsCard",
    component: _dd1b8bc2,
    name: "finances-single-pool-commissions-CommissionsCard"
  }, {
    path: "/finances/single-pool/commissions/PartnerActivityTable",
    component: _c40d31c0,
    name: "finances-single-pool-commissions-PartnerActivityTable"
  }, {
    path: "/finances/single-pool/commissions/PendingCommissions",
    component: _692a2b1a,
    name: "finances-single-pool-commissions-PendingCommissions"
  }, {
    path: "/",
    component: _577cb6b0,
    name: "index"
  }],

  fallback: false
}

export function createRouter (ssrContext, config) {
  const base = (config._app && config._app.basePath) || routerOptions.base
  const router = new Router({ ...routerOptions, base  })

  // TODO: remove in Nuxt 3
  const originalPush = router.push
  router.push = function push (location, onComplete = emptyFn, onAbort) {
    return originalPush.call(this, location, onComplete, onAbort)
  }

  const resolve = router.resolve.bind(router)
  router.resolve = (to, current, append) => {
    if (typeof to === 'string') {
      to = normalizeURL(to)
    }
    return resolve(to, current, append)
  }

  return router
}
