import type WalletAddress from "~/pages/finances/wallet/models/WalletAddress";
import Asset from './Asset';
import {toBN, toWei, fromWei} from "~/core/helpers/GlobalHelpers";
import { MetaWorldManager } from "~/core/services/map/MetaWorldManager";

const currency = 'BNB';
const icon = require('~/assets/images/icons/currencies/bnb.png');
const GAS_FEE_MULTIPLIER_FOR_INTERNAL = 1.3;

export default class BNBAsset extends Asset {
    contractAddress: string = '';

    constructor (value: number, rate: number, isInsufficient: boolean, metaWorldManager: any) {
        super(null, null, currency, icon, value, rate, isInsufficient, metaWorldManager);
        if (!rate) {
            this.setBNBPriceRate();
        }
        this.contractAddress = metaWorldManager.getWeb3Contract('WBNB').options.address;
    }

    async setBNBPriceRate() {
        const BNBPrice = await MetaWorldManager.sharedInstance().contracts.GymNetwork.methods.getBNBPrice().call()
        this._rate = Number(BNBPrice);
    }

    async estimateGas(
        gasPrice_placeholder: number,
        addressFrom: WalletAddress,
        addressTo: WalletAddress,
    ): Promise<BNBAsset> {
        const {_metaWorldManager} = this;
        const web3 = _metaWorldManager.web3();
        const weiAmount = toWei(this._value.toString());
        const transaction = {
            from: addressFrom,
            to: addressTo,
            value: weiAmount
        };
        const gasPrice = toBN(await web3.eth.getGasPrice());
        const gasAmount = toBN(await web3.eth.estimateGas(transaction)).mul(gasPrice);
        const amount = fromWei(gasAmount.toString());
        const parsedAmount = amount === 0 ? 0 : parseFloat(amount) * GAS_FEE_MULTIPLIER_FOR_INTERNAL;
        return new BNBAsset(
            parsedAmount,
            this._rate,
            false,
            this._metaWorldManager
        );
    }

    _getSendTransaction(): Object {
        return {
            type: 'bnb',
        };
    }

    async estimateSwapGas(
        path: string[],
        walletAddress: string,
        amount: number,
        amountOutMin = 0,
    ) {

        const {_metaWorldManager} = this;

        const transaction = _metaWorldManager.contracts.GymRouter.methods.swapExactETHForTokensSupportingFeeOnTransferTokens(
            amountOutMin, path, walletAddress,
        );

        const gasEstimated = toBN(await transaction.estimateGas({ from: walletAddress, value: toWei(String(amount)) }));
        const gasPrice = toBN(await _metaWorldManager.readWeb3().eth.getGasPrice());

        const totalAmountWithGas = (gasEstimated.mul(gasPrice));
        const GasEstimatedInBNB = fromWei(totalAmountWithGas.toString());

        return Number(GasEstimatedInBNB) * GAS_FEE_MULTIPLIER_FOR_INTERNAL;

    }

    subtract(subtrahend: number): BNBAsset {
        const parentAmt = super.subtract(subtrahend);
        return new BNBAsset(
            subtrahend,
            this._rate,
            parentAmt.isInsufficient,
            this._metaWorldManager,
        );
    }

    copyRate(asset: BNBAsset): BNBAsset {
        return new BNBAsset(
            this.value,
            asset._rate,
            this._isInsufficient,
            this._metaWorldManager,
        );
    }
};
