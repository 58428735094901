import { getExactDate } from "./getExactDate";

export function generateWeeklyEmptyChartData () {
    const result = new Array(7);
    const date = getExactDate();
    for(let i = 6; i >= 0; i--) {
        date.setDate(date.getDate() - 1);
        const dateString = date.toISOString();
        result[i] = {
            date: dateString,
            usdt: 0,
            gymnet: 0,
            utility: 0,
            y: 0,
            x: dateString,
        };
    }
    return result;
}
