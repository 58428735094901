
import {defineComponent} from 'vue'
import QrcodeVue from 'qrcode.vue'
import PurchaseCountdown from '../purchase-countdown/PurchaseCountdown.vue'
import ClipboardCopier from '~/components/utils/ClipboardCopier.vue'
import {toWei} from '~/core/helpers/GlobalHelpers'
import {ExternalWalletPayment} from '~/core/models/purchase-popup-2/ExternalWalletPayment'

export default defineComponent({
    name: 'PurchaseExternalWidget',
    components: {
        ClipboardCopier,
        QrcodeVue,
        PurchaseCountdown
    },
    props: {
        transactionObject: {
            type: ExternalWalletPayment,
            required: true,
        },
    },
    computed: {
        currency() {
            return this.transactionObject.priceInfo.totalAmount.currency;
        },
        qrCodeValue() {
            const currency = this.transactionObject.cryptocurrencyObject;
            const transferTo = this.transactionObject.paymentAddress;
            const amount = this.transactionObject.missingAmountAsset.value

            let paymentTemplate = currency.paymentTemplate

            paymentTemplate = paymentTemplate.replace('{TRANSFER_TO}', transferTo)
            if (['BEP20', 'ERC20'].includes(currency.tokenStandard) && !currency.isNative) {
                paymentTemplate = paymentTemplate.replace('{TOKEN}', this.$contracts[this.$metaverseId()][currency.code]?.options.address)
            }
            if (currency.isEvmBased) {
                paymentTemplate = paymentTemplate.replace('{CHAIN_ID}', currency.chain.chainId)
                paymentTemplate = paymentTemplate.replace('{AMOUNT}', toWei(amount))
            } else {
                paymentTemplate = paymentTemplate.replace('{AMOUNT}', amount)
            }
            return paymentTemplate
        }
    }
})

