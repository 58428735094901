import {diagLog, PARCEL_TYPES} from "~/core/services/utils/Constants";
import {MetaBundle} from "~/core/models/MetaBundle";
import { UserInventoryInfoContainer } from "./UserInventoryInfoContainer";


export class MetaInventoryContainer {

    static sharedInstance() {
        if(MetaInventoryContainer.instance === null || MetaInventoryContainer.instance === undefined) {
            MetaInventoryContainer.instance = new MetaInventoryContainer();
        }
        return MetaInventoryContainer.instance;
    }
    constructor() {
        this.authUserData = null;
        this.bundles = [];
        this.userBalance = null;
        this.userInventoryInfo = new UserInventoryInfoContainer();
        this.minerRewardsData = null;
        this.userCryptoBalance = null;
    }

    setUserBalance(balanceData) {
        this.userBalance = balanceData;
    }

    setUserCryptoBalance(userCryptoBalanceData) {
        this.userCryptoBalance = userCryptoBalanceData;
    }

    setAuthUserData(userData) {
        this.authUserData = userData;
    }

    setMinerRewardsData(minerRewardsData) {
        this.minerRewardsData = minerRewardsData;
    }

    storeBundles(bundles) {
        this.bundles = bundles.map(bnd => new MetaBundle(bnd));
    }

    destroyUserInventoryData() {
        this.userBalance = null;
        this.userCryptoBalance = null;
        this.userInventoryInfo.clear();
        this.minerRewardsData = null;
    }
}
