/**
 * @description Initialize KeenThemes custom components
 */
import {MenuComponent} from "~/assets/ts/MenuComponent";
import {ScrollComponent} from "~/assets/ts/ScrollComponent";
import {DrawerComponent} from "~/assets/ts/DrawerComponent";

export const initializeComponents = () => {
    setTimeout(() => {
        MenuComponent.bootstrap();
        DrawerComponent.bootstrap();
        ScrollComponent.bootstrap();
    }, 0);
}

export const reinitializeComponents = () => {
    setTimeout(() => {
        MenuComponent.reinitialization();
        reinitializeScrollComponent().then(() => {
            ScrollComponent.updateAll();
        });
        DrawerComponent.reinitialization();
    }, 0);
}

const reinitializeScrollComponent = async () => {
    await ScrollComponent.reinitialization();
};
