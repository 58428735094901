import { render, staticRenderFns } from "./Header.vue?vue&type=template&id=d73f94fe&scoped=true"
import script from "./Header.vue?vue&type=script&setup=true&lang=ts"
export * from "./Header.vue?vue&type=script&setup=true&lang=ts"
import style1 from "./Header.vue?vue&type=style&index=1&id=d73f94fe&prod&lang=scss"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "d73f94fe",
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {BaseDropdownSimple: require('/root/dashboard/core/components/base/dropdown/Simple.vue').default})
