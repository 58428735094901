import { ACTIONS, GETTERS, MUTATIONS } from "./names";
import { MyCurrentRankService } from "~/pages/partner-area/career-status/services/MyCurrentRankService";
import { POLLING_STORE_FUNCTIONS } from "./polling";

export default {
    ...POLLING_STORE_FUNCTIONS.actions,

    async [ACTIONS.LOAD_PROCESS_AND_SAVE_PROGRESS_DATA_BY_UNILEVEL] (
        { dispatch, commit, getters },
        { save }: {save?: () => boolean} = {},
    ) {
        if (getters[GETTERS.PROGRESS_DATA_LOADING]) {
            return getters[GETTERS.PROGRESS_DATA_LOADING_PROMISE];
        }
        commit(MUTATIONS.SET_PROGRESS_DATA_LOADING, true);
        let result = null;
        try {
            const loadingPromise = dispatch(ACTIONS.LOAD_AND_PROCESS_PROGRESS_DATA_BY_UNILEVEL);
            commit(MUTATIONS.SET_PROGRESS_DATA_LOADING_PROMISE, loadingPromise);
            result = await loadingPromise;

            if (!save || save()) {
                await dispatch(ACTIONS.SAVE_PROGRESS_DATA_BY_UNILEVEL, result);
            }

            commit(MUTATIONS.SET_PROGRESS_DATA_LOADING_ERROR, null);
        } catch (e) {
            console.warn('Failed to fetch rank info', e);
            commit(MUTATIONS.SET_PROGRESS_DATA_LOADING_ERROR, e);
        } finally {
            commit(MUTATIONS.SET_PROGRESS_DATA_LOADING, false);
            commit(MUTATIONS.SET_PROGRESS_DATA_LOADING_PROMISE, null);
            return result;
        }
    },

    async [ACTIONS.LOAD_AND_PROCESS_PROGRESS_DATA_BY_UNILEVEL] ({ dispatch }) {
        const turnoverData = await dispatch(ACTIONS.LOAD_PROGRESS_DATA_BY_UNILEVEL);
        return dispatch(ACTIONS.PROCESS_PROGRESS_DATA_BY_UNILEVEL, turnoverData);
    },
    [ACTIONS.PROCESS_PROGRESS_DATA_BY_UNILEVEL] (_ctx, data) {
        return {
            left: Number(data?.left || 0),
            right: Number(data?.right || 0),
            hasIgnoredBonus: !!data?.haveIgnoredBonus,
        }
    },
    async [ACTIONS.SAVE_PROGRESS_DATA_BY_UNILEVEL] (
        { commit },
        { left, right, hasIgnoredBonus }
    ) {
        commit(MUTATIONS.SET_PROGRESS_BY_BINARY_LEFT, Number(left || 0));
        commit(MUTATIONS.SET_PROGRESS_BY_BINARY_RIGHT, Number(right || 0));
        commit(MUTATIONS.SET_HAS_IGNORED_BONUS, !!hasIgnoredBonus);
    },

    async [ACTIONS.LOAD_PROGRESS_DATA_BY_UNILEVEL] () {
        return await MyCurrentRankService.getTurnoverDataByUnilevel();
    },

    [ACTIONS.CLEAR_PROGRESS_BY_UNILEVEL_STATE] ({ commit, dispatch }) {
        commit(MUTATIONS.SET_PROGRESS_BY_BINARY_LEFT, null);
        commit(MUTATIONS.SET_PROGRESS_BY_BINARY_RIGHT, null);
        commit(MUTATIONS.SET_HAS_IGNORED_BONUS, null);
        dispatch(ACTIONS.STOP_POLLING);
    },
};