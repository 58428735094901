export const STATE = {
    PROVIDER: 'provider',
} as const;

export const ACTIONS = {
    SET_PROVIDER: 'setProvider',
} as const;

export const MUTATIONS = {
    SET_PROVIDER: 'SET_PROVIDER',
} as const;

export const GETTERS = {
    PROVIDER: 'provider',
} as const;