import { waitFor } from "./waitFor";

export async function callAndRetryOnError(func, retries, interval) {
    while (retries > 0) {
        try {
            return await func();
        } catch (e) {
            retries -= 1;
            if (retries === 0) throw e;
            await waitFor(interval);
        }
    }
}