import {MetaParcelDelegate} from "./MetaParcel";

export class MetaParcelFacade extends MetaParcelDelegate {
    constructor(parcel, context, size, deviationX, deviationY) {
        super();
        this.parcel = parcel;
        this.parcel.delegate = this;
        this.context = context;
        this.size = size;
        this.deviationX = deviationX;
        this.deviationY = deviationY;
    }

    draw(isSquareShapeAchieved = false) {
        if (this.parcel.isImage() || this.parcel.isReserved() && this.parcel.reservedArea.hasImage()) {
            return;
        }
        let x = (this.parcel.x - this.deviationX) * this.size;
        let y = (this.parcel.y - this.deviationY) * this.size;
        let w = this.size;
        let h = this.size;
        let leftBorderOffset = 0;
        let topBorderOffset = 0;
        let rightBorderOffset = 0;
        let bottomBorderOffset = 0;
        this.context.clearRect(x, y, this.size, this.size); // TODO not sure if this is needed
        if(this.size > 6 && !isSquareShapeAchieved && !this.parcel.isReserved()) {
            if(this.parcel.isOwned()) {
                if(!this.parcel.isParcelSelfOwned(-1, 0)) {
                    leftBorderOffset = 1;
                }
                if(!this.parcel.isParcelSelfOwned(0, -1)) {
                    topBorderOffset = 1;
                }
                if(!this.parcel.isParcelSelfOwned(1, 0)) {
                    rightBorderOffset = 1;
                }
                if(!this.parcel.isParcelSelfOwned(0, 1)) {
                    bottomBorderOffset = 1;
                }
            } else {
                topBorderOffset = 1;
                rightBorderOffset = 1;
                bottomBorderOffset = 1;
                leftBorderOffset = 1;
            }
            x = x + leftBorderOffset;
            y = y + topBorderOffset;
            w = w - leftBorderOffset - rightBorderOffset;
            h = h - topBorderOffset - bottomBorderOffset;
        }
        let p = new Path2D();
        p.rect(x, y, w, h);
        this.context.fillStyle = this.parcel.color[this.parcel.state];
        this.context.fill(p);
    }

    dataModified(isSquareShapeAchieved) {
        this.draw(isSquareShapeAchieved);
    }
}
