

import {copyToClipboard, toCurrencyFormat, toNumericFormat} from '@/core/helpers/GlobalHelpers';
import {ExternalWalletPayment} from '~/core/models/purchase-popup-2/ExternalWalletPayment';
import MunicipalityTx from '~/core/models/purchase-popup-2/MunicipalityTx';
import {UserBalance} from '~/core/models/purchase-popup-2/UserBalance';
import {PaymentMethodEnum} from '~/core/types/purchase-popup-2/PaymentMethodEnum';
import {UseExtraBalancesTypes} from "~/core/models/purchase-popup-2/UseExtraBalancesTypes";

const BUSD_FEE_VALUE = 3;

export default {
    name: 'TotalWidget',
    props: {
        transactionObject: {
            type: [MunicipalityTx, ExternalWalletPayment],
            required: true,
        },
        paymentMethod: {
            type: String,
            required: true,
        },
        userBalance: {
            type: UserBalance,
            default: null,
        },
        isMissingAmountScreen: {
            type: Boolean,
            default: false,
        },
    },
    data() {
        return {
            PaymentMethodEnum,
            toCurrencyFormat,
            toNumericFormat,
        };
    },
    computed: {
        utilityAsset() {
            return this.transactionObject._isUsingExtraBalance === UseExtraBalancesTypes.BALANCE_GYMNET
                ? this.transactionObject.priceInfo.splitAmountGymnet.gymnet
                : this.transactionObject.priceInfo.splitAmountUtility.gymnet;
        },
        mainAsset() {
            let asset = this.transactionObject.priceInfo.totalAmount;
            if (this.transactionObject.isUsingExtraBalance) {
                asset = this.transactionObject.priceInfo.splitAmountUtility.usdt;
            }
            if (this.paymentMethod === PaymentMethodEnum.INTERNAL &&
                this.transactionObject.priceInfo.gasAmount.value > this.userBalance.bnb.value) {
                asset = asset.copy(asset.value + BUSD_FEE_VALUE);
            }
            if (this.paymentMethod === PaymentMethodEnum.EXTERNAL) {
                if (this.isMissingAmountScreen) {
                    asset = this.transactionObject.missingAmountAsset;
                } else {
                    asset = this.transactionObject.totalAmountAsset;
                }
            }
            return asset;
        },
        totalUSD() {
            let totalUSD = this.mainAsset.usd;
            if (this.transactionObject.isUsingExtraBalance) {
                totalUSD += this.utilityAsset.usd;
            }
            return totalUSD;
        },
    },
    methods: {
        copyTokenPrice() {
            copyToClipboard(this.mainAsset.value);
        },
    },
};

