import { ACTIONS, MUTATIONS, STATE } from "./names";

export default {
    [ACTIONS.SHOW_INFO_TOAST]({ commit, dispatch }, { title, description, duration }) {
        commit(MUTATIONS.SET_CLOSE_TOAST);
        commit(MUTATIONS.SET_SHOW_TOAST, { title, description });
        commit(MUTATIONS.SET_SHOW_INFO_TOAST);
        commit(MUTATIONS.SET_TOAST_DURATION, duration);
        dispatch(ACTIONS.CLOSE_IN_DURATION);
    },
    [ACTIONS.SHOW_ERROR_TOAST]({ commit, dispatch }, { title, description, duration }) {
        commit(MUTATIONS.SET_CLOSE_TOAST);
        commit(MUTATIONS.SET_SHOW_TOAST, { title, description });
        commit(MUTATIONS.SET_SHOW_ERROR_TOAST);
        commit(MUTATIONS.SET_TOAST_DURATION, duration);
        dispatch(ACTIONS.CLOSE_IN_DURATION);
    },
    [ACTIONS.CLOSE_IN_DURATION]({ commit, state }) {
        const durationTimeout = setTimeout(() => {
            commit(MUTATIONS.SET_CLOSE_TOAST);
            clearTimeout(durationTimeout);
        }, state[STATE.TOAST_DURATION]);
    },
    [ACTIONS.CLOSE_TOAST]({ commit }) {
        commit(MUTATIONS.SET_CLOSE_TOAST);
    }
};
