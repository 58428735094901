import { GETTERS, STATE } from "./names";
import { GETTERS as USER_AUTH_GETTERS } from "../../auth/names";
import { State } from "./types";

export default {
    [GETTERS.IS_VERIFYING_EMAIL](state: State) {
        return state[STATE.IS_VERIFYING_EMAIL];
    },
    [GETTERS.LAST_EMAIL_DATE](state: State, getters: any, rootState: any, rootGetters: any) {
        return new Date(rootGetters[`affility/user/auth/${USER_AUTH_GETTERS.CURRENT_USER}`]?.lastEmailSent).getTime();
    },
    [GETTERS.IS_EMAIL_SENT_NOTIFICATION_VISIBLE](state: State) {
        return state[STATE.EMAIL_SENT_NOTIFICATION_VISIBLE];
    },
}