import axios from "axios";
import ApiService from "./ApiService";
import { MAP_ENDPOINTS } from '@/core/services/utils/Constants';
import { METAVERSES } from '@/core/services/utils/MetaverseConstants';

export class MapDataLoaderService {
    static metaverseId = 1;
    static metaverse = METAVERSES[this.metaverseId];
    static promises = {};

    static init (metaverseId) {
        this.setMetaverseId(metaverseId);
        this.promises = {
            mapBoundaries: this.loadMapBoundaries(),
            reservedAreas: this.loadReservedAreas(),
            reservedAreasData: this.loadReservedAreasData(),
            businessParcelData: this.loadBusinessParcelData(),
            imageParcelData: this.loadImageParcelData(),
            ownedParcelData: this.loadOwnedParcelData(),
        };
        return this.promises;
    }

    static setMetaverseId (metaverseId) {
        this.metaverseId = metaverseId;
        this.metaverse = METAVERSES[this.metaverseId];
    }

    static async loadMapBoundaries () {
        const result = await ApiService.query(this.metaverse.files[0], {
            transformRequest: [(data, headers) => {
                delete headers.common.Authorization;
                return data;
            }],
        });
        return result.data;
    }

    static async loadReservedAreas () {
        return await ApiService.query(MAP_ENDPOINTS.reserved_areas, {
            params: {
                metaverse_id: this.metaverseId,
            },
        });
    }

    static async loadReservedAreasData () {
        return await ApiService.query(MAP_ENDPOINTS.reserved_areas_data, {
            params: {
                metaverse_id: this.metaverseId,
            },
        });
    }

    static async loadBusinessParcelData () {
        return await ApiService.query(MAP_ENDPOINTS.business_parcels_data, {
            params: {
                metaverse_id: this.metaverseId,
            },
        });
    }

    static async loadImageParcelData () {
        return await ApiService.query(MAP_ENDPOINTS.image_parcels_data, {
            params: {
                metaverse_id: this.metaverseId,
            },
        });
    }

    static async loadOwnedParcelData () {
        return await ApiService.query(MAP_ENDPOINTS.owned_parcels_data, {
            params: {
                metaverse_id: this.metaverseId,
            },
        });
    }
}