import { MUTATIONS, STATE } from "./names";
import { POLLING_STORE_FUNCTIONS } from "./polling";
import { BinaryTreeRawNode } from "./types/BinaryTreeRawNode";
import type { State } from "./types/State";

export default {
    [MUTATIONS.SET_LEFT_CV] (state: State, payload: number | null) {
        state[STATE.LEFT_CV] = payload;
    },
    [MUTATIONS.SET_RIGHT_CV] (state: State, payload: number | null) {
        state[STATE.RIGHT_CV] = payload;
    },
    [MUTATIONS.SET_LEFT_SUBTREE_SIZE] (state: State, payload: number | null) {
        state[STATE.LEFT_SUBTREE_SIZE] = payload;
    },
    [MUTATIONS.SET_RIGHT_SUBTREE_SIZE] (state: State, payload: number | null) {
        state[STATE.RIGHT_SUBTREE_SIZE] = payload;
    },
    [MUTATIONS.SET_LEFT_SUBTREE_ROOT] (state: State, payload: BinaryTreeRawNode | null) {
        state[STATE.LEFT_SUBTREE_ROOT] = payload;
    },
    [MUTATIONS.SET_RIGHT_SUBTREE_ROOT] (state: State, payload: BinaryTreeRawNode | null) {
        state[STATE.RIGHT_SUBTREE_ROOT] = payload;
    },
    [MUTATIONS.SET_BINARY_PATH] (state: State, payload: string | null) {
        state[STATE.BINARY_PATH] = payload;
    },
    [MUTATIONS.SET_BINARY_RANK_NAME] (state: State, payload: string | null) {
        state[STATE.BINARY_RANK_NAME] = payload;
    },
    [MUTATIONS.SET_SUBTREE_DATA_LOADING] (state: State, payload: boolean) {
        state[STATE.SUBTREE_DATA_LOADING] = payload;
    },
    [MUTATIONS.SET_SUBTREE_DATA_LOADING_ERROR] (state: State, payload: Error | null) {
        state[STATE.SUBTREE_DATA_LOADING_ERROR] = payload;
    },
    [MUTATIONS.SET_SUBTREE_DATA_LOADING_PROMISE] (state: State, payload: Promise<void> | null) {
        state[STATE.SUBTREE_DATA_LOADING_PROMISE] = payload;
    },
    ...POLLING_STORE_FUNCTIONS.mutations,
};