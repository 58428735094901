export const STATE = {

} as const;

export const ACTIONS = {
    POLL_DASHBOARD_DATA: 'POLL_DASHBOARD_DATA',
    STOP_POLLING: 'STOP_POLLING',
} as const;


export const MUTATIONS = {

} as const;

export const GETTERS = {
    DASHBOARD_DATA: 'DASHBOARD_DATA',
} as const;