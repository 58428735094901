/**
 * User
 */
export const DEFAULT_USER_AVATAR_PATH = require('~/assets/images/blank/blank-avatar-user.png');

/**
 * Miner
 */
export const DEFAULT_MINER_ICON_PATH = require('~/assets/images/gymstreet/parcels/miner.png');

/**
 * Application logos
 */
export const STANDARD_LOGO = require('~/assets/images/logos/gymstreet/gymstreet.png');
export const CHRISTMAS_LOGO = require('~/assets/images/logos/gymstreet/gymstreet-christmas.svg');
export const DEFAULT_LOGO = STANDARD_LOGO
