import {DEFAULT_PARCEL_SIZE, diagLog, PARCEL_DEFAULT_MATRIX_SIZE} from "~/core/services/utils/Constants";
import {MetaParcelFacade} from "~/core/services/map/MetaParcelFacade";
import { MetaParcelsRegistry } from "./MetaParcelsRegistry";

export class MetaCanvas {
    constructor(zoomLevel) {
        this.rows = [];
        this.isFinalized = false;
        this.x = PARCEL_DEFAULT_MATRIX_SIZE;
        this.x1 = 0;
        this.y = 0;
        this.y1 = 0;
        this.isDrawn = false;
        this.isLastCanvas = false;
        this.zoomLevel = zoomLevel;
    }

    setZoomLevel(zoomLevel) {
        this.zoomLevel = zoomLevel;
        if(this.systemCanvas) {
            this.systemCanvas.width = this.getWidth();
            this.systemCanvas.style.top = `${this.getTopCoordinate()}px`;
            this.systemCanvas.style.left = `${this.getLeftCoordinate()}px`;
        }
    }

    getTopCoordinate() {
        return this.y * this.zoomLevel + this.zoomLevel;
    }

    getLeftCoordinate() {
        return this.x * this.zoomLevel;
    }

    getWidth() {
        return (this.x1 - this.x) * this.zoomLevel + this.zoomLevel;
    }

    getHeight() {
        return (this.y1 - this.y) * this.zoomLevel + this.zoomLevel;
    }

    addRow(metaMatrixRow) {
        let leftParcel = metaMatrixRow.first();
        let rightParcel = metaMatrixRow.last();
        if(leftParcel.x < this.x) {
            this.x = leftParcel.x;
        }
        if(rightParcel.x > this.x1) {
            this.x1 = rightParcel.x;
        }
        this.rows.push(metaMatrixRow);
    }

    canFinalize() {
        return this.rows.length === 50;
    }

    finalize(indx, parentElement, isLastPortion = false) {
        let topLeft = this.rows[0].first();
        let lastRow = this.rows[this.rows.length - 1];
        let bottomRight = lastRow.last();
        this.y = topLeft.y;
        this.y1 = bottomRight.y;
        this.isLastCanvas = isLastPortion;
        diagLog(this, `Creating canvas: ${indx}`, this.x, this.y, this.x1, this.y1);
        if(!this.isFinalized) {
            this.systemCanvas = document.createElement('canvas');
            this.systemCanvas.style.top = `${this.getTopCoordinate()}px`;
            this.systemCanvas.style.left = `${this.getLeftCoordinate()}px`;
            this.systemCanvas.style.position = 'absolute';
            this.systemCanvas.width = this.getWidth();
            this.systemCanvas.id = indx;
            this.isFinalized = true;
        }
    }

    drawParcelsGroup() {
        this.systemCanvas.height = this.isLastCanvas ? this.getHeight() + this.getHeight() / 2 : this.getHeight();
        this.canvasContext = this.systemCanvas.getContext('2d');
        for(let r of this.rows) {
            for(let p of r.allItems()) {
                let parcelFacade = new MetaParcelFacade(p, this.canvasContext, this.zoomLevel, this.x, this.y);
                parcelFacade.draw();
            }
        }
        this.isDrawn = true;
        let mpr = MetaParcelsRegistry.sharedInstance();
        if(mpr.imageParcels){
            for(let img of mpr.imageParcels){
                if(
                    (img.y >= this.y && img.y <= this.y1)
                    || (img.y+img.height >= this.y && img.y+img.height <= this.y1)
                    || (img.y <= this.y && img.y+img.height >= this.y1)
                ) {
                    let ctx = this.systemCanvas.getContext('2d');
                    const image = img.imageElement;
                    if(image.complete){
                        if(image.naturalHeight > 0) {
                            ctx.drawImage(image, img.x*this.zoomLevel-this.getLeftCoordinate(), (img.y+1)*this.zoomLevel-this.getTopCoordinate(), img.width*this.zoomLevel, img.height*this.zoomLevel)
                        } else {
                            console.error("Unknown error at MetaCanvas.js drawParcelsGroup, Image is broken");
                        }
                    } else {
                        image.loading = 'eager';
                        image.addEventListener('load', () => {
                            ctx.drawImage(image, img.x*this.zoomLevel-this.getLeftCoordinate(), (img.y+1)*this.zoomLevel-this.getTopCoordinate(), img.width*this.zoomLevel, img.height*this.zoomLevel)
                        });
                    }
                }
            }
        }
        for(const reservedAreaId in mpr.matrix.reservedAreas) {
            const reservedArea = mpr.matrix.reservedAreas[reservedAreaId];
            if (
                reservedArea.hasImage() && (
                    (reservedArea.y >= this.y && reservedArea.y <= this.y1)
                    || (reservedArea.y+reservedArea.getHeight() >= this.y && reservedArea.y+reservedArea.getHeight() <= this.y1)
                    || (reservedArea.y <= this.y && reservedArea.y+reservedArea.getHeight() >= this.y1)
                )
            ) {
                const ctx = this.systemCanvas.getContext('2d');
                if(reservedArea.image.complete) {
                    if(reservedArea.image.naturalHeight > 0) {
                        ctx.drawImage(reservedArea.image, reservedArea.x*this.zoomLevel-this.getLeftCoordinate(), (reservedArea.y+1)*this.zoomLevel-this.getTopCoordinate(), reservedArea.getWidth()*this.zoomLevel, reservedArea.getHeight()*this.zoomLevel);
                    } else {
                        console.error("Unknown error at MetaCanvas.js drawParcelsGroup, Image is broken");
                    }
                } else {
                    reservedArea.image.loading = 'eager'; // force load image
                    reservedArea.image.addEventListener('load', () => {
                        setTimeout(() => {
                            ctx.drawImage(reservedArea.image, reservedArea.x*this.zoomLevel-this.getLeftCoordinate(), (reservedArea.y+1)*this.zoomLevel-this.getTopCoordinate(), reservedArea.getWidth()*this.zoomLevel, reservedArea.getHeight()*this.zoomLevel)
                        }, 0)
                    });
                }
            }
        }
    }

    removeParcelsGroup() {
        this.canvasContext?.clearRect(0, 0, this.systemCanvas.width, this.systemCanvas.height);
        this.systemCanvas.height = 1;
        this.isDrawn = false;
    }

    destroy() {
        this.systemCanvas?.remove();
        this.systemCanvas = null;
        this.canvasContext = null;
        this.rows = [];
        this.isFinalized = false;
        this.isDrawn = false;
    }
}
