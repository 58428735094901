import WalletAddress from "~/pages/finances/wallet/models/WalletAddress";
import { fromWei, getClientRuntimeConfig } from "~/core/helpers/GlobalHelpers";
import Web3 from "web3";
import { ETH_CONTRACTS } from "~/contracts/addresses";
import ContractService from "./ContractService";
import { MetaWorldManager } from "~/core/services/map/MetaWorldManager";

const $config = getClientRuntimeConfig();
export const ethWeb3 = new Web3(new Web3.providers.HttpProvider($config.ethNode, {
    keepAlive: true,
}));

export class EthService {
    public static async getNativeBalance(walletAddress: WalletAddress): Promise<number> {
        const wei = await ethWeb3.eth.getBalance(walletAddress);
        return Number(fromWei(wei));
    }

    public static async getMoonbergBalance(walletAddress: WalletAddress): Promise<number> {
        if (ETH_CONTRACTS['Moonberg'][$config.chainId]) {
            const contract = await ContractService.createErc20AssetContract(
                ETH_CONTRACTS['Moonberg'][$config.chainId],
                MetaWorldManager.sharedInstance(),
                ethWeb3,
            );
            return Number(fromWei(await contract.methods.balanceOf(walletAddress).call()));
        } else {
            return 0;
        }
    }
}
