
export class MetaSelectionMetaData {
    constructor() {
        this.selectedReservedAreas = new Set();
        this.selectedReservedParcels = new Set();
        this.selectedImages = new Set();
        this.selectedImageParcels = new Set();
        this.selectedSelfOwnedParcels = new Set();
        this.selectedOwnedParcels = new Set();
        this.selectedFreeParcels = new Set();
        this.mainSelection = [];
        this.wholeSelectedArea = [];
    }



    addParcel(...metaParcels) {
        for(const p of metaParcels) {
            if(p.isReserved()) {
                this.selectedReservedAreas.add(p.reservedArea);
                this.selectedReservedParcels.add(p);
            }
            if (p.isImage()) {
                this.selectedImages.add(p.image);
                this.selectedImageParcels.add(p);
            }
            if (p.isSelfOwned()) {
                this.selectedSelfOwnedParcels.add(p);
            }
            if (p.isOwned() && !p.isSelfOwned()) {
                this.selectedOwnedParcels.add(p);
            }
            if (!p.isReserved() && !p.isOwned() && !p.isImage() && !p.isSelfOwned()) {
                this.selectedFreeParcels.add(p);
            }
            this.wholeSelectedArea.push(p);
        }
    }

    reset() {
        this.selectedReservedAreas = new Set();
        this.selectedReservedParcels = new Set();
        this.selectedImages = new Set();
        this.selectedImageParcels = new Set();
        this.selectedSelfOwnedParcels = new Set();
        this.selectedOwnedParcels = new Set();
        this.selectedFreeParcels = new Set();
        this.mainSelection = [];
        this.wholeSelectedArea = [];
    }

    anyReservedAreaSelected() {
        return this.selectedReservedAreas.size > 0;
    }

    anyReservedParcelsSelected() {
        return this.selectedReservedParcels.size > 0;
    }    

    anyImageSelected() {
        return this.selectedImages.size > 0;
    }

    anyOwnedParcelsSelected() {
        return this.selectedOwnedParcels.size > 0;
    }

    anySelfOwnedParcelsSelected() {
        return this.selectedSelfOwnedParcels.size > 0;
    }

    anyFreeParcelsSelected() {
        return this.selectedFreeParcels.size > 0;
    }
}
