import { render, staticRenderFns } from "./ContractAddresses.vue?vue&type=template&id=f17cef20&scoped=true"
import script from "./ContractAddresses.vue?vue&type=script&setup=true&lang=ts"
export * from "./ContractAddresses.vue?vue&type=script&setup=true&lang=ts"
import style0 from "./ContractAddresses.vue?vue&type=style&index=0&id=f17cef20&prod&scoped=true&lang=scss"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "f17cef20",
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {BaseImageSvgThemedIcon: require('/root/dashboard/core/components/base/image/SvgThemedIcon.vue').default,BaseLinkHeader: require('/root/dashboard/core/components/base/link/Header.vue').default,ComposedListTokenImport: require('/root/dashboard/core/components/composed/list/TokenImport.vue').default,ComposedDropdownHeader: require('/root/dashboard/core/components/composed/dropdown/Header.vue').default})
