import {
    BUNDLE_NAMES,
    TRANSACTION_STATUSES,
    SUPPORTED_CURRENCIES,
    BUNDLE_NAMES_BITOPEX
} from '../services/utils/Constants';
import { hideStringData, ceilTo, toNumericFormat } from '../helpers/GlobalHelpers';
import { RawExternalWalletPayment } from '../types/purchase-popup-2/RawExternalWalletPayment';
import moment from 'moment';
import { CashFtBundleNamesById } from '~/pages/nfts/products/constants/CashFtBundleNamesById';
import {TranslationHelper} from "~/core/services/TranslationHelper";

// TODO create correct types transaction statuses etc.
export type ProductType = 'bundle' | 'parcel' | 'miner'; // typeof Object.values(PRODUCT_TYPES);
export type TransactionCurrency = 'BTC' | 'ETH' | 'BNB' | 'ADA' | 'XRP' | 'SOL' | 'LUNC' | 'AVAX' | 'BUSD' | 'USDT'; // typeof SUPPORTED_CURRENCIES[number]['NAME'];
export type RepaymentStatuses = 'completed' | 'processing' | 'paid' | 'expired' | 'created' | 'fee_not_refunded'; // typeof Object.values(TRANSACTION_STATUSES);
export type TransactionStatuses = RepaymentStatuses | 'pending' | 'in_transactional_wallet' | 'in_whalefin_wallet' | 'swapping' | 'swapped';

export class MetaWeb3Transaction {
    constructor (public rawPaymentData: RawExternalWalletPayment) {
    }

    public get productType(): ProductType {
        return this.rawPaymentData.product_type;
    }
    public get bundleId(): number {
        return this.rawPaymentData.bundle_sc_id;
    }
    public get quantity(): number {
        return this.rawPaymentData.quantity;
    }
    public get paymentUid(): string {
        return this.rawPaymentData.payment_uid;
    }
    public get status(): TransactionStatuses {
        return this.rawPaymentData.status;
    }
    public get currency(): TransactionCurrency {
        return this.rawPaymentData.currency;
    }
    public get paymentWalletAddress(): string {
        return this.rawPaymentData.payment_wallet_address;
    }
    public get amount(): string {
        return this.rawPaymentData.amount;
    }
    public get grantTransactionHash(): string {
        return this.rawPaymentData.grant_transaction_hash;
    }
    public get feeIncludedAmount(): string {
        return this.rawPaymentData.fee_included_amount;
    }
    public get deviationAmount(): string {
        return this.rawPaymentData.deviation_amount;
    }
    public get repaymentStatus(): RepaymentStatuses {
        return this.rawPaymentData.repayment_status;
    }
    public get createdAt(): Date {
        return new Date(this.rawPaymentData.created_at);
    }
    public get amount_in_usdt(): number {
        return Number(this.rawPaymentData.amount_in_usdt);
    }
    public get ownerAddress(): string {
        return this.rawPaymentData.user_wallet_address;
    }

    get formattedProductType (): string {
        let productType = null;
        if (this.productType === 'bundle') {
            if(this.createdAt && this.createdAt > new Date('2023-06-01') && this.createdAt < new Date('2023-10-07')) {
                productType = BUNDLE_NAMES_BITOPEX[this.bundleId];
            } else {
                productType = BUNDLE_NAMES[this.bundleId];
            }
            if (!productType) {
                return TranslationHelper.translate(`${CashFtBundleNamesById[this.bundleId]} Card`);
            }
        } else {
            if(this.productType === 'miner' && this.createdAt && this.createdAt > new Date('2023-06-01') && this.createdAt < new Date('2023-10-07')) {
                productType = 'Dual Miner';
            } else {
                productType = this.productType;
            }
            productType = productType.charAt(0).toUpperCase() + productType.slice(1);
        }
        if (this.quantity > 1) {
            productType += ` (${this.quantity})`;
        }
        return productType;
    }

    get formattedTransationStatus (): TransactionStatuses {
        return TRANSACTION_STATUSES[this.status.toUpperCase()];
    }

    get formattedAmount (): string {
        return Number(this.amount).toFixed(5);
    }

    get formattedFeeIncludedAmount () : string {
        return toNumericFormat(this.feeIncludedAmount, 6);
    }

    get formatedTransactionDateTime(): string {
        return moment(this.createdAt).format('DD.MM.YYYY (HH:mm)');
    }

    get formattedTransactionDate (): string {
        return this.createdAt.toLocaleDateString();
    }

    get formattedTransactionTime (): string {
        return this.createdAt.toLocaleTimeString();
    }

    get currencyObject () {
        return SUPPORTED_CURRENCIES.find(item => item.CODE.toLowerCase() === this.currency.toLowerCase());
    }

    get shortPaymentUID (): string {
        return hideStringData(this.paymentUid, 4, 4);
    }

    get shortWalletAddress (): string {
        return hideStringData(this.paymentWalletAddress, 4, 4);
    }

    get shortGrantTransactionHash (): string {
        return hideStringData(this.grantTransactionHash, 4, 4);
    }

    get formattedDeviationAmount (): number {
        return ceilTo(this.deviationAmount, 6);
    }
}
