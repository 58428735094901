import { AsyncComponent, DefineComponent } from "vue";
import { TermsAndConditionVersions } from "./TermsAndConditionVersions";
import RegulationsWrappersAffiliate from '~/core/components/regulations/wrappers/Affiliate.vue';
import RegulationsWrappersCards from '~/core/components/regulations/wrappers/Cards.vue';
import RegulationsWrappersGeneral from '~/core/components/regulations/wrappers/General.vue';
import { ACTIONS as TERMS_AND_CONDITIONS_ACTIONS } from "~/store/affility/user/flows/terms-and-conditions/names";

export const TermsAndConditionsDetails = {
    [TermsAndConditionVersions.General]: {
        title: 't&c',
        component: RegulationsWrappersGeneral,
        acceptDefaultHandler: ({ dispatch }) => dispatch(TERMS_AND_CONDITIONS_ACTIONS.ACCEPT_GENERAL_TERMS_AND_CONDITIONS_AND_UPDATE_USER),
    },
    [TermsAndConditionVersions.Affiliate]: {
        title: 'affiliate_t&c',
        component: RegulationsWrappersAffiliate,
        acceptDefaultHandler: ({ dispatch }) => dispatch(TERMS_AND_CONDITIONS_ACTIONS.ACCEPT_AFFILIATE_TERMS_AND_CONDITIONS),
    },
    [TermsAndConditionVersions.Cards]: {
        title: 'cards_t&c',
        component: RegulationsWrappersCards,
        acceptDefaultHandler: ({ dispatch }) => dispatch(TERMS_AND_CONDITIONS_ACTIONS.ACCEPT_CARDS_TERMS_AND_CONDITIONS_AND_UPDATE_USER),
    },
} as Record<TermsAndConditionVersions, {
    title: string,
    component: string | DefineComponent | AsyncComponent,
    acceptDefaultHandler: (...args: unknown[]) => unknown | Promise<unknown>
}>;
