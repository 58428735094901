export const STATE = {
} as const;

export const ACTIONS = {
    CLEAR_COMMISSIONS_STATE: 'clearCommissionsState',
} as const;

export const MUTATIONS = {
} as const;

export const GETTERS = {
} as const;