export enum OrderStatus {
    COMPLETED = 'completed',
    PENDING = 'pending',
    EXPIRED = 'expired',
}


export const  OrderStatusColor= {
    completed: 'text-success',
    pending :'text-secondary',
    expired : 'text-danger',

}