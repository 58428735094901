import { PaymentType } from "~/core/models/purchase-popup-2/PaymentType";
import { fromWei } from "~/core/helpers/GlobalHelpers";
import WalletAddress from "~/pages/finances/wallet/models/WalletAddress";

export type BundleInfo = {
    typeID: number,
    isSuper: boolean,
};

export class BundleService {

    public static async getUtilityPrices (bundles: BundleInfo[], contract: any, walletAddress: WalletAddress) {
        const utilityPriceBundlePromises = Object.values(bundles).map(({ typeID, isSuper }) => {
            return isSuper
                ? contract.methods.getPriceForSuperBundle(typeID, walletAddress, PaymentType.GYMNET).call().then((price: any) => {
                    return { typeID, price };
                }).catch(() => {
                    console.log(typeID);
                })
                : contract.methods.getPriceForBundle(typeID, PaymentType.UTILITY).call().then((price: any) => {
                    return { typeID, price }
                });
        });
        const utilityPricesWei = await Promise.all(utilityPriceBundlePromises);
        const utilityPrices = new Map<number, any>();
        utilityPricesWei.forEach(({ typeID, price }) => {
            utilityPrices.set(typeID, { usdt: Number(fromWei(price[0])), gymnet: Number(fromWei(price[2])) });
        });

        return utilityPrices;
    }

    public static async fetchParcelUtilityPrice (count: number, municipalityContract: any) {
        const paymentType = PaymentType.UTILITY;
        try {
            const priceInfo = await municipalityContract.methods.getPriceForPurchaseParcels(count, paymentType).call();
            const result = {
                usdt: parseFloat(fromWei(priceInfo[0]).toString()),
                gymnet: parseFloat(fromWei(priceInfo[2]).toString()),
            };
            return result;
        } catch (e) {
            console.error('Failed to fetch parcel price info', e);
        };
    }
};
