import { MetaWeb3Transaction } from '../models/MetaWeb3Transaction';
import { RawExternalWalletPayment } from '../types/purchase-popup-2/RawExternalWalletPayment';
import ApiService from '~/core/services/api-interaction/ApiService';
import type { BuyCryptoRequest } from '~/core/models/purchase-popup-2/BuyCryptoRequest';
import { MetaWorldManager } from '~/core/services/map/MetaWorldManager';
import {fromWei, toWei} from "~/core/helpers/GlobalHelpers";
import GYMNETService from '~/pages/finances/wallet/services/GYMNETService';
import { CurrencyName } from '~/pages/finances/wallet/models/CurrencyName';
import { OrderStatus } from "~/pages/finances/wallet/constants/OrderStatus";
import { OrderSource } from '~/pages/finances/wallet/constants/OrderSource';
import { OrderPaymentMethod } from '~/pages/finances/wallet/constants/OrderPaymentMethod';
import { OrderProduct } from '~/pages/finances/wallet/constants/OrderProduct';
import type { RawOrderHistoryEntry } from '~/pages/finances/wallet/types/RawOrderHistoryEntry';
import { OrderHistoryEntry } from '~/pages/finances/wallet/models/OrderHistoryEntry';
import { MOONBERG_INITIAL_RATE } from '~/constants/constants';

export class PaymentService {

    static async fetchOrderHistory (
        page = 1,
        perPage = 10,
        filters,
        transformData = true,
    ) {
        ApiService.setHeader();
        const params = {
            page,
            per_page: perPage,
            statuses: filters?.status.length === Object.keys(OrderStatus).length  ? [] : filters?.status,
            transaction_sources: filters?.transaction_source.length === Object.keys(OrderSource).length  ? [] :filters?.transaction_source,
            payment_methods: filters?.payment_method.length === Object.keys(OrderPaymentMethod).length  ? [] : filters?.payment_method,
            products_by_type: filters?.orderProducts.length === Object.keys(OrderProduct).length  ? [] : filters?.orderProducts,
        };
        const response = await ApiService.query('orders/history', {
            params, 
        });
        const entries = response.data.data as RawOrderHistoryEntry[];
        let orderHistory = entries as (RawOrderHistoryEntry | OrderHistoryEntry)[];
        if (transformData) {
            orderHistory = entries.map((item) => new OrderHistoryEntry(item));
        }
        return {
            history: orderHistory,
            meta: {
                page: Number(response.data.meta.page),
                perPage: Number(response.data.meta.per_page),
                total: Number(response.data.meta.total),
            },
        };
    }

    static async getPayment (paymentUID: string, transformData = true) {
        ApiService.setHeader();
        const response = await ApiService.query('payment', {
            params: { payment_uid: paymentUID },
        });

        if (!transformData) {
            return response.data.payment;
        }

        return new MetaWeb3Transaction(response.data.payment);
    }

    static async fetchExchangeRate (currency: string): Promise<{data: {rate: number}}> {
        ApiService.setHeader();
        return await ApiService.query('payment/exchange-rate', { params: { currency } });
    }

    static async getTokenPriceUsd (code: CurrencyName, throwException = false): Promise<number> {

        switch (code) {
            case 'BNB':
                return await MetaWorldManager.sharedInstance().contracts.GymNetwork.methods.getBNBPrice().call();
            case 'BUSD':
                return 1;
            case 'USDT':
                return 1;
            case 'GYMNET':
                return GYMNETService.getRate(MetaWorldManager.sharedInstance());
            case 'BTC':
                try {
                    const resBTC: any = await this.fetchExchangeRate('BTC');
                    return (1 / Number(resBTC.data?.rate)) || 0;
                } catch (e) {
                    if(throwException) {
                        throw e;
                    }
                    return 0;
                }
            case 'ETH':
                try {
                    const resETH: any = await this.fetchExchangeRate('ETH');
                    return (1 / Number(resETH.data?.rate)) || 0;
                } catch (e) {
                    if(throwException) {
                        throw e;
                    }
                    return 0;
                }
            case 'MNBRG': 
                return MOONBERG_INITIAL_RATE;
            case 'BPX':
                try {
                    const usdtAddress = MetaWorldManager.sharedInstance().contracts.USDT._address;
                    const bitopexAddress = MetaWorldManager.sharedInstance().contracts.BitopexToken._address;

                    const resFIL = await MetaWorldManager.sharedInstance().contracts.PancakeRouter.methods.getAmountsOut(
                        toWei('1'), [bitopexAddress, usdtAddress ],
                    ).call();

                    return Number(fromWei(resFIL[1]));
                } catch (e) {
                    if(throwException) {
                        throw e;
                    }
                    console.error(e);
                    return 0;
                }
            case 'FIL':
                try {
                    const usdtAddress = MetaWorldManager.sharedInstance().contracts.USDT._address;
                    const filecoinAddress = MetaWorldManager.sharedInstance().contracts.FileCoin._address;

                    const resFil = await MetaWorldManager.sharedInstance().contracts.PancakeRouter.methods.getAmountsOut(
                        toWei('1'), [filecoinAddress, usdtAddress],
                    ).call();

                    return Number(fromWei(resFil[1]));
                } catch (e) {
                    if(throwException) {
                        throw e;
                    }
                    return 0;
                }
        }
    }

    static async buyCrypto (data: BuyCryptoRequest) {
        ApiService.setHeader();
        const result = await ApiService.post('payment/crypto/buy', data);
        return result.data;
    }

    static async buyCryptoForWallet (data: BuyCryptoRequest) {
        ApiService.setHeader();
        const result = await ApiService.post('payment/crypto/buy-for', data);
        return result.data;
    }

    static async* listenForPayment (
        paymentUID: string,
        interval: number,
        stillRunning: () => boolean,
        transformData = false,
    ) {
        let payment: MetaWeb3Transaction | RawExternalWalletPayment = null;
        while (stillRunning()) {
            try {
                payment = await PaymentService.getPayment(paymentUID, transformData);
            } catch { /* ignore */ } finally {
                yield payment;
            }
            await new Promise(resolve => setTimeout(resolve, interval));
        }
        return payment;
    }
}
  