
import { defineComponent } from 'vue';
import { mapGetters } from 'vuex';
import GymNewButton from '~/components/utils/form-components/GymNewButton.vue';
import InfoTooltip from '~/components/utils/InfoTooltip.vue';
import TotalWidget from '../../components/total-widget/TotalWidget.vue';
import PurchaseExternalWidget from '../../components/purchase-external-widget/PurchaseExternalWidget.vue';
import ClipboardCopier from '~/components/utils/ClipboardCopier.vue';
import { toNumericFormat } from "~/core/helpers/GlobalHelpers";
import { ExternalWalletPayment } from '~/core/models/purchase-popup-2/ExternalWalletPayment';
import { PaymentMethodEnum } from '~/core/types/purchase-popup-2/PaymentMethodEnum';
import { UserBalance } from '~/core/models/purchase-popup-2/UserBalance';
import { PaymentService } from '~/core/services/PaymentService';
import { PaymentStatus } from '~/core/types/purchase-popup-2/PaymentStatus';
import { RawExternalWalletPayment } from '~/core/types/purchase-popup-2/RawExternalWalletPayment';
import { BuyCryptoRequest } from '~/core/models/purchase-popup-2/BuyCryptoRequest';

const PAYMENT_UPDATE_INTERVAL = 5000;
const MARCH_19_2024 = 1710846000000;

export default defineComponent({
    name: 'ExternalWalletMissingAmount',
    components: {
        PurchaseExternalWidget,
        TotalWidget,
        InfoTooltip,
        GymNewButton,
        ClipboardCopier,
    },
    props: {
        transactionObject: {
            type: ExternalWalletPayment,
            required: true,
        },
        userBalance: {
            type: UserBalance,
            default: null,
        },
    },
    emits: [
        'onRequestAnotherQuote',
        'onCryptoSentClick',
        'onMissingCryptoAmount',
        'onSuccessFullPayment',
        'onStartLoading',
        'onStopLoading',
    ],
    data() {
        return {
            toNumericFormat,
            PaymentMethodEnum,
            scanInterval: false,
        }
    },
    computed: {
        ...mapGetters('application/purchase-control', [
            'externalPayment',
        ]),
        ...mapGetters({
            authUser: "application/driver/authUser"
        }),
        isRegisteredAfterMarch19() {
            var userTransactionTimestamp = new Date(this.transactionObject?.rawPaymentData?.created_at).getTime(); // Convert to timestamp

            return userTransactionTimestamp >= MARCH_19_2024;
        }
    },
    mounted () {
        if(!this.transactionObject.isExpired) {
            this.setupScanInterval();
        }
    },
    beforeDestroy() {
        this.scanInterval = false;
    },
    methods: {
        async requestAnotherQuote () {
            this.$emit('onStartLoading');
            try {
                const currencyAsset = this.transactionObject.totalAmountAsset;
                const currency = this.transactionObject.cryptocurrencyObject;
                const buyData: BuyCryptoRequest = {
                    bundle_sc_id: this.transactionObject.bundleType,
                    product_type: this.transactionObject.productType,
                    quantity: this.transactionObject.quantity,
                    currency: currency.code,
                };
                let rawExternalPayment = null;
                if(!this.transactionObject.owner) {
                    rawExternalPayment = await PaymentService.buyCrypto(buyData)
                } else {
                    rawExternalPayment = await PaymentService.buyCryptoForWallet({
                        ...buyData,
                        wallet_address: this.transactionObject.owner,
                    });
                }
                const externalPayment = new ExternalWalletPayment(
                    rawExternalPayment,
                    currencyAsset,
                    currency,
                );
                this.$emit('onRequestAnotherQuote', externalPayment);
                this.$emit('onStopLoading');
            } catch(e) {
                console.log(e);
                this.$emit('onStopLoading', e);
            }
        },
        contactSupport () {
            let link = `mailto:support@gymstreet.io?subject=Payment ${this.transactionObject.paymentId}&body=I have a problem with my payment ${this.transactionObject.paymentId}. It is expired.`;
            if(Number(this.transactionObject.missingAmountAsset.value)) {
                link += ` I\'ve sent ${this.transactionObject.missingAmountAsset.value} ${this.transactionObject.missingAmountAsset.currency}.`;
            }
            window.open(link);
        },
        handleISentTheCrypto () {
            this.$emit('onCryptoSentClick')
        },
        async setupScanInterval () {
            this.scanInterval = true;
            const paymentUpdateGenerator = PaymentService.listenForPayment(
                this.transactionObject.paymentId,
                PAYMENT_UPDATE_INTERVAL,
                () => this.scanInterval,
            );
            for await (const payment of paymentUpdateGenerator) {
                if(payment) {
                    const rawPayment = payment as RawExternalWalletPayment;
                    if (rawPayment.status === PaymentStatus.EXPIRED ||
                        rawPayment.status === PaymentStatus.CREATED && Number(rawPayment.deviation_amount) < 0 ||
                        rawPayment.grant_transaction_hash !== null) {
                        this.scanInterval = false;
                        if(this.externalPayment || this.transactionObject === this.externalPayment) {
                            this.$store.commit('application/purchase-control/SET_EXTERNAL_PAYMENT',
                                ExternalWalletPayment.fromPaymentAndNewRawData(
                                    this.transactionObject,
                                    rawPayment,
                                )
                            );
                        } else {
                            this.transactionObject.rawPaymentData = rawPayment;
                        }
                        if(rawPayment.status === PaymentStatus.EXPIRED ||
                            rawPayment.status === PaymentStatus.CREATED && Number(rawPayment.deviation_amount) < 0) {
                            this.$emit('onMissingCryptoAmount');
                        } else if (rawPayment.grant_transaction_hash !== null) {
                            this.$emit('onSuccessFullPayment');
                        }
                    }
                }
            }
        },
    }
})
