import ApiService from "~/core/services/api-interaction/ApiService";
import { ACTIONS, MUTATIONS } from "./names";
import { ACTIONS as AUTH_ACTIONS } from "../../auth/names";
import { ACTIONS as USER_FEEDBACK_ACTIONS } from "../../feedback/names";
import type { AxiosRequestConfig } from "axios";

export default {
    [ACTIONS.CLEAR_UPGRADING_TO_AFFILIATE_STATE]({ commit }) {
        commit(MUTATIONS.SET_IS_UPGRADING_TO_AFFILIATE, false);
    },
    [ACTIONS.START_UPGRADE_TO_AFFILIATE]({ commit, dispatch }) {
        commit(MUTATIONS.SET_IS_UPGRADING_TO_AFFILIATE, true);
    },
    [ACTIONS.CANCEL_UPGRADE_TO_AFFILIATE]({ commit }) {
        commit(MUTATIONS.SET_IS_UPGRADING_TO_AFFILIATE, false);
    },
    async [ACTIONS.UPGRADE_TO_AFFILIATE_UPDATE_USER_AND_SHOW_NOTIFICATION]({ commit, dispatch }) {
        await dispatch(ACTIONS.UPGRADE_TO_AFFILIATE_AND_UPDATE_USER);
        return dispatch(ACTIONS.CLOSE_AND_SHOW_UPGRADE_TO_AFFILIATE_NOTIFICATION);
    },
    async [ACTIONS.UPGRADE_TO_AFFILIATE_AND_UPDATE_USER]({ dispatch }) {
        await dispatch(ACTIONS.UPGRADE_TO_AFFILIATE);
        const responseBinary = await ApiService.get(`${this.$config.binaryTreeApi}/api/users`);
        
        dispatch(`affility/user/auth/${AUTH_ACTIONS.UPDATE_USER_AUTH_DATA}`, {
            isReferralLinkActive: responseBinary?.data?.user?.isReferralLinkActive,
            subscriptionExpiresAt: responseBinary?.data?.subscription_expires_at,
            isFastStartIncentiveActive: responseBinary?.data?.user?.isFastStartIncentiveActive,
            is_fast_start_incentive_active: responseBinary?.data?.user?.isFastStartIncentiveActive,
            is_referral_link_active: responseBinary?.data?.user?.isReferralLinkActive,
            subscription_expires_at: responseBinary?.data?.subscription_expires_at,
        }, { root: true });
    },
    async [ACTIONS.UPGRADE_TO_AFFILIATE]() {
        return ApiService.patch(`${this.$config.binaryTreeApi}/api/users`, {
            is_referral_link_active: true
        } as AxiosRequestConfig);
    },
    [ACTIONS.CLOSE_AND_SHOW_UPGRADE_TO_AFFILIATE_NOTIFICATION]({ dispatch }) {
        dispatch(ACTIONS.CANCEL_UPGRADE_TO_AFFILIATE);
        return dispatch(ACTIONS.SHOW_UPGRADE_TO_AFFILIATE_NOTIFICATION);
    },
    [ACTIONS.SHOW_UPGRADE_TO_AFFILIATE_NOTIFICATION]({ dispatch }) {
        return dispatch(`affility/user/feedback/${USER_FEEDBACK_ACTIONS.SHOW_SUCCESS_MODAL}`, {
            desc: this.app.i18n.t('upgrade_to_affiliate_success'),
        }, { root: true });
    },

};