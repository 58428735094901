import AuthService from '../services/api-interaction/AuthService';
import { MetaWorldManager } from '../services/map/MetaWorldManager'
import ERROR_MESSAGES from './error-messages'
import { GlobalMakerService } from "~/core/services/GlobalMakerService";



export function isValidEmail (value) {
    const regex = /^[a-zA-Z0-9._-]+(?:\+\d+)*@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/g
    return regex.test(value)
}

export async function validateEmailAndGetWalletAddress (_: string, value: string, callback: Function) {
    const isValid = isValidEmail(value)
    if (!isValid) {
        callback(new Error(GlobalMakerService.$i18n.t(ERROR_MESSAGES.EMAIL_ERR.INVALID)))
        return
    }
    try {
        const address = await AuthService.getAddressByEmail(value);
        if(address) {
            callback();
            return address;
        } else {
            callback(new Error(GlobalMakerService.$i18n.t(ERROR_MESSAGES.USER_ERR.NO_WALLET_FOUND)))
        }
    } catch(e) {
        callback(new Error(GlobalMakerService.$i18n.t(ERROR_MESSAGES.USER_ERR.NOT_EXISTS)))
    }
}


export function isCurrentUserEmail (currentUserEmail: string, _, value: string, callback: Function) {
    if (currentUserEmail === value) {
        callback(new Error(GlobalMakerService.$i18n.t(ERROR_MESSAGES.USER_ERR.NO_GIFT)));
        return [GlobalMakerService.$i18n.t(ERROR_MESSAGES.USER_ERR.NO_GIFT)];
    }
    callback();
}
