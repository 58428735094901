import { render, staticRenderFns } from "./Mobile.vue?vue&type=template&id=aea98320&scoped=true"
import script from "./Mobile.vue?vue&type=script&setup=true&lang=ts"
export * from "./Mobile.vue?vue&type=script&setup=true&lang=ts"
import style0 from "./Mobile.vue?vue&type=style&index=0&id=aea98320&prod&scoped=true&lang=scss"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "aea98320",
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {BaseImageSvgThemedIcon: require('/root/dashboard/core/components/base/image/SvgThemedIcon.vue').default,ComposedSelectMetaverse: require('/root/dashboard/core/components/composed/select/Metaverse.vue').default,ComposedHeaderBalanceSection: require('/root/dashboard/core/components/composed/header/BalanceSection.vue').default,ComposedActionAuthButtons: require('/root/dashboard/core/components/composed/action/AuthButtons.vue').default,ComposedButtonNotificationToggle: require('/root/dashboard/core/components/composed/button/NotificationToggle.vue').default,ComposedImageBlockiesAvatar: require('/root/dashboard/core/components/composed/image/BlockiesAvatar.vue').default})
