import BigNumber from "bignumber.js";
import {fromWei, getClientRuntimeConfig} from "~/core/helpers/GlobalHelpers";
import {MetaWorldManager} from "~/core/services/map/MetaWorldManager";
import {MetaInventoryContainer} from "~/core/models/MetaInventoryContainer";

BigNumber.config({DECIMAL_PLACES: 18});

const $config = getClientRuntimeConfig();
const GYMNET_API_URL = $config.gymnetApi;

export const state = () => {
    return {
        gymStreetLevelPoolData: {
            totalClaims: 0,
            pendingGYMNET: 0,
            pendingBUSD: 0
        },
        totalClaimedBUSD_ETH: 0,
        totalClaimedGYMNETETH: 0,
        GSTransactionLoading: false,
        GSTransactionData: []
    };
};

export const actions = {
    async load({commit, rootState}) {
        try {

            const address = MetaWorldManager.sharedInstance().getWalletAddressForFetchInfo();
            if (address) {
                const gymStreetLevelPool    = await this.$contracts[this.$metaverseId()].GymStreetLevelPool.methods.userRewardInfo(address).call();
                const totalClaimedGYMNETWEI = gymStreetLevelPool.gymnetClaimedAmount;
                const totalClaimedGYMNETETH = fromWei(totalClaimedGYMNETWEI);

                let GYMPrice = MetaInventoryContainer.sharedInstance().minerRewardsData.globalStatistics.price;
                const totalCLaimedGymnetInUSD = totalClaimedGYMNETETH * GYMPrice;
                const totalClaimedBUSD_WEI    = gymStreetLevelPool.busdClaimedAmount
                const totalClaimedBUSD_ETH    = fromWei(totalClaimedBUSD_WEI);
                const totalClaimedDollarValue = totalClaimedBUSD_ETH + totalCLaimedGymnetInUSD;

                const pendingRewardsGymnet_WEI = gymStreetLevelPool.gymnetAmount;
                const pendingRewardsGymnet_ETH = fromWei(pendingRewardsGymnet_WEI);

                const pendingRewardsBUSD_WEI = gymStreetLevelPool.busdAmount;
                const pendingRewardsBUSD_ETH = fromWei(pendingRewardsBUSD_WEI);
                const gymStreetLevelPoolData = {
                    totalClaims: parseFloat(totalClaimedDollarValue).toFixed(2),
                    pendingGYMNET: parseFloat(pendingRewardsGymnet_ETH).toFixed(2),
                    pendingBUSD: parseFloat(pendingRewardsBUSD_ETH).toFixed(2)
                }
                commit("SET_DATA", {
                    gymStreetLevelPoolData,
                    totalClaimedBUSD_ETH,
                    totalClaimedGYMNETETH
                });
            }

        } catch (error) { // TODO check usages and refactor
            console.error("Unknown error at user/gymstreetLevelPool load", error);
        }
    },
    claimAll({dispatch}) {
        return dispatch(
            "transactions/send",
            {
                callback: this.$write[this.$metaverseId()].GymStreetLevelPool.methods.claimAll(),
            },
            {root: true}
        ).then(() => {
            return dispatch("load");
        });
    },
    async getUserLevelGymStreetTransaction({commit}, address) {
        commit('SET_GS_TRANSACTION_LOADING', true)
        try {
            const GSTransaction     = await this.$api.get(`${GYMNET_API_URL}/api/get-user-gym-street-level-pool-snapshots?address=${address}`);
            const GSTransactionData = GSTransaction?.data?.userData;
            commit('SET_GS_TRANSACTION_DATA', GSTransactionData);
        } finally {
            commit('SET_GS_TRANSACTION_LOADING', false)
        }
    },

};

export const mutations = {
    SET_DATA(state, data) {
        for (const key in data) {
            state[key] = data[key];
        }
    },
    SET_GS_TRANSACTION_LOADING(state, value) {
        state.GSTransactionLoading = value;
    },
    SET_GS_TRANSACTION_DATA(state, data) {
        state.GSTransactionData = data;
    }
};

export const getters = {
    data: (state) => state,
    GSTransactionData: ({GSTransactionData}) => GSTransactionData,
    GSTransactionLoading: ({GSTransactionLoading}) => GSTransactionLoading,
};
