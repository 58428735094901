import { getStorePollingNames } from "~/core/utilities/store-polling/getStorePollingNames";

export const UNIQUE_IDENTIFIER = 'binary' as const;
export const POLLING_NAMES = getStorePollingNames(UNIQUE_IDENTIFIER);

export const STATE = {
    LEFT_CV: 'leftCv',
    RIGHT_CV: 'rightCv',
    LEFT_SUBTREE_SIZE: 'leftSubtreeSize',
    RIGHT_SUBTREE_SIZE: 'rightSubtreeSize',
    LEFT_SUBTREE_ROOT: 'leftSubtreeRoot',
    RIGHT_SUBTREE_ROOT: 'rightSubtreeRoot',
    BINARY_PATH: 'binaryPath',
    BINARY_RANK_NAME: 'binaryRankName',
    SUBTREE_DATA_LOADING: 'subtreeDataLoading',
    SUBTREE_DATA_LOADING_ERROR: 'subtreeDataLoadingError',
    SUBTREE_DATA_LOADING_PROMISE: 'subtreeDataLoadingPromise',
    ...POLLING_NAMES.state,
} as const;

export const ACTIONS = {
    LOAD_PROCESS_AND_SAVE_SUBTREE_DATA: 'loadProcessAndSaveSubtreeData',
    LOAD_AND_PROCESS_SUBTREE_DATA: 'loadAndProcessSubtreeData',
    LOAD_SUBTREE_DATA: 'loadSubtreeData',
    PROCESS_SUBTREE_DATA: 'processSubtreeData',
    SAVE_SUBTREE_DATA: 'saveSubtreeData',
    CLEAR_BINARY_STATE: 'clearBinaryState',
    ...POLLING_NAMES.actions,
} as const;

export const MUTATIONS = {
    SET_LEFT_CV: 'SET_LEFT_CV',
    SET_RIGHT_CV: 'SET_RIGHT_CV',
    SET_LEFT_SUBTREE_SIZE: 'SET_LEFT_SUBTREE_SIZE',
    SET_RIGHT_SUBTREE_SIZE: 'SET_RIGHT_SUBTREE_SIZE',
    SET_LEFT_SUBTREE_ROOT: 'SET_LEFT_SUBTREE_ROOT',
    SET_RIGHT_SUBTREE_ROOT: 'SET_RIGHT_SUBTREE_ROOT',
    SET_BINARY_PATH: 'SET_BINARY_PATH',
    SET_BINARY_RANK_NAME: 'SET_BINARY_RANK_NAME',
    SET_SUBTREE_DATA_LOADING: 'SET_SUBTREE_DATA_LOADING',
    SET_SUBTREE_DATA_LOADING_ERROR: 'SET_SUBTREE_DATA_LOADING_ERROR',
    SET_SUBTREE_DATA_LOADING_PROMISE: 'SET_SUBTREE_DATA_LOADING_PROMISE',
    ...POLLING_NAMES.mutations,
} as const;

export const GETTERS = {
    LEFT_CV: 'leftCv',
    RIGHT_CV: 'rightCv',
    LEFT_SUBTREE_SIZE: 'leftSubtreeSize',
    RIGHT_SUBTREE_SIZE: 'rightSubtreeSize',
    LEFT_SUBTREE_ROOT: 'leftSubtreeRoot',
    RIGHT_SUBTREE_ROOT: 'rightSubtreeRoot',
    BINARY_PATH: 'binaryPath',
    BINARY_RANK_NAME: 'binaryRankName',
    SUBTREE_DATA_LOADING: 'subtreeDataLoading',
    SUBTREE_DATA_LOADING_ERROR: 'subtreeDataLoadingError',
    SUBTREE_DATA_LOADING_PROMISE: 'subtreeDataLoadingPromise',
    ...POLLING_NAMES.getters,
} as const;