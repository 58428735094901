export const constructSignatureMessage = (contents: string[]): string => {
    let messageContent = '';
    if (!contents.length) {
        return '';
    } else {
        for (let i = 0; i < contents.length; i++) messageContent += contents[i] + '\n\n'
    }

    return messageContent;
}
