import { GETTERS } from "./names";
import { POLLING_STORE_FUNCTIONS } from "./polling";
import type { State } from "./types";
import type { BalanceInfo } from "~/pages/finances/commissions/types/BalanceInfo";

export default {
    ...POLLING_STORE_FUNCTIONS.getters,
    [GETTERS.BINARY_WEEKLY_CAP]: (state: State) => state.binaryWeeklyCap,
    [GETTERS.BINARY_CURRENT_PROGRESS]: (state: State) => state.binaryCurrentProgress,
    [GETTERS.TOTAL_BINARY_COMMISSION]: (state: State) => state.totalBinaryCommission,
    [GETTERS.CLAIMED_COMMISSIONS]: (state: State) => state.claimedCommissions,
    [GETTERS.AVAILABLE_COMMISSIONS]: (state: State) => state.availableCommissions,
    [GETTERS.COLLECTED_BINARY_BONUS]: (state: State) => state.collectedBinaryBonus,
    [GETTERS.COLLECTED_MATCHING_BONUS]: (state: State) => state.collectedMatchingBonus,
    [GETTERS.TOTAL_COMMISSIONS]: (state: State, getters) => {
        const balanceArray = [
            getters[GETTERS.CLAIMED_COMMISSIONS],
            getters[GETTERS.AVAILABLE_COMMISSIONS],
            getters[GETTERS.COLLECTED_BINARY_BONUS],
            getters[GETTERS.COLLECTED_MATCHING_BONUS],
        ];

        const total = balanceArray.reduce((acc, balance) => {
            if(balance) {
                acc.USDT = Number(acc.USDT) + balance.USDT;
                acc.GYMNET = Number(acc.GYMNET) + balance.GYMNET;
                acc.UTILITY = Number(acc.UTILITY) + balance.UTILITY;
            }
            return acc;
        }, { USDT: null, GYMNET: null, UTILITY: null });

        return total as BalanceInfo;
    },
    [GETTERS.DEFAULT_DISTRIBUTION_DATE]: (state: State) => {
        // not implemented and the dependent code is not used,
        // just left it here in case it will be used in the future
        return new Date();
    },
    [GETTERS.BINARY_BONUS_DISTRIBUTION_TIME]: (state: State, getters) => {
        if (!getters[GETTERS.COLLECTED_BINARY_BONUS].distributionTime) {
            return getters[GETTERS.DEFAULT_DISTRIBUTION_DATE];
        }
        return new Date(getters[GETTERS.COLLECTED_BINARY_BONUS].distributionTime);
    },
    [GETTERS.MATCHING_BONUS_DISTRIBUTION_TIME]: (state: State, getters) => {
        if (!getters[GETTERS.COLLECTED_MATCHING_BONUS].distributionTime) {
            return getters[GETTERS.DEFAULT_DISTRIBUTION_DATE];
        }
        return new Date(getters[GETTERS.COLLECTED_MATCHING_BONUS].distributionTime);
    },
    [GETTERS.COMMISSIONS_INFO_DATA_LOADING]: (state: State) => state.commissionsInfoDataLoading,
    [GETTERS.COMMISSIONS_INFO_DATA_LOADING_ERROR]: (state: State) => state.commissionsInfoDataLoadingError,
    [GETTERS.COMMISSIONS_INFO_DATA_LOADING_PROMISE]: (state: State) => state.commissionsInfoDataLoadingPromise,
}