import ApiService from "~/core/services/api-interaction/ApiService";

export const GLOBAL_POOL_TYPES = {
    CROWN_DIAMOND: 'Crown Diamond Pool',
    WHITE_DIAMOND: 'White Diamond Pool',
    RED_DIAMOND: 'Red Diamond Pool',
    DOUBLE_DIAMOND: 'Double Diamond Pool',
    DIAMOND: 'Diamond Pool',
}

// States
export const state = () => {
    return {
        globalTurnoverPoolLoading: true,
        globalTurnoverPool: {
            turnover: 0,
            childTurnovers: [],
            poolsQualifications: {}
        },
        globalTurnoverPoolHistoryLoading: true,
        globalTurnoverPoolHistory: {
            data: [],
            total: 0,
        },
        globalTurnoverPoolRulesData: {
            requiredTurnover: 0,
            usersData: []
        },
    }
}

// Actions
export const actions = {
    async getGlobalTurnoverPool( { commit } ) {
        try {
            const res = await ApiService.get(`${this.$config.binaryTreeApi}/api/global-pool/status`);
            commit('SET_GLOBAL_TURNOVER_POOL_DATA', res.data);
        } catch(e) {
            console.log(e);
        }
    },
    async getGlobalTurnoverPoolHistory( { commit }, { page, perPage } ) {
        commit('SET_GLOBAL_TURNOVER_POOL_HISTORY_LOADING', true);
        try {
            const res = await ApiService.query(`${this.$config.binaryTreeApi}/api/global-pool/bonus-history`, {
                params: { page, perPage }
            });
            commit('SET_GLOBAL_TURNOVER_POOL_HISTORY_DATA', res.data);
        } catch(e) {
            console.log(e);
        } finally {
            commit('SET_GLOBAL_TURNOVER_POOL_HISTORY_LOADING', false);
        }
    },
    openRulesPopup({ commit }, {milestone, poolData}) {
        commit('SET_RULES_POPUP_DATA', {milestone, poolData});
    }
}

// Mutations
export const mutations = {
    SET_GLOBAL_TURNOVER_POOL_LOADING: (state, payload) => {
        state.globalTurnoverPoolLoading = payload;
    },
    SET_GLOBAL_TURNOVER_POOL_DATA: (state, payload) => {
        state.globalTurnoverPool.turnover = payload.turnover || 0;
        state.globalTurnoverPool.childTurnovers = payload.childTurnovers || [];
        (payload?.poolsQualifications || []).forEach(( {pool, turnover} ) => {
            state.globalTurnoverPool.poolsQualifications[pool] = turnover;
        });
    },
    SET_GLOBAL_TURNOVER_POOL_HISTORY_LOADING: (state, payload) => {
        state.globalTurnoverPoolHistoryLoading = payload;
    },
    SET_GLOBAL_TURNOVER_POOL_HISTORY_DATA: (state, payload) => {
        state.globalTurnoverPoolHistory.data = payload.data;
        state.globalTurnoverPoolHistory.total = payload.meta.total;

    },
    SET_RULES_POPUP_DATA: (state, {milestone, poolData}) => {
        state.globalTurnoverPoolRulesData.usersData = [];
        state.globalTurnoverPoolRulesData.requiredTurnover = parseInt(milestone);
        state.globalTurnoverPoolRulesData.poolData = poolData;
        const descendingSort = (state.globalTurnoverPool.childTurnovers || []).sort((a, b) => b.turnover - a.turnover);
        descendingSort.forEach(( user ) => {
            const percentage = (parseFloat(user.turnover) * 100) / state.globalTurnoverPoolRulesData.requiredTurnover;
            const payload = {
                generatedTurnover: user.turnover,
                qualifiedTurnover: user.turnover >= (milestone / 2) ? (milestone / 2) : user.turnover,
                username: user.username,
                walletAddress: user.wallet_address,
                percentage: percentage < 50 ? percentage : 50,
            };
            state.globalTurnoverPoolRulesData.usersData.push(payload);
        });
    }
}

// Getters
export const getters = {
    globalTurnoverPoolLoading: ({ globalTurnoverPoolLoading }) => globalTurnoverPoolLoading,
    globalTurnoverPool: ({ globalTurnoverPool }) => globalTurnoverPool,
    globalTurnoverPoolHistoryLoading: ({ globalTurnoverPoolHistoryLoading }) => globalTurnoverPoolHistoryLoading,
    globalTurnoverPoolHistory: ({ globalTurnoverPoolHistory }) => globalTurnoverPoolHistory,
    globalTurnoverPoolRulesData: ({ globalTurnoverPoolRulesData }) => globalTurnoverPoolRulesData,
}

const getLeftToQualifyNumber = (required, generated) => {
    return generated > required ? 0 : required - generated;
}
