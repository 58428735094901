import { MetaBundle } from "~/core/models/MetaBundle";
import { MysteryBoxPrize } from "../models/MysteryBoxPrize";
import { MysteryBoxPrizeType } from "../constants/MysteryBoxPrizeType";
import { PrizeBundleIdByType } from "../constants/PrizeBundleIdByType";
import Asset from "~/pages/finances/wallet/models/Asset";

const MINER_PRICE_USD = 50;

export class MysteryBox {
    public realPrice: Asset;
    public prizeList: MysteryBoxPrize[];

    constructor (
        public type: number,
        public price: Asset,
        public discount: number,
        chances: number[],
        bundles: MetaBundle[],
        parcelPrice: number,
    ) {
        this.realPrice = new Asset(
            null,
            "Gym Network Token",
            "GYMNET",
            require("~/assets/images/icons/gymnet-icon.svg"),
            Math.ceil(price.value / (100 - discount) * 100),
            price.rate,
            false,
            null,
        );
        const prizesCount = chances.length / 2;
        const prizeList = [];
        for (let i = 0; i < prizesCount; i++) {
            const chance = Number(chances[i * 2]) / 100; // chance is integer and is equal to (chance * 100) because of solidity limitations
            const quantity = Number(chances[i * 2 + 1]); // array is formatted as [chance, quantity, chance, quantity, ...]
            const id = MysteryBoxPrizeType.PRIZE_LAND + i * 10; // prize types increase by 10 starting from 100 (100, 110, 120, ...)
            if (chance !== 0 && quantity !== 0) {
                let price = 0;
                let bundle = null;
                if (id === MysteryBoxPrizeType.PRIZE_LAND) {
                    price = parcelPrice;
                } else if (id === MysteryBoxPrizeType.PRIZE_LAND_AND_MINER) {
                    price = parcelPrice + MINER_PRICE_USD;
                } else {
                    bundle = bundles.find((bundle) => bundle.smartContractId === PrizeBundleIdByType[id]);
                    if (bundle) {
                        price = Math.ceil(bundle.discountedPrice);
                    }
                }
                prizeList.push(
                    new MysteryBoxPrize(chance, id, quantity, price, bundle),
                );
            }
        }
        this.prizeList = prizeList;
    }
}