import { render, staticRenderFns } from "./GymSignInPopup.vue?vue&type=template&id=67ca5bcb&scoped=true"
import script from "./GymSignInPopup.vue?vue&type=script&lang=ts&setup=true"
export * from "./GymSignInPopup.vue?vue&type=script&lang=ts&setup=true"
import style0 from "./GymSignInPopup.vue?vue&type=style&index=0&id=67ca5bcb&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "67ca5bcb",
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {BaseButtonClose: require('/root/dashboard/core/components/base/button/Close.vue').default,FlowsSigninAuthCore: require('/root/dashboard/core/components/flows/signin/AuthCore.vue').default,FlowsSigninComponentsLanguagesDropdown: require('/root/dashboard/core/components/flows/signin/components/LanguagesDropdown.vue').default,ComposedPopupFullscreenAffility: require('/root/dashboard/core/components/composed/popup/FullscreenAffility.vue').default})
