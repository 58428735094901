
import { copyToClipboard } from '@/core/helpers/GlobalHelpers'

export default {
    name: 'ClipboardCopier',
    props: {
        value: {
            type: String,
            required: true
        },
        copyTimeout: {
            type: Number,
            default: 2000
        },
        initialTimeout: {
            type: Number,
            default: null
        },
        icon: {
            type: String,
            default: require('~/assets/images/icons/copy-blue.svg')
        },
        iconCopied: {
            type: String,
            default: require('~/assets/images/icons/upgraded.svg')
        },
        overInput: {
            type: Boolean,
            default: false
        }
    },
    emits: ['onCopy', 'onCanCopy'],
    data () {
        return {
            timer: null
        }
    },
    mounted () {
        if (this.initialTimeout) {
            this.setCopyTimeout(this.initialTimeout)
        }
    },
    methods: {
        clearTimer () {
            if (this.timer) {
                clearTimeout(this.timer)
                this.timer = null
            }
        },
        setCopyTimeout (timer) {
            this.clearTimer()
            this.timer = setTimeout(() => {
                this.timer = null
                this.$emit('onCanCopy')
            }, timer)
        },
        copy () {
            copyToClipboard(this.value)
            this.setCopyTimeout(this.copyTimeout)
            this.$emit('onCopy')
        }
    }
}
