import {
    PARCEL_UPGRADED_MINERS_COUNT,
    PARCEL_MINERS_COUNT,
    PARCEL_LAND_TYPES,
    PARCEL_TYPES,
    PARCEL_OWNERSHIP_TYPES,
    PARCEL_STATES,
} from "~/core/services/utils/Constants";
import { MetaParcelsRegistry } from "./MetaParcelsRegistry";
import { MetaColorRegistry } from "./MetaColorRegistry";


// This language has no support for doing this better
export class MetaParcelDelegate {
    dataModified() {
        throw new Error("Not implemented");
    }
}

/**
 * Parcel object, that is going to be used with the MetaCoordinateSystem.
 * Width and height parameters are given for achieving max genericity, but in fact it will always be a square.
 */
export class MetaParcel {
    constructor(data) {
        this.id = null;
        this.x = data.x;
        this.y = data.y;
        this.pt = data.pt;
        this.lt = data.lt;
        this.delegate = null;
        this.ownerId = null;
        this.ownerWalletAddress = null;
        this.reservedArea = null;
        this.image = null;
        this.selfOwned = false;
        this.state = PARCEL_STATES.NORMAL;
        this.color = this._requestColorFromRegistry();
    }

    _requestColorFromRegistry() {
        const parcelType = this.pt;
        const landType = this.lt;
        const ownershipType = this.getOwnershipType();
        return MetaColorRegistry.getParcelColor(landType, parcelType, ownershipType);
    }

    getOwnershipType() {
        let ownershipType = PARCEL_OWNERSHIP_TYPES.FREE;
        if(this.isSelfOwned()) {
            ownershipType = PARCEL_OWNERSHIP_TYPES.SELF_OWNED;
        } else if (this.isOwned()) {
            ownershipType = PARCEL_OWNERSHIP_TYPES.OWNED;
        }
        return ownershipType;
    }

    tokenId() {
        return this.id ? this.id : `${this.x}:${this.y}`;
    }

    isOwned() {
        return this.ownerId !== null;
    }

    isSelfOwned() {
        return this.selfOwned;
    }

    isReserved() {
        return this.reservedArea !== null;
    }

    isImage() {
        return this.image !== null;
    }

    imageWalletAddress() {
        return this.isImage() ? this.image.wallet_address : null;
    }

    imagePath() {
        return this.isImage() ? this.image.image_path : null;
    }

    setDelegate(delegate) {
        this.delegate = delegate;
    }

    setNormalState() {
        this.state = PARCEL_STATES.NORMAL;
        if(this.delegate) {
            this.delegate.dataModified();
        }
    }

    setSelected(isSquareShapeAchieved) {
        this.state = PARCEL_STATES.SELECTED;
        if(this.delegate) {
            this.delegate.dataModified(isSquareShapeAchieved);
        }
    }

    setHovered() {
        this.state = PARCEL_STATES.HOVERED;
        if(this.delegate) {
            this.delegate.dataModified();
        }
    }

    changeColor() {
        this.color = this._requestColorFromRegistry();
        if(this.delegate) {
            this.delegate.dataModified();
        }
    }

    parcelName() {
        let name = "Standard parcel";
        switch(this.lt) {
            case PARCEL_LAND_TYPES.NEAR_OCEAN:
                name = "Near Ocean";
                break;
            case PARCEL_LAND_TYPES.OCEAN:
                name = "Ocean";
                break;
        }

        return name;
    }

    isUpgraded() {
        return this.pt === PARCEL_TYPES.UPGRADED;
    }

    isBasic() {
        return this.pt === PARCEL_TYPES.STANDARD;
    }

    isParcelSelfOwned(dx, dy) {
        return MetaParcelsRegistry.sharedInstance().
            parcelAt(this.y + dy, this.x + dx)?.ownerWalletAddress === this.ownerWalletAddress;
    }
}
