import { ACTIONS } from "./names";
import { ACTIONS as RANK_ACTIONS } from "../../user/rank/names";
import { ACTIONS as TEAM_ACTIONS } from "../../user/team/names";
import { ACTIONS as BINARY_SUBTREE_ACTIONS } from "../../user/binary/names";
import { ACTIONS as PROMOS_ACTIONS } from "../../promos/names";
import { ACTIONS as DASHBOARD_TOTAL_INCOME_ACTIONS } from "./income/names";

export default {
    [ACTIONS.POLL_DASHBOARD_DATA] ({ dispatch, commit, getters },payload) {
        dispatch(`affility/user/rank/${RANK_ACTIONS.POLL_DATA}`, null, { root: true });
        dispatch(`affility/user/team/${TEAM_ACTIONS.POLL_DATA}`, null, { root: true });
        dispatch(`affility/user/binary/${BINARY_SUBTREE_ACTIONS.POLL_DATA}`, null, { root: true });
        dispatch(`affility/pages/dashboard/income/${DASHBOARD_TOTAL_INCOME_ACTIONS.POLL_DATA}`, null, { root: true });
        dispatch(`affility/promos/${PROMOS_ACTIONS.GET_PROMOS}`, payload, { root: true });
    },
    [ACTIONS.STOP_POLLING] ({ dispatch, commit, getters }) {
        dispatch(`affility/user/rank/${RANK_ACTIONS.STOP_POLLING}`, null, { root: true });
        dispatch(`affility/user/team/${TEAM_ACTIONS.STOP_POLLING}`, null, { root: true });
        dispatch(`affility/user/binary/${BINARY_SUBTREE_ACTIONS.STOP_POLLING}`, null, { root: true });
        dispatch(`affility/pages/dashboard/income/${DASHBOARD_TOTAL_INCOME_ACTIONS.STOP_POLLING}`, null, { root: true });
    },
};
