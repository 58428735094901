import { STATE } from "./names";

export default () => {
    return {
        [STATE.NOTIFICATIONS_HISTORY_LOAD_COUNT]: 10,
        [STATE.TOTAL_NOTIFICATIONS_COUNT]: 0,
        [STATE.NOTIFICATION_HISTORY]: [],
        [STATE.NOTIFICATIONS_HISTORY_LOADING]: false,
        [STATE.NOTIFICATIONS_HISTORY_LOADING_ERROR]: null as unknown | null,
        [STATE.NOTIFICATIONS_HISTORY_LOADING_PROMISE]: null as Promise<unknown> | null,
    };
};