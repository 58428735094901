import { PARCEL_LAND_TYPES, PARCEL_TYPES, PARCEL_OWNERSHIP_TYPES } from "../utils/Constants";
import { MetaColor } from "./MetaColor";
import {
    OCEAN_COLORS,
    NEAR_OCEAN_COLORS,
    INLAND_COLORS,
    RESERVED_COLORS,
    BUSINESS_COLORS,
    OWNED_COLORS,
    SELF_OWNED_COLORS
} from "../utils/ColorConstants";

export class MetaColorRegistry {
    static parcelColors = new Map();

    constructor() {
    }

    static getParcelColor(landType, parcelType, ownershipType) {
        const colorId = '' + landType + parcelType + ownershipType;
        
        if(MetaColorRegistry.parcelColors.has(colorId)) {
            return MetaColorRegistry.parcelColors.get(colorId);
        }

        const newColor = MetaColorRegistry.createColor(landType, parcelType, ownershipType);
        if(newColor) {
            MetaColorRegistry.parcelColors.set(colorId, newColor);
            return newColor;
        } else {
            console.error('Unknown error at MetaColorRegistry.js getParcelColor, Cannot find color for land type: ' + landType);
        }
    }

    static createSpecificColor(colorObj) {
        return new MetaColor(colorObj.NORMAL, colorObj.HOVERED, colorObj.SELECTED, colorObj.DISABLED);
    }

    static createColor(landType, parcelType, ownershipType) {
        let color = null;
        switch(ownershipType) {
            case PARCEL_OWNERSHIP_TYPES.FREE:
                color = MetaColorRegistry.createColorByParcelType(parcelType);
                if(!color) {
                    color = MetaColorRegistry.createColorByLandType(landType);
                }
                break;
            case PARCEL_OWNERSHIP_TYPES.OWNED:
                color = MetaColorRegistry.createSpecificColor(OWNED_COLORS);
                break;
            case PARCEL_OWNERSHIP_TYPES.SELF_OWNED:
                color = MetaColorRegistry.createSpecificColor(SELF_OWNED_COLORS);
                break;
        }
        return color;
    }

    static createColorByLandType(landType) {
        let color = null;
        switch(landType) {
            case PARCEL_LAND_TYPES.OCEAN:
                color = MetaColorRegistry.createSpecificColor(OCEAN_COLORS);
                break;
            case PARCEL_LAND_TYPES.NEAR_OCEAN:
                color = MetaColorRegistry.createSpecificColor(NEAR_OCEAN_COLORS);
                break;
            case PARCEL_LAND_TYPES.INLAND:
                color = MetaColorRegistry.createSpecificColor(INLAND_COLORS);
                break;
        }
        return color;
    }

    static createColorByParcelType(parcelType) {
        let color = null;
        switch(parcelType) {
            case PARCEL_TYPES.RESERVED:
                color = MetaColorRegistry.createSpecificColor(RESERVED_COLORS);
                break;
            case PARCEL_TYPES.BUSINESS:
                color = MetaColorRegistry.createSpecificColor(BUSINESS_COLORS);
                break;
        }
        return color;
    }
}