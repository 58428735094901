import { STATE } from './names';
import { POLLING_STORE_FUNCTIONS } from './polling';

export default () => {
    return {
        [STATE.HAS_IGNORED_BONUS]: null as boolean | null,
        [STATE.PROGRESS_BY_BINARY_LEFT]: null as number | null,
        [STATE.PROGRESS_BY_BINARY_RIGHT]: null as number | null,
        [STATE.PROGRESS_DATA_LOADING]: false,
        [STATE.PROGRESS_DATA_LOADING_ERROR]: null as Error | null,
        [STATE.PROGRESS_DATA_LOADING_PROMISE]: null as Promise<unknown> | null,
        ...POLLING_STORE_FUNCTIONS.state,
    };
};