import AuthService from '../services/api-interaction/AuthService';
import { GlobalMakerService } from '../services/GlobalMakerService';
import { LocalScheme } from '~auth/runtime';
import DEFAULTS from '~/core/auth/configs/common';

export default class CashFTEasyLoginScheme extends LocalScheme {

    constructor($auth, options, ...defaults) {
        super($auth, options, ...defaults, DEFAULTS);
    }

    async fetchUser() {
        await super.fetchUser();

        if (this.options.user.setToStore && this.$auth.user) {
            GlobalMakerService.$store.dispatch(this.options.user.storeDispatchPath, {
                user: this.$auth.user
            });
        }

        this.updateServiceTokens();
    }

    async login(_opts = {}) {
        const cashFtEasyLoginToken = _opts.token;
        const response = await AuthService.getAuthTokenFromCashFTToken(cashFtEasyLoginToken);

        this.updateTokens(response);

        if (!this.requestHandler.interceptor) {
            this.requestHandler.initializeRequestInterceptor();
        }
        if (this.options.user.autoFetch) {
            await this.fetchUser();
        }

        return response.user;
    }

    async logout() {
        this.$auth.reset();
    }

    updateTokens(tokenData) {
        super.updateTokens(tokenData);

        this.updateServiceTokens();
    }

    updateServiceTokens() {
        const token = this.token?.get();

        if(!token) {
            return;
        }

        if(this.options.token.setApiServiceToken) {
            GlobalMakerService.$store.dispatch(this.options.token.storeDispatchPath, {
                apiToken: token
            });
        }

        if(this.options.token.setJwtServiceToken) {
            GlobalMakerService.$store.dispatch(this.options.token.storeDispatchPath, {
                jwtToken: token
            });
        }
    }
}

