import { STATE } from "./names";
import type { SidebarItem } from "./types";

export default () => {
    return {
        [STATE.MOBILE_SIDEBAR_OPEN]: false,
        [STATE.OPENED_SUBMENU]: null as SidebarItem | null,
        [STATE.ACTIVE_ITEM_PATH]: [] as SidebarItem[],
        [STATE.MOBILE_PROFILE_SIDEBAR_OPEN]: false,
        [STATE.MOBILE_CONTRACT_ADDRESSES_SIDEBAR_OPEN]: false,
        [STATE.MOBILE_LANGUAGES_SIDEBAR_OPEN]: false,
    };
};