import { MetaWorldManager } from "../services/map/MetaWorldManager";
import ERROR_MESSAGES from "./error-messages";

export function isValidEmail(value) {
    const regex = /^[a-zA-Z0-9._-]+(?:\+\d+)*@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/g
    return regex.test(value);
}

export async function emailExists (rule, value, callback) {
    let locIsValid = isValidEmail(value);
    if (locIsValid) {
        let isExists = await MetaWorldManager.sharedInstance().validateIsCredentialExists({email: value});
        if (isExists) {
            callback(new Error(ERROR_MESSAGES.EMAIL_ERR.EXISTS));
        }
    } else {
        callback(new Error(ERROR_MESSAGES.EMAIL_ERR.INVALID));
    }
}