
import {defineComponent} from "vue"

export type InfoAlertCardComponent = {
    text: string,
    type: 'info' | 'danger',
};

export default defineComponent<keyof InfoAlertCardComponent, InfoAlertCardComponent>({
    name: "InfoAlertCard",
    props: {
        text: String,
        type: {
            type: String,
            default: 'info',
        },
        isHtml: {
            type: Boolean,
            default: false,
        },
        border: {
            type: Boolean,
            default: false,
        },
    },
});
