import ApiService from "~/core/services/api-interaction/ApiService";
import {getClientRuntimeConfig} from "~/core/helpers/GlobalHelpers";
import {MetaInventoryContainer} from "~/core/models/MetaInventoryContainer";

const $config = getClientRuntimeConfig();

export class MyCurrentRankService {
    static async getRankData () {
        try {
            const responseRankData = await ApiService.get(`${$config.binaryTreeApi}/api/career/rank-statistics`);

            return responseRankData?.data?.result ? responseRankData?.data?.result : {
                totalOwnPurchaseAmount: 0,
                binaryCycleSum: 0,
                leftLegRanks: 0,
                rightLegRanks: 0,
                leftValue: 0,
                rightValue: 0,
            }
        } catch (err) {
            throw new Error(err);
        }
    }

    static async getTurnoverDataByUnilevel () {
        try {
            const responseRankData = await ApiService.get(`${$config.binaryTreeApi}/api/career/turnover-by-unilevel`);

            return responseRankData?.data;
        } catch (err) {
            throw new Error(err);
        }
    }

    static async getTurnoverTableData ({page = 1, perPage = 5}) {
        const authUser = MetaInventoryContainer.sharedInstance().authUserData;
        const responseData = [];

        try {
            const responseRankData = await ApiService.query(`${$config.binaryTreeApi}/api/career/binary-commissions`, {
                params: { page, perPage }
            });

            if (responseRankData?.data?.data && responseRankData?.data?.data.length) {
                responseRankData?.data?.data.forEach(dt => {
                    responseData.push({
                        id: dt.id,
                        type: 'binary commission',
                        weakLegPoints: dt.weakLegPoints,
                        balanceBeforeInWeakLeg: (dt.balanceBeforeInWeakLeg || 0) + (authUser?.totalTeamCvPoints || 0),
                        balanceAfterInWeakLeg: (dt.balanceAfterInWeakLeg || 0) + (authUser?.totalTeamCvPoints || 0),
                    })
                });

                if (
                    Math.ceil(responseRankData?.data?.meta?.total / perPage) === Number(responseRankData?.data?.meta?.page) &&
                    typeof authUser?.totalTeamCvPoints === 'number' && authUser?.totalTeamCvPoints > 0
                ) {
                    responseData[responseData.length - 1].id = 0;
                    responseData[responseData.length - 1].type = 'Historical CV Points';
                    responseData[responseData.length - 1].weakLegPoints = null;
                    responseData[responseData.length - 1].balanceBeforeInWeakLeg = 0;
                    responseData[responseData.length - 1].balanceAfterInWeakLeg = authUser?.totalTeamCvPoints;
                }
            }

            return {
                data: responseData || [],
                totalCount: responseRankData?.data?.meta ? Number(responseRankData?.data?.meta.total) : 0
            }
        } catch (err) {
            throw new Error(err);
        }
    }

    static async getQualifiedPartners () {
        try {
            const responseRankData = await ApiService.get(`${$config.binaryTreeApi}/api/career/qualified-partners`);

            return responseRankData?.data?.result ? responseRankData?.data?.result : []
        } catch (err) {
            throw new Error(err);
        }
    }
}
