import {ERROR_STATUSES} from "~/core/services/utils/Constants";

export class ErrorHandler {
    static getErrorMessage(err: any): string | null {
        let errMessage = null;

        switch (err) {
            case ERROR_STATUSES.INVALID_VERIFICATION_CODE:
                errMessage = 'Invalid Verification Code';
                break;
            case ERROR_STATUSES.INVALID_EMAIL_ADDRESS:
                errMessage = 'Invalid Email Address';
                break;
            case ERROR_STATUSES.INVALID_USER_UPDATE:
                errMessage = 'User Update Failed';
                break;
            case ERROR_STATUSES.AUTHORIZATION_ERROR:
                errMessage = 'UnAuthorized';
                break;
            case ERROR_STATUSES.INVALID_ARGS:
                errMessage = 'Invalid Arguments Supplied';
                break;
            case ERROR_STATUSES.EXISTING_EMAIL:
                errMessage = 'Email Already Exists';
                break;
            case ERROR_STATUSES.PERMISSION_DENIED:
                errMessage = 'Permission Denied';
                break;
            case ERROR_STATUSES.INVALID_REFERRER_CODE:
                errMessage = 'Invalid referrer code';
                break;
            case ERROR_STATUSES.EXISTING_WALLET:
                errMessage = 'Existing wallet';
                break;
            default:
                if (typeof err === 'string') {
                    errMessage = err;
                }
                break;
        }
        return errMessage;
    }
    static getErrorDescription(err: any): string | null {
        let errMessage = null;

        switch (err) {
            case ERROR_STATUSES.EXISTING_WALLET:
                errMessage = "This wallet address is already associated with another user account. Please use another one!";
                break;
            default:
                if (typeof err === 'string') {
                    errMessage = err;
                }
                break;
        }

        return errMessage;
    }
}
