import { GETTERS, STATE } from "./names";
import type { State } from "./types";
import type { Notification } from "../types/Notification";
import { POLLING_STORE_FUNCTIONS } from "./polling";

export default {
    ...POLLING_STORE_FUNCTIONS.getters,
    [GETTERS.UNREAD_NOTIFICATIONS]: (state: State): Notification[] => state[STATE.UNREAD_NOTIFICATIONS],
    [GETTERS.UNREAD_NOTIFICATIONS_LOADING]: (state: State): boolean => state[STATE.UNREAD_NOTIFICATIONS_LOADING],
    [GETTERS.UNREAD_NOTIFICATIONS_LOADING_ERROR]: (state: State): unknown | null => state[STATE.UNREAD_NOTIFICATIONS_LOADING_ERROR],
    [GETTERS.UNREAD_NOTIFICATIONS_LOADING_PROMISE]: (state: State): Promise<Notification[]> | null => state[STATE.UNREAD_NOTIFICATIONS_LOADING_PROMISE] as Promise<Notification[]> | null,
    [GETTERS.NOTIFICATIONS_READ_BY_OTHER_SESSIONS_LOADING]: (state: State): boolean => state[STATE.NOTIFICATIONS_READ_BY_OTHER_SESSIONS_LOADING],
    [GETTERS.NOTIFICATIONS_READ_BY_OTHER_SESSIONS_LOADING_ERROR]: (state: State): unknown | null => state[STATE.NOTIFICATIONS_READ_BY_OTHER_SESSIONS_LOADING_ERROR],
    [GETTERS.NOTIFICATIONS_READ_BY_OTHER_SESSIONS_LOADING_PROMISE]: (state: State): Promise<unknown> | null => state[STATE.NOTIFICATIONS_READ_BY_OTHER_SESSIONS_LOADING_PROMISE],
};