import { MUTATIONS, STATE } from "./names";
import { POLLING_STORE_FUNCTIONS } from "./polling";
import type { State } from "./types";

export default {
    [MUTATIONS.SET_TOTAL_BINARY_INCOME_LEFT](state: State, payload: number | null) {
        state[STATE.TOTAL_BINARY_INCOME_LEFT] = payload;
    },
    [MUTATIONS.SET_TOTAL_BINARY_INCOME_RIGHT](state: State, payload: number | null) {
        state[STATE.TOTAL_BINARY_INCOME_RIGHT] = payload;
    },
    [MUTATIONS.SET_BINARY_VALUE](state: State, payload: number | null) {
        state[STATE.BINARY_VALUE] = payload;
    },
    [MUTATIONS.SET_MATCHING_BONUS_VALUE](state: State, payload: number | null) {
        state[STATE.MATCHING_BONUS_VALUE] = payload;
    },
    [MUTATIONS.SET_DIRECT_SALES_VALUE](state: State, payload: number | null) {
        state[STATE.DIRECT_SALES_VALUE] = payload;
    },
    [MUTATIONS.SET_TOTAL_INCOME_LOADING](state: State, payload: boolean) {
        state[STATE.TOTAL_INCOME_LOADING] = payload;
    },
    [MUTATIONS.SET_TOTAL_INCOME_LOADING_ERROR](state: State, payload: string) {
        state[STATE.TOTAL_INCOME_LOADING_ERROR] = payload;
    },
    [MUTATIONS.SET_TOTAL_INCOME_LOADING_PROMISE](state: State, payload: Promise<void>) {
        state[STATE.TOTAL_INCOME_LOADING_PROMISE] = payload;
    },
    ...POLLING_STORE_FUNCTIONS.mutations,
}
