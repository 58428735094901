import { GETTERS, STATE } from "./names";
import { POLLING_STORE_FUNCTIONS } from "./polling";
import type { State } from "./types";

export default {
    [GETTERS.TOTAL_BINARY_INCOME_LEFT]: (state: State) => state[STATE.TOTAL_BINARY_INCOME_LEFT],
    [GETTERS.TOTAL_BINARY_INCOME_RIGHT]: (state: State) => state[STATE.TOTAL_BINARY_INCOME_RIGHT],
    [GETTERS.BINARY_VALUE]: (state: State) => state[STATE.BINARY_VALUE],
    [GETTERS.MATCHING_BONUS_VALUE]: (state: State) => state[STATE.MATCHING_BONUS_VALUE],
    [GETTERS.DIRECT_SALES_VALUE]: (state: State) => state[STATE.DIRECT_SALES_VALUE],
    [GETTERS.TOTAL_INCOME_LOADING]: (state: State) => state[STATE.TOTAL_INCOME_LOADING],
    [GETTERS.TOTAL_INCOME_LOADING_ERROR]: (state: State) => state[STATE.TOTAL_INCOME_LOADING_ERROR],
    [GETTERS.TOTAL_INCOME_LOADING_PROMISE]: (state: State) => state[STATE.TOTAL_INCOME_LOADING_PROMISE],
    ...POLLING_STORE_FUNCTIONS.getters,
}
