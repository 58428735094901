import Swal from "sweetalert2";

export class PopupHelper {
    static showErrorAlert(title, text, actionHandler = null) {
        Swal.fire({
            title: title,
            text: text,
            icon: 'error',
            confirmButtonText: 'Ok',
        }).then(async (result) => {
            if(actionHandler) {
                await actionHandler();
            }
        })
    }

    static showInfoAlert(title, text, isHtml, confirmButtonText = 'Ok', showCancelButton = false, customClass = '') {
        return Swal.fire({
            title: title,
            [isHtml ? 'html' : 'text']: text,
            icon: 'info',
            confirmButtonText,
            showCancelButton,
            customClass,
        })
    }

    static showSuccessNotification (title, text, showConfirmButton = false, actionHandler = null) {
        let options = {
            position: 'center',
            icon: 'success',
            title: title,
            text: text ?? '',
            showConfirmButton: showConfirmButton,
            timer: 2500,
        };
        if (showConfirmButton) {
            delete options.timer;
        }
        Swal.fire(options).then(async (result) => {
            if(showConfirmButton && actionHandler) {
                await actionHandler();
            }
        })
    }

    static confirm(title, text, confirmButtonText, cancelButtonText, customClass) {
        return Swal.fire({
            title: title,
            text: text,
            icon: 'question',
            showCancelButton: true,
            confirmButtonText: confirmButtonText,
            cancelButtonText: cancelButtonText,
            customClass: customClass,
        })
    }
}
