import Hashids from "hashids";
export class HashIdService {
    static h: Hashids = null;

    static setHashIds(hashIds: Hashids): void {
        HashIdService.h = hashIds;
    }

    static encode(n: any): any {
        return HashIdService.h.encode(n);
    }

    static decode(n: any): any {
        return HashIdService.h.decode(n)[0];
    }
}
