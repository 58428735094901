
import GymNewButton from "~/components/utils/form-components/GymNewButton.vue";
import {PaymentMethodEnum} from "~/core/types/purchase-popup-2/PaymentMethodEnum";

export default {
    name: 'PaymentSuccesful',
    components: {
        GymNewButton,
    },
    props: {
        paymentMethod: {
            type: [String],
        }
    },
    emits: ['onGoToNFTs'],
    data() {
        return {
            PaymentMethodEnum,
        }
    },
    methods: {
        handleGoToNFTs () {
            this.$emit('onGoToNFTs')
        }
    }
}
