
export function getStorePollingNames(uniqueIdentifier?: string) {
    return {
        state: {
            CLEAR_FUNCTION: `clearFunction${uniqueIdentifier ? `_${uniqueIdentifier}` : ''}` as const,
            POLLING_INTERVAL_CHANGER: `pollingInterval${uniqueIdentifier ? `_${uniqueIdentifier}` : ''}` as const,
        },
        getters: {
            CLEAR_FUNCTION: `clearFunction${uniqueIdentifier ? `_${uniqueIdentifier}` : ''}` as const,
            POLLING_INTERVAL_CHANGER: `pollingInterval${uniqueIdentifier ? `_${uniqueIdentifier}` : ''}` as const,
        },
        actions: {
            POLL_DATA: `pollData${uniqueIdentifier ? `_${uniqueIdentifier}` : ''}` as const,
            STOP_POLLING: `stopPolling${uniqueIdentifier ? `_${uniqueIdentifier}` : ''}` as const,
            CHANGE_POLLING_INTERVAL: `changePollingInterval${uniqueIdentifier ? `_${uniqueIdentifier}` : ''}` as const,
        },
        mutations: {
            SET_CLEAR_FUNCTION: `SET_CLEAR_FUNCTION${uniqueIdentifier ? `_${uniqueIdentifier}` : ''}` as const,
            SET_POLLING_INTERVAL_CHANGER: `SET_POLLING_INTERVAL_CHANGER${uniqueIdentifier ? `_${uniqueIdentifier}` : ''}` as const,
        },
    };
}