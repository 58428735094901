import { MUTATIONS, STATE } from "./names"
import { POLLING_STORE_FUNCTIONS } from "./polling"
import type { State } from "./types"

export default {
    [MUTATIONS.SET_PROGRESS_BY_BINARY_LEFT](state: State, payload: number) {
        state[STATE.PROGRESS_BY_BINARY_LEFT] = payload
    },
    [MUTATIONS.SET_PROGRESS_BY_BINARY_RIGHT](state: State, payload: number) {
        state[STATE.PROGRESS_BY_BINARY_RIGHT] = payload
    },
    [MUTATIONS.SET_HAS_IGNORED_BONUS](state: State, payload: boolean) {
        state[STATE.HAS_IGNORED_BONUS] = payload
    },
    [MUTATIONS.SET_PROGRESS_DATA_LOADING](state: State, payload: boolean) {
        state[STATE.PROGRESS_DATA_LOADING] = payload
    },
    [MUTATIONS.SET_PROGRESS_DATA_LOADING_ERROR](state: State, payload: Error | null) {
        state[STATE.PROGRESS_DATA_LOADING_ERROR] = payload
    },
    [MUTATIONS.SET_PROGRESS_DATA_LOADING_PROMISE](state: State, payload: Promise<unknown> | null) {
        state[STATE.PROGRESS_DATA_LOADING_PROMISE] = payload
    },
    ...POLLING_STORE_FUNCTIONS.mutations,
}