import { getStorePollingNames } from "~/core/utilities/store-polling/getStorePollingNames";

export const UNIQUE_IDENTIFIER = 'progress-unilevel' as const;
export const POLLING_NAMES = getStorePollingNames(UNIQUE_IDENTIFIER);

export const STATE = {
    PROGRESS_BY_BINARY_LEFT: 'progressByBinaryLeft',
    PROGRESS_BY_BINARY_RIGHT: 'progressByBinaryRight',
    HAS_IGNORED_BONUS: 'hasIgnoredBonus',
    PROGRESS_DATA_LOADING: 'progressDataLoading',
    PROGRESS_DATA_LOADING_ERROR: 'progressDataLoadingError',
    PROGRESS_DATA_LOADING_PROMISE: 'progressDataLoadingPromise',
    ...POLLING_NAMES.state,
} as const;

export const ACTIONS = {
    LOAD_PROCESS_AND_SAVE_PROGRESS_DATA_BY_UNILEVEL: 'loadProcessAndSaveProgressDataByUnilevel',
    LOAD_AND_PROCESS_PROGRESS_DATA_BY_UNILEVEL: 'loadAndProcessUnilevelProgressDataByUnilevel',
    LOAD_PROGRESS_DATA_BY_UNILEVEL: 'loadProgressDataByUnilevel',
    PROCESS_PROGRESS_DATA_BY_UNILEVEL: 'processProgressDataByUnilevel',
    SAVE_PROGRESS_DATA_BY_UNILEVEL: 'saveProgressDataByUnilevel',
    CLEAR_PROGRESS_BY_UNILEVEL_STATE: 'clearProgressByUnilevelState',
    ...POLLING_NAMES.actions,
} as const; 

export const MUTATIONS = {
    SET_PROGRESS_BY_BINARY_LEFT: 'SET_PROGRESS_BY_BINARY_LEFT',
    SET_PROGRESS_BY_BINARY_RIGHT: 'SET_PROGRESS_BY_BINARY_RIGHT',
    SET_HAS_IGNORED_BONUS: 'SET_HAS_IGNORED_BONUS',
    SET_PROGRESS_DATA_LOADING: 'SET_PROGRESS_DATA_LOADING',
    SET_PROGRESS_DATA_LOADING_ERROR: 'SET_PROGRESS_DATA_LOADING_ERROR',
    SET_PROGRESS_DATA_LOADING_PROMISE: 'SET_PROGRESS_DATA_LOADING_PROMISE',
    ...POLLING_NAMES.mutations,
} as const;

export const GETTERS = {
    PROGRESS_BY_BINARY_LEFT: 'progressByBinaryLeft',
    PROGRESS_BY_BINARY_RIGHT: 'progressByBinaryRight',
    HAS_IGNORED_BONUS: 'hasIgnoredBonus',
    PROGRESS_DATA_LOADING: 'progressDataLoading',
    PROGRESS_DATA_LOADING_ERROR: 'progressDataLoadingError',
    PROGRESS_DATA_LOADING_PROMISE: 'progressDataLoadingPromise',
    ...POLLING_NAMES.getters,
} as const;