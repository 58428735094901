export const STATE = {
    MY_TEAM_GUIDE_STEP: 'myTeamGuideStep',
    ENROLLMENT_SELECT_VIEW: 'enrollmentSelectView',
    ENROLLMENT_TREE_DATA: 'enrollmentTreeData',
    ENROLLMENT_STATS_DATA: 'enrollmentStatsData',
    ENROLLMENT_TREE_LOADER: 'enrollmentTreeLoader',
    ENROLLMENT_TABLE_DATA: 'enrollmentTableData',
    ENROLLMENT_TABLE_LOADER: 'enrollmentTableLoader',
    CV_POINTS_DATA: 'cvPointsData',
    CV_POINTS_DATA_LOADER: 'cvPointsDataLoader',
    CV_POINTS_SEARCH_VALUE: 'cvPointsSearchValue',
    BINARY_TREE_DATA_LOADER: 'binaryTreeDataLoader',
    BINARY_TREE_DATA: 'binaryTreeData',
    EXTENDED_REFERRAL_LINK_DATA: 'extendedReferralLinkData',
} as const;

export const MUTATIONS = {
    SET_MY_TEAM_GUIDE_STEP: 'setMyTeamGuideStep',
    SET_ENROLLMENT_SELECT_VIEW: 'setEnrollmentSelectView',
    SET_ENROLLMENT_STATS_DATA: 'setEnrollmentStatsData',
    SET_CLEAR_ENROLLMENT_STATS_DATA: 'setClearEnrollmentStatsData',
    SET_ENROLLMENT_TREE_MAIN_DATA: 'setEnrollmentTreeMainData',
    SET_ENROLLMENT_TREE_USER_UPDATE: 'setEnrollmentTreeUserUpdate',
    SET_ENROLLMENT_TREE_RESET: 'setEnrollmentTreeReset',
    SET_ENROLLMENT_TREE_MAIN_USER_RESET: 'setEnrollmentTreeMainUserReset',
    SET_ENROLLMENT_TREE_LOADER: 'setEnrollmentTreeLoader',
    SET_ENROLLMENT_TREE_SEARCH: 'setEnrollmentTreeSearch',
    SET_ENROLLMENT_TABLE_DATA: 'setEnrollmentTableData',
    SET_ENROLLMENT_TABLE_DATA_RESET: 'setEnrollmentTableDataReset',
    SET_ENROLLMENT_TABLE_LOADER: 'setEnrollmentTableLoader',
    SET_ENROLLMENT_TABLE_PARTNERS: 'setEnrollmentTablePartners',
    SET_ENROLLMENT_TABLE_SEARCH: 'setEnrollmentTableSearch',
    SET_CV_POINTS_DATA: 'setCvPointsData',
    SET_CV_POINTS_DATA_LOADER: 'setCvPointsDataLoader',
    SET_CV_POINTS_DATA_RESET: 'setCvPointsDataReset',
    SET_CV_POINTS_SEARCH_VALUE: 'setCvPointsSearchValue',
    SET_BINARY_TREE_DATA_LOADER: 'setBinaryTreeDataLoader',
    SET_BINARY_TREE_DATA: 'setBinaryTreeData',
    SET_BINARY_TREE_USER_UPDATE: 'onBinaryTreeUserUpdate',
    SET_BINARY_TREE_RESET: 'setBinaryTreeReset',
    SET_EXTENDED_REFERRAL_LINK_DATA: 'setExtendedReferralLinkData',
} as const;

export const ACTIONS = {
    ON_MY_TEAM_GUIDE_STEP: 'onMyTeamGuideStep',
    ON_ENROLLMENT_SELECT_VIEW: 'onEnrollmentSelectView',
    ON_ENROLLMENT_STATS_DATA: 'onEnrollmentStatsData',
    ON_ENROLLMENT_TREE_DATA: 'onEnrollmentTreeData',
    ON_ENROLLMENT_TREE_USER_UPDATE: 'onEnrollmentTreeUserUpdate',
    ON_ENROLLMENT_TREE_RESET: 'onEnrollmentTreeReset',
    ON_ENROLLMENT_TREE_MAIN_USER_RESET: 'onEnrollmentTreeMainUserReset',
    ON_ENROLLMENT_TREE_LOADER: 'onEnrollmentTreeLoader',
    ON_ENROLLMENT_TREE_SEARCH: 'onEnrollmentTreeSearch',
    ON_ENROLLMENT_TABLE_DATA: 'onEnrollmentTableData',
    ON_ENROLLMENT_TABLE_DATA_RESET: 'onEnrollmentTableDataReset',
    ON_ENROLLMENT_TABLE_LOADER: 'onEnrollmentTableLoader',
    ON_ENROLLMENT_TABLE_PARTNERS: 'onEnrollmentTablePartners',
    ON_ENROLLMENT_TABLE_SEARCH: 'onEnrollmentTableSearch',
    ON_CV_POINTS_DATA: 'onCvPointsData',
    ON_CV_POINTS_DATA_LOADER: 'onCvPointsDataLoader',
    ON_CV_POINTS_DATA_RESET: 'onCvPointsDataReset',
    ON_CV_POINTS_SEARCH_VALUE: 'onCvPointsSearchValue',
    ON_BINARY_TREE_DATA_LOADER: 'onBinaryTreeDataLoader',
    ON_BINARY_TREE_DATA: 'onBinaryTreeData',
    ON_BINARY_TREE_DATA_LEFT_RIGHT: 'onBinaryTreeDataLeftRight',
    ON_BINARY_TREE_USER_UPDATE: 'onBinaryTreeUserUpdate',
    ON_BINARY_TREE_RESET: 'onBinaryTreeReset',
    ON_BINARY_TREE_SEARCH: 'onBinaryTreeSearch',
    ON_EXTENDED_REFERRAL_LINK_DATA: 'onExtendedReferralLinkData',
    ON_CHECK_USERNAME: 'onCheckUsername',
    ON_CREATE_REF_LINK: 'onCreateRefLink',
    ON_UPDATE_USER_GUIDE_SEEN: 'onUpdateUserGuideSeen',
    CLEAR_MY_TEAM_DATA: 'clearMyTeamData',
} as const;

export const GETTERS = {
    MY_TEAM_GUIDE_STEP: 'myTeamGuideStep',
    ENROLLMENT_SELECT_VIEW: 'enrollmentSelectView',
    ENROLLMENT_STATS_DATA: 'enrollmentStatsData',
    ENROLLMENT_TREE_DATA: 'enrollmentTreeData',
    ENROLLMENT_TREE_LOADER: 'enrollmentTreeLoader',
    ENROLLMENT_TABLE_DATA: 'enrollmentTableData',
    ENROLLMENT_TABLE_LOADER: 'enrollmentTableLoader',
    CV_POINTS_DATA: 'cvPointsData',
    CV_POINTS_DATA_LOADER: 'cvPointsDataLoader',
    CV_POINTS_SEARCH_VALUE: 'cvPointsSearchValue',
    BINARY_TREE_DATA_LOADER: 'binaryTreeDataLoader',
    BINARY_TREE_DATA: 'binaryTreeData',
    EXTENDED_REFERRAL_LINK_DATA: 'extendedReferralLinkData',
} as const;
