import {MetaWorldManager} from "~/core/services/map/MetaWorldManager";
import {PopupHelper} from "~/core/helpers/PopupHelper";
const DEV_TEST_API_URL = 'https://d0og318id9.execute-api.eu-central-1.amazonaws.com/prod/';

export const state = () => {
    return {
        streamlineBonusData: {
            loading: false,
            items: [],
            meta: [],
        },
        sbTransactionHistoryData: {
            loading: false,
            items: [],
            meta: [],
        },
        sbStreamlineTurnoverStatistics: [],
        isClaimLoading: false,
        sbTotalTurnoverChart: [],
        newRegistrationMembers: {
            loading: false,
            items: [],
            meta: [],
        },
        newRegistrationMembersCashFT: {
            loading: false,
            items: [],
            meta: [],
        },

        isPendingClaim: false,
        singleLands: [],
        singleLandsPrice: 100,
        singleMinerPrice: 50,
        priceForPurchaseMiners: 50,
    };
};

// Actions
export const actions = {
    async getStreamlineBonusData ({commit}, address) {
        commit('SET_STREAMLINE_BONUS_LOADING', true);
        try {
            const streamlineBonus = await this.$api.get(`${DEV_TEST_API_URL}api/v1/streamline/wallet-info?wallet_address=${address}`);
            commit('SET_STREAMLINE_BONUS_DATA', streamlineBonus?.data);
        } finally {
            commit('SET_STREAMLINE_BONUS_LOADING', false);
        }
    },

    async getSBTransactionHistory ({commit}, payload) {
        commit('SET_SB_TRANSACTION_HISTORY_LOADING', true);
        try {
            const sbTransactionHistory = await this.$api.get(`${DEV_TEST_API_URL}api/v1/streamline/transaction-history?wallet_address=${payload.address}&limit=10`, {
                params: {page: payload.page},
            });
            commit('SET_SB_TRANSACTION_HISTORY_DATA', sbTransactionHistory?.data);
        } finally {
            commit('SET_SB_TRANSACTION_HISTORY_LOADING', false);
        }
    },

    async getNewRegistrationMembers ({commit}, page = 1) {
        commit('SET_NEW_REGISTRATIONS_LOADING', true);
        try {
            const registrationMembers = await this.$api.get(`${DEV_TEST_API_URL}api/v1/streamline/members?page=${page}&limit=5`);
            commit('SET_NEW_REGISTRATION_DATA', registrationMembers?.data);
        } finally {
            commit('SET_NEW_REGISTRATIONS_LOADING', false);
        }
    },

    async getNewRegistrationMembersForGymstreetCashFT ({commit}, page = 1) {
        commit('SET_NEW_REGISTRATIONS_LOADING', true);
        try {
            const registrationMembers = await this.$api.get(`${DEV_TEST_API_URL}api/v1/streamline/new/members?page=${page}&limit=5`);
            commit('SET_NEW_REGISTRATION_DATA_CASHFT', registrationMembers?.data);
        } finally {
            commit('SET_NEW_REGISTRATIONS_LOADING', false);
        }
    },

    async getStreamlineBonusDataForGymstreetCashFT ({commit}, payload) {
        commit('SET_STREAMLINE_BONUS_LOADING', true);
        try {
            const streamlineBonus = await this.$api.get(`${DEV_TEST_API_URL}api/v1/streamline/new/wallet-info?wallet_address=${payload.address}&cash_ft=${payload.isCashFT}`);
            commit('SET_STREAMLINE_BONUS_DATA', streamlineBonus?.data);
        } finally {
            commit('SET_STREAMLINE_BONUS_LOADING', false);
        }
    },

    async getSBTransactionHistoryForGymstreetCashFT ({commit}, payload) {
        commit('SET_SB_TRANSACTION_HISTORY_LOADING', true);
        try {
            const sbTransactionHistory = await this.$api.get(`${DEV_TEST_API_URL}api/v1/streamline/new/transaction-history?wallet_address=${payload.address}&limit=10&cash_ft=${payload.isCashFT}`, {
                params: {page: payload.page},
            });
            commit('SET_SB_TRANSACTION_HISTORY_DATA', sbTransactionHistory?.data);
        } finally {
            commit('SET_SB_TRANSACTION_HISTORY_LOADING', false);
        }
    },
    async claimSB ({commit, dispatch}, address) {
        commit('SET_SB_CLAIM_LOADING', true);
        MetaWorldManager.sharedInstance().showLoading();

        return this.$api(
            {
                url: `api/v1/streamline/claim-request`,
                baseURL: DEV_TEST_API_URL,
                method: 'post',
                data:
                    {
                        wallet_address: address,
                    },
            }).then((res) => {
            dispatch('getStreamlineBonusData');
            dispatch('getSBTransactionHistory');
        }).catch((err) => {
            if (err.response?.data?.message) {
                PopupHelper.showErrorAlert(err.response.data.message);
            }
        }).finally(() => {
            commit('SET_SB_CLAIM_LOADING', false);
            MetaWorldManager.sharedInstance().hideLoading();
        });
    },

    async claimSBNEW ({commit, dispatch}, address) {
        commit('SET_SB_CLAIM_LOADING', true);
        MetaWorldManager.sharedInstance().showLoading();

        return this.$api(
            {
                url: `api/v1/streamline/new/claim-request`,
                baseURL: DEV_TEST_API_URL,
                method: 'post',
                data:
                    {
                        wallet_address: address,
                        cash_ft: false,
                    },
            }).then((res) => {
            dispatch('getStreamlineBonusData');
            dispatch('getSBTransactionHistory');
        }).catch((err) => {
            if (err.response?.data?.message) {
                PopupHelper.showErrorAlert(err.response.data.message);
            }
        }).finally(() => {
            commit('SET_SB_CLAIM_LOADING', false);
            MetaWorldManager.sharedInstance().hideLoading();
        });
    },
    async getPriceForPurchaseMiners ({commit}, count) {
        try {
            const minerPrice      = await this.$contracts[this.$metaverseId()].Municipality.methods.getPriceForPurchaseMiners(count).call();
            const minerPriceInETH = this.$web3().utils.fromWei(minerPrice[1]);

            commit('SET_PRICE_FOR_PURCHASE_MINERS', minerPriceInETH);
        } catch (e) {
            console.log(e);
        }
    },
};

// Mutations
export const mutations = {
    SET_STREAMLINE_BONUS_LOADING (state, value) {
        state.streamlineBonusData.loading = value;
    },
    SET_STREAMLINE_BONUS_DATA (state, data) {
        state.streamlineBonusData.items      = data;
        state.sbStreamlineTurnoverStatistics = data.daily_turnover_statistics;
        state.sbTotalTurnoverChart           = data.total_turnover_statistics;
    },
    SET_SB_TRANSACTION_HISTORY_LOADING (state, value) {
        state.sbTransactionHistoryData.loading = value;
    },
    SET_SB_TRANSACTION_HISTORY_DATA (state, data) {
        data?.items.forEach(item => {
            state.isPendingClaim = item.status === 'pending';
        });
        state.sbTransactionHistoryData.items = data.items;
        state.sbTransactionHistoryData.meta  = data.meta;
    },
    SET_SB_CLAIM_LOADING (state, value) {
        state.isClaimLoading = value;
    },
    SET_NEW_REGISTRATIONS_LOADING (state, value) {
        state.newRegistrationMembers.loading = value;
    },
    SET_NEW_REGISTRATION_DATA (state, data) {
        state.newRegistrationMembers.items = data.items;
        state.newRegistrationMembers.meta  = data.meta;
    },
    SET_NEW_REGISTRATION_DATA_CASHFT (state, data) {
        state.newRegistrationMembersCashFT.items = data.items;
        state.newRegistrationMembersCashFT.meta  = data.meta;
    },
    SET_PRICE_FOR_PURCHASE_MINERS (state, value) {
        state.priceForPurchaseMiners = value;
    },
};

// Getters
export const getters = {
    streamlineBonusData: ({streamlineBonusData}) => streamlineBonusData,
    sbTransactionHistoryData: ({sbTransactionHistoryData}) => sbTransactionHistoryData,
    sbStreamlineTurnoverStatistics: ({sbStreamlineTurnoverStatistics}) => sbStreamlineTurnoverStatistics,
    isClaimLoading: ({isClaimLoading}) => isClaimLoading,
    sbTotalTurnoverChart: ({sbTotalTurnoverChart}) => sbTotalTurnoverChart,
    singleLands: ({singleLands}) => singleLands,
    newRegistrationMembers: ({newRegistrationMembers}) => newRegistrationMembers,
    isPendingClaim: ({isPendingClaim}) => isPendingClaim,
    singleLandsPrice: ({singleLandsPrice}) => singleLandsPrice,
    singleMinerPrice: ({singleMinerPrice}) => singleMinerPrice,
    priceForPurchaseMiners: ({priceForPurchaseMiners}) => priceForPurchaseMiners,
    newRegistrationMembersCashFT: ({newRegistrationMembersCashFT}) => newRegistrationMembersCashFT,
};
