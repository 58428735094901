import { MysteryBoxPrizeType } from "./MysteryBoxPrizeType";

type PrizeNameByIdType = Readonly<
    Record<
        100 |
        110 |
        120 |
        130 |
        140 |
        150 |
        160 |
        170 |
        180 |
        190 |
        200 |
        210 |
        220,
        'Parcel' |
        'Parcel and Dual Miner' |
        'Basic Bundle' |
        'Super Bundle R6' |
        'Super Bundle R8' |
        'Super Bundle R10' |
        'Super Bundle R15' |
        'Super Bundle R20' |
        'Super Bundle R22S' |
        'Super Bundle R22M'
    >
>;

export const PrizeNameById: PrizeNameByIdType = {
    [MysteryBoxPrizeType.PRIZE_LAND]: 'Parcel',
    [MysteryBoxPrizeType.PRIZE_LAND_AND_MINER]: 'Parcel and Dual Miner',
    [MysteryBoxPrizeType.PRIZE_BASIC_BUNDLE]: 'Basic Bundle',
    [MysteryBoxPrizeType.PRIZE_SUPER_BUNDLE_R6]: 'Super Bundle R6',
    [MysteryBoxPrizeType.PRIZE_SUPER_BUNDLE_R8]: 'Super Bundle R8',
    [MysteryBoxPrizeType.PRIZE_SUPER_BUNDLE_R10]: 'Super Bundle R10',
    [MysteryBoxPrizeType.PRIZE_SUPER_BUNDLE_R15]: 'Super Bundle R15',
    [MysteryBoxPrizeType.PRIZE_SUPER_BUNDLE_R20]: 'Super Bundle R20',
    [MysteryBoxPrizeType.PRIZE_SUPER_BUNDLE_R22S]: 'Super Bundle R22S',
    [MysteryBoxPrizeType.PRIZE_SUPER_BUNDLE_R22M]: 'Super Bundle R22M',
    [MysteryBoxPrizeType.PRIZE_SUPER_BUNDLE_R22M_2X]: 'Super Bundle R22M',
    [MysteryBoxPrizeType.PRIZE_SUPER_BUNDLE_R22M_3X]: 'Super Bundle R22M',
    [MysteryBoxPrizeType.PRIZE_SUPER_BUNDLE_R22M_7X]: 'Super Bundle R22M',
};
