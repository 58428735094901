import { BalanceInfo } from "../types/BalanceInfo";
import { RawCommissionBalanceInfo } from "../types/RawCommissionBalanceInfo";
import moment from "moment";
import 'moment-timezone';

const DISTRIBUTION_HOUR = 11;

export class CommissionBalanceInfo {
    public binaryWeeklyCap: number;
    public binaryCurrentProgress: number;
    public totalBinaryCommission: number;
    public claimedCommissions: BalanceInfo;
    public availableCommissions: BalanceInfo;
    public collectedBinaryBonus: BalanceInfo & { distributionTime: Date };
    public collectedMatchingBonus: BalanceInfo & { distributionTime: Date };

    constructor(data: RawCommissionBalanceInfo) {
        for(const key in data) {
            if(typeof data[key] === 'object' && data[key] !== null) {
                data[key].USDT = Number(data[key].USDT);
                data[key].GYMNET = Number(data[key].GYMNET);
                data[key].UTILITY = Number(data[key].UTILITY);
            }
            this[key] = data[key];
        }
    }

    public get totalCommissions() {
        const balanceArray = [
            this.claimedCommissions,
            this.availableCommissions,
            this.collectedBinaryBonus,
            this.collectedMatchingBonus,
        ];

        const total = balanceArray.reduce((acc, balance) => {
            if(balance) {
                acc.USDT += balance.USDT;
                acc.GYMNET += balance.GYMNET;
                acc.UTILITY += balance.UTILITY;
            }
            return acc;
        }, { USDT: 0, GYMNET: 0, UTILITY: 0 });

        return total as BalanceInfo;
    }

    get defaultDitributionDate() {
        const EXAMPLE_DISTRIBUTION_TIMESTAMP = 1695625200000;
        // DISTRIBUTION TIME 1696834800000
        const MILLISECONDS_IN_A_WEEK = 604800000

        const currentTS = Date.now();
        const intervalFromLastDistribution = (currentTS - EXAMPLE_DISTRIBUTION_TIMESTAMP) % MILLISECONDS_IN_A_WEEK;
        const lastDistributionTS = Math.floor(currentTS - intervalFromLastDistribution);
        const nextDistributionTS = lastDistributionTS + MILLISECONDS_IN_A_WEEK;

        return new Date(nextDistributionTS);
    }

    get binaryBonusDistributionTime() {
        if(!this.collectedBinaryBonus.distributionTime) {
            return this.defaultDitributionDate;
        }
        return new Date(this.collectedBinaryBonus.distributionTime);
    }

    get matchingBonusDistributionTime() {
        if(!this.collectedMatchingBonus.distributionTime) {
            return this.defaultDitributionDate;
        }
        return new Date(this.collectedMatchingBonus.distributionTime);
    }
}
