import config from "../config/DefaultLayoutConfig";

class LayoutService {
    /**
     * @description initialize default layout
     */
    public static init(): void {
        LayoutService.initAside();
    }

    /**
     * @description init aside
     */
    public static initAside(): void {

        if (!config.aside.display) {
            return;
        }

        // Enable Aside
        document.body.classList.add("aside-enabled");


        // Minimized
        if (
            config.aside.minimized &&
            config.aside.toggle
        ) {
            document.body.setAttribute("data-kt-aside-minimize", "on");
        }

        if (config.aside.fixed) {
            // Fixed Aside
            document.body.classList.add("aside-fixed");

        }

        // Default minimized
        if (config.aside.minimized) {
            document.body.setAttribute("data-kt-aside-minimize", "on");

        }
    }
}

export default LayoutService;
