
export default {
    name: 'CustomBoxLoader',
    props: {
        customClass: {
            type: String,
            default: '',
        }
    },
}
