import { GETTERS, STATE } from "./names";
import { State } from "./types";

export default {
    [GETTERS.IS_TOAST_OPEN] (state: State) {
        return state[STATE.IS_TOAST_OPEN];
    },
    [GETTERS.TOAST_TYPE] (state: State) {
        return state[STATE.TOAST_TYPE];
    },
    [GETTERS.TOAST_TITLE] (state: State) {
        return state[STATE.TOAST_TITLE];
    },
    [GETTERS.TOAST_DESCRIPTION] (state: State) {
        return state[STATE.TOAST_DESCRIPTION];
    },
}
