import ApiService from "~/core/services/api-interaction/ApiService";
import {getClientRuntimeConfig} from "~/core/helpers/GlobalHelpers";

const $config = getClientRuntimeConfig();
const BINARY_TREE__API = `${$config.binaryTreeApi}/api/users`;

export class SubscriptionService {

    static async getBinaryUsers () {
        try {
            const responseBinaryUsers = await ApiService.get(`${BINARY_TREE__API}`);

            return responseBinaryUsers?.data;

        } catch (err) {
            throw new Error(err);
        }
    }
}
