import { getExactDate } from "./getExactDate";

export function generateDailyEmptyChartData () {
    const result = new Array(24);
    const date = new Date();
    date.setMinutes(0, 0, 0);
    // date.setHours(date.getHours() + 1);
    for(let i = 23; i >= 0; i--) {
        date.setHours(date.getHours() - 1);
        const dateString = date.toISOString();
        result[i] = {
            date: dateString,
            usdt: 0,
            gymnet: 0,
            utility: 0,
            y: 0,
            x: dateString,
        };
    }
    return result;
}
