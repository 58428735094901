import { ref } from "vue";
import { getPollingGenerator } from "./getPollingGenerator";

export function runControlledInterval(
    method,
    {
        interval = 10000,
        immediate = true,
        stopOnError = false,
    } = {}) {
    const generator = ref(null);
    const pollingInterval = ref(interval);
    const isRunning = ref(false);
    const result = ref(null);
    const error = ref(null);
    const isLoading = ref(false);

    const start = async () => {
        if (generator.value && isRunning.value) return; 
        isRunning.value = true;
        isLoading.value = true;
        generator.value = getPollingGenerator(method, pollingInterval, isRunning);

        for await (const item of generator.value){
            result.value = item.result;
            error.value = item.error;
            if(stopOnError && item.error){
                generator.value = null;
                isLoading.value = false;
                break;
            } 
        }

        generator.value = null;
        isLoading.value = false;
    };
    const clear = () => {
        isRunning.value = false;
    };
    const changeInterval = (interval)=>{
        pollingInterval.value = interval;
    }
    const retry = () => {
        if (!isRunning.value && generator.value === null) {
            start();
        }
    };

    if (immediate) {
        start();
    }

    return {
        start,
        clear,
        changeInterval,
        retry,
        isRunning,
        result,
        isLoading,
        error,
    };
};