import Asset from "~/pages/finances/wallet/models/Asset";
import WalletAsset from "~/pages/finances/wallet/models/WalletAsset";

export class UserBalance {
    constructor(
        public readonly bnb: Asset,
        public readonly gymnet: Asset,
        public readonly busd: Asset,
        public readonly utility: Asset,
        public readonly usdt: Asset,
        public readonly pap: WalletAsset,
    ) {}
}
