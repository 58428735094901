import {DataService} from "~/core/services/DataService";

const ID_TOKEN_KEY: string = "auth_token" as string;
const ID_SIGNATURE_KEY: string = "sign_key" as string;

/**
 * @description get token form localStorage
 */
export const getToken = (): string | undefined => {
    return DataService.getFromLocalStorage(ID_TOKEN_KEY);
};


/**
 * @description save token into localStorage
 * @param token: string
 */
export const saveToken = (token: string): void => {
    // const expireTime = new Date().getSeconds() + (10);
    DataService.storeToLocalStorage(ID_TOKEN_KEY, token);
};


/**
 * @description remove token form localStorage
 */
export const destroyToken = (): void => {
    DataService.removeFromLocalStorage(ID_TOKEN_KEY);
};


/**
 * @description get signature form localStorage
 */
export const getSignature = async (): Promise<string | undefined> => {
    return DataService.getFromLocalStorage(ID_SIGNATURE_KEY);
};


/**
 * @description save signature into localStorage
 * @param signature: string
 */
export const saveSignature = (signature: string): void => {
    DataService.storeToLocalStorage(ID_SIGNATURE_KEY, signature);
};


/**
 * @description remove signature form localStorage
 */
export const destroySignature = (): void => {
    DataService.removeFromLocalStorage(ID_SIGNATURE_KEY);
};

export default {
    getToken,
    saveToken,
    destroyToken,
    getSignature,
    saveSignature,
    destroySignature,
};

