
import {ErrorHandler} from "~/core/helpers/ErrorHandler";

class APIServiceError extends Error {
    constructor(error) {
        let message = "Invalid response received from server.  If this problem persists, please contact support.";
        if(error.response?.data?.code) {
            message = ErrorHandler.getErrorMessage(error.response?.data?.code);
        } else if (typeof error === 'string') {
            message = error;
        } else if(error.response?.data?.error && typeof error.response?.data?.error === 'string') {
            message = error.response?.data?.error;
        } else if(error instanceof Error) {
            message = error.message;
        }
        if(!message) {
            message = error.response?.data?.message;
        }
        super(message);
        this.httpErrorCode = error.response?.status;
        this.httpErrorText = error.response?.statusText;
        this.code = error.response?.data?.code;
        this.returnedMessage = error.response?.data?.message;
    }
}

export default APIServiceError;
