import { STATE } from "./names";
import type { NotificationTypes } from "../types/NotificationTypes";
import type { NotificationHandler } from "./types";
import { POLLING_STORE_FUNCTIONS } from "./polling";

export default () => {
    return {
        ...POLLING_STORE_FUNCTIONS.state,
        [STATE.UNREAD_NOTIFICATIONS]: [],
        [STATE.UNREAD_NOTIFICATIONS_LOADING]: false,
        [STATE.UNREAD_NOTIFICATIONS_HANDLERS]: {} as Record<NotificationTypes, NotificationHandler[]>,
        [STATE.UNREAD_NOTIFICATIONS_LOADING_ERROR]: null as unknown | null,
        [STATE.UNREAD_NOTIFICATIONS_LOADING_PROMISE]: null as Promise<unknown> | null,
        [STATE.NOTIFICATIONS_READ_BY_OTHER_SESSIONS_LOADING]: false,
        [STATE.NOTIFICATIONS_READ_BY_OTHER_SESSIONS_LOADING_ERROR]: null as unknown | null,
        [STATE.NOTIFICATIONS_READ_BY_OTHER_SESSIONS_LOADING_PROMISE]: null as Promise<unknown> | null,
    };
};