import { MUTATIONS, STATE } from "./names";
import type { NotificationHandler, State } from "./types";
import type { Notification } from "../types/Notification";
import type { NotificationTypes } from "../types/NotificationTypes";
import { POLLING_STORE_FUNCTIONS } from "./polling";

export default {
    ...POLLING_STORE_FUNCTIONS.mutations,
    [MUTATIONS.SET_UNREAD_NOTIFICATIONS](state: State, notifications: Notification[]) {
        state[STATE.UNREAD_NOTIFICATIONS] = notifications;
    },
    [MUTATIONS.ADD_UNREAD_NOTIFICATIONS](state: State, notifications: Notification[]) {
        state[STATE.UNREAD_NOTIFICATIONS].unshift(...notifications);
    },
    [MUTATIONS.SET_UNREAD_NOTIFICATIONS_LOADING_PROMISE](state: State, promise: Promise<Notification[]> | null) {
        state[STATE.UNREAD_NOTIFICATIONS_LOADING_PROMISE] = promise;
    },
    [MUTATIONS.SET_UNREAD_NOTIFICATIONS_LOADING](state: State, loading: boolean) {
        state[STATE.UNREAD_NOTIFICATIONS_LOADING] = loading;
    },
    [MUTATIONS.SET_UNREAD_NOTIFICATIONS_LOADING_ERROR](state: State, error: unknown | null) {
        state[STATE.UNREAD_NOTIFICATIONS_LOADING_ERROR] = error;
    },
    [MUTATIONS.SET_NOTIFICATIONS_READ_BY_OTHER_SESSIONS_LOADING](state: State, loading: boolean) {
        state[STATE.NOTIFICATIONS_READ_BY_OTHER_SESSIONS_LOADING] = loading;
    },
    [MUTATIONS.SET_NOTIFICATIONS_READ_BY_OTHER_SESSIONS_LOADING_ERROR](state: State, error: unknown | null) {
        state[STATE.NOTIFICATIONS_READ_BY_OTHER_SESSIONS_LOADING_ERROR] = error;
    },
    [MUTATIONS.SET_NOTIFICATIONS_READ_BY_OTHER_SESSIONS_LOADING_PROMISE](state: State, promise: Promise<unknown> | null) {
        state[STATE.NOTIFICATIONS_READ_BY_OTHER_SESSIONS_LOADING_PROMISE] = promise;
    },
    [MUTATIONS.ADD_UNREAD_NOTIFICATIONS_HANDLER](state: State, { type, handler }: { type: NotificationTypes; handler: NotificationHandler }) {
        const array = state[STATE.UNREAD_NOTIFICATIONS_HANDLERS][type] || [];
        array.push(handler);
        state[STATE.UNREAD_NOTIFICATIONS_HANDLERS][type] = array;
    },
    [MUTATIONS.REMOVE_UNREAD_NOTIFICATIONS_HANDLER](state: State, { type, handler }: { type: NotificationTypes; handler: NotificationHandler }) {
        let array = state[STATE.UNREAD_NOTIFICATIONS_HANDLERS][type] || [];
        if (!handler) {
            array = [];
        } else {
            array = array.filter((h) => h !== handler);
        }
        state[STATE.UNREAD_NOTIFICATIONS_HANDLERS][type] = array;
    },
    [MUTATIONS.CLEAR_UNREAD_NOTIFICATIONS_HANDLERS](state: State) {
        state[STATE.UNREAD_NOTIFICATIONS_HANDLERS] = {} as Record<NotificationTypes, NotificationHandler[]>;
    },
}