import { BROADCAST_CHANNEL_NAME, BROADCAST_EVENT_TYPES } from "~/core/services/utils/Constants";

export default (ctx, inject) => {
    if(typeof BroadcastChannel === 'undefined') {
        console.warn('BroadcastChannel is not supported in this browser.');
    } else {
        const store = ctx.store;
        if(store) {
            const channel = new BroadcastChannel(BROADCAST_CHANNEL_NAME);
            channel.onmessage = (event) => {
                switch(event.data.type) {
                    case BROADCAST_EVENT_TYPES.STORE_DISPATCH:
                        store.dispatch(event.data.action, {
                            ...event.data.payload,
                            broadcast: true,
                        })
                        break;
                    case BROADCAST_EVENT_TYPES.STORE_COMMIT:
                        store.commit(event.data.action, {
                            ...event.data.payload,
                            broadcast: true,
                        });
                        break;
                    default:
                        console.warn(`Unknown event type received from BroadcastChannel. (${event.data.type})`, event);
                }
            };
            ctx.$broadcast = channel;
            inject('broadcast', channel);
        } else {
            console.error(`Store is not available in the context. Make sure you've added the plugin after the store.`);
        }
    }
}