var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c(_setup.tag,{tag:"component",staticClass:"base-menu-item d-flex align-items-stretch justify-content-between",class:[{ 
        [`${_setup.theme}`]: !!_setup.theme,
        'nested': !!_setup.nested,
        'mobile-font': !!_setup.mobileFont,
        'desktop-font': !!_setup.desktopFont,
        'responsive-font': !_setup.mobileFont && !_setup.desktopFont && !!_setup.responsiveFont,
    }]},[_vm._t("default")],2)
}
var staticRenderFns = []

export { render, staticRenderFns }