import { IMAGE_STATUS_TYPES } from "../utils/Constants";

export class GalleryImage {
    constructor({id, url, status, uploaded_at}) {
        this.id = id;
        this.url = url;
        this.status = status;
        this.uploaded_at = uploaded_at;
    }

    getFolder() {
        switch (this.status) {
            case IMAGE_STATUS_TYPES.APPROVED:
                return 'approved';
            case IMAGE_STATUS_TYPES.PENDING:
                return 'pending';
            default:
                throw new Error(`Option ${this.status} doesn't exists`);
        }
    }
}