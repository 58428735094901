import moment from "moment";

export function getLastDayOfMonth() {
    return moment().utcOffset(4 * 60).endOf('month').set({
        hour: 23,
        minute: 59,
        second: 59,
        millisecond: 999,
    }).valueOf();
}
